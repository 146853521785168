import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'helper',
  initialState: {
    active: false,
    history: false,
    positions: [],
    adminData: {
      current: [],
      filter: [],
      orderBy: [],
      default: [],
      total: 0,
      limit: 0,
    },
    adminFilterData: [],
    tableAdminData: [],
    activeAllTable: false,
    activeAllTarget: false,
    targetSelect: [],
    targetDetail: {},
    groupEditData: {
      select: {},
      current: {},
    },
    notification: {
      title: '',
      mainContent: '',
      userIds: [],
    },
    loading: false,
    selectedUser: [],
    selectedDevice: [],
    currentPage: 1,
    filterType: '01',
    pathname: '',
    userData: [],
    members: [],
    allData: [],
    limitData: [],
    checkAll: false,
    checkedList: [],
    changePasswordUser: {},
    dataEditDevice: {},
    dataDateTimeSheetLog: '',
  },

  reducers: {
    active(state) {
      state.active = true;
    },
    unActive(state) {
      state.active = false;
    },
    openHistory(state) {
      state.history = true;
    },
    closeHistory(state) {
      state.history = false;
    },
    getPositions(state, action) {
      state.positions = action.payload;
    },
    getUserData(state, action) {
      state.userData = action.payload;
    },
    getMembers(state, action) {
      state.members = action.payload;
    },
    currentPage(state, actions) {
      state.currentPage = actions.payload;
    },
    setAdminData(state, actions) {
      state.adminData = actions.payload;
    },
    setadminFilterData(state, actions) {
      state.adminFilterData = actions.payload;
    },
    changeActiveTable(state, actions) {
      state.activeAllTable = actions.payload;
    },
    changeActiveTarget(state, actions) {
      state.activeAllTarget = actions.payload;
    },
    pushData(state, actions) {
      state.tableAdminData.push(actions.payload);
    },
    removeData(state, actions) {
      const newData = state.selectedUser.filter((data) => data?.id !== actions.payload?.id);
      state.tableAdminData = newData;
    },
    pushAllData(state, actions) {
      state.selectedUser = actions.payload;
    },
    pushAllDeviceData(state, actions) {
      state.selectedDevice = actions.payload;
    },
    pushDeviceEditData(state, actions) {
      state.dataEditDevice = actions.payload;
    },
    pushDateTimesheetLog(state, actions) {
      state.dataDateTimeSheetLog = actions.payload;
    },
    removeAllData(state, actions) {
      state.selectedUser = [];
    },
    updateData(state, actions) {
      state.selectedUser.forEach((data, index) => {
        if (data?.id === actions.payload.id) state.selectedUser[index] = actions.payload;
      });
    },
    updateDeviceData(state, actions) {
      state.selectedDevice.forEach((data, index) => {
        if (data?.id === actions.payload.id) state.selectedDevice[index] = actions.payload;
      });
    },
    pushTarget(state, actions) {
      const cond = state.targetSelect.find((e) => {
        if (e.main === actions.payload.main) {
          const filterGroup = e.groups.find((data) => {
            if (data.groupId === actions.payload.groupId) {
              data.target.push(actions.payload.target);
              return true;
            }
            return false;
          });
          if (!filterGroup) e.groups.push({ target: [actions.payload.target], groupId: actions.payload.groupId });
          return true;
        }
        return false;
      });
      // if (!cond) state.targetSelect.push({ main: actions.payload.main, target: [actions.payload.target], groupId: actions.payload.groupId });
      if (!cond) state.targetSelect.push({ main: actions.payload.main, groups: [{ target: [actions.payload.target], groupId: actions.payload.groupId }] });
    },
    removeTarget(state, actions) {
      state.targetSelect.forEach((data, i) => {
        if (data.main === actions.payload.main) {
          data.groups.forEach((group, groupIndex) => {
            if (group.groupId === actions.payload.groupId) {
              const targetFilter = group.target.filter((e) => e.id !== actions.payload.target.id);
              state.targetSelect[i].groups[groupIndex] = { target: targetFilter, groupId: actions.payload.groupId };
              if (targetFilter.length === 0) state.targetSelect[i].groups.splice(groupIndex, 1);
            }
          });
        }
        if (data.groups.length === 0) state.targetSelect.splice(i, 1);
      });
    },
    addTargetDetail(state, actions) {
      state.targetDetail = actions.payload;
    },
    pushAllTarget(state, actions) {
      state.targetSelect = actions.payload;
    },
    removeAllTarget(state, actions) {
      state.targetSelect = [];
    },
    setGroupEditData(state, actions) {
      state.groupEditData.select = actions.payload.select;
      state.groupEditData.current = actions.payload.current;
    },
    setNotification(state, actions) {
      state.notification = actions.payload;
    },
    setLoading(state, actions) {
      state.loading = actions.payload;
    },
    selectedUser(state, actions) {
      state.selectedUser = actions.payload;
    },
    selectedDevice(state, actions) {
      state.selectedDevice = actions.payload;
    },
    clearSelectedUser(state, actions) {
      state.selectedUser = [];
    },
    clearSelectedDevice(state, actions) {
      state.selectedUser = [];
    },
    setFilterType(state, actions) {
      state.filterType = actions.payload;
    },
    setPathname(state, action) {
      state.pathname = action.payload;
    },
    getAllData(state, actions) {
      state.allData = actions.payload;
    },
    setLimitData(state, actions) {
      state.limitData = actions.payload;
    },
    setCheckAll(state, actions) {
      state.checkAll = actions.payload;
    },
    getCheckedList(state, actions) {
      state.checkedList = actions.payload;
    },
    getChangePasswordUser(state, actions) {
      state.changePasswordUser = actions.payload;
    },
  },
});

export { actions as helperActions };
export { reducer as helperReducer };
