/* eslint-disable no-plusplus */
/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Alert, Backdrop, CircularProgress, LinearProgress, Snackbar, useMediaQuery,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
// eslint-disable-next-line import/no-extraneous-dependencies
import theme from './common/theme';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
// import BottomMenu from './common/components/BottomMenu';
import CachingController from './CachingController';

import { useEffectAsync } from './reactHelper'; // v5.6
import store, {
  clientAlarmsActions, deviceTypeActions, sessionActions, setMessage, setMessageSuccess, setPermission,
} from './store'; // v5.6
import AnpiLogo from './resources/images/Anpilogo.png';
import { selectMessageError } from './store/messageError';
import { selectLockScreen } from './store/lockScreen';
import baseRequest from './apis/baseRequest';
import uris from './apis/uris';
import { encrypted } from './payment/components/utils';
import { selectMessageSuccess } from './store/messageSuccess';

const useStyles = makeStyles(() => ({
  page: {
    flexGrow: 1,
    overflow: 'auto',
  },
  menu: {
    zIndex: 1204,
  },
  alert: {
    backgroundColor: '#2C2C2C',
    color: '#FFFFFF',
  },
  logo: {
    maxHeight: '30px',
    width: '30px',
  },
}));

const App = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));

  const newServer = useSelector((state) => state.session.server.newServer);
  const initialized = useSelector((state) => !!state.session.user);
  const errorMessage = useSelector(selectMessageError);
  const successMessage = useSelector(selectMessageSuccess);
  const lockScreenChecked = useSelector(selectLockScreen);

  useEffect(() => {
    if (location.pathname === '/') {
      navigate('/admin');
    }
  }, [location.pathname]);

  const clientAlarms = useSelector((state) => state.clientAlarms.active);

  useEffectAsync(async () => {
    if (!initialized) {
      const response = await fetch('/api/session');
      if (response.ok) {
        const user = await response.json();
        dispatch(sessionActions.updateUser(user));
        await baseRequest.get(`${uris.GET_PERMISSION_BY_USER}/${user.id}`).then((res) => {
          // console.log(res?.data || []);
          window.localStorage.setItem('permissions', encrypted(JSON.stringify(res?.data || [])));
        });
      } else if (newServer) {
        navigate('/register');
      } else {
        navigate('/login');
      }
    }
    const elements = document.getElementsByClassName('maplibregl-popup');
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < elements.length; i++) {
      elements[i].style.display = 'none';
    }
    return null;
  }, [initialized, location]);

  useEffect(() => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      dispatch(deviceTypeActions.set('mobile'));
    } else {
      dispatch(deviceTypeActions.set('computer'));
    }
  // eslint-disable-next-line no-undef
  }, [navigator.userAgent]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(setMessage(null));
    dispatch(setMessageSuccess(null));
  };

  return !initialized ? (<LinearProgress />) : (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={lockScreenChecked}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={errorMessage}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error" variant="filled">
          {errorMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={successMessage}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" variant="filled">
          {successMessage}
        </Alert>
      </Snackbar>
      {/* <SocketController /> */}
      <CachingController />
      {clientAlarms && (
      <Alert
        icon={<img src={AnpiLogo} alt="warning" className={classes.logo} />}
        className={classes.alert}
        onClick={() => {
          dispatch(clientAlarmsActions.off());
          navigate('/clientalarm/safetyconfirm');
        }}
        style={{ position: 'fixed', zIndex: 9999, width: '100%', bottom: '0px', display: 'flex' }}
        severity="info"
      >
        Anpy【防災/子供老人みまもり】 安否確認が届いています。回答してください。
      </Alert>
      )}
      <div className={classes.page}>
        <Outlet />
      </div>
      {!desktop && (
        <div className={classes.menu}>
          {/* <BottomMenu /> */}
        </div>
      )}
    </>
  );
};

export default App;
