import {
  useCallback,
  useEffect, useId,
} from 'react';
import { map } from './core/MapView';
import { findFonts } from './core/mapUtil';
import canvasMapInfo from '../common/util/canvasMapInfo';
import { useEffectAsync } from '../reactHelper';

const MapPositionInfo = ({ dataMapInfo, onClick }) => {
  const id = 'map-info';
  const clusters = `${id}-cluster`;

  const onMouseEnter = () => map.getCanvas().style.cursor = 'pointer';
  const onMouseLeave = () => map.getCanvas().style.cursor = '';

  const onMarkerClick = ((event) => {
    event.preventDefault();
    const feature = event.features[0]?.properties;
    map.flyTo({
      center: [feature?.lng, feature?.lat],
      offset: [0, 0],
      zoom: 18,
      essential: true,
    });
    onClick(feature?.id);
  });

  const onClusterClick = useCallback((event) => {
    event.preventDefault();
    const features = map.queryRenderedFeatures(event.point, {
      layers: [clusters],
    });
    const clusterId = features[0].properties.cluster_id;
    map.getSource(id).getClusterExpansionZoom(clusterId, (error, zoom) => {
      if (!error) {
        map.easeTo({
          center: features[0].geometry.coordinates,
          zoom,
        });
      }
    });
  }, [clusters]);

  const onMapClick = useCallback((event) => {
    if (!event.defaultPrevented && onClick) {
      onClick();
    }
  }, [onClick]);

  useEffect(() => {
    map.getLayer(id);
    map.addSource(id, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      },
      cluster: true,
      clusterMaxZoom: 14,
      clusterRadius: 30,
    });

    map.addLayer({
      id,
      type: 'symbol',
      source: id,
      filter: ['!has', 'point_count'],
      layout: {
        'icon-image': ['get', 'icon'],
        'icon-size': 1,
        'icon-allow-overlap': true,
        'icon-ignore-placement': true,
      },
    });
    map.addLayer({
      id: clusters,
      type: 'symbol',
      source: id,
      filter: ['has', 'point_count'],
      layout: {
        'icon-image': 'mapInfo',
        'icon-size': 1,
        'icon-offset': [0, -50],
        'text-field': '{point_count_abbreviated}',
        'text-allow-overlap': true,
        'icon-allow-overlap': true,
        'text-offset': [0, -4.5],
        'text-font': findFonts(map),
        'text-size': 14,
      },
    });

    map.on('mouseenter', id, onMouseEnter);
    map.on('mouseleave', id, onMouseLeave);
    map.on('mouseenter', clusters, onMouseEnter);
    map.on('mouseleave', clusters, onMouseLeave);
    map.on('click', id, onMarkerClick);
    map.on('click', clusters, onClusterClick);
    map.on('click', onMapClick);

    return () => {
      map.off('mouseenter', id, onMouseEnter);
      map.off('mouseleave', id, onMouseLeave);
      map.off('mouseenter', clusters, onMouseEnter);
      map.off('mouseleave', clusters, onMouseLeave);
      map.off('click', id, onMarkerClick);
      map.off('click', clusters, onClusterClick);
      map.off('click', onMapClick);
      if (map.getLayer(id)) {
        map.removeLayer(id);
      }
      if (map.getLayer(clusters)) {
        map.removeLayer(clusters);
      }
      if (map.getSource(id)) {
        map.removeSource(id);
      }
    };
  }, [map]);

  useEffect(() => {
    map.on('styledata', () => {
      const { layers } = map.getStyle();
      const clusterLayers = layers.filter((layer) => layer.id.includes('clusters'));
      if (clusterLayers.length > 0 && map.getLayer(id)) {
        map.moveLayer(clusters, clusterLayers[0].id);
        map.moveLayer(id, clusterLayers[0].source);
      }
    });
  }, []);

  useEffectAsync(async () => {
    if (dataMapInfo) {
      await canvasMapInfo(dataMapInfo, id);
    }
  }, [dataMapInfo]);

  return null;
};

export default MapPositionInfo;
