export default [
  {
    role: 'device',
    label: 'sharedDevice',
    type: 'screen',
    permissionGroup: 'functional',
    attribute: 0,
    children: [
      {
        role: 'device.export',
        label: 'reportExport',
        type: 'button',
        permissionGroup: 'subFunctional',
        attribute: 0,
      },
      {
        role: 'device.edit',
        label: 'sharedEdit',
        type: 'button',
        permissionGroup: 'subFunctional',
        attribute: 0,
      },
      {
        role: 'device.delete',
        label: 'sharedDelete',
        type: 'button',
        permissionGroup: 'subFunctional',
        attribute: 0,
      },
      {
        role: 'device.add',
        label: 'sharedAdd',
        type: 'button',
        permissionGroup: 'subFunctional',
        attribute: 0,
      },
      {
        role: 'device.import',
        label: 'sharedImport',
        type: 'button',
        permissionGroup: 'subFunctional',
        attribute: 0,
      },
      {
        role: 'device.viewHistory',
        label: 'viewHistory',
        type: 'button',
        permissionGroup: 'subFunctional',
        attribute: 0,
      },
    ],
  },
  {
    role: 'timeSheet',
    label: 'linkListTimeSheet',
    type: 'screen',
    permissionGroup: 'functional',
    attribute: 0,
    children: [
      {
        role: 'timeSheet.areaSetting',
        label: 'linkListCreateArea',
        type: 'screen',
        permissionGroup: 'subFunctional',
        attribute: 0,
        children: [
          {
            role: 'timeSheet.areaSetting.edit',
            label: 'sharedEdit',
            type: 'button',
            permissionGroup: 'subFunctional',
            attribute: 0,
          },
          {
            role: 'timeSheet.areaSetting.createArea',
            type: 'button',
            label: 'sharedCreateArea',
            permissionGroup: 'subFunctional',
            attribute: 0,
          },
        ],
      },
      {
        role: 'timeSheet.export',
        type: 'button',
        label: 'reportExport',
        permissionGroup: 'subFunctional',
        attribute: 0,
      },
    ],
  },
  {
    role: 'tools',
    type: 'screen',
    label: 'linkListTool',
    permissionGroup: 'functional',
    attribute: 0,
    children: [
      {
        role: 'tools.areaSetting',
        label: 'linkListCreateArea',
        type: 'screen',
        permissionGroup: 'subFunctional',
        attribute: 0,
        children: [
          {
            role: 'tools.areaSetting.edit',
            label: 'sharedEdit',
            type: 'button',
            permissionGroup: 'subFunctional',
            attribute: 0,
          },
          {
            role: 'tools.areaSetting.createArea',
            type: 'button',
            label: 'sharedCreateArea',
            permissionGroup: 'subFunctional',
            attribute: 0,
          },
        ],
      },
      {
        role: 'tools.settingAccount',
        label: 'linkListSettingAccount',
        type: 'screen',
        permissionGroup: 'subFunctional',
        attribute: 0,
      },
      {
        role: 'tools.reset',
        label: 'linkListReset',
        type: 'screen',
        permissionGroup: 'subFunctional',
        attribute: 0,
      },
      {
        role: 'tools.sendNotifications',
        label: 'linkListSendNotifications',
        type: 'screen',
        permissionGroup: 'subFunctional',
        attribute: 0,
      },
      {
        role: 'tools.changePassword',
        label: 'linkListChangePassword',
        type: 'screen',
        permissionGroup: 'subFunctional',
        attribute: 0,
      },
      {
        role: 'tools.movingAdministrativeAccounts',
        label: 'linkListMovingAccount',
        type: 'screen',
        permissionGroup: 'subFunctional',
        attribute: 0,
      },
      {
        role: 'tools.suspensionOrResume',
        label: 'linkListSuspensionOrResume',
        type: 'screen',
        permissionGroup: 'subFunctional',
        attribute: 0,
        children: [
          {
            role: 'tools.suspensionOrResume.continueUsing',
            type: 'button',
            label: 'continueUsing',
            permissionGroup: 'subFunctional',
            attribute: 0,
          },
          {
            role: 'tools.suspensionOrResume.pauseUsing',
            label: 'pauseUsing',
            type: 'button',
            permissionGroup: 'subFunctional',
            attribute: 0,
          },
        ],
      },
      {
        role: 'tools.generateRepresentAccount',
        type: 'screen',
        label: 'linkListRepresentAccount',
        permissionGroup: 'subFunctional',
        attribute: 0,
      },
      {
        role: 'tools.mapInformation',
        label: 'mapInfo',
        type: 'screen',
        permissionGroup: 'subFunctional',
        attribute: 0,
      },
    ],
  },
  {
    role: 'home',
    type: 'screen',
    label: 'homepage',
    permissionGroup: 'functional',
    attribute: 0,
    children: [
      {
        role: 'home.csv',
        type: 'button',
        label: 'csvButton',
        permissionGroup: 'subFunctional',
        attribute: 0,
      },
      {
        role: 'home.edit',
        label: 'sharedEdit',
        type: 'button',
        permissionGroup: 'subFunctional',
        attribute: 0,
      },
      {
        role: 'home.delete',
        label: 'sharedDelete',
        type: 'button',
        permissionGroup: 'subFunctional',
        attribute: 0,
      },
      {
        role: 'home.detail',
        label: 'detailTableHeader',
        type: 'button',
        permissionGroup: 'subFunctional',
        attribute: 0,
      },
    ],
  },
  {
    role: 'settings',
    label: 'settings',
    type: 'screen',
    permissionGroup: 'functional',
    attribute: 0,
    children: [
      {
        role: 'settings.adminAccounts',
        label: 'adminAccount',
        type: 'screen',
        permissionGroup: 'subFunctional',
        attribute: 0,
        children: [
          {
            role: 'settings.adminAccounts.csv',
            type: 'button',
            label: 'csvButton',
            permissionGroup: 'subFunctional',
            attribute: 0,
          },
          {
            role: 'settings.adminAccounts.create',
            label: 'create',
            type: 'button',
            permissionGroup: 'subFunctional',
            attribute: 0,
          },
          {
            role: 'settings.adminAccounts.edit',
            type: 'button',
            label: 'sharedEdit',

            permissionGroup: 'subFunctional',
            attribute: 0,
          },
          {
            role: 'settings.adminAccounts.delete',
            type: 'button',
            label: 'sharedDelete',

            permissionGroup: 'subFunctional',
            attribute: 0,
          },
          {
            role: 'settings.adminAccounts.disable',
            type: 'button',
            label: 'disable',
            permissionGroup: 'subFunctional',
            attribute: 0,
          },
          {
            role: 'settings.adminAccounts.enable',
            type: 'button',
            label: 'enable',

            permissionGroup: 'subFunctional',
            attribute: 0,
          },
        ],
      },
      {
        role: 'settings.roles',
        label: 'roles',
        type: 'screen',
        permissionGroup: 'subFunctional',
        attribute: 0,
        children: [
          {
            role: 'settings.roles.csv',
            label: 'csvButton',
            type: 'button',
            permissionGroup: 'subFunctional',
            attribute: 0,
          },
          {
            role: 'settings.roles.create',
            label: 'create',
            type: 'button',
            permissionGroup: 'subFunctional',
            attribute: 0,
          },
          {
            role: 'settings.roles.edit',
            label: 'sharedEdit',
            type: 'button',
            permissionGroup: 'subFunctional',
            attribute: 0,
          },
          {
            role: 'settings.roles.delete',
            label: 'sharedDelete',
            type: 'button',
            permissionGroup: 'subFunctional',
            attribute: 0,
          },
          {
            role: 'settings.roles.disable',
            label: 'disable',
            type: 'button',
            permissionGroup: 'subFunctional',
            attribute: 0,
          },
          {
            role: 'settings.roles.enable',
            label: 'enable',

            type: 'button',
            permissionGroup: 'subFunctional',
            attribute: 0,
          },
        ],
      },
      {
        role: 'settings.networkSetting',
        type: 'screen',
        permissionGroup: 'subFunctional',
        label: 'networkSetting',
        remarks: '',
        attribute: 0,
        children: [
          {
            role: 'settings.networkSetting.new',
            type: 'button',
            label: 'new',
            permissionGroup: 'subFunctional',
            attribute: 0,
          },
          {
            role: 'settings.networkSetting.edit',
            type: 'button',
            label: 'sharedEdit',
            permissionGroup: 'subFunctional',
            attribute: 0,
          },
          {
            role: 'settings.networkSetting.delete',
            type: 'button',
            label: 'sharedDelete',
            permissionGroup: 'subFunctional',
            attribute: 0,
          },
          {
            role: 'settings.networkSetting.import',
            type: 'button',
            label: 'sharedImport',

            permissionGroup: 'subFunctional',
            attribute: 0,
          },
          {
            role: 'settings.networkSetting.export',
            type: 'button',
            label: 'reportExport',
            permissionGroup: 'subFunctional',
            attribute: 0,
          },
        ],
      },
      {
        role: 'settings.policySetting',
        label: 'policySetting',
        type: 'screen',
        permissionGroup: 'subFunctional',
        attribute: 0,
      },
    ],
  },
];
