/* eslint-disable no-undef */
import React, {
  useRef, useState, useCallback, useEffect,
} from 'react';
import {
  Button, Modal, Typography, Box,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import LaunchIcon from '@mui/icons-material/Launch';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import moment from 'moment';
import StatiticUsers from './components/StatiticUsers';
import BreadcrumbsCustom from '../components/BreadcrumbsCustom';
import { useTranslation } from '../../common/components/LocalizationProvider';
import NewTable from '../components/NewTable';
import {
  valueTranslate, getUserData, getUserIds,
} from '../common/utils';
import { Member } from '../common/DefineClass';
import { helperActions } from '../../store';
import fetchApi from '../FetchApi';
import configColorLayout from '../../settings/ConfigColorLayout';
import hasPermission from '../common/hasPermission';

const userModel = new Member();

const useStyle = makeStyles((theme) => ({
  cardContainer: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-evenly',
    },
  },
  accountDefault: {
    backgroundColor: configColorLayout,
    color: 'white',
    margin: '1rem 3rem 1rem 0',
    width: '200px',
    [theme.breakpoints.down('sm')]: {
      width: '130px',
    },
  },
  accountSpecial: {
    backgroundColor: '#D0F000',
    color: 'black',
    margin: '1rem 0',
    width: '200px',
    [theme.breakpoints.down('sm')]: {
      width: '130px',
    },
  },
  csvBtn: {
    display: 'inline-flex',
    color: 'black',
    textDecoration: 'none',
    backgroundColor: '#e0e0e0',
    width: '90px',
    height: '40px',
    borderRadius: '5px',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    marginRight: '1rem',
    '&:hover': {
      backgroundColor: '#e0e0e0',
    },
  },
  listBtn: {
    display: 'inline-flex',
    margin: '2rem 0 1rem 0',
  },
  configTable: {
    height: `${window.innerHeight - 200}px`,
  },
  submitBtn: {
    width: '120px',
    border: 'outset 5px red',
    backgroundColor: 'red',
    '&:hover': {
      backgroundColor: 'red',
    },
  },
  closeBtn: {
    width: '120px',
    border: 'outset 5px grey',
    backgroundColor: 'grey',
    '&:hover': {
      backgroundColor: 'grey',
    },
  },
  modalTitle: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const ListTable = () => {
  const navigate = useNavigate();
  const classes = useStyle();
  const tableRef = useRef();
  const t = useTranslation();
  const dispatch = useDispatch();

  const [gridApi, getGridApi] = useState(null);
  const [dataSelect, setDataSelect] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const checkedList = useSelector((state) => state.helper.checkedList);

  const data = getUserData(checkedList, dataSelect);
  const userIds = getUserIds(checkedList, dataSelect);

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleDeleteUser = async () => {
    const response = await fetchApi('/api/admin/users', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userIds }),
    });
    if (response.ok) {
      navigate('/admin/finish');
    } else {
      console.log('not ok');
    }
  };

  const onBtnExport = useCallback(() => {
    const columnDefs = tableRef.current.api.getColumnDefs();
    const columnKeys = columnDefs.slice(2).map((columnDef) => columnDef.colId);

    const params = {
      columnKeys,
    };

    const categoryColumnConfig = {
      field: userModel.field2,
      valueFormatter: (params) => valueTranslate(t, params.value, 'account'),
    };

    const paymentStatusColumnConfig = {
      field: userModel.field3,
      valueFormatter: (params) => valueTranslate(t, params.value, 'paymentStatus'),
    };

    const useStColumnConfig = {
      field: userModel.field6,
      valueFormatter: (params) => valueTranslate(t, params.value, 'account'),
    };

    const billingMethodConfig = {
      field: userModel.field11,
      valueFormatter: (params) => valueTranslate(t, params.value, 'billingMethod'),
    };

    const useStartDateColumnConfig = {
      field: userModel.field12,
      valueFormatter: (params) => moment(params.value).format('YYYY/MM/DD'),
    };

    const paymentMethodColumnConfig = {
      field: userModel.field19,
      valueFormatter: (params) => valueTranslate(t, params.value, 'paymentMethod'),
    };

    const providerTypeConfig = {
      field: userModel.field23,
      valueFormatter: (params) => valueTranslate(t, params.value, 'type'),
    };

    // Gọi hàm chuyển đổi giá trị tương ứng với từng cột trong processCellCallback
    tableRef.current.api.exportDataAsCsv({
      processCellCallback: (params) => {
        const column = params.column.getColDef();

        switch (column.field) {
          case userModel.field2:
            return categoryColumnConfig.valueFormatter(params);
          case userModel.field3:
            return paymentStatusColumnConfig.valueFormatter(params);
          case userModel.field6:
            return useStColumnConfig.valueFormatter(params);
          case userModel.field9:
            return params.node.data.representationId || params.node.data.managedUserId;
          case userModel.field11:
            return billingMethodConfig.valueFormatter(params);
          case userModel.field12:
            return useStartDateColumnConfig.valueFormatter(params);
          case userModel.field19:
            return paymentMethodColumnConfig.valueFormatter(params);
          case userModel.field23:
            return providerTypeConfig.valueFormatter(params);
          default:
            return params.value;
        }
      },
      onlySelected: true,
      fileName: 'TREMIL-export.csv',
      rowData: data,
      ...params,
    });
  }, []);

  useEffect(() => {
    dispatch(helperActions.selectedUser(data));
  }, [checkedList, dataSelect]);

  useEffect(() => {
    if (checkedList.length === 0) {
      setDataSelect([]);
    }
  }, [checkedList]);

  const handleSelectionChange = () => {
    setDataSelect(gridApi?.getSelectedRows() || []);
  };

  const handleRowDataUpdated = () => {
    gridApi?.forEachNode((node) => {
      if (checkedList.length !== 0) {
        node.setSelected(true);
      }
    });
  };

  const dataTable = {
    getGridApi,
    tableRef,
    floatingFilter: true,
    showPagination: true,
    className: classes.configTable,
    onSelectionChanged: handleSelectionChange,
    onRowDataUpdated: handleRowDataUpdated,
    childrenTop: (
      <div>
        <BreadcrumbsCustom getAfter="admin" />
        <div style={{ width: '500px' }}>
          <StatiticUsers />
          {/* <AdminCard className={classes.accountDefault} normal />
          <AdminCard className={classes.accountSpecial} /> */}
        </div>
        <div className={classes.listBtn}>
          {/* <CSVLink
            data={checkedList}
            filename="Tremeal-file.csv"
            style={{ cursor: checkedList.length === 0 ? 'default' : 'pointer' }}
            className={classes.csvBtn}
            onClick={(e) => (checkedList.length !== 0)}
          >
            <InsertDriveFileIcon style={{ paddingRight: '5px', fontSize: '30px', opacity: checkedList.length === 0 ? 0.3 : 1 }} />
            <div style={{ opacity: checkedList.length === 0 ? 0.3 : 1 }}>CSV</div>
          </CSVLink> */}
          {hasPermission('home.csv') && (
          <Button
            onClick={onBtnExport}
            className={classes.csvBtn}
            disabled={!(checkedList.length > 0 || dataSelect.length > 0)}
          >
            <InsertDriveFileIcon style={{ paddingRight: '5px', fontSize: '30px' }} />
            <div>CSV</div>
          </Button>
          )}
          {hasPermission('home.edit') && (
          <Button
            onClick={() => navigate('edit')}
            className={classes.csvBtn}
            disabled={!(checkedList.length > 0 || dataSelect.length > 0)}
          >
            <LaunchIcon style={{ paddingRight: '5px', fontSize: '30px' }} />
            <div>{t('sharedEdit')}</div>
          </Button>
          )}
          {hasPermission('home.delete') && (
          <Button
            onClick={() => setOpenModal(true)}
            className={classes.csvBtn}
            disabled={!(checkedList.length > 0 || dataSelect.length > 0)}
          >
            <DeleteOutlineOutlinedIcon style={{ paddingRight: '5px', fontSize: '30px' }} />
            <div>{t('sharedDelete')}</div>
          </Button>
          )}
        </div>
      </div>
    ),
  };
  return (
    <>
      <NewTable {...dataTable} />
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 500,
          height: 200,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}
        >
          <div>
            <Typography className={classes.modalTitle}>{t('confirmDelete')}</Typography>
            <br />
            <Typography className={classes.modalTitle}>{t('confirmEdit2')}</Typography>
            <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '30px' }}>
              <Button
                className={classes.closeBtn}
                variant="contained"
                sx={{ mt: 1, mb: 2 }}
                onClick={handleClose}
              >
                {t('sharedCancel')}
              </Button>
              <Button
                className={classes.submitBtn}
                variant="contained"
                onClick={() => {
                  handleDeleteUser();
                  setOpenModal(false);
                }}
                sx={{ mt: 1, mb: 2 }}
              >
                {t('perform')}
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default ListTable;
