import React, {
  forwardRef, useState, useRef, useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select, MenuItem } from '@mui/material';
import { helperActions } from '../../../../store';
import baseRequest from '../../../../apis/baseRequest';
import uris from '../../../../apis/uris';

const CustomSelectComponent = forwardRef((props, ref) => {
  const { field, options } = props.column.colDef; // Assuming options is passed as props
  const dispatch = useDispatch();

  const [selectedOption, setSelectedOption] = useState('');
  const [listCategory, setListCategory] = useState([]);
  const refSelect = useRef();
  const refInput = useRef();

  const adminData = useSelector((state) => state.helper.adminData);

  const handleFilterSelect = (value) => {
    const copyData = { ...adminData };
    const filter = [...copyData.filter];
    const existingFilterIndex = filter.findIndex((filterField) => filterField.name === field);
    if (existingFilterIndex === -1) {
      filter.push({ name: field, type: '05', value });
      copyData.filter = filter;
      dispatch(helperActions.setAdminData(copyData));
    } else {
      const copyFilter = [...filter];
      copyFilter[existingFilterIndex] = { ...filter[existingFilterIndex], value };
      dispatch(helperActions.setAdminData({ ...copyData, filter: copyFilter }));
    }
  };

  const fetchData = () => {
    baseRequest.get(uris.LIST_CATEGORY).then((response) => {
      setListCategory(response?.data);
    });
  };

  const onSelectChanged = (event) => {
    const { value } = event.target;
    setSelectedOption(value);
    handleFilterSelect(value);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div ref={refInput} style={{ width: '100%' }}>
      <Select
        className="map-select-filter"
        ref={refSelect}
        value={selectedOption}
        onChange={onSelectChanged}
        MenuProps={{ sx: { height: '300px' } }}
        displayEmpty
      >
        {listCategory?.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            <img
              style={{ marginRight: 5, filter: option?.iconColor }}
              className="map-category-img"
              src={option?.pathIcon}
              alt={option?.name}
              width={20}
              height={20}
            />
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
});

export default CustomSelectComponent;
