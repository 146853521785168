/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect, useRef } from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  indeterminateCheckbox: {
    '& input[type="checkbox"]': {
      position: 'absolute',
      opacity: 0,
      cursor: 'pointer',
    },
    '& span': {
      display: 'inline-block',
      verticalAlign: 'middle',
      width: 16,
      height: 16,
      borderRadius: 3,
      border: '1px solid #ccc',
      backgroundColor: '#fff',
      transition: 'border-color 0.3s, background-color 0.3s',
    },
    '& span:after': {
      content: '""',
      display: 'block',
      width: 6,
      height: 6,
      backgroundColor: '#14d173',
      borderRadius: '50%',
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      opacity: 0,
      transition: 'opacity 0.3s',
    },
    '& input[type="checkbox"]:checked + span:after': {
      opacity: 1,
    },
    '& input[type="checkbox"]:indeterminate + span': {
      borderColor: '#14d173',
      backgroundColor: '#14d173',
    },
    '& input[type="checkbox"]:indeterminate + span:after': {
      display: 'none',
    },
  },
});

const updateInput = (ref, checked) => {
  const input = ref.current;
  if (input) {
    input.checked = checked;
    input.indeterminate = checked == null;
  }
};

const ThreeStateCheckbox = ({ name, checked, onChange }) => {
  const classes = useStyles();
  const inputRef = useRef(null);
  const checkedRef = useRef(checked);
  useEffect(() => {
    checkedRef.current = checked;
    updateInput(inputRef, checked);
  }, [checked]);

  const handleClick = () => {
    switch (checkedRef.current) {
      case true:
        checkedRef.current = false;
        break;
      case false:
        checkedRef.current = null;
        break;
      default: // null
        checkedRef.current = true;
        break;
    }
    updateInput(inputRef, checkedRef.current);

    if (onChange) {
      onChange(checkedRef.current);
    }
  };

  const inputStyle = {
    height: '16px',
    width: '16px',
    margin: '0px',
    backgroundColor: checked === true ? '#14d173' : 'white',
  };

  return (
    <input
      ref={inputRef}
      type="checkbox"
      name={name}
      onClick={handleClick}
      style={inputStyle}
    />
  );
};

const CheckBoxAll = ({ setCheck }) => {
  const [checked, setChecked] = useState(false);

  const handleChange = (checked) => {
    if (checked === true) {
      setCheck(checked);
    } else if (checked === null) {
      setCheck(null);
    } else {
      setCheck(false);
    }
  };

  return (
    <ThreeStateCheckbox checked={checked} onChange={handleChange} />
  );
};
export default CheckBoxAll;
