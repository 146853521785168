/* eslint-disable react/no-unstable-nested-components */
import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Pagination, TablePagination } from '@mui/material';
import { helperActions } from '../../store';
import Table from './Table';
import { useTranslation } from '../../common/components/LocalizationProvider';
import FloatingFilterComponent from './FloatingFilterComponent';
import {
  Device,
} from '../common/DefineClass';
import { convertWidth } from '../common/utils';
import CheckBoxAll from './CheckBoxAll';
import DatePicker from './DatePicker';
import baseRequest from '../../apis/baseRequest';
import '../styles/components/NewDeviceTable.css';
import uris from '../../apis/uris';
import DateRangePicker from './DateRangePicker';
import { formatDateUTCToLocal } from '../common/handleTimeZone';
import hasPermission from '../common/hasPermission';

const NewDeviceTable = (props) => {
  const {
    removeAllColumn,
    removeColumn,
    listData,
    editable,
    editGroup,
    changePasswordById,
    changeGroupById,
    newCustomeColumn,
    className,
    saveData,
    editStatus,
    showPagination,
    getElementRef,
    setGridApi,
    listChecked,
    isLoadDevice,
    ...others
  } = props;
  const mainRef = useRef();
  const dispatch = useDispatch();
  const t = useTranslation();
  const deviceModel = new Device();

  const filterData = useSelector((state) => state.helper.adminData.filter);
  const direct = useSelector((state) => state.helper.adminData.orderBy);
  const [data, setData] = useState([]);
  const [listNode, setListNode] = useState([]);

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(50);
  const [checked, setChecked] = useState(false);

  const count = Math.ceil(totalPages / limit);
  const detailButton = (data) => (hasPermission('device.edit') ? (
    <Link
      onClick={() => dispatch(helperActions.pushDeviceEditData(data))}
      underline="always"
      style={{ color: '#1e70c4' }}
      to={`/admin/devices/edit/${data.id}`}
    >
      Edit
    </Link>
  )
    : '');

  const redirectLocationButton = (id) => (hasPermission('device.viewHistory') ? <Link to={`details/history/${id}`}>View history</Link> : '');
  const dataConversion = (deviceData) => {
    deviceData.managerUserID = deviceData.managerUser?.id;
    return deviceData;
  };

  // Declare columns
  const columnDefs = [
    {
      width: convertWidth(4),
      field: 'isChecked',
      filter: false,
      cellStyle: { textAlign: 'center' },
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      headerComponent: () => <CheckBoxAll setCheck={setChecked} />,
    },
    {
      headerName: 'Edit',
      cellStyle: { textAlign: 'center' },
      width: convertWidth(5),
      cellRenderer: (params) => detailButton(params.data),
      field: deviceModel.edit,
      filter: false,
    },

    {
      headerName: 'ID',
      cellStyle: { textAlign: 'center' },
      width: convertWidth(6),
      field: deviceModel.id,
      floatingFilterComponent: FloatingFilterComponent,
      filter: false,
    },
    {
      headerName: `${t('deviceIDUpper')}/${t('imei')}`,
      field: deviceModel.uniqueId,
      width: convertWidth(20),
      floatingFilterComponent: FloatingFilterComponent,
      type: 'string',
    },
    {
      headerName: t('deviceName'),
      field: deviceModel.name,
      width: convertWidth(20),
      floatingFilterComponent: FloatingFilterComponent,
      type: 'string',
    },
    {
      headerName: t('managerUser'),
      field: deviceModel.managerUser,
      width: convertWidth(24),
      cellRenderer: (params) => `${params.data?.managerUser?.name} | ${params.data?.managerUser?.email}`,
      floatingFilterComponent: null,
      type: 'string',
    },
    {
      headerName: 'Manager User ID',
      field: deviceModel.managerUserID,
      width: convertWidth(24),
      hide: true,
      // cellRenderer: (params) => `${params.data?.managerUser?.id}`,
      floatingFilterComponent: FloatingFilterComponent,
      filter: false,
    },
    {
      headerName: t('registeredDate'),
      field: deviceModel.registeredDate,
      width: convertWidth(14),
      cellStyle: { textAlign: 'center' },
      type: 'date',
      cellRenderer: (params) => formatDateUTCToLocal(params?.data?.registeredDate, 'YYYY/MM/DD HH:mm:ss'),
      floatingFilterComponent: DateRangePicker,
      // floatingFilterComponentParams: { suppressFilterButton: true },
    },
    {
      headerName: t('locationHistory'),
      field: deviceModel.locationhistory,
      width: convertWidth(12.5),
      cellStyle: { textAlign: 'center' },
      cellRenderer: (params) => redirectLocationButton(params?.data?.id),
      floatingFilterComponent: FloatingFilterComponent,
      filter: false,
    },
  ];

  const components = useMemo(() => ({
    agDateInput: DatePicker,
  }), []);

  // Configuration for columns
  const configColumn = () => {
    const copyColumn = [...columnDefs];
    if (editable) {
      editable.forEach((number) => (copyColumn[number].editable = true));
    }
    if (removeAllColumn) {
      return [];
    }
    if (removeColumn) {
      removeColumn.forEach((number) => (copyColumn[number] = 'remove'));
      const newCol = copyColumn.filter((cols) => cols !== 'remove');
      return newCol;
    }
    return newCustomeColumn || columnDefs;
  };

  useEffect(() => {
    listNode.forEach((node) => {
      if (listChecked?.includes(node.data.id)) {
        node.setSelected(true);
      }
    });
  }, [listNode, listChecked]);

  const dataTable = {
    className,
    setListNode,
    rowSelection: 'multiple',
    rowMultiSelectWithClick: true,
    customColumnDefs: configColumn(),
    listData: listData || data,
    components,
    ...others,
  };

  const getData = (filter, limit, offset, direct, checked) => {
    let name = '';
    let deviceUid = '';
    for (let i = 0; i < filterData.length; i += 1) {
      if (filterData[i].name === deviceModel.name) {
        name = filterData[i].value;
      } else if (filterData[i].name === deviceModel.uniqueId) {
        deviceUid = filterData[i].value;
      }
    }
    baseRequest.post(uris.DEVICES_SEARCH, {
      userId: '',
      name,
      limit,
      deviceUid,
      offset,
      orderBy: direct.filter((data) => data.direct),
      filterBy: filter.filter((data) => data.value),
    }).then((response) => {
      setTotalPages(response?.total);
      setData(response?.data.map(dataConversion));
      if (checked === true) {
        dispatch(helperActions.getCheckedList(response?.data));
      } else if (checked === null) {
        dispatch(helperActions.getCheckedList(response?.data));
      } else {
        dispatch(helperActions.getCheckedList([]));
      }
    });
    if (!saveData) {
      dispatch(helperActions.removeAllData());
    }
  };

  // Function to change page
  const handlePageChange = (event, page) => {
    const offsetValue = page * limit - limit;
    setOffset(offsetValue);
    setCurrentPage(page);
    dispatch(helperActions.currentPage(page));
    setLimit(limit);
    setChecked(false);
    getData(filterData, limit, offsetValue, direct);
  };

  useEffect(() => {
    if (checked === true) {
      getData(filterData, 0, 0, direct, true);
    } else if (checked === null) {
      getData(filterData, limit, offset, direct, null);
    } else {
      getData(filterData, limit, offset, direct, false);
    }
  }, [direct, checked]);
  useEffect(() => {
    if (isLoadDevice) getData(filterData, limit, offset, direct, false);
  }, [isLoadDevice]);
  useEffect(() => {
    setOffset(0);
    setLimit(50);
    setCurrentPage(1);
    setChecked(false);
    getData(filterData, 50, 0, direct, false);
  }, [filterData]);

  return (
    <div className="new-device-table-content-box" ref={mainRef}>
      <Table {...dataTable} />
      {showPagination === true && (
        <div className="container-pagination">
          <Pagination
            className="pagination"
            size="small"
            count={count}
            page={currentPage}
            onChange={handlePageChange}
            showFirstButton
            showLastButton
          />
          <TablePagination
            size="small"
            component="div"
            count={totalPages}
            rowsPerPage={limit}
            page={currentPage - 1}
            onPageChange={() => {}}
            rowsPerPageOptions={[]}
            nextIconButtonProps={{ style: { display: 'none' } }}
            backIconButtonProps={{ style: { display: 'none' } }}
          />
        </div>
      )}
    </div>
  );
};

export default NewDeviceTable;
