import React, { useEffect, useState } from 'react';
import {
  Box, Checkbox, FormControlLabel, Grid, Typography,
} from '@mui/material';
import { useTranslation } from '../../../../../common/components/LocalizationProvider';

const RenderButtons = ({ title, childRole, handleCheckedCheckBox, permisstion }) => {
  const t = useTranslation();
  const [hasButon, setHasButton] = useState(false);
  const containsButton = () => {
    const hasButton = childRole?.some((item) => item?.children?.some((itemCheck) => itemCheck.type === 'button'));
    setHasButton(hasButton);
  };

  useEffect(() => {
    containsButton();
  }, [childRole]);

  return (
    hasButon && (
      <Box mb={5} className="container-form">
        <div className="title-form">{title}</div>
        {childRole?.map((item) => (
          item?.children?.some((itemCheck) => itemCheck.type === 'button') && (
          <Box>
            <Typography ml={3}>{t(item?.role.split('.')[0])}</Typography>

            <Grid container flexDirection="column" spacing={1} ml={10} mt={1} mb={2}>
              {item?.children.length > 0 && <Typography>{t(item?.label)}</Typography>}
              <Grid item xs={12}>
                <Grid container>
                  {item?.children?.map((item2) => item2.type === 'button' && (
                  <Grid item xs={2}>
                    <FormControlLabel
                      key={item2.role}
                      style={{ textTransform: 'capitalize' }}
                      control={(
                        <Checkbox
                          checked={permisstion.includes(item2.role)}
                          onChange={(_, status) => handleCheckedCheckBox(status, item2.role)}
                          color="success"
                        />
          )}
                      label={t(item2.label)}
                    />
                  </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Box>
          )
        ))}
      </Box>
    )

  );
};

export default RenderButtons;
