/* eslint-disable no-undef */
import React, {
  useRef, useState, useEffect,
} from 'react';
import {
  Button,
  Alert,
  Snackbar,
  FormControl,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import moment from 'moment';
// eslint-disable-next-line import/no-extraneous-dependencies
import { saveAs } from 'file-saver';
import {
  PlayArrow,
  Upload,
} from '@mui/icons-material';
import { utils } from 'xlsx';
import { useTranslation } from '../../../../common/components/LocalizationProvider';
import {
  getAdminAccountData, getAdminAccountIds,
} from '../../../common/utils';
import { helperActions, setMessage, setMessageSuccess } from '../../../../store';
import TableAdminAccounts from '../adminAccounts/components/TableAdminAccounts';
import baseRequest from '../../../../apis/baseRequest';
import './networkSetting.css';
import uris from '../../../../apis/uris';

import { formatDateTime } from '../../../common/handleTimeZone';
import ModalConfirmAdmin from '../../../components/modal/ModalConfirm';
import HeaderSettings from '../HeaderSettings';
import hasPermission from '../../../common/hasPermission';
import TableNetworkSetting from './TableNetworkSetting';

const ListAccounts = () => {
  const navigate = useNavigate();
  const tableRef = useRef();
  const t = useTranslation();
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);

  const [gridApi, getGridApi] = useState(null);
  const [dataSelect, setDataSelect] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [isLoadNetwork, setIsLoadNetwork] = useState(false);
  const [propsModal, setPropsModal] = useState({});
  const inputFile = useRef(null);

  const checkedList = useSelector((state) => state.helper.checkedList);
  const data = getAdminAccountData(checkedList, dataSelect);
  const id = getAdminAccountIds(checkedList, dataSelect);

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleDeleteNetwork = async () => {
    const data = {
      ids: id,
    };
    baseRequest.post(uris.DELETE_NETWORK_SETTING, data).then((response) => {
      if (response?.status === 'NG') dispatch(setMessage(response.message));
      else { navigate('/admin/finish/settings/networkSetting'); }
    });
  };

  useEffect(() => {
    dispatch(helperActions.selectedDevice(data));
  }, [checkedList, dataSelect]);

  useEffect(() => {
    if (checkedList.length === 0) {
      setDataSelect([]);
    }
  }, [checkedList]);

  const handleSelectionChange = () => {
    setDataSelect(gridApi?.getSelectedRows() || []);
  };

  const handleRowDataUpdated = () => {
    gridApi?.forEachNode((node) => {
      if (checkedList.length !== 0) {
        node.setSelected(true);
      }
    });
  };

  const onBtnExport = () => {
    let dataExport;
    if (Array.isArray(data)) {
      dataExport = data.map((item) => {
        const newItem = { ...item };

        newItem.lastModified = item?.lastModified ? formatDateTime(item?.lastModified, 'YYYY/MM/DD HH:mm') : 'No data';
        newItem.createdAt = item?.createdAt ? formatDateTime(item?.createdAt, 'YYYY/MM/DD HH:mm') : 'No data';
        newItem.id = item.id;
        return {
          id: newItem.id,
          ipAddress: newItem.ipAddress,
          spare: newItem.spare,
          createdAt: newItem.createdAt,
          lastModified: newItem.lastModified,

        };
      });
    }

    const headers = [t('id'), t('ipAddress'), t('spare'), t('createdAt'), t('lastModified')];
    const worksheet = utils.json_to_sheet([]);
    utils.sheet_add_aoa(worksheet, [headers], { origin: 'A1' });
    utils.sheet_add_json(worksheet, dataExport, { origin: 'A2', skipHeader: true });
    worksheet['!cols'] = [
      { wch: 10 },
      { wch: 30 },
      { wch: 10 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
    ];
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const csvOutput = utils.sheet_to_csv(worksheet, { header: true });
    const blob = new Blob([csvOutput], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, `ARINA_NetworkSetting_list_${moment().format('YYYY-MM-DD_HH-mm-ss')}.csv`);
  };
  const handleImportNetworkSetting = (data) => {
    baseRequest.call(uris.IMPORT_NETWORK_SETTING, 'POST', data, null, true).then((response) => {
      if (response.status === 'NG') dispatch(setMessage(response.message));
      else { dispatch(setMessageSuccess(response?.message)); setIsLoadNetwork(true); }
    });
  };
  const handleClearFile = () => {
    setFile(null);
    if (inputFile.current) {
      inputFile.current.value = '';
    }
  };
  const handleChangeFile = (e) => {
    if (e.target.files.length > 0) {
      setFile(null);
      const valueFile = e.target.files[0];
      if (valueFile?.name?.length > 0) {
        const maxSize = 5 * 1024 * 1024;
        if ((/\.(csv)$/i.test(valueFile.name)) && (valueFile.size < maxSize)) {
          setFile(e.target.files[0]);
          handleImportNetworkSetting(valueFile);
        } else {
          dispatch(setMessage('You need to select the csv file and have a capacity of less than 5MB.'));
        }
      }
    }
  };
  const dataTable = {
    getGridApi,
    tableRef,
    floatingFilter: true,
    showPagination: true,
    className: 'config-permission-table',
    isLoadNetwork,
    setIsLoadNetwork,
    onSelectionChanged: handleSelectionChange,
    onRowDataUpdated: handleRowDataUpdated,
    childrenTop: (
      <div>
        <div className="list-btn-network">
          {hasPermission('settings.networkSetting.export') && (
          <Button
            onClick={onBtnExport}
            className="network-setting-btn"
            disabled={!(checkedList.length > 0 || dataSelect.length > 0)}
          >
            <InsertDriveFileIcon style={{ paddingRight: '5px', fontSize: '30px' }} />
            <div>{t('csvExport')}</div>
          </Button>
          )}
          {hasPermission('settings.networkSetting.import') && (
          <Button
            className="network-setting-btn"
            onClick={() => {
              handleClearFile();
              inputFile.current.click();
            }}
          >
            <Upload className="icon-btn" />
            <div>{t('csv')}</div>
            <input style={{ display: 'none' }} type="file" accept=".csv" onChange={handleChangeFile} ref={inputFile} />
          </Button>
          )}
          {hasPermission('settings.networkSetting.new') && (
          <Button
            onClick={() => navigate('/admin/settings/networkSetting/add')}
            className="network-setting-btn"
            sx={{ width: '100px !important' }}
          >
            <BorderColorIcon style={{ paddingRight: '5px', fontSize: '30px' }} />
            <div>{t('new')}</div>
          </Button>
          )}
          {hasPermission('settings.networkSetting.delete') && (
          <Button
            onClick={() => { setOpenModal(true); setPropsModal({ confirm: t('confirmDelete'), msgWarning: t('confirmEdit2'), action: handleDeleteNetwork }); }}
            className="network-setting-btn"
            sx={{ width: '100px !important' }}
            disabled={!(checkedList.length > 0 || dataSelect.length > 0)}
          >
            <DeleteOutlineOutlinedIcon
              style={{ paddingRight: '5px', fontSize: '30px' }}
            />
            <div>{t('sharedDelete')}</div>
          </Button>
          )}

        </div>
        <div className="network-setting-des">
          <Typography>{t('descriptionNetworkSetting')}</Typography>
          <Typography sx={{ letterSpacing: 0.5 }}>{t('exampleNetworkSetting')}</Typography>
        </div>
      </div>
    ),
  };
  return (
    <>
      <div style={{ borderBottom: 'none' }} className="upsert-device-header">
        <FormControl onClick={() => {
          navigate('/admin/settings');
        }}
        >
          <HeaderSettings />
        </FormControl>
      </div>
      <TableNetworkSetting {...dataTable} />
      <ModalConfirmAdmin
        openModal={openModal}
        handleClose={handleClose}
        propsModal={propsModal}
      />

    </>
  );
};

export default ListAccounts;
