import React from 'react';
import { useController } from 'react-hook-form';
import { FormControl, FormHelperText } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import LabelAdd from '../../devices/components/LabelAdd';

const FormDatePicker = ({ label, required, controllerProps, dateTimePickerProps }) => {
  const { field, fieldState } = useController(controllerProps);
  const { error } = fieldState;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {label && <LabelAdd label={label} required={required} />}
      <FormControl className="form-control" error={!!error}>
        <DateTimePicker
          ampm={false}
          value={field.value || null}
          {...dateTimePickerProps}
          {...field}
        />
        {error && <FormHelperText>{error.message}</FormHelperText>}
      </FormControl>
    </LocalizationProvider>
  );
};

export default FormDatePicker;
