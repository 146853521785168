/* eslint-disable no-undef */
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import {
  Box, Button, Pagination, TablePagination,
} from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from '../../common/components/LocalizationProvider';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import Table from '../components/Table';
import Header from '../tools/components/Header';
import { convertWidth, linkValueFormatter } from '../common/utils';
import CheckBoxAll from '../components/CheckBoxAll';
import { helperActions } from '../../store';
import '../styles/pages/HistoryDevices.css';
import baseRequest from '../../apis/baseRequest';
import uris from '../../apis/uris';
import { formatDateUTCToLocal, formatTimeUTCToLocal } from '../common/handleTimeZone';

const linkButton = (data) => {
  const linkGoogle = ` http://maps.google.com/maps?q=${data.latitude},${data.longitude}`;
  return (
    <a href={linkGoogle} target="_blank" rel="noopener noreferrer">
      {linkGoogle}
    </a>
  );
};

const HistoryDevices = () => {
  const checkedList = useSelector((state) => state.helper.checkedList);
  const t = useTranslation();
  const tableRef = useRef();
  const dispatch = useDispatch();

  const [gridApi, getGridApi] = useState(null);
  const [dataSelect, setDataSelect] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [linkData, setLinkData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(50);
  const [checked, setChecked] = useState(false);
  const count = Math.ceil(total / limit);

  const url = window.location.href;
  const id = url.match(/\/(\d+)$/)[1];

  const getData = (limit, offset, checked) => {
    const params = {
      deviceId: id,
      limit,
      offset,
    };
    baseRequest.get(uris.DEVICES_HISTORY, params).then((response) => {
      const transformHistoryData = response.data?.map((data) => ({
        ...data,
        time: data.fixtime,
      }));
      setRowData(transformHistoryData);
      setTotal(response.total);
      if (checked === true) {
        dispatch(helperActions.getCheckedList(response.data));
      } else if (checked === null) {
        dispatch(helperActions.getCheckedList(response.data));
      } else {
        dispatch(helperActions.getCheckedList([]));
      }
    });
  };
  useEffect(() => {
    if (checked === true) {
      getData(limit, offset, true);
    } else if (checked === null) {
      getData(limit, offset, null);
    } else {
      getData(limit, offset, false);
    }
  }, [checked]);

  const columnDefs = [
    {
      width: convertWidth(4),
      field: 'isChecked',
      filter: false,
      // headerCheckboxSelection: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      headerComponent: () => <CheckBoxAll setCheck={setChecked} />,
    },
    {
      headerName: t('DeviceId'),
      field: 'deviceid',
      filter: false,
      width: convertWidth(10),
    },
    {
      headerName: t('deviceName'),
      field: 'name',
      filter: false,
      width: convertWidth(13),
    },
    {
      headerName: t('date'),
      field: 'fixtime',
      cellStyle: { textAlign: 'center' },
      filter: false,
      valueFormatter: (params) => formatDateUTCToLocal(params.value),
      width: convertWidth(10),
    },
    {
      headerName: t('time'),
      field: 'time',
      filter: false,
      cellStyle: { textAlign: 'center' },
      valueFormatter: (params) => formatTimeUTCToLocal(params.value),
      width: convertWidth(10),
    },
    {
      headerName: t('latitude'),
      field: 'latitude',
      filter: false,
      width: convertWidth(10),
    },
    {
      headerName: t('longitude'),
      field: 'longitude',
      filter: false,
      width: convertWidth(10),
    },
    {
      headerName: 'LINK',
      field: 'link',
      filter: false,
      cellStyle: { textAlign: 'center' },
      cellRenderer: (params) => {
        setLinkData(params.data);
        return linkButton(params.data);
      },
      width: convertWidth(43),
    },
  ];
  const onBtnExport = useCallback(() => {
    const columnDefs = tableRef.current.api.getColumnDefs();
    const columnKeys = columnDefs.slice(1).map((columnDef) => columnDef.colId);
    const params = {
      columnKeys,
    };
    const linkColumnConfig = {
      field: 'link',
      valueFormatter: (params) => linkValueFormatter(params.node.data),
    };

    const dateColumnConfig = {
      field: 'fixtime',
      valueFormatter: (params) => formatDateUTCToLocal(params.value),
    };

    const timeColumnConfig = {
      field: 'time',
      valueFormatter: (params) => formatTimeUTCToLocal(params.value),
    };
    tableRef.current.api.exportDataAsCsv({
      processCellCallback: (params) => {
        const column = params.column.getColDef();
        if (column.field === 'link') {
          return linkColumnConfig.valueFormatter(params);
        }
        if (column.field === 'fixtime') {
          return dateColumnConfig.valueFormatter(params);
        }

        if (column.field === 'time') {
          return timeColumnConfig.valueFormatter(params);
        }
        return params.value;
      },
      onlySelected: true,
      fileName: `ARINA-History-of-DeviceId${id}.csv`,
      ...params,
    });
  }, [linkData]);

  const handlePageChange = (e, page) => {
    setCurrentPage(page);
    const offsetValue = page * limit - limit;
    setOffset(offsetValue);
    setLimit(limit);
    setChecked(false);
    getData(limit, offsetValue);
  };
  useEffect(() => {
    if (checkedList.length === 0) {
      setDataSelect([]);
    }
  }, [checkedList]);

  const handleSelectionChange = () => {
    setDataSelect(gridApi?.getSelectedRows() || []);
  };

  const handleRowDataUpdated = () => {
    gridApi?.forEachNode((node) => {
      if (checkedList.length !== 0) {
        node.setSelected(true);
      }
    });
  };

  const dataTable = {
    getGridApi,
    tableRef,
    autoResize: 1440,
    listData: rowData,
    rowSelection: 'multiple',
    floatingFilterOff: true,
    customColumnDefs: columnDefs,
    onSelectionChanged: handleSelectionChange,
    onRowDataUpdated: handleRowDataUpdated,

    className: 'config-permission-table',
    childrenTop: (
      <div style={{ marginBottom: '1rem' }}>
        <Button
          onClick={onBtnExport}
          className="csv-btn"
          disabled={dataSelect.length <= 0}
        >
          <GetAppIcon style={{ paddingRight: '5px', fontSize: '30px' }} />
          <div>Export</div>
        </Button>
      </div>
    ),
  };

  return (
    <>
      <Header noDescription link={-1} />
      <Box className="box-config">
        <Table {...dataTable} />
        <div style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: '#f1f0f0' }}>
          <Pagination
            className="pagination"
            size="small"
            count={count}
            onChange={handlePageChange}
            showFirstButton
            showLastButton
          />
          <TablePagination
            size="small"
            component="div"
            count={total}
            rowsPerPage={limit}
            page={currentPage - 1}
            onPageChange={() => {}}
            rowsPerPageOptions={[]}
            nextIconButtonProps={{ style: { display: 'none' } }}
            backIconButtonProps={{ style: { display: 'none' } }}
          />
        </div>
      </Box>
    </>
  );
};

export default HistoryDevices;
