/* eslint-disable no-undef */
import React, {
  useRef, useState, useEffect,
} from 'react';
import {
  Button,
  Alert,
  Snackbar,
  FormControl,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import moment from 'moment';
import {
  PlayArrow,
} from '@mui/icons-material';
import { utils, writeFile } from 'xlsx';
import { useTranslation } from '../../../../common/components/LocalizationProvider';
import {
  getAdminAccountData, getAdminAccountIds,
} from '../../../common/utils';
import { helperActions, setMessage } from '../../../../store';
import TableAdminAccounts from './TableRoles';
import baseRequest from '../../../../apis/baseRequest';
import '../../../styles/pages/ListDevice.css';
import uris from '../../../../apis/uris';
import { formatDateTime } from '../../../common/handleTimeZone';
import ModalConfirmAdmin from '../../../components/modal/ModalConfirm';
import HeaderSettings from '../HeaderSettings';
import hasPermission from '../../../common/hasPermission';

const ListAccounts = () => {
  const navigate = useNavigate();
  const tableRef = useRef();
  const t = useTranslation();
  const dispatch = useDispatch();
  const [gridApi, getGridApi] = useState(null);
  const [dataSelect, setDataSelect] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [showError, setShowError] = useState(false);
  const [isLoadDevice, setIsLoadDevice] = useState(false);
  const [propsModal, setPropsModal] = useState({});
  const checkedList = useSelector((state) => state.helper.checkedList);
  const data = getAdminAccountData(checkedList, dataSelect);
  const id = getAdminAccountIds(checkedList, dataSelect);

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleDeleteRole = async () => {
    baseRequest.post(uris.ROLES_DELETE, { ids: id }).then((response) => {
      if (response?.status === 'NG') dispatch(setMessage(response.error));
      else { navigate('/admin/finish/settings/roles'); }
    });
  };
  const handleEnable = async () => {
    baseRequest.post(uris.ROLES_ENABLE_DISABLE, { ids: id, enable: true }).then(() => {
      navigate('/admin/finish/settings/roles');
    });
  };
  const handleDisable = async () => {
    baseRequest.post(uris.ROLES_ENABLE_DISABLE, { ids: id, enable: false }).then(() => {
      navigate('/admin/finish/settings/roles');
    });
  };
  useEffect(() => {
    dispatch(helperActions.selectedDevice(data));
  }, [checkedList, dataSelect]);

  useEffect(() => {
    if (checkedList.length === 0) {
      setDataSelect([]);
    }
  }, [checkedList]);

  const handleSelectionChange = () => {
    setDataSelect(gridApi?.getSelectedRows() || []);
  };

  const handleRowDataUpdated = () => {
    gridApi?.forEachNode((node) => {
      if (checkedList.length !== 0) {
        node.setSelected(true);
      }
    });
  };

  const onBtnExport = () => {
    let dataExport;
    if (Array.isArray(data)) {
      dataExport = data.map((item) => {
        const newItem = { ...item };
        newItem.lastLoginTime = item?.lastLoginTime ? formatDateTime(item?.lastLoginTime) : 'No data';
        newItem.modifiedDateTime = item?.modifiedDateTime ? formatDateTime(item?.modifiedDateTime) : 'No data';

        return {
          accountName: newItem.accountName,
          accountId: newItem.accountId,
          status: newItem.status,
          department: newItem.department,
          email: newItem.email,
          phoneNumber: newItem.phoneNumber,
          role: newItem.role,
          lastLoginTime: newItem.lastLoginTime,
          modifiedDateTime: newItem.modifiedDateTime,
        };
      });
    }

    const headers = [t('accountName'), t('accountID'), t('status'), t('department'), t('email'), t('phoneNumber'), t('role'), t('lastLoginTime'), t('modifiedDateTime')];
    const worksheet = utils.json_to_sheet([]);
    utils.sheet_add_aoa(worksheet, [headers], { origin: 'A1' });
    utils.sheet_add_json(worksheet, dataExport, { origin: 'A2', skipHeader: true });
    worksheet['!cols'] = [
      { wch: 10 },
      { wch: 30 },
      { wch: 10 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
    ];
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    writeFile(workbook, `ARINA_AdminAccount_list_${moment().format('YYYY-MM-DD HH:mm:ss')}.csv`);
  };

  const dataTable = {
    getGridApi,
    tableRef,
    floatingFilter: true,
    showPagination: true,
    className: 'config-permission-table',
    isLoadDevice,
    setIsLoadDevice,
    onSelectionChanged: handleSelectionChange,
    onRowDataUpdated: handleRowDataUpdated,
    childrenTop: (
      <div>
        <div className="list-btn">
          {hasPermission('settings.roles.csv') && (
          <Button
            onClick={onBtnExport}
            className="csv-btn"
            disabled={!(checkedList.length > 0 || dataSelect.length > 0)}
          >
            <InsertDriveFileIcon style={{ paddingRight: '5px', fontSize: '30px' }} />
            <div>CSV</div>
          </Button>
          )}
          {hasPermission('settings.roles.create') && (
          <Button
            onClick={() => navigate('/admin/settings/roles/add')}
            className="csv-btn"
          >
            <BorderColorIcon style={{ paddingRight: '5px', fontSize: '30px' }} />
            <div>{t('create')}</div>
          </Button>
          )}
          {hasPermission('settings.roles.delete') && (
          <Button
            onClick={() => { setOpenModal(true); setPropsModal({ confirm: t('confirmDelete'), msgWarning: t('confirmEdit2'), action: handleDeleteRole }); }}
            className="csv-btn"
            disabled={!(checkedList.length > 0 || dataSelect.length > 0)}
          >
            <DeleteOutlineOutlinedIcon
              style={{ paddingRight: '5px', fontSize: '30px' }}
            />
            <div>{t('sharedDelete')}</div>
          </Button>
          )}
          {hasPermission('settings.roles.disable') && (
          <Button
            onClick={() => { setOpenModal(true); setPropsModal({ confirm: t('confirmDisableRoles'), msgWarning: '', action: handleDisable }); }}
            className="csv-btn"
            sx={{ padding: '0 5px' }}
            disabled={!(checkedList.length > 0 || dataSelect.length > 0)}
          >
            <NotInterestedIcon style={{ paddingRight: '5px', fontSize: '30px' }} />
            <div>{t('disable')}</div>
          </Button>
          )}
          {hasPermission('settings.roles.enable') && (
          <Button
            onClick={() => { setOpenModal(true); setPropsModal({ confirm: t('confirmEnableRoles'), msgWarning: '', action: handleEnable }); }}
            className="csv-btn"
            disabled={!(checkedList.length > 0 || dataSelect.length > 0)}
          >
            <PlayArrow style={{ paddingRight: '5px', fontSize: '30px' }} />
            <div>{t('enable')}</div>
          </Button>
          )}
        </div>
      </div>
    ),
  };
  return (
    <>
      <div style={{ borderBottom: 'none' }} className="upsert-device-header">
        <FormControl onClick={() => {
          navigate('/admin/settings');
        }}
        >
          <HeaderSettings />
        </FormControl>
      </div>

      <TableAdminAccounts {...dataTable} />
      <Snackbar
        open={showError}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setShowError(false)}
      >
        <Alert onClose={() => setShowError(false)} severity="success" variant="filled">
          Import successfully
        </Alert>
      </Snackbar>
      <ModalConfirmAdmin
        openModal={openModal}
        handleClose={handleClose}
        propsModal={propsModal}
      />

    </>
  );
};

export default ListAccounts;
