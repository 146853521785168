/* eslint-disable import/no-extraneous-dependencies */
import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/material/styles';
import {
  IconButton,
  Avatar,
  ListItemAvatar,
  ListItemButton,
  Box,
  Button,
  Icon,
  Dialog,
  Paper,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';

import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import moment from 'moment';
import { devicesActions } from '../store';
import { formatPercentage } from '../common/util/formatter';
import { useTranslation } from '../common/components/LocalizationProvider';
import AccuracyPopupLocationSharing from '../common/components/AccuracyPopupLocationSharing';
import Battery100X from '../resources/images/iconXD/100X.svg';
import Battery70X from '../resources/images/iconXD/70X.svg';
import Battery50X from '../resources/images/iconXD/50X.svg';
import Battery20X from '../resources/images/iconXD/20X.svg';
import Battery0X from '../resources/images/iconXD/0X.svg';
import AccuracyPopup from '../resources/images/location_share/accuracypopup.png';
import UnSelect from '../resources/images/location_share/Btn_unselect.png';
import { map } from '../map/core/MapView';
import GoodIcon from '../resources/images/location_share/icon_good.svg';
import SosIcon from '../resources/images/location_share/sos_status_icon.svg';
import SadFacesIcon from '../resources/images/location_share/sad_faces.svg';
import NotGoodIcon from '../resources/images/location_share/icon_not_good.svg';
import NoRepStatusIcon from '../resources/images/location_share/icon_no_rep_status.svg';
import InitialStatusIcon from '../resources/images/location_share/icon_initial_status.svg';
import DefaultAvatar from './resource/default_avatar.png';

const useStyles = makeStyles((theme) => ({
  list: {
    height: '100%',
    borderRadius: '10px',
  },
  avatar: {
    width: '62px',
    height: '62px',
    margin: '21px 0',
    border: '5px solid white',
  },
  icon: {
    width: '100%',
    height: '100%',
  },
  listItem: {
    backgroundColor: '#F5F5F5',
    '&:hover': {
      backgroundColor: '#F5F5F5',
      opacity: 0.8,
    },
    '&:selection': {
      transition: 'background-color 0.3s, opacity 0.3s',
    },
    height: '104px',
    margin: '0 11px 7px 11px',
    borderRadius: '10px',
  },
  boxContent: {
    paddingLeft: '15px',
    width: '155px',
    height: '100%',
    marginTop: '11px',
    marginBottom: '10px',
    flexGrow: 1,
  },
  positive: {
    color: theme.palette.colors.positive,
    marginBottom: '6px',
    padding: 0,
  },
  neutral: {
    color: theme.palette.colors.neutral,
    marginBottom: '6px',
    padding: 0,
  },
  offline: {
    color: theme.palette.colors.offline,
    marginBottom: '6px',
    padding: 0,
  },
  mobileBattery: {
    height: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
  },
  configAddressText: {
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  },
  batteryIcon: {
    width: '25px', height: '25px', marginRight: '5px',
  },
  status: {
    color: '#0eaa92', height: 24, width: 24,
  },
  box: {
    width: '310px',
    height: '450px',
    padding: '20px',
    color: '#6C6A6A',
    border: '1px solid #00000000',
    borderRadius: '14px',
    boxShadow: '0px 2px 15px #61728B42',
    opacity: 1,
    overflow: 'hidden',
  },
  btn: {
    width: '100%',
    height: '47px',
    border: '1px solid #009380',
    borderRadius: '24px',
    color: '#009380',
    opacity: 1,
    '&:hover': {
      backgroundColor: 'white',
    },
  },
}));

const DeviceRow = ({ index, style, data }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const t = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));

  const [hiddenTool, setHiddenTool] = useState(false);
  const [textMemo, setTextMemo] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogNoData, setOpenDialogNoData] = useState(false);

  const { items } = data;
  const item = items[index];

  const selectedDeviceId = useSelector((state) => state.devices.selectedId);
  // const position = useSelector((state) => state.session.positions[item.id]);
  // const address = useSelector((state) => state.positions.address);
  const history = useSelector((state) => state.helper.history);

  const todayDate = moment().format('YYYY年M月D日');

  const handleOpen = (e) => {
    e.preventDefault();
    e.cancelBubble = true;
    e.stopPropagation();
    setOpenDialog(true);
  };

  const secondaryText = () => {
    const addressContent = item?.address || t('sharedLoading');

    return (
      <Tooltip
        title={addressContent}
        placement="top-start"
        enterDelay={20}
      >
        <div className={classes.configAddressText}>{addressContent}</div>
      </Tooltip>
    );
  };

  // Effect để cập nhật text
  useEffect(() => {
    setTextMemo(secondaryText());
  }, [item, t]);

  // useEffect(() => {
  //   if (currentLocation === '/history') setHiddenTool(true);
  // }, [currentLocation]);
  function getStatus(item) {
    switch (item) {
      case 0:
        return InitialStatusIcon;
      case 1:
        return GoodIcon;
      case 2:
        return SadFacesIcon;
      case 3:
        return NotGoodIcon;
      case 4:
        return SosIcon;
      case 99:
        return NoRepStatusIcon;
      default:
        return InitialStatusIcon;
    }
  }

  const timeLoading = item?.time && moment.utc(item.time, 'HH:mm:ss', true).isValid()
    ? moment.utc(item.time, 'HH:mm:ss').local().format('HH:mm')
    : '--:--';

  return (
    <div style={style}>
      <Box style={desktop ? { paddingTop: '20px' } : {}}>
        <ListItemButton
          key={item?.uid}
          className={classes.listItem}
          onClick={() => {
            if (item.lat !== null && item.lng !== null) {
              map.flyTo({
                center: [item.lng, item.lat],
                offset: [0, 0],
                zoom: 24,
                essential: true,
              });
            } else {
              // setOpenDialogNoData(true);
            }
            dispatch(devicesActions.select(item.uid));
          }}
          // disabled={item.disabled}
          dense
        >
          {selectedDeviceId && desktop && !history && (
          <IconButton
            sx={{
              position: 'absolute',
              top: '-25px',
              right: '-20px',
            }}
            size="small"
            onClick={(e) => {
              e.preventDefault();
              e.cancelBubble = true;
              e.stopPropagation();
              dispatch(devicesActions.select(null));
            }}
          >
            <img src={UnSelect} alt="unSelected" style={{ width: 40, height: 40 }} />
          </IconButton>
          )}
          <ListItemAvatar>
            <Avatar className={classes.avatar}>
              <img
                className={classes.icon}
                src={item?.avatar ? item.avatar : DefaultAvatar}
                alt="user avatar"
              />
            </Avatar>
          </ListItemAvatar>
          <div className={classes.boxContent}>
            <div style={{
              display: 'flex',
              fontWeight: 'bold',
              fontSize: '14px',
              color: '#6C6A6A',
              alignItems: 'center',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              justifyContent: 'space-between',
            }}
            >
              <div style={{
                display: '-webkit-box',
                WebkitLineClamp: 1,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                width: '150px',
                maxWidth: '150px',
              }}
              >
                {item?.name}
              </div>
              {history && (
              <div style={{ marginLeft: 10 }}>
                {todayDate}
              </div>
              )}

              {/* <div style={{ */}
              {/*  height: '23px', */}
              {/*  backgroundImage: `url(${getStatus(item?.userStatus)})`, */}
              {/*  width: '23px', */}
              {/*  display: 'inline-block', */}
              {/*  backgroundSize: 'cover', */}
              {/*  margin: '0 5px', */}
              {/* }} */}
              {/* /> */}
              {timeLoading}
            </div>
            <div style={{
              fontSize: '12px',
              color: '#6C6A6A',
              marginTop: '5px',
            }}
            >
              {textMemo}
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {item?.spd !== null ? parseFloat(item?.spd).toFixed(2) : <div>-</div>}
                {' '}
                Km/h
                <AccuracyPopupLocationSharing deviceId={item?.uid} acc={item?.acc} />
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {item && (
                <div>
                  {item.hasOwnProperty('bat') && (
                  <Tooltip
                    title={
                      `${t('positionBatteryLevel')}: ${formatPercentage(
                        item.bat,
                      )}`
                      }
                  >
                    <span className={classes.mobileBattery}>
                      {item.bat >= 70 ? (
                        <Icon fontSize="small" className={classes.batteryIcon}>
                          <img src={Battery100X} alt="battery 100%" style={{ width: '100%', height: '100%' }} />
                        </Icon>
                      ) : item?.bat >= 50 ? (
                        <Icon fontSize="small" className={classes.batteryIcon}>
                          <img src={Battery70X} alt="battery 100%" style={{ width: '100%', height: '100%' }} />
                        </Icon>
                      ) : item?.bat >= 20 ? (
                        <Icon fontSize="small" className={classes.batteryIcon}>
                          <img src={Battery50X} alt="battery 100%" style={{ width: '100%', height: '100%' }} />
                        </Icon>
                      ) : item?.bat > 0 && item?.bat <= 20 ? (
                        <Icon fontSize="small" className={classes.batteryIcon}>
                          <img src={Battery20X} alt="battery 100%" style={{ width: '100%', height: '100%' }} />
                        </Icon>
                      ) : item?.bat === 0 ? (
                        <Icon fontSize="small" className={classes.batteryIcon}>
                          <img src={Battery0X} alt="battery 100%" style={{ width: '100%', height: '100%' }} />
                        </Icon>
                      ) : null}
                    </span>
                  </Tooltip>
                  )}
                </div>
                )}
                <img src={getStatus(item?.status_flag)} alt="status-flag" className={classes.status} />
              </div>
              <IconButton onClick={(e) => handleOpen(e)}>
                <HelpOutlineOutlinedIcon sx={{ color: '#0EAA92' }} />
              </IconButton>
            </div>
          </div>
        </ListItemButton>
      </Box>
      <div>
        <Dialog
          open={openDialog}
          PaperProps={{ style: { borderRadius: 14 } }}
        >
          <Paper className={classes.box} sx={{ borderRadius: '14px' }}>
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', margin: '10px' }}>
              <img src={AccuracyPopup} alt="popup" style={{ marginBottom: '10px' }} />
              <Button
                className={classes.btn}
                onClick={() => setOpenDialog(false)}
                sx={{ '&:hover': { backgroundColor: '#009380' } }}
              >
                閉じる
              </Button>
            </div>
          </Paper>
        </Dialog>
      </div>
      <div>
        <Dialog
          open={openDialogNoData}
          PaperProps={{ style: { borderRadius: 14 } }}
        >
          <Paper className={classes.box} sx={{ borderRadius: '14px' }}>
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', margin: '10px' }}>
              no data
              <Button
                className={classes.btn}
                onClick={() => setOpenDialogNoData(false)}
                sx={{ '&:hover': { backgroundColor: '#009380' } }}
              >
                閉じる
              </Button>
            </div>
          </Paper>
        </Dialog>
      </div>
    </div>
  );
};

const DevicesList = ({ devices, element }) => {
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));
  const listRef = useRef();
  const classes = useStyles();
  const itemSize = 110;
  function addEvent(event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
  }

  useEffect(() => {
    const eventTypes = [
      'touchstart',
      'touchmove',
      'touchend',
      'touccancel',
      'mouseup',
      'mouchmove',
      'mousedown',
      'scroll',
      'mouseover',
      'mouseout',
    ];
    if (listRef && !desktop) {
      // eslint-disable-next-line no-inner-declarations

      eventTypes.forEach((eventType) => {
        listRef?.current?.addEventListener(eventType, addEvent);
      });
    } else if ((listRef && desktop)) {
      eventTypes.forEach((eventType) => {
        listRef?.current?.removeEventListener(eventType, addEvent);
      });
    }
  }, [listRef.current]);

  return (
    <AutoSizer>
      {({ height, width }) => (
        <FixedSizeList
          width={width}
          height={height}
          itemCount={devices?.length || 0}
          itemData={{ items: devices || [] }}
          itemSize={itemSize}
          overscanCount={10}
          outerRef={listRef}
        >
          {DeviceRow}
        </FixedSizeList>
      )}
    </AutoSizer>
  );
};

export default DevicesList;
