/* eslint-disable no-undef */
import { parse, stringify } from 'wellknown';
import circle from '@turf/circle';
import ShareMarkerBg from '../../resources/images/location_share/marker_bg.png';
import DefaultAvatar from '../../location_sharing/resource/default_avatar.png';

export const loadImage = (url) => new Promise((imageLoaded) => {
  try {
    if (url === null || url === '') url = DefaultAvatar;
    const image = new Image();
    image.onload = () => imageLoaded(image);
    url = url.replace(/s3\.[\w-]+\.amazonaws/, 's3.amazonaws');
    image.src = url;
    image.crossOrigin = '';
  } catch (e) {
    console.warn(`Cannot load image cause by: ${e}`);
  }
});

const canvasTintImage = (image, color) => {
  const canvas = document.createElement('canvas');
  canvas.width = image.width * devicePixelRatio;
  canvas.height = image.height * devicePixelRatio;
  canvas.style.width = `${image.width}px`;
  canvas.style.height = `${image.height}px`;

  const context = canvas.getContext('2d');

  context.save();
  context.fillStyle = color;
  context.globalAlpha = 1;
  context.fillRect(0, 0, canvas.width, canvas.height);
  context.globalCompositeOperation = 'destination-atop';
  context.globalAlpha = 1;
  context.drawImage(image, 0, 0, canvas.width, canvas.height);
  context.restore();

  return canvas;
};

export const pointOnCircle = (centerX, centerY, radius, angleDegrees) => {
  // Convert the angle from degrees to radians
  const angleRadians = (angleDegrees * Math.PI) / 180;

  // Calculate the coordinates (x, y) of the point on the circle
  const x = centerX + radius * Math.cos(angleRadians);
  const y = centerY + radius * Math.sin(angleRadians);

  return { x, y };
};

export const prepareIcon = async (background, icon, color) => {
  const canvas = document.createElement('canvas');

  const markerBg = await loadImage(ShareMarkerBg);

  canvas.width = markerBg.width;
  canvas.height = markerBg.height;

  const context = canvas.getContext('2d');

  // Draw marker
  context.drawImage(markerBg, 0, 0, canvas.width, canvas.height);

  // Draw yellow circle inside marker
  context.beginPath();
  context.arc(canvas.width / 2, 68, 25, 0, Math.PI * 2);
  context.fillStyle = color || '#F0C237';
  context.fill();
  context.closePath();

  // Push to stack
  context.save();

  // const fillColor = '#c92a2a';

  // // Create bottom triangle
  // const p1 = pointOnCircle(center.x, center.y, radius, 150);
  // const p2 = pointOnCircle(center.x, center.y, radius, 30);

  // context.beginPath();
  // context.moveTo(p1.x, p1.y);
  // context.lineTo(p2.x, p2.y);
  // context.lineTo(center.x, radius * 3);
  // context.fillStyle = fillColor; // Marker color
  // context.fill();
  // context.closePath();

  // // Create a circular clipping path
  // context.beginPath();
  // context.arc(center.x, center.y, radius - borderWidth / 2, 0, Math.PI * 2);
  // context.closePath();

  // // Create border
  // context.strokeStyle = fillColor;
  // context.lineWidth = borderWidth;
  // context.stroke();

  // context.clip();

  // if (icon) {
  //   context.drawImage(icon, 0, 0, canvas.width, canvas.width);
  // } else {
  //   context.beginPath();
  //   context.arc(center.x, center.y, radius, 0, Math.PI * 2);
  //   context.fillStyle = 'white';
  //   context.fill();

  //   context.closePath();
  // }

  if (icon) {
    context.beginPath();
    context.arc(canvas.width / 2, 68, 25, 0, Math.PI * 2);
    context.closePath();
    context.clip();

    icon.width = 25;
    icon.height = 25;

    context.drawImage(icon, canvas.width / 2 - 25, 43, 50, 50);
  }

  context.restore();

  return context.getImageData(0, 0, canvas.width, canvas.height);
};

export const reverseCoordinates = (it) => {
  if (!it) {
    return it;
  } if (Array.isArray(it)) {
    if (it.length === 2 && !Number.isNaN(it[0]) && !Number.isNaN(it[1])) {
      return [it[1], it[0]];
    }
    return it.map((it) => reverseCoordinates(it));
  }
  return {
    ...it,
    coordinates: reverseCoordinates(it.coordinates),
  };
};

export const geofenceToFeature = (theme, item) => {
  let geometry;
  // console.log(item);
  if (item.area.indexOf('CIRCLE') > -1) {
    const coordinates = item.area.replace(/CIRCLE|\(|\)|,/g, ' ').trim().split(/ +/);
    const options = { steps: 32, units: 'meters' };
    const polygon = circle([Number(coordinates[1]), Number(coordinates[0])], Number(coordinates[2]), options);
    geometry = polygon.geometry;
  } else {
    geometry = reverseCoordinates(parse(item.area));
  }
  return {
    id: item.id,
    type: 'Feature',
    geometry,
    properties: {
      name: item.name,
      // color: item.attributes.color || theme.palette.colors.geometry,
    },
  };
};

export const geometryToArea = (geometry) => stringify(reverseCoordinates(geometry));

export const circleFormat = (center, radius) => `CIRCLE(${center},${radius})`;

export const polygonToCircle = (polygonString) => {
  const polygon = parse(polygonString);
  // console.log('🚀 ~ file: mapUtil.js:98 ~ polygonToCircle ~ polygon:', polygon.coordinates[0]);
  const points = polygon.coordinates[0];

  const centerX = points.reduce((acc, curr) => acc + curr[0], 0) / points.length;
  const centerY = points.reduce((acc, curr) => acc + curr[1], 0) / points.length;

  const radiusM = Math.sqrt(points.reduce((acc, curr) => {
    const xDiff = curr[0] - centerX;
    const yDiff = curr[1] - centerY;
    return acc + xDiff * xDiff + yDiff * yDiff;
  }, 0) / points.length);

  const radiusKm = radiusM * 100000;

  return `CIRCLE(${centerX} ${centerY},${radiusKm})`;
};

// export const convertToCircle = (areaName, center, radius) => {
//   //   const geoData = transformGeoJson(areaData.area);
//   console.log('[check areaData', center, radius);

//   //   const center = geoData[];
//   //   const radius;
//   return {
//     name: areaName,
//     area: `POLYGON ([${center}], ${radius})`,
//   };
// };

export const findFonts = (map) => {
  const fontSet = new Set();
  const { layers } = map.getStyle();
  layers?.forEach?.((layer) => {
    layer.layout?.['text-font']?.forEach?.(fontSet.add, fontSet);
  });
  const availableFonts = [...fontSet];
  const regularFont = availableFonts.find((it) => it.includes('Regular'));
  if (regularFont) {
    return [regularFont];
  }
  const anyFont = availableFonts.find(Boolean);
  if (anyFont) {
    return [anyFont];
  }
  return ['Roboto Regular'];
};

export const addImageToMap = async (map, image, lon, lat, idImage, sourceId) => new Promise((resolve, reject) => {
  try {
    const id = `point-${idImage}`;

    if (!map.hasImage(id)) {
      map.addImage(id, image);
    }

    if (sourceId) {
      const source = map.getSource(sourceId);
      // eslint-disable-next-line no-underscore-dangle
      const currentData = source._data || { type: 'FeatureCollection', features: [] };

      const newFeature = {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [lon, lat],
        },
        properties: {
          icon: id,
          id: idImage,
          lat,
          lng: lon,
        },
      };
      currentData.features.push(newFeature);
      source.setData(currentData);
    } else {
      map.addLayer({
        id,
        type: 'symbol',
        source: {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: [lon, lat],
                },
              },
            ],
          },
        },
        layout: {
          'icon-image': id,
          'icon-size': 1,
          'icon-allow-overlap': true,
          'icon-ignore-placement': true,
        },
      });
    }

    resolve();
  } catch (error) {
    reject(error);
  }
});

export const createIconCanvas = async (backgroundUrl, iconUrl, name, iconColor, color = '#FFF') => new Promise((resolve, reject) => {
  const backgroundImage = new Image();
  const iconImage = new Image();

  backgroundImage.crossOrigin = 'anonymous';
  iconImage.crossOrigin = 'anonymous';
  iconImage.alt = name;

  backgroundImage.src = backgroundUrl;
  iconImage.src = iconUrl;

  let backgroundLoaded = false;
  let iconLoaded = false;

  function drawCanvas() {
    if (backgroundLoaded && iconLoaded) {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      canvas.width = backgroundImage.width + 20;
      canvas.height = backgroundImage.height + 20;

      context.drawImage(backgroundImage, 10, -30, backgroundImage.width, backgroundImage.height);

      context.beginPath();
      context.arc(canvas.width / 2, 38, 25, 0, Math.PI * 2);
      context.fillStyle = color;
      context.fill();
      context.closePath();

      context.save();
      context.beginPath();
      context.arc(canvas.width / 2, 38, 25, 0, Math.PI * 2);
      context.clip();

      context.filter = iconColor;

      iconImage.width = 36;
      iconImage.height = 36;
      context.drawImage(iconImage, canvas.width / 2 - 19, 19, iconImage.width, iconImage.height);

      context.restore();

      canvas.toBlob((blob) => {
        if (blob) {
          const img = new Image();
          img.src = URL.createObjectURL(blob);
          img.onload = () => resolve(img);
          img.onerror = (e) => reject(new Error(`Failed to load blob as image: ${e.message}`));
        } else {
          reject(new Error('Failed to convert canvas to blob.'));
        }
      }, 'image/png');
    }
  }

  backgroundImage.onload = () => {
    backgroundLoaded = true;
    drawCanvas();
  };

  iconImage.onload = () => {
    iconLoaded = true;
    drawCanvas();
  };

  backgroundImage.onerror = (e) => reject(new Error(`Failed to load background image from URL: ${backgroundUrl}. Error: ${e.message}`));
  iconImage.onerror = (e) => reject(new Error(`Failed to load icon image from URL: ${iconUrl}. Error: ${e.message}`));
});
