// eslint-disable-next-line import/no-extraneous-dependencies
import axios from 'axios';
import { decrypted } from '../payment/components/utils';
import store, { setMessage, setLockScreen } from '../store';

// eslint-disable-next-line no-undef
export const basicAuth = (email, password) => `Basic ${btoa(`${email}:${decrypted(password)}`)}`;
const contentBase = 'application/json; charset=utf-8';
const contentForm = 'multipart/form-data';
const url = (uri) => `${process.env.REACT_APP_URL_NAME}${uri}`;
const handleResponseError = (error, handleError = false, reject) => {
  store.dispatch(setLockScreen(false));
  if (error?.response?.status === 401) {
    store.dispatch(setMessage(error?.response?.data));
    return setTimeout(() => {
      // eslint-disable-next-line no-undef
      window.location.href = '/login';
    }, 5000);
  }
  return handleError === true ? reject(error) : store.dispatch(setMessage(error?.response?.data || ''));
};

export default {
  // eslint-disable-next-line no-bitwise,consistent-return
  call: async (uri, method = 'POST' | 'GET' | 'PUT' | 'DELETE', bodyParameters = {}, handleError, isFormUpload = false, configRequest = {}) => {
    store.dispatch(setLockScreen(true));

    try {
      const headers = {
        'Content-Type': isFormUpload ? contentForm : contentBase,
        // eslint-disable-next-line no-undef
        Authorization: basicAuth(localStorage.getItem('email'), (localStorage.getItem('password'))),
      };
      let configAxios = {
        url: url(uri),
        method,
        headers,
        data: bodyParameters,
        timeout: process.env.TIME_OUT,
      };
      if (configRequest) configAxios = Object.assign(configAxios, configRequest);
      return new Promise((resolve, reject) => {
        axios(configAxios).then((response) => {
          store.dispatch(setLockScreen(false));
          resolve(response.data);
        }).catch((error) => handleResponseError(error, handleError, reject));
      });
    } catch (error) {
      console.log(`Error :${error}`);
    }
  },
  get: (uri, params = {}, handleError) => new Promise((resolve, reject) => {
    store.dispatch(setLockScreen(true));
    axios.get(url(uri), {
      params,
      headers: {
        // eslint-disable-next-line no-undef
        Authorization: basicAuth(localStorage.getItem('email'), (localStorage.getItem('password'))),
      },
    }).then((response) => {
      store.dispatch(setLockScreen(false));
      resolve(response.data);
    }).catch((error) => handleResponseError(error, handleError, reject));
  }),
  post: async (uri, body = {}, params = {}, handleError) => new Promise((resolve, reject) => {
    store.dispatch(setLockScreen(true));
    axios.post(url(uri), body, {
      params,
      headers: {
        // eslint-disable-next-line no-undef
        Authorization: basicAuth(localStorage.getItem('email'), (localStorage.getItem('password'))),
      },
    }).then((response) => {
      store.dispatch(setLockScreen(false));
      resolve(response.data);
    }).catch((error) => handleResponseError(error, handleError, reject));
  }),
  put: async (uri, body = {}, params = {}, handleError) => new Promise((resolve, reject) => {
    store.dispatch(setLockScreen(true));
    axios.put(url(uri), body, {
      params,
      headers: {
        // eslint-disable-next-line no-undef
        Authorization: basicAuth(localStorage.getItem('email'), (localStorage.getItem('password'))),
      },
    }).then((response) => {
      store.dispatch(setLockScreen(false));
      resolve(response.data);
    }).catch((error) => handleResponseError(error, handleError, reject));
  }),
  delete: async (uri, params = {}, handleError) => new Promise((resolve, reject) => {
    store.dispatch(setLockScreen(true));
    axios.delete(url(uri), {
      headers: {
        // eslint-disable-next-line no-undef
        Authorization: basicAuth(localStorage.getItem('email'), (localStorage.getItem('password'))),
      },
      params,
    }).then((response) => {
      store.dispatch(setLockScreen(false));
      resolve(response.data);
    }).catch((error) => handleResponseError(error, handleError, reject));
  }),
};
