import React, { useState, useRef } from 'react';
import {
  Button, Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Header from '../../Header';
import { useTranslation } from '../../../../../common/components/LocalizationProvider';
import FormTextField from '../../../../components/form/FormTextField';
import baseRequest from '../../../../../apis/baseRequest';
import uris from '../../../../../apis/uris';
import { useEffectAsync } from '../../../../../reactHelper';
import store, { setMessage } from '../../../../../store';
import ModalConfirmAdmin from '../../../../components/modal/ModalConfirm';
import hiddenIcon from '../../../../../assets/mapIcon/hiddenIcon.svg';

const Index = () => {
  const t = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [statusMenu, setStatusMenu] = useState(false);
  const [targetForm, setTargetForm] = useState(false);
  const [facilitiesForm, setFacilitiesForm] = useState(false);
  const [clientXY, setClientXY] = useState({ x: 0, y: 0 });
  const [objectHandle, setObjectHandle] = useState({});
  const [listCategory, setListCategory] = useState([]);
  const [listTarget, setListTarget] = useState([]);
  const [listFacility, setListFacility] = useState([]);
  const rootRef = useRef(null);
  const navigate = useNavigate();
  const useFormTarget = useForm({ mode: 'onChange' });
  const useFormFacilities = useForm({ mode: 'onChange' });

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleRightClickMenu = (e, id, object) => {
    // 0 là xử lý category, 1 là target 2 là facility
    setObjectHandle({ id, object });
    e.preventDefault();
    if (rootRef.current) {
      const rect = rootRef.current.getBoundingClientRect();
      const x = e.clientX - rect.left + rootRef.current.scrollLeft;
      const y = e.clientY - rect.top + rootRef.current.scrollTop;

      setClientXY({ x, y });
      setStatusMenu(true);
    }
  };

  const handleDelete = () => {
    const valueObject = objectHandle?.object;
    const uri = valueObject === 0 ? uris.DELETE_CATEGORY : valueObject === 1 ? uris.DELETE_TARGET : uris.DELETE_FACILITIES;
    baseRequest.post(uri, { id: objectHandle?.id }).then((response) => {
      if (response?.status === 'NG') {
        store.dispatch(setMessage(response?.error || ''));
      } else {
        navigate('/admin/finish/tools/mapInfo/settings');
      }
    });
  };

  const fetchData = async () => {
    baseRequest.get(uris.LIST_CATEGORY).then((response) => {
      setListCategory(response?.data);
    });
  };

  const fetchTargets = async () => {
    baseRequest.get(uris.LIST_TARGET).then((response) => {
      setListTarget(response?.data);
    });
  };

  const fetchFacilities = async () => {
    baseRequest.get(uris.LIST_FACILITIES).then((response) => {
      setListFacility(response?.data);
    });
  };

  const objectHandleFind = () => (objectHandle?.object === 0 ? listCategory : objectHandle?.object === 1 ? listTarget : listFacility);

  const handleHidden = () => {
    const valueObject = objectHandle?.object;
    const uri = valueObject === 0 ? uris.HIDDEN_CATEGORY : valueObject === 1 ? uris.HIDDEN_TARGET : uris.HIDDEN_FACILITIES;
    const value = objectHandleFind()?.find((item) => item.id === objectHandle?.id)?.enable;
    baseRequest.post(uri, { id: objectHandle?.id, hide: value }).then(async (response) => {
      if (response?.status === 'NG') {
        store.dispatch(setMessage(response?.error || ''));
      } else if (valueObject === 0) {
        await fetchData();
      } else if (valueObject === 1) {
        await fetchTargets();
      } else await fetchFacilities();
    });
  };

  const handleCloseMenu = () => {
    setStatusMenu(false);
  };

  const resetForm = (state, form) => {
    state(false);
    form.reset();
  };

  const handleEdit = () => {
    const valueObject = objectHandle?.object;
    if (valueObject === 0) {
      navigate(`editCategory/${objectHandle?.id}`);
    } else if (valueObject === 1) {
      useFormTarget.setValue('name', objectHandleFind()?.find((item) => item?.id === objectHandle?.id)?.name);
      useFormTarget.setValue('id', objectHandle?.id);
      setTargetForm(true);
    } else {
      useFormFacilities.setValue('name', objectHandleFind()?.find((item) => item?.id === objectHandle?.id)?.name);
      useFormFacilities.setValue('id', objectHandle?.id);
      setFacilitiesForm(true);
    }
  };

  const handleSubmitTargetFacilities = (check, data) => {
    const uriEdit = check ? uris.EDIT_TARGET : uris.EDIT_FACILITIES;
    const uriAdd = check ? uris.CREATE_TARGET : uris.CREATE_FACILITIES;
    baseRequest.post(data?.id ? uriEdit : uriAdd, data).then((response) => {
      if (response?.status === 'NG') {
        store.dispatch(setMessage(response?.error || ''));
      } else {
        if (check) resetForm(setTargetForm, useFormTarget);
        else resetForm(setFacilitiesForm, useFormFacilities);
        navigate('/admin/finish/tools/mapInfo/settings');
      }
    });
  };

  useEffectAsync(async () => {
    await fetchData();
    await fetchTargets();
    await fetchFacilities();
  }, []);

  return (
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div ref={rootRef} onClick={handleCloseMenu} style={{ position: 'relative' }}>
      <Header noDescription link="/admin/tools/mapInfo" />
      <ModalConfirmAdmin
        openModal={openModal}
        handleClose={handleClose}
        propsModal={{
          confirm: t('confirmDelete'),
          msgWarning: t('confirmEdit2'),
          action: handleDelete,
        }}
      />
      {statusMenu && (
        <div className="context-menu" style={{ top: clientXY.y, left: clientXY.x }}>
          <Button onClick={handleHidden} className="context-menu-button">{objectHandleFind()?.find((item) => item.id === objectHandle?.id)?.enable ? t('ChangeToHidden') : t('ChangeToDisplay')}</Button>
          <Button onClick={handleEdit} className="context-menu-button">{t('sharedEdit')}</Button>
          <Button onClick={() => setOpenModal(true)} className="context-menu-button">{t('sharedDelete')}</Button>
        </div>
      )}
      <div className="section-settings">
        <Typography
          variant="h5"
          sx={{ fontWeight: 'bold', marginBottom: 2 }}
        >
          {t('categoryTableHeader')}
        </Typography>
        <div className="map-category-container">
          {listCategory?.map((item) => (
            <div
              className="map-category-item"
              key={item?.id}
              onContextMenu={(e) => handleRightClickMenu(e, item?.id, 0)}
            >
              <img src={hiddenIcon} alt={item.name} className={item?.enable ? 'map-category-icon-display' : 'map-category-icon-hide'} />
              <div className={!item?.enable && 'map-category-item-container'}>
                <img style={{ filter: item?.iconColor }} src={item?.pathIcon} alt={item.name} />
              </div>
              <p>{item.name}</p>
            </div>
          ))}
        </div>
        <Button
          className="map-info-setting-btn-submit"
          component="label"
          variant="contained"
          tabIndex={-1}
          onClick={() => navigate('addCategory')}
          startIcon={<AddIcon />}
        >
          {t('sharedAdd')}
        </Button>
      </div>
      <div className="section-settings">
        <Typography
          variant="h5"
          sx={{ fontWeight: 'bold', marginBottom: 2 }}
        >
          {t('targetMapInfo')}
        </Typography>
        <div className="map-info-label">
          {listTarget?.map((item) => (
            <div className={`map-info-label-item ${!item?.enable && 'map-info-item-hidden'}`} onContextMenu={(e) => handleRightClickMenu(e, item?.id, 1)}>
              {item.name}
            </div>
          ))}
        </div>
        {!targetForm && (
          <Button
            onClick={() => setTargetForm(true)}
            sx={{ margin: '10px 0' }}
            className="map-info-setting-btn-submit"
            component="label"
            variant="contained"
            tabIndex={-1}
            startIcon={<AddIcon />}
          >
            {t('sharedAdd')}
          </Button>
        )}
        {targetForm && (
          <form className="map-info-form" onSubmit={useFormTarget.handleSubmit((data) => handleSubmitTargetFacilities(true, data))}>
            <FormTextField
              controllerProps={{
                control: useFormTarget.control,
                name: 'name',
                rules: { required: t('FieldNotNull'), maxLength: { value: 50, message: t('Max50DAll') } },
              }}
              textFieldProps={{
                placeholder: t('positionInput'),
              }}
              required
            />
            <Button
              onClick={() => resetForm(setTargetForm, useFormTarget)}
              variant="contained"
              className="map-info-btn-back"
            >
              {t('sharedCancel')}
            </Button>
            <Button type="submit" disabled={!useFormTarget.formState.isValid} variant="contained" className="map-info-btn-save">{useFormTarget.watch('id') ? t('sharedSave') : t('sharedAdd')}</Button>
          </form>
        )}
      </div>
      <div className="section-settings">
        <Typography
          variant="h5"
          sx={{ fontWeight: 'bold', marginBottom: 2 }}
        >
          {t('facilitiesMapInfo')}
        </Typography>
        <div className="map-info-label">
          {listFacility?.map((item) => (
            <div className={`map-info-label-item ${!item?.enable && 'map-info-item-hidden'}`} onContextMenu={(e) => handleRightClickMenu(e, item?.id, 2)}>
              {item.name}
            </div>
          ))}
        </div>
        {!facilitiesForm && (
          <Button
            onClick={() => setFacilitiesForm(true)}
            sx={{ margin: '10px 0' }}
            className="map-info-setting-btn-submit"
            component="label"
            variant="contained"
            tabIndex={-1}
            startIcon={<AddIcon />}
          >
            {t('sharedAdd')}
          </Button>
        )}
        {facilitiesForm && (
          <form className="map-info-form" onSubmit={useFormFacilities.handleSubmit((data) => handleSubmitTargetFacilities(false, data))}>
            <FormTextField
              controllerProps={{
                control: useFormFacilities.control,
                name: 'name',
                rules: { required: t('FieldNotNull'), maxLength: { value: 50, message: t('Max50DAll') } },
              }}
              textFieldProps={{
                placeholder: t('positionInput'),
              }}
              required
            />
            <Button
              onClick={() => resetForm(setFacilitiesForm, useFormFacilities)}
              variant="contained"
              className="map-info-btn-back"
            >
              {t('sharedCancel')}
            </Button>
            <Button type="submit" disabled={!useFormFacilities.formState.isValid} variant="contained" className="map-info-btn-save">{useFormFacilities.watch('id') ? t('sharedSave') : t('sharedAdd')}</Button>
          </form>
        )}
      </div>
    </div>
  );
};

export default Index;
