import React, { useState } from 'react';
import {
  Typography, FormControl, Snackbar,
  RadioGroup,
  FormControlLabel,
  Radio,
  Alert,
} from '@mui/material';
import Header from '../Header';
import { useTranslation } from '../../../../common/components/LocalizationProvider';
import CreateAccountByCSV from './CreateAccountByCSV';
import CreateManualAccount from './CreateManualAccount';

const RepresentAccount = () => {
  const t = useTranslation();
  const [showError, setShowError] = useState(false);
  const [value, setValue] = React.useState('manual');

  const handleChangeRadioOption = (event) => {
    setValue(event.target.value);
  };

  return (
    <div>
      <Snackbar
        open={showError}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setShowError(false)}
      >
        <Alert onClose={() => setShowError(false)} severity="success" variant="filled">
          Import successfully
        </Alert>
      </Snackbar>
      <Header topic="Create a manager account">
        <Typography>{t('specicalAccountPageTitle')}</Typography>
      </Header>
      <FormControl>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={value}
          onChange={handleChangeRadioOption}
        >
          <FormControlLabel value="manual" control={<Radio color="success" size="small" />} label={t('manual')} />
          <FormControlLabel value="csv" control={<Radio color="success" size="small" />} label={t('csv')} />
        </RadioGroup>
      </FormControl>

      <div style={{ margin: '20px 0 100px' }}>
        {value === 'csv' ? <CreateAccountByCSV valueOption={value} setValues={setValue} /> : <CreateManualAccount />}
      </div>

    </div>
  );
};

export default RepresentAccount;
