/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-undef */
import React, {
  useState, useEffect, useRef, useCallback,
} from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import maplibregl from 'maplibre-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import {
  TextField, Typography, Button, useMediaQuery,
  FormControl,
} from '@mui/material';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/styles';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PartyMode } from '@mui/icons-material';
import Header from '../Header';
import { useTranslation } from '../../../../common/components/LocalizationProvider';
import { useCatchCallback, useEffectAsync } from '../../../../reactHelper';
import MapView, { map } from '../../../../map/core/MapView';
import MapDefaultCamera from '../../../../map/main/MapDefaultCamera';
import MapCurrentLocation from '../../../../map/MapCurrentLocation';
import MapGeocoder from '../../../../map/geocoder/MapGeocoder';
import TableConfig from '../../../components/TableConfig';
import { errorsActions, geofencesActions } from '../../../../store';
import ComfirmationPopup from '../ComfirmationPopup';
import {
  circleFormat,
  geofenceToFeature, geometryToArea, polygonToCircle, reverseCoordinates,
} from '../../../../map/core/mapUtil';
import CircleMode from './CircleMode';
import theme from '../../../../map/draw/theme';
import NewTable from '../../../components/NewTable';
import fetchApi from '../../../FetchApi';
import ModalConfirm from '../../../components/modal/ModalConfirm';

const draw = new MapboxDraw({
  displayControlsDefault: false,
  controls: {
    // polygon: true,
    // line_string: true,
    // trash: true,
  },
  modes: {
    ...MapboxDraw.modes,
    draw_circle: CircleMode,
  },
  userProperties: false,
  styles: [...theme, {
    id: 'gl-draw-title',
    type: 'symbol',
    filter: ['all'],
    layout: {
      'text-field': '{user_name}',
      'text-font': ['Roboto Regular'],
      'text-size': 12,
    },
    paint: {
      'text-halo-color': 'white',
      'text-halo-width': 1,
    },
  }],
});

const useStyles = makeStyles({
  configTable: {
    height: '600px',
  },
  saveBtn: {
    width: '100px',
    backgroundColor: 'red',
    color: 'white',
    '&:hover': {
      backgroundColor: 'red',
    },
    '&:disabled': {
      backgroundColor: 'gray',
      color: 'white',
      '&:hover': {
        backgroundColor: 'gray',
      },
    },
  },
  title: {
    fontWeight: 'bold',
    margin: '20px 0 20px 0',
    '&::after': {
      content: '"*"',
      color: 'red',
    },
  },
  areaBtn: {
    width: '120px !important',
    height: '40px !important',
    backgroundColor: '#ffebad !important',
    color: 'black !important',
    border: '7px outset #ffebad !important',
  },
});

const EditArea = () => {
  const t = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const tableRef = useRef(null);
  // true là timesheet false là tool
  const checkPath = window.location.pathname.includes('areaSetting');
  const { id } = useParams();

  const isMobile = useMediaQuery('(max-width:600px)');
  const centerStyle = isMobile ? { marginBottom: '20px' } : {};

  const [item, setItem] = useState([{}]);
  const [drawBtn, setDrawBtn] = useState();
  const [updateName, setUpdateName] = useState('');
  const [gridApi, getGridApi] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [dataSelect, setDataSelect] = useState([]);
  // const [dataTable, setDataTable] = useState();
  const checkedList = useSelector((state) => state.helper.checkedList);

  const geofences = useSelector((state) => state.geofences.items);

  class MyCustomControl {
    onAdd(map) {
      this.containerCir = document.createElement('button');

      this.map = map;

      this.containerCir.onclick = () => {
        const zoom = this.map.getZoom();

        draw.changeMode('draw_circle', {
          initialRadiusInKm: 1 / 2 ** (zoom - 11),
        });

        draw.delete('-96.5801808656236544.76489866786821');
      };
      this.containerCir.className =
        `mapbox-gl-draw_ctrl-draw-btn my-custom-control-cir ${classes.areaBtn}`;

      this.content = document.createElement('div');
      this.content.innerText = t('areaBtn');
      this.mainContainer = document.createElement('div');

      this.mainContainer.className = 'mapboxgl-ctrl-group mapboxgl-ctrl';
      this.mainContainer.appendChild(this.containerCir);
      this.containerCir.appendChild(this.content);

      return this.mainContainer;
    }

    onRemove() {
      // this.container.parentNode.removeChild(this.container);
      this.mainContainer.remove();
      this.map = undefined;
    }
  }
  const myCustomControl = new MyCustomControl();

  const refreshGeofences = useCatchCallback(async () => {
    const response = await fetch('/api/geofences');
    if (response.ok) {
      dispatch(geofencesActions.refresh(await response.json()));
    } else {
      throw Error(await response.text());
    }
  }, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchApi(`/api/admin/geofences/linktouser/${id}`);
      if (response.ok) {
        const responseData = await response.json();
        setItem(responseData);
        setUpdateName(responseData[0].name);
      } else {
        throw Error(await response.text());
      }
    };
    if (id) {
      fetchData();
    }
  }, []);

  useEffect(() => {
    map.addControl(myCustomControl, 'bottom-right');
    map.addControl(draw);
    setDrawBtn(myCustomControl);
    return () => {
      map.removeControl(myCustomControl);
      map.removeControl(draw);
    };
  }, [map, draw, location]);

  useEffect(() => {
    const onDrawCreate = async (event) => {
      const copyItem = { ...item[0] };
      const feature = event.features[0];
      draw.deleteAll();
      draw.add(feature);
      copyItem.area = polygonToCircle(geometryToArea(feature.geometry));
      setItem([copyItem]);
    };

    map.on('draw.create', onDrawCreate);
    return () => {
      map.off('draw.create', onDrawCreate);
    };
  }, [draw, map]);

  useEffect(() => {
    draw.deleteAll();
    if (item[0]?.area) {
      draw.add(geofenceToFeature(theme, item[0]));
    }
  }, [geofences, item]);

  useEffect(() => {
    const listener = async (event) => {
      const feature = event.features[0];
      if (item[0]?.area) {
        const copyItem = { ...item[0] };
        copyItem.area = polygonToCircle(geometryToArea(feature.geometry));
        setItem([copyItem]);
      }
    };

    map.on('draw.update', listener);
    return () => map.off('draw.update', listener);
  }, [dispatch, item]);

  const handleDeleteArea = async () => {
    try {
      const response = await fetch(`/api/geofences/${id}`, {
        method: 'DELETE',
      });
      if (response.ok) {
        refreshGeofences();
        navigate(checkPath ? '/admin/timesheet/areaSetting' : '/admin/tools/setupArea');
      } else {
        throw Error(await response.text());
      }
    } catch (error) {
      dispatch(errorsActions.push(error.message));
    }
  };

  const handleUpdateArea = async () => {
    try {
      const response = await fetch('/api/admin/geofences/linktouser', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          name: updateName,
          area: item[0].area,
          userIds: gridApi.getSelectedRows().map((data) => data.id),
          geofenceid: Number(id),
        }),
      });
      if (response.ok) {
        dispatch(geofencesActions.update(item));
        map.removeControl(drawBtn);
        navigate(checkPath ? '/admin/timesheet/areaSetting' : '/admin/tools/setupArea');
      } else {
        throw Error(await response.text());
      }
    } catch (error) {
      dispatch(errorsActions.push(error.message));
    }
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleBack = () => {
    setOpenModal(false);
    return checkPath ? navigate('/admin/timesheet/areaSetting') : navigate('/admin/tools/setupArea');
  };
  const handleCheckData = () => {
    // if (areaData.name || areaData.area) {
    setOpenModal(true);
    // } else navigate('/admin/home/timesheet/areaSetting');
  };
  const handleSelectionChange = () => {
    setDataSelect(gridApi?.getSelectedRows() || []);
  };

  const handleRowDataUpdated = () => {
    gridApi?.forEachNode((node) => {
      if (checkedList.length !== 0) {
        node.setSelected(true);
      }
    });
  };
  const dataTable = {
    getGridApi,
    floatingFilter: true,
    showPagination: true,
    className: classes.configTable,
    removeColumn: [1],
    onSelectionChanged: handleSelectionChange,
    onRowDataUpdated: handleRowDataUpdated,
  };
  return (
    <div>
      <div>
        <FormControl>
          <Header handleOnNavigate={handleCheckData} noDescription />
        </FormControl>
      </div>
      <ModalConfirm
        openModal={openModal}
        handleClose={handleCloseModal}
        propsModal={
          {
            confirm: t('confirmMessage'),
            msgWarning: t('messageWarning'),
            action: handleBack,
          }
        }
      />
      <div style={{ margin: '10px' }}>
        <Typography variant="h5" className={classes.title}>{t('sharedAreaName')}</Typography>
        <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap-reverse', alignItems: 'center' }}>
          <TextField
            required
            style={{ width: '80%', minWidth: '100px', maxWidth: '400px' }}
            name="areaName"
            value={updateName}
            onChange={(event) => setUpdateName(event.target.value)}
            sx={{ color: 'black' }}
          />
          <ComfirmationPopup
            btnTitle={t('sharedDelete')}
            btnIcon={<FontAwesomeIcon icon={faTrashCan} size="lg" />}
            btnColor="#0579cc"
            styleButton={centerStyle}
            confirmText={t('confirmDelete')}
            subConfirmText={t('confirmEdit2')}
            submit={() => handleDeleteArea()}
          />
        </div>
        <div>
          <Typography variant="h5" className={classes.title}>{t('sharedArea')}</Typography>
          <Typography>{t('areaText')}</Typography>
        </div>
        <div style={{ flexGrow: 1, height: '500px', width: '100%', border: '4px solid' }}>
          <MapView>
            <MapDefaultCamera />
          </MapView>
          <MapCurrentLocation />
          <MapGeocoder />
        </div>
        <Typography variant="h5" sx={{ fontWeight: 'bold', margin: '20px 0 20px 0' }}>{t('applicableObject')}</Typography>
        <NewTable {...dataTable} />
        <div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: '20px' }}>
          <ComfirmationPopup
            btnTitle={t('sharedSave')}
            btnColor="#ff0505"
            confirmText={t('confirmEdit1')}
            submit={handleUpdateArea}
            disabled={!updateName}
          />
        </div>
      </div>
    </div>
  );
};

export default EditArea;
