/* eslint-disable no-undef */
import React, {
  useRef, useState, useEffect,
} from 'react';
import {
  Button,
  Alert,
  Snackbar,
  FormControl,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import moment from 'moment';
// eslint-disable-next-line import/no-extraneous-dependencies
import { saveAs } from 'file-saver';
import {
  PlayArrow,
} from '@mui/icons-material';
import { utils, writeFile } from 'xlsx';
import { useTranslation } from '../../../../common/components/LocalizationProvider';
import {
  getAdminAccountData, getAdminAccountIds,
} from '../../../common/utils';
import { helperActions, setMessage } from '../../../../store';
import TableAdminAccounts from './components/TableAdminAccounts';
import baseRequest from '../../../../apis/baseRequest';
import '../../../styles/pages/ListDevice.css';
import uris from '../../../../apis/uris';

import { formatDateTime } from '../../../common/handleTimeZone';
import ModalConfirmAdmin from '../../../components/modal/ModalConfirm';
import HeaderSettings from '../HeaderSettings';
import hasPermission from '../../../common/hasPermission';
import ModalSuccess from '../../../components/modal/ModalSuccess';

const ListAccounts = () => {
  const navigate = useNavigate();
  const tableRef = useRef();
  const t = useTranslation();
  const dispatch = useDispatch();
  const [gridApi, getGridApi] = useState(null);
  const [dataSelect, setDataSelect] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [showError, setShowError] = useState(false);
  const [isLoadAdminAccount, setIsLoadAdminAccount] = useState(false);
  const [propsModal, setPropsModal] = useState({});
  const [openSuccess, setOpenSuccess] = useState(false);

  const checkedList = useSelector((state) => state.helper.checkedList);
  const data = getAdminAccountData(checkedList, dataSelect);
  const id = getAdminAccountIds(checkedList, dataSelect);

  const handleClose = () => {
    setOpenModal(false);
  };
  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };
  const handleDeleteDevice = async () => {
    const data = {
      ids: id,
    };
    baseRequest.post(uris.ADMIN_ACCOUNT_DELETE, data).then((response) => {
      if (response?.status === 'NG') dispatch(setMessage(response.message));
      else { setOpenSuccess(true); setIsLoadAdminAccount(true); }
    });
  };
  const handleEnable = async () => {
    baseRequest.post(uris.ADMIN_ACCOUNT_ENABLE_DISABLE, { ids: id, disable: false }).then(() => {
      navigate('/admin/finish/settings/adminAccount');
    });
  };
  const handleDisable = async () => {
    baseRequest.post(uris.ADMIN_ACCOUNT_ENABLE_DISABLE, { ids: id, disable: true }).then(() => {
      navigate('/admin/finish/settings/adminAccount');
    });
  };
  useEffect(() => {
    dispatch(helperActions.selectedDevice(data));
  }, [checkedList, dataSelect]);

  useEffect(() => {
    if (checkedList.length === 0) {
      setDataSelect([]);
    }
  }, [checkedList]);

  const handleSelectionChange = () => {
    setDataSelect(gridApi?.getSelectedRows() || []);
  };

  const handleRowDataUpdated = () => {
    gridApi?.forEachNode((node) => {
      if (checkedList.length !== 0) {
        node.setSelected(true);
      }
    });
  };

  const onBtnExport = () => {
    let dataExport;
    if (Array.isArray(data)) {
      dataExport = data.map((item) => {
        const newItem = { ...item };

        newItem.lastLoginTime = item?.lastLoginTime ? formatDateTime(item?.lastLoginTime) : 'No data';
        newItem.updateDate = item?.updateDate ? formatDateTime(item?.updateDate) : 'No data';
        newItem.status = item.status === false ? t('disable') : t('enable');
        return {
          accountName: newItem.name,
          accountId: newItem.uid,
          status: newItem.status,
          department: newItem.department,
          email: newItem.email,
          phoneNumber: newItem.phone,
          role: newItem.role,
          lastLoginTime: newItem.lastLoginTime,
          modifiedDateTime: newItem.updateDate,
        };
      });
    }

    const headers = [t('accountName'), t('accountID'), t('status'), t('department'), t('email'), t('phoneNumber'), t('role'), t('lastLoginTime'), t('modifiedDateTime')];
    const worksheet = utils.json_to_sheet([]);
    utils.sheet_add_aoa(worksheet, [headers], { origin: 'A1' });
    utils.sheet_add_json(worksheet, dataExport, { origin: 'A2', skipHeader: true });
    worksheet['!cols'] = [
      { wch: 10 },
      { wch: 30 },
      { wch: 10 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
    ];
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const csvOutput = utils.sheet_to_csv(worksheet, { header: true });
    const blob = new Blob([csvOutput], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, `ARINA_AdminAccount_list_${moment().format('YYYY-MM-DD_HH-mm-ss')}.csv`);
  };

  const dataTable = {
    getGridApi,
    tableRef,
    floatingFilter: true,
    showPagination: true,
    className: 'config-permission-table',
    isLoadAdminAccount,
    setIsLoadAdminAccount,
    onSelectionChanged: handleSelectionChange,
    onRowDataUpdated: handleRowDataUpdated,
    childrenTop: (
      <div>
        <div className="list-btn">
          {hasPermission('settings.adminAccounts.csv') && (
          <Button
            onClick={onBtnExport}
            className="csv-btn"
            disabled={!(checkedList.length > 0 || dataSelect.length > 0)}
          >
            <InsertDriveFileIcon style={{ paddingRight: '5px', fontSize: '30px' }} />
            <div>CSV</div>
          </Button>
          )}
          {hasPermission('settings.adminAccounts.create') && (
          <Button
            onClick={() => navigate('/admin/settings/adminAccount/add')}
            className="csv-btn"
          >
            <BorderColorIcon style={{ paddingRight: '5px', fontSize: '30px' }} />
            <div>{t('create')}</div>
          </Button>
          )}
          {hasPermission('settings.adminAccounts.delete') && (
          <Button
            onClick={() => { setOpenModal(true); setPropsModal({ confirm: t('confirmDelete'), msgWarning: t('confirmEdit2'), action: handleDeleteDevice }); }}
            className="csv-btn"
            disabled={!(checkedList.length > 0 || dataSelect.length > 0)}
          >
            <DeleteOutlineOutlinedIcon
              style={{ paddingRight: '5px', fontSize: '30px' }}
            />
            <div>{t('sharedDelete')}</div>
          </Button>
          )}
          {hasPermission('settings.adminAccounts.disable') && (
          <Button
            onClick={() => { setOpenModal(true); setPropsModal({ confirm: t('confirmDisableAccount'), msgWarning: '', action: handleDisable }); }}
            className="csv-btn"
            sx={{ padding: '0 5px' }}
            disabled={!(checkedList.length > 0 || dataSelect.length > 0)}
          >
            <NotInterestedIcon style={{ paddingRight: '5px', fontSize: '30px' }} />
            <div>{t('disable')}</div>
          </Button>
          )}
          {hasPermission('settings.adminAccounts.enable') && (
          <Button
            onClick={() => { setOpenModal(true); setPropsModal({ confirm: t('confirmEnableAccount'), msgWarning: '', action: handleEnable }); }}
            className="csv-btn"
            disabled={!(checkedList.length > 0 || dataSelect.length > 0)}
          >
            <PlayArrow style={{ paddingRight: '5px', fontSize: '30px' }} />
            <div>{t('enable')}</div>
          </Button>
          )}
        </div>
      </div>
    ),
  };
  return (
    <>
      <div style={{ borderBottom: 'none' }} className="upsert-device-header">
        <FormControl onClick={() => {
          navigate('/admin/settings');
        }}
        >
          <HeaderSettings />
        </FormControl>
      </div>

      <TableAdminAccounts {...dataTable} />
      <Snackbar
        open={showError}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setShowError(false)}
      >
        <Alert onClose={() => setShowError(false)} severity="success" variant="filled">
          Delete successfully
        </Alert>
      </Snackbar>
      <ModalConfirmAdmin
        openModal={openModal}
        handleClose={handleClose}
        propsModal={propsModal}
      />
      <ModalSuccess
        openSuccess={openSuccess}
        handleClose={handleCloseSuccess}
        title="Admin account deleted successfully!"
      />
    </>
  );
};

export default ListAccounts;
