/* eslint-disable react/no-array-index-key */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import { makeStyles } from '@mui/styles';
import {
  Backdrop, CircularProgress, Skeleton, Pagination, TablePagination,
} from '@mui/material';
import { helperActions } from '../../store';
import Table from './Table';
import checkBoxConfig from './CheckBoxConfig';
import TargetCheckBoxConfig from './TargetCheckBoxConfig';
import floatingCheckBox from './FloatingCheckbox';
import floatingTargetCheckBox from './FloatingTargetCheckbox';
import { useTranslation } from '../../common/components/LocalizationProvider';
import fetchApi from '../FetchApi';
import { mockData } from '../data';
import { useEffectAsync } from '../../reactHelper';

const useStyle = makeStyles(() => ({
  contentBox: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '1',
  },
  pagination: {
    '&.Mui-selected': {
      color: 'red',
    },
  },
}));
const buttonDetail = (data) => {
  const t = useTranslation();
  const dispatch = useDispatch();
  return (
    <Link
      underline="always"
      sx={{ color: 'blue' }}
      onClick={() => dispatch(helperActions.addTargetDetail(data))}
      to="/admin/home/details"
    >
      {t('detailTableHeader')}
    </Link>
  );
};

const listColumPercent = {
  checkbox: 2.71,
  detail: 3.48,
  category: 5.42,
  status: 6.20,
  name: 6.97,
  managerId: 6.20,
  disabled: 4.26,
  mail: 13.17,
  groupName: 5.5,
  targetName: 7.75,
  targetId: 6.58,
  subManager: 6.20,
  subId: 6.97,
  subMail: 11.62,
};

const TableConfig = (props) => {
  const {
    removeAllColumn,
    removeColumn,
    listData,
    editable,
    editGroup,
    changeMimamoryPasswordbyId,
    changeSubManagerPasswordbyId,
    changeManagerPasswordbyId,
    newCustomeColumn,
    className,
    subMangerTable,
    saveData,
    showPagination,
    ...others
  } = props;

  const classes = useStyle();
  const elementHeight = 40;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const t = useTranslation();
  const mainRef = useRef();
  const defaultWidth = 1400;

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(2);

  const adminData = useSelector((state) => state.helper.adminData);

  useEffect(() => {
    const configData = (data) => {
      const result = [];
      data.forEach((data) => {
        const newGroup = [];
        data.groups.forEach((targetGroup) => {
          const conditon = targetGroup.members.filter((targetData) => targetData.role === 'submanager');
          const copyTargetGroup = { ...targetGroup };

          if (conditon.length > 0) {
            copyTargetGroup.members = conditon;
            newGroup.push(copyTargetGroup);
          }
        });
        if (newGroup.length > 0) {
          const copyData = { ...data };
          copyData.groups = newGroup;
          result.push(copyData);
        }
      });

      dispatch(helperActions.setAdminData({
        current: result,
        filter: [],
        orderBy: [],
        default: result,
      }));
    };

    const fetchData = async () => {
      dispatch(helperActions.setLoading(true));
      if (!listData) {
        const response = await fetchApi('/api/admin/members/list?role=manager', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            limit: 21,
            offset: 0,
          }),
        }, mockData.data, 'TEST');

        if (response.ok) {
          const objRecive = await response.json();
          if (!subMangerTable) {
            dispatch(helperActions.setAdminData({
              current: objRecive,
              filter: [],
              orderBy: [],
              default: objRecive,
            }));
          } else {
            configData(objRecive);
          }
        }
      }
      dispatch(helperActions.setLoading(false));
    };
    fetchData();
    if (!saveData) {
      dispatch(helperActions.changeActiveTarget(false));
      dispatch(helperActions.changeActiveTable(false));
      dispatch(helperActions.removeAllData());
      dispatch(helperActions.removeAllTarget());
    }
  }, []);

  const convertWidth = (percent) => (defaultWidth * percent) / 100;

  const columnDefs = [
    {
      cellRenderer: (params) => checkBoxConfig(params),
      width: convertWidth(listColumPercent.checkbox),
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filter: false,
      headerComponent: floatingCheckBox,
    },
    {
      headerName: t('detailTableHeader'),
      field: 'detail',
      filter: false,
      cellStyle: { textAlign: 'center' },
      cellRenderer: (params) => buttonDetail(params.data),
      width: convertWidth(listColumPercent.detail),
    },
    {
      headerName: t('categoryTableHeader'),
      width: convertWidth(listColumPercent.category),
      field: 'category',
    },
    {
      headerName: t('statusTableHeader'),
      field: 'status',
      width: convertWidth(listColumPercent.status),
    },
    {
      headerName: t('nameTableHeader'),
      field: 'name',
      width: convertWidth(listColumPercent.name),
    },
    {
      headerName: t('managerIdTableHeader'),
      field: 'id',
      width: convertWidth(listColumPercent.managerId),
      // eslint-disable-next-line react/no-unstable-nested-components
      cellRenderer: ((params) => (
        <div key={params?.data?.id} style={{ height: `${elementHeight}px`, display: 'flex', alignItems: 'center' }}>
          {params?.data?.id}
          {changeManagerPasswordbyId && (
          <EditIcon
            style={{ marginLeft: '1rem' }}
            onClick={() => {
              navigate(`/admin/tools/changePasswordForm/${params?.data?.id}`);
            }}
          />
          )}
        </div>
      )),
    },
    {
      headerName: t('userStatusTableHeader'),
      field: 'disabled',
      width: convertWidth(listColumPercent.disabled),
      cellRenderer: (params) => (params?.data?.disabled ? 'Using' : 'Stopped'),
    },
    {
      headerName: t('managerEmailTableHeader'),
      field: 'email',
      width: convertWidth(listColumPercent.mail),
    },
    {
      headerName: t('subManagerTableHeader'),
      field: 'managerId',
      width: convertWidth(listColumPercent.subManager),
      cellRenderer: (params) => params?.data?.groups?.map((data) => data?.data?.map((e, i) => <div key={i} style={{ height: `${elementHeight}px`, borderBottom: '1px solid black', width: '100%' }}>{e?.name}</div>)),
    },
    {
      headerName: t('subManagerIdTableHeader'),
      field: 'subManagerId',
      width: convertWidth(listColumPercent.subId),
      cellRenderer: (params) => params?.data?.groups?.map((data) => data?.data?.map((e, i) => (
        <div key={i} style={{ height: `${elementHeight}px`, borderBottom: '1px solid black', display: 'flex', alignItems: 'center', justifyContent: changeMimamoryPasswordbyId ? 'end' : 'center' }}>
          <div>{e?.id}</div>
          {changeMimamoryPasswordbyId && (
          <EditIcon
            style={{ marginLeft: '1rem' }}
            onClick={() => {
              navigate(`/admin/tools/changePasswordForm/${e?.id}`);
            }}
          />
          )}
        </div>
      ))),
    },
    {
      headerName: t('groupTableHeader'),
      field: 'group',
      width: convertWidth(listColumPercent.groupName),
      floatingFilterComponentParams: {
        checkBy: 'groupName',
      },
      cellRenderer: (params) => params?.data?.groups?.map((data, index) => {
        if (data?.groupName !== 'OTHER') {
          return (
            <div
              key={index}
              style={{
                height: `${elementHeight * data.members.length}px`,
                borderBottom: '1px solid black',
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                paddingLeft: 'calc(var(--ag-cell-horizontal-padding) - 1px)',
                paddingRight: 'calc(var(--ag-cell-horizontal-padding) - 1px)',
              }}
            >
              <div>{data?.groupName}</div>
              {editGroup && (
              <EditIcon
                style={{ position: 'absolute', right: 20 }}
                onClick={() => {
                  dispatch(helperActions.setGroupEditData({ select: data, current: params?.data }));
                  navigate(`edit/manager=${params?.data?.id}&group=${data?.id}`);
                }}
              />
              )}
            </div>
          );
        }
        return (
          <div key={index} style={{ height: `${elementHeight * data.members.length}px`, borderBottom: '1px solid black', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            {
            data?.members?.map((v, i) => (
              <div
                key={i}
                style={{
                  height: `${elementHeight}px`,
                  width: '100%',
                  display: 'flex',
                  position: 'relative',
                  alignItems: 'center',
                  borderBottom: '1px solid black',
                  paddingLeft: 'calc(var(--ag-cell-horizontal-padding) - 1px)',
                  paddingRight: 'calc(var(--ag-cell-horizontal-padding) - 1px)',
                }}
              >
                <div>No Group</div>
                {editGroup && (
                <EditIcon
                  key={`edit${i}`}
                  style={{ position: 'absolute', right: 20 }}
                  onClick={() => {
                    dispatch(helperActions.setGroupEditData({ select: data, current: params.data }));
                    navigate(`edit/manager=${params.data.id}&group=${data.id}`);
                  }}
                />
                )}
              </div>
            ))
          }
          </div>
        );
      }),
    },
    {
      headerName: t('userTargetHeader'),
      field: 'group',
      width: convertWidth(listColumPercent.targetName),
      // eslint-disable-next-line react/no-array-index-key
      cellRenderer: (params) => params?.data?.groups?.map((data) => data?.members?.map((e, i) => (
        <div
          key={i}
          style={{
            height: `${elementHeight}px`,
            borderBottom: '1px solid black',
            width: '100%',
            paddingLeft: 'calc(var(--ag-cell-horizontal-padding) - 1px)',
            paddingRight: 'calc(var(--ag-cell-horizontal-padding) - 1px)',
          }}
        >
          {e?.name}
        </div>
      ))),
      floatingFilterComponentParams: {
        checkBy: 'name',
      },
    },
    {
      headerName: t('userIdTableHeader'),
      field: 'group',
      width: convertWidth(listColumPercent.targetId),
      cellRenderer: (params) => params?.data?.groups?.map((data) => data?.members?.map((e, i) => (
        <div
          key={i}
          style={{
            paddingLeft: 'calc(var(--ag-cell-horizontal-padding) - 1px)',
            paddingRight: 'calc(var(--ag-cell-horizontal-padding) - 1px)',
            height: `${elementHeight}px`,
            borderBottom: '1px solid black',
            display: 'flex',
            justifyContent: changeMimamoryPasswordbyId ? 'end' : 'center',
            alignItems: 'center',
          }}
        >
          <div>{e?.id}</div>
          {changeMimamoryPasswordbyId && (
          <EditIcon
            style={{ marginLeft: '1rem' }}
            onClick={() => {
              navigate(`/admin/tools/changePasswordForm/${e?.id}`);
            }}
          />
          )}
        </div>
      ))),
      floatingFilterComponentParams: {
        checkBy: 'id',
      },
    },
    {
      headerName: t('userStatusTableHeader'),
      field: 'group',
      width: convertWidth(listColumPercent.disabled),
      // eslint-disable-next-line react/no-array-index-key
      cellRenderer: (params) => params?.data?.groups?.map((data) => data?.members?.map((e, i) => (
        <div
          key={i}
          style={{
            paddingLeft: 'calc(var(--ag-cell-horizontal-padding) - 1px)',
            paddingRight: 'calc(var(--ag-cell-horizontal-padding) - 1px)',
            height: `${elementHeight}px`,
            borderBottom: '1px solid black',
            width: '100%',
          }}
        >
          {e.disabled ? 'Using' : 'Stopped'}
        </div>
      ))),
    },
    {
      headerName: t('subManagerEmailTableHeader'),
      field: 'subManagerEmail',
      width: convertWidth(listColumPercent.subMail),
      cellRenderer: (params) => params?.data?.groups.map((data) => data?.members?.map((e, i) => <div key={i} style={{ height: `${elementHeight}px`, borderBottom: '1px solid black', width: '100%' }}>{e.email}</div>)),
    },
    {
      headerName: '',
      cellRenderer: (params) => params?.data?.groups?.map((data) => data?.members?.map((e, i) => <div key={`${i}checkBoxTarget`} style={{ height: `${elementHeight}px`, borderBottom: '1px solid black', width: '100%' }}><TargetCheckBoxConfig data={{ main: params?.data?.id, target: e, groupId: data?.groupId, currentData: params?.data }} /></div>)),
      width: convertWidth(listColumPercent.checkbox),
      field: 'floatingTargetCheckBox',
      filter: false,
      headerComponent: floatingTargetCheckBox,
    },
  ];

  const configColumn = () => {
    const copyColumn = [...columnDefs];
    if (editable) {
      editable.forEach((number) => copyColumn[number].editable = true);
    }
    if (removeAllColumn) {
      return [];
    }
    if (removeColumn) {
      removeColumn.forEach((number) => copyColumn[number] = 'remove');
      const newCol = copyColumn.filter((cols) => cols !== 'remove');
      const listWidth = newCol.map((data) => data.width);
      const currentWidth = listWidth.reduce((value, currentValue) => value + currentValue, 0);
      newCol.forEach((cols) => cols.width += (defaultWidth - currentWidth) / listWidth.length);
      return newCol;
    }
    return newCustomeColumn || columnDefs;
  };

  const dataTable = {
    customColumnDefs: configColumn,
    listData: listData || adminData.current,
    elementHeight,
    className,
    getTargetArray: ['groups', 'members'],
    ...others,
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };

  return (
    <div className={classes.contentBox} ref={mainRef}>
      <Table {...dataTable} />
      { showPagination && (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Pagination count={10} showFirstButton showLastButton />
        <TablePagination
          component="div"
          count={100}
          page={page}
          onChangePage={handleChangePage}
          rowsPerPage={rowsPerPage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPageOptions={[]}
          nextIconButtonProps={{ style: { display: 'none' } }}
          backIconButtonProps={{ style: { display: 'none' } }}
        />
      </div>
      )}
    </div>
  );
};

export default TableConfig;
