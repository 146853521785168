/* eslint-disable react/no-unstable-nested-components */
import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Pagination, TablePagination } from '@mui/material';
import { filter } from 'lodash';
import store, { helperActions, setMessage } from '../../../../store';
import Table from '../../../components/Table';
import { useTranslation } from '../../../../common/components/LocalizationProvider';
import FloatingFilterComponent from '../../../components/FloatingFilterComponent';
import {
  NetworkSetting,
  AdminAccountStatus,
  Device,
  PaymentStatus,
} from '../../../common/DefineClass';
import { convertWidth } from '../../../common/utils';
import CheckBoxAll from '../../../components/CheckBoxAll';
import DatePicker from '../../../components/DatePicker';
import baseRequest from '../../../../apis/baseRequest';
import '../../../styles/components/NewDeviceTable.css';
import uris from '../../../../apis/uris';
import DateRangePicker from '../../../components/DateRangePicker';
import { formatDateUTCToLocal } from '../../../common/handleTimeZone';
import UseFilter from '../../../components/UseFilter';
import ModalSuccess from '../../../components/modal/ModalSuccess';
import SelectFilter from '../../../tools/components/mapInfo/SelectFilter';

const TableNetworkSetting = (props) => {
  const {
    removeAllColumn,
    removeColumn,
    listData,
    editable,
    editGroup,
    changePasswordById,
    changeGroupById,
    newCustomeColumn,
    className,
    saveData,
    editStatus,
    showPagination,
    getElementRef,
    setGridApi,
    setIsLoadNetwork,
    listChecked,
    isLoadNetwork,
    ...others
  } = props;
  const mainRef = useRef();
  const dispatch = useDispatch();
  const t = useTranslation();
  const NetworkSettingModel = new NetworkSetting();
  const adminStatus = new AdminAccountStatus();
  const navigate = useNavigate();
  const filterData = useSelector((state) => state.helper.adminData.filter);
  const direct = useSelector((state) => state.helper.adminData.orderBy);

  const [data, setData] = useState([]);
  const [listNode, setListNode] = useState([]);

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(50);
  const [checked, setChecked] = useState(false);
  const [roles, setRoles] = useState([]);

  const count = Math.ceil(totalPages / limit);
  const detailButton = (data) => (
    <Link
      underline="always"
      style={{ color: '#1e70c4' }}
      to={`/admin/settings/networkSetting/edit/${data.id}`}
    >
      Edit
    </Link>
  );

  // Declare columns
  const columnDefs = [
    {
      width: convertWidth(4),
      field: 'isChecked',
      filter: false,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      headerComponent: () => <CheckBoxAll setCheck={setChecked} />,
    },
    {
      headerName: 'Edit',
      cellStyle: { textAlign: 'center' },
      width: convertWidth(6),
      cellRenderer: (params) => detailButton(params.data),
      field: 'id',
      filter: false,
    },
    {
      headerName: t('ipAddress'),
      width: convertWidth(21),
      field: NetworkSettingModel.ipAddress,
      type: 'string',
      floatingFilterComponent: FloatingFilterComponent,
    },
    {
      headerName: t('spare'),
      field: NetworkSettingModel.spare,
      width: convertWidth(25),
      type: 'string',
      floatingFilterComponent: FloatingFilterComponent,
    },
    {
      headerName: t('createdAt'),
      field: NetworkSettingModel.createdAt,
      width: convertWidth(19),
      type: 'date',
      cellStyle: { textAlign: 'center' },
      cellRenderer: (params) => (params?.data?.createdAt ? formatDateUTCToLocal(params?.data?.createdAt, 'YYYY/MM/DD HH:mm') : 'No data'),
      floatingFilterComponent: DateRangePicker,
    },
    {
      headerName: t('lastModified'),
      field: NetworkSettingModel.lastModified,
      width: convertWidth(19),
      type: 'date',
      cellStyle: { textAlign: 'center' },
      cellRenderer: (params) => (params?.data?.lastModified ? formatDateUTCToLocal(params?.data?.lastModified, 'YYYY/MM/DD HH:mm') : 'No data'),
      floatingFilterComponent: DateRangePicker,
    },

  ];

  const components = useMemo(() => ({
    agDateInput: DatePicker,
  }), []);

  // Configuration for columns
  const configColumn = () => {
    const copyColumn = [...columnDefs];
    if (editable) {
      editable.forEach((number) => (copyColumn[number].editable = true));
    }
    if (removeAllColumn) {
      return [];
    }
    if (removeColumn) {
      removeColumn.forEach((number) => (copyColumn[number] = 'remove'));
      const newCol = copyColumn.filter((cols) => cols !== 'remove');
      return newCol;
    }
    return newCustomeColumn || columnDefs;
  };

  useEffect(() => {
    listNode.forEach((node) => {
      if (listChecked?.includes(node.data.id)) {
        node.setSelected(true);
      }
    });
  }, [listNode, listChecked]);

  const dataTable = {
    className,
    setListNode,
    rowSelection: 'multiple',
    rowMultiSelectWithClick: true,
    customColumnDefs: configColumn(),
    listData: data,
    components,
    ...others,
  };

  const getData = (filter, limit, offset, direct, checked) => {
    baseRequest.post(uris.NETWORK_SETTING_LIST, {
      limit,
      offset,
      orderBy: direct.filter((data) => data.direct),
      filterBy: filter.filter((data) => data.value),
    }).then((response) => {
      setTotalPages(response?.total);
      setData(response?.data);
      if (checked === true) {
        dispatch(helperActions.getCheckedList(response?.data));
      } else if (checked === null) {
        dispatch(helperActions.getCheckedList(response?.data));
      } else {
        dispatch(helperActions.getCheckedList([]));
      }
    });
    if (!saveData) {
      dispatch(helperActions.removeAllData());
    }
  };

  // Function to change page
  const handlePageChange = (event, page) => {
    const offsetValue = page * limit - limit;
    setOffset(offsetValue);
    setCurrentPage(page);
    dispatch(helperActions.currentPage(page));
    setLimit(limit);
    setChecked(false);
    getData(filterData, limit, offsetValue, direct);
  };

  useEffect(() => {
    if (checked === true) {
      getData(filterData, 0, 0, direct, true);
    } else if (checked === null) {
      getData(filterData, limit, offset, direct, null);
    } else {
      getData(filterData, limit, offset, direct, false);
    }
  }, [filterData, direct, checked]);
  useEffect(() => {
    if (isLoadNetwork) {
      getData(filterData, limit, offset, direct, false);
      setIsLoadNetwork(false);
    }
  }, [isLoadNetwork]);
  useEffect(() => {
    setOffset(0);
    setLimit(50);
    setCurrentPage(1);
    setChecked(false);
    getData(filterData, 50, 0, direct, false);
  }, [filterData]);

  return (
    <div className="new-device-table-content-box" ref={mainRef}>
      {Array.isArray(roles) && (
      <Table {...dataTable} />
      )}
      {showPagination === true && (
      <div className="container-pagination">
        <Pagination
          className="pagination"
          size="small"
          count={count}
          page={currentPage}
          onChange={handlePageChange}
          showFirstButton
          showLastButton
        />
        <TablePagination
          size="small"
          component="div"
          count={totalPages}
          rowsPerPage={limit}
          page={currentPage - 1}
          onPageChange={() => {}}
          rowsPerPageOptions={[]}
          nextIconButtonProps={{ style: { display: 'none' } }}
          backIconButtonProps={{ style: { display: 'none' } }}
        />
      </div>
      )}

    </div>
  );
};

export default TableNetworkSetting;
