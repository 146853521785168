import {
  Box, Button, Modal, Typography,
} from '@mui/material';
import React from 'react';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import configColorLayout from '../../../settings/ConfigColorLayout';

const ModalSuccess = ({ openSuccess, handleClose, title }) => {
  const t = useTranslation;
  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <Modal
      open={openSuccess}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 500,
          height: 170,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 3,
        }}
      >
        <div>
          <Typography className="upsert-device-modal-title">{title}</Typography>
          <div className="upsert-device-modal-content">
            <Button
              variant="contained"
              onClick={handleClose}
              sx={{ mt: 1, mb: 2, width: '100px', backgroundColor: configColorLayout }}
            >
              Ok
            </Button>

          </div>
        </div>
      </Box>
    </Modal>
  );
};
export default ModalSuccess;
