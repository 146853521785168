import React from 'react';
import { List, ListItemIcon, MenuItem } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import HandymanIcon from '@mui/icons-material/Handyman';
import SettingsIcon from '@mui/icons-material/Settings';
import { Link, useLocation } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { DevicesOther } from '@mui/icons-material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useTranslation } from '../../common/components/LocalizationProvider';
import configColorLayout from '../../settings/ConfigColorLayout';
import { decrypted } from '../../payment/components/utils';
import hasPermission from '../common/hasPermission';

const useStyles = makeStyles(() => ({
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: configColorLayout,
    '&.Mui-selected': {
      backgroundColor: '#F0C000',
    },
    '&:hover, &:focus': {
      backgroundColor: '#F0C000',
    },
  },
  menuIcon: {
    fontSize: '3.5rem', color: 'black',
  },
  menuTitle: {
    fontSize: '2rem', fontWeight: 'bold',
  },
  menuList: {
    '&.MuiList-root': {
      padding: '4px 0',
    },
  },
}));

const AdminMenu = () => {
  const t = useTranslation();
  const classes = useStyles();
  const location = useLocation();
  // eslint-disable-next-line no-undef
  const permissions = JSON.parse(decrypted(localStorage.getItem('permissions')));

  const menuItems = [
    { title: t('homepage'), link: '/admin/home', icon: <HomeIcon className={classes.menuIcon} />, selected: '/admin/home', permission: 'home' },
    { title: t('devices'), link: '/admin/devices', icon: <DevicesOther className={classes.menuIcon} />, selected: '/admin/devices', permission: 'device' },
    { title: t('tool'), link: '/admin/tools', icon: <HandymanIcon className={classes.menuIcon} />, selected: '/admin/tools', permission: 'tools' },
    { title: t('timesheet'), link: '/admin/timesheet', icon: <CalendarMonthIcon className={classes.menuIcon} />, selected: '/admin/timesheet', permission: 'timeSheet' },
    { title: t('settingsTitle'), link: '/admin/settings', icon: <SettingsIcon className={classes.menuIcon} />, selected: '/admin/settings', permission: 'settings' },
  ];
  return (
    <List className={classes.menuList}>
      {menuItems.map((item) => hasPermission(item.permission) && (
        <MenuItem
          key={item.permission}
          disabled={!permissions?.some((x) => x.role === item.permission)}
          component={Link}
          to={item.link}
          selected={location.pathname.startsWith(item.selected)}
          className={classes.menuItem}
        >
          <ListItemIcon>{item.icon}</ListItemIcon>
          <div className={classes.menuTitle}>{item.title}</div>
        </MenuItem>
      ))}
    </List>
  );
};

export default AdminMenu;
