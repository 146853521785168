import React, { useEffect, useState } from 'react';
import {
  Button,
  FormControl,
  Modal,
  Box,
  Typography,
  Grid,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Autocomplete,
  TextField,
  FormHelperText,
} from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Controller, useForm } from 'react-hook-form';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import HeaderDevice from '../../../tools/components/HeaderDevice';
import configColorLayout from '../../../../settings/ConfigColorLayout';
import { useTranslation } from '../../../../common/components/LocalizationProvider';
import FormTextField from '../../../components/form/FormTextField';
import FormAutoComplete from '../../../components/form/FormAutoComplete';
import baseRequest from '../../../../apis/baseRequest';
import './components/AccountInfo.css';
import uris from '../../../../apis/uris';
import store, { setMessage } from '../../../../store';
import ModalConfirm from '../../../components/modal/ModalConfirm';
import { encrypted } from '../../../common/utils';
import ModalSuccess from '../../../components/modal/ModalSuccess';
import LabelAdd from '../../../devices/components/LabelAdd';

const CreateAccountAdmin = () => {
  const { control, handleSubmit, setValue, watch, formState: { isValid, isDirty, errors } } = useForm({
    mode: 'onChange',
  });

  const [roles, setRoles] = useState([]);

  const [openSuccess, setOpenSuccess] = useState(false);
  // nếu id khác null thì handle edit
  const { id } = useParams();
  const passwordValue = watch('password', '');
  const [openModal, setOpenModal] = useState(false);
  const [showPassword, setShowPassword] = useState(true);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(true);
  const [loadingRoles, setLoadingRoles] = useState(true);

  const navigate = useNavigate();
  const t = useTranslation();
  const handleUppsertAdminAccount = (data) => {
    data.roleId = data.role.id;
    data.password = data.password === '' ? '' : encrypted(data.password);
    if (id) {
      data.id = id;
    }

    delete data.uid;
    delete data.confirmPassword;
    delete data.role;
    baseRequest.post(id ? uris.EDIT_ADMIN_ACCOUT : uris.ADMIN_ACCOUNT_CREATE, data).then((response) => (response.status === 'NG' ? store.dispatch(setMessage(response.error)) : setOpenSuccess(true)));
  };
  const fetchManagerUsers = async () => {
    await baseRequest.get(uris.LIST_ROLE_ACCOUNT).then((response) => {
      if (response?.status === 'NG') {
        store.dispatch(setMessage(response?.error || ''));
      } else {
        setRoles(response?.data);
        setLoadingRoles(false);
      }
    });
  };
  const handleClose = () => {
    setOpenModal(false);
  };
  const handleBack = () => {
    setOpenModal(false);
    navigate('/admin/settings/adminAccount');
  };

  const handleCheckData = () => {
    if (isDirty) {
      setOpenModal(true);
    } else {
      navigate('/admin/settings/adminAccount');
    }
  };

  const handleDetailAccount = async () => {
    const data = { userId: id };
    await baseRequest.post(uris.DETAIL_ADMIN_ACCOUNT, data).then((response) => {
      if (response?.status === 'NG') {
        store.dispatch(setMessage(response?.error || ''));
      } else {
        const role = roles?.find((role) => role.id === response?.data?.roleId);
        setValue('name', response?.data?.name);
        setValue('id', response?.data?.id);
        setValue('department', response?.data?.department);
        setValue('email', response?.data?.email);
        setValue('role', role);
        setValue('password', '');
        setValue('confirmPassword', '');
        setValue('phoneNumber', response?.data?.phoneNumber);
      }
    });
  };
  useEffect(() => {
    const fetchData = async () => {
      await fetchManagerUsers(); // Đợi fetchManagerUsers hoàn tất
      if (!loadingRoles && id) {
        // Chỉ gọi handleDetailAccount nếu roles đã được tải xong
        await handleDetailAccount(); // Đợi handleDetailAccount hoàn tất
      }
    };

    fetchData();
  }, [id, loadingRoles]);

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
    navigate('/admin/settings/adminAccount');
  };

  return (
    <div>
      <div style={{ borderBottom: 'none' }} className="upsert-device-header">
        <FormControl>
          <HeaderDevice handleOnNavigate={handleCheckData} noDescription />
        </FormControl>
      </div>

      <ModalSuccess
        openSuccess={openSuccess}
        handleClose={handleCloseSuccess}
        title="Admin Account has resgistered"
      />
      <ModalConfirm
        openModal={openModal}
        handleClose={handleClose}
        propsModal={
          {
            confirm: t('confirmMessage'),
            msgWarning: t('messageWarning'),
            action: handleBack,
          }
        }
        textAcceptButton={t('accept')}
      />
      <div className="container-form">
        <div className="title-form">{t('accountInfo')}</div>
        <form onSubmit={handleSubmit(handleUppsertAdminAccount)} className="main-form">
          <Grid container spacing={1} ml={15} mt={3}>
            <Grid item xs={6}>
              <FormTextField
                label={t('accountName')}
                controllerProps={{
                  control,
                  name: 'name',
                  rules: { required: t('FieldNotNull'), maxLength: { value: 128, message: t('Max128D') } },
                }}
                required
                autocomplete="off"
              />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
                label={t('accountID')}
                controllerProps={{
                  control,
                  name: 'id',
                }}
                textFieldProps={{
                  disabled: true,
                  placeholder: t('auto'),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
                label={t('department')}
                controllerProps={{
                  control,
                  name: 'department',
                  rules: { required: t('FieldNotNull'), maxLength: { value: 128, message: t('Max128D') } },
                }}
                required
              />
            </Grid>

          </Grid>
          <Grid container spacing={1} ml={15}>
            <Grid item xs={6}>
              <FormTextField
                label={t('email')}
                controllerProps={{
                  control,
                  name: 'email',
                  rules: {
                    required: t('FieldNotNull'),
                    maxLength: { value: 128, message: t('Max128D') },
                    pattern: {
                      value: /^(?!.*\.\.)(?!^\.)[a-zA-Z0-9]+([._+-][a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/,
                      message: t('validateEmail'),
                    },
                  },
                }}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
                label={t('phoneNumber')}
                controllerProps={{
                  control,
                  name: 'phoneNumber',
                  rules: {
                    required: false,
                    pattern: {
                      value: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
                      message: t('validatePhoneNumber'),
                    },
                  },
                }}
              />
            </Grid>
            {roles && !watch('role') && (
            <Grid item xs={6}>
              <FormAutoComplete
                label={t('role')}
                controllerProps={{
                  control,
                  name: 'role',
                  rules: { required: t('FieldNotNull') },
                }}
                autoCompleteProps={{
                  getOptionLabel: (option) => option?.role || '',
                  options: roles,
                }}
                required
              />
            </Grid>

            )}
            {watch('role') && (
            <Grid item xs={6}>
              <FormAutoComplete
                label={t('role')}
                controllerProps={{
                  control,
                  name: 'role',
                  rules: { required: t('FieldNotNull') },
                }}
                autoCompleteProps={{
                  getOptionLabel: (option) => option?.role || '',
                  options: roles,
                }}
                required
              />
            </Grid>

            )}

          </Grid>
          <Grid container spacing={1} ml={15} mb={3}>
            <Grid item xs={6}>
              <FormTextField
                label={t('userPassword')}
                controllerProps={{
                  control,
                  name: 'password',
                  rules: {
                    required: id ? false : t('FieldNotNull'),
                    minLength: { value: 8, message: t('requiredPassword') },
                  },
                }}
                autocomplete="off"
                required={!id}
                textFieldProps={{
                  type: showPassword ? 'password' : 'text',
                  placeholder: t('requiredPassword'),
                  InputProps: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label={showPassword ? 'Hide password' : 'Show password'}
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  },
                }}
              />

            </Grid>
            <Grid item xs={6}>
              <FormTextField
                label={t('confirmPassword')}
                controllerProps={{
                  control,
                  name: 'confirmPassword',
                  rules: {
                    required: id ? false : t('FieldNotNull'),
                    minLength: { value: 8, message: t('requiredPassword') },
                    validate: (value) => value === passwordValue || t('notMatchPassword'),
                  },
                }}
                textFieldProps={{
                  placeholder: t('requiredPassword'),
                  type: showPasswordConfirm ? 'password' : 'text',
                  InputProps: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label={showPassword ? 'Hide password' : 'Show password'}
                          onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
                          edge="end"
                        >
                          {showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  },
                }}
                labelStyle={{ width: '150px' }}
                required={!id}
              />
            </Grid>

          </Grid>
          <div className="upsert-device-add-item">
            <Button
              disabled={!id ? !isValid : !(Object.keys(errors).length === 0 && errors.constructor === Object)}
              type="submit"
              variant="contained"
              style={{ backgroundColor: configColorLayout, width: '150px' }}
            >
              {id ? t('sharedSave') : t('shareAddNew')}
            </Button>
          </div>
        </form>
      </div>

    </div>
  );
};

export default CreateAccountAdmin;
