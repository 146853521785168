import {
  Box, Button, Modal, Typography,
} from '@mui/material';
import React from 'react';
import { useTranslation } from '../../../common/components/LocalizationProvider';
// eslint-disable-next-line import/no-extraneous-dependencies

const ModalConfirm = ({ openModal, handleClose, propsModal, textAcceptButton }) => {
  const t = useTranslation();
  const { confirm, msgWarning, action } = propsModal;
  return (
  // eslint-disable-next-line react/jsx-filename-extension
    <div className="form-text-field">
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 500,
            height: 200,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <div>
            <Typography className="upsert-device-modal-title">{confirm}</Typography>
            <br />
            <Typography className="upsert-device-modal-title">{msgWarning}</Typography>
            <div className="upsert-device-modal-content">
              <Button
                className="close-button"
                variant="contained"
                sx={{ mt: 1, mb: 2 }}
                onClick={handleClose}
              >
                {t('sharedCancel')}
              </Button>
              <Button
                className="submit-button"
                variant="contained"
                onClick={() => {
                  handleClose();
                  action();
                }}
                sx={{ mt: 1, mb: 2 }}
              >
                {textAcceptButton || t('perform')}
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ModalConfirm;
