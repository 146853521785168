import { Box, IconButton, Modal } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import baseRequest from '../../../apis/baseRequest';
import uris from '../../../apis/uris';
import store, { setMessage } from '../../../store';

const QRCodeLogin = ({ showQR, setShowQR, id }) => {
  const [url, setUrl] = useState();
  const handleShowQRCode = () => {
    const data = { userId: id };
    baseRequest.post(uris.SHOW_QRCODE, data).then((response) => {
      if (response?.status === 'NG') {
        store.dispatch(setMessage(response?.error || ''));
      } else {
        setShowQR(true);
        setUrl(response.data);
      }
    });
  };
  useEffect(() => {
    if (showQR) {
      handleShowQRCode();
    }
  }, [showQR]);
  return (
    url && (
    <Modal
      open={showQR}
      onClose={() => setShowQR(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          height: 420,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 3,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <IconButton
          onClick={() => setShowQR(false)}
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <img src={`data:image/png;base64,${url}`} width="80%" height="80%" alt="qr code" />
        {/* <QRCode value={url} size={250} /> */}
      </Box>
    </Modal>
    )

  );
};
export default QRCodeLogin;
