/* eslint-disable import/no-extraneous-dependencies */
import React, {
  useState, useEffect, useRef, useCallback,
} from 'react';
import {
  Button, Switch, Paper, Toolbar, IconButton, Typography, Slider,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import MapView, { map } from '../map/core/MapView';
import MapRoutePath from '../map/MapRoutePathLocationSharing';
import MapPositions from '../map/MapPositionsLocationSharing';

import { useTranslation } from '../common/components/LocalizationProvider';
import DeviceDrawer from '../location_sharing/DeviceDrawer';
import { devicesActions, helperActions } from '../store';
import MapCamera from '../map/MapCamera';
import baseRequest from '../apis/baseRequest';
import uris from '../apis/uris';
import { useEffectAsync } from '../reactHelper';
import canvasMapInfo, { cleanupMap } from '../common/util/canvasMapInfo';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    overflow: 'hidden',
    '& .maplibregl-ctrl': {
      marginTop: '70px',
    },
    '& .maplibregl-ctrl-top-right': {
      display: 'none',
    },
  },
  title: {
    flexGrow: 1,
  },
  slider: {
    width: '100%',
    color: '#009380',
  },
  controls: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dialogBox: {
    '& .MuiPaper-rounded': {
      borderRadius: '20px',
    },
  },
  dialog: {
    width: '310px',
    height: '207px',
    padding: '0 24px',
    borderRadius: '50px',
  },
  dialogText: {
    margin: '52px auto 30px auto',
    color: '#6C6A6A',
    fontSize: '15px',
    textAlign: 'center',
  },
  dialogBtn: {
    width: '100%',
    height: '47px',
    color: 'white',
    backgroundColor: '#009380',
    borderRadius: '50px',
    '&:hover': {
      color: 'white',
      backgroundColor: '#009380',
    },
    '&:active': {
      color: 'white',
      backgroundColor: '#009380',
    },
  },
  switchReplay: {
    position: 'absolute',
    right: '10px',
    padding: '10px',
    display: 'flex',
    top: '70px',
    '& .MuiSwitch-track': {
      backgroundColor: '#009380 !important',
      opacity: '1 !important',
      borderRadius: '10px',
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: 'white',
    },
  },
}));

const HistoryLocationSharingPage = () => {
  const t = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();
  const timerRef = useRef();
  const dispatch = useDispatch();

  const desktop = useMediaQuery(theme.breakpoints.up('md'));
  const phone = useMediaQuery(theme.breakpoints.down('sm'));

  const turnOffDrawer = useSelector((state) => state.helper.active);
  const defaultDeviceId = useSelector((state) => state.devices.selectedId);
  const positions = useSelector((state) => state.positions.todayHistory);
  const members = useSelector((state) => state.helper.userData);
  const selectedMember = useSelector((state) => state.devices.members);

  const originalMember = selectedMember[0];

  const [index, setIndex] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [data, setData] = useState([]);
  const [dataMapInfo, setDataMapInfo] = useState([]);
  const [address, setAddress] = useState([]);

  const onClick = useCallback((positionId) => {
    if (positionId) {
      // navigate(`/position/${positionId}`);
      console.log('clicked');
    }
  }, [navigate]);

  const fetchData = async () => {
    baseRequest.get(uris.SHARE_LOCATION_LIST_MAP_INFO).then((response) => setDataMapInfo(response?.data));
  };

  useEffect(() => {
    if (members && members.length > 0) {
      const mergedData = positions?.map((position) => {
        const mergedObject = {
          id: originalMember.uid,
          attributes: {
            batteryLevel: position?.bat,
            distance: '',
          },
          deviceId: originalMember.uid,
          latitude: position?.lat,
          longitude: position?.lng,
          speed: position?.spd,
          address: '',
          accuracy: position?.acc,
          status: 'online',
          avatar: originalMember.avatar,
          name: originalMember.name,
        };

        return mergedObject;
      });
      setData(mergedData);
    }
  }, [positions, members]);

  // Biến đổi mảng 2 để thêm các trường còn thiếu từ mảng mẫu
  const transformedPositionArray = positions.map((item) => ({
    ...originalMember,
    ...item,
  }));

  const noDataMember = [originalMember];

  // const getLocations = () => {
  //   const positionsArray = transformedPositionArray.map((it) => ({ latitude: it.lat, longitude: it.lng, deviceId: it.uid }));
  //   positionsArray.forEach(({ latitude, longitude, deviceId }) => {
  //     const showAddress = async () => {
  //       if (positionsArray.length > 0 && latitude && longitude) {
  //         const query = new URLSearchParams({ latitude, longitude });
  //         const response = await fetch(`https://tre-stg.tks.asia/api/server/geocode?${query.toString()}`);
  //         if (response.ok) {
  //           const getAddress = await response.text();
  //           // dispatch(positionsActions.pushAddress({ getAddress, deviceId }));
  //         } else {
  //           // throw Error(await response.text());
  //           console.log('error');
  //         }
  //       }
  //     };
  //     showAddress();
  //   });
  // };

  // const getLocations = () => {
  //   const fetchAddress = async (it) => {
  //     const { lat, lng, uid } = it;

  //     if (lat && lng) {
  //       const query = new URLSearchParams({ latitude: lat, longitude: lng });
  //       const response = await fetch(`https://tre-stg.tks.asia/api/server/geocode?${query.toString()}`);

  //       if (response.ok) {
  //         const getAddress = await response.text();
  //         return { ...it, address: getAddress };
  //       }
  //       console.log('Error fetching address');
  //     }

  //     return it;
  //   };

  //   const updatedTransformedPositionArray = transformedPositionArray.map(fetchAddress);

  //   // console.log(updatedTransformedPositionArray);
  //   setAddress(updatedTransformedPositionArray);
  // };
  // console.log({ address, transformedPositionArray });

  // useEffect(() => {
  //   getLocations();
  // }, []);

  useEffect(() => {
    if (playing && transformedPositionArray?.length > 0) {
      timerRef.current = setInterval(() => {
        if (index >= transformedPositionArray.length) {
          return;
        }
        setIndex((index) => Math.min(index + 1, transformedPositionArray.length - 1));
      }, 500);
    } else {
      clearInterval(timerRef.current);
    }

    return () => clearInterval(timerRef.current);
  }, [playing, transformedPositionArray]);

  useEffect(() => {
    if (index >= transformedPositionArray?.length - 1) {
      clearInterval(timerRef.current);
      setPlaying(false);
    }
  }, [index, transformedPositionArray]);

  useEffect(() => {
    fetchData().catch();
    if (defaultDeviceId) {
      if (transformedPositionArray.length > 0) {
        setIndex(0);
        if (transformedPositionArray?.length) {
          setPlaying(!playing);
        } else {
          setOpenDialog(true);
        }
      }
    }
  }, []);

  useEffectAsync(async () => {
    await canvasMapInfo(dataMapInfo);
    return () => {
      cleanupMap(dataMapInfo);
    };
  }, [dataMapInfo]);

  if (transformedPositionArray.length > 0) {
    return (
      <div className={classes.root}>
        <MapView>
          <MapRoutePath positions={positions} />
          {index < transformedPositionArray?.length && data && (
            <MapPositions positions={[data[index]]} onClick={onClick} members={members} />
          )}
        </MapView>
        <MapCamera coordinates={positions.map((item) => [item.lng, item.lat])} />
        <Switch
          className={classes.switchReplay}
          checked
          onChange={() => {
            dispatch(helperActions.closeHistory());
            dispatch(devicesActions.select(null));
          }}
        />
        <div />
        <DeviceDrawer
          height={200}
          deviceId={defaultDeviceId}
          list={[transformedPositionArray[index]]}
          onlyOne
          closeBtn
          slider={(
            <div style={{ margin: '0 25px' }}>
              <Slider
                className={classes.slider}
                max={transformedPositionArray.length - 1}
                step={null}
                marks={transformedPositionArray.map((_, index) => ({ value: index }))}
                value={index}
                onChange={(_, index) => {
                  if (index >= transformedPositionArray.length) return; setIndex(index);
                }}
              />
            </div>
            )}
        />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <MapView />
      <Switch
        className={classes.switchReplay}
        checked
        onChange={() => {
          dispatch(helperActions.closeHistory());
          dispatch(devicesActions.select(null));
        }}
      />
      <DeviceDrawer
        height={200}
        deviceId={defaultDeviceId}
        list={noDataMember}
        onlyOne
        closeBtn
        slider={(
          <div style={{ margin: '0 25px' }}>
            <Slider
              className={classes.slider}
              max={transformedPositionArray.length - 1}
              step={null}
              marks={transformedPositionArray.map((_, index) => ({ value: index }))}
              value={index}
              onChange={(_, index) => {
                if (index >= transformedPositionArray.length) return; setIndex(index);
              }}
            />
          </div>
            )}
      />
      <Dialog open className={classes.dialogBox}>
        <div className={classes.dialog}>
          <div className={classes.dialogText}>履歴はありません</div>
          <Button
            className={classes.dialogBtn}
            onClick={() => {
              dispatch(helperActions.closeHistory());
              dispatch(devicesActions.select(null));
            }}
          >
            閉じる
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default HistoryLocationSharingPage;
