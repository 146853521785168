/* eslint-disable no-restricted-syntax */
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import {
  IconButton, Typography, Link, Snackbar,
  FormControl,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import moment from 'moment';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Header from '../tools/components/Header';
import { useTranslation } from '../../common/components/LocalizationProvider';
import ConfirmationPopup from '../tools/components/ComfirmationPopup';
import { helperActions } from '../../store';
import QrCode from '../components/QRCode';
import fetchApi from '../FetchApi';
import {
  Member, PaymentStatus,
  RatePlan, BillingMethod, PaymentMethod,
} from '../common/DefineClass';
import NewTable from '../components/NewTable';
import {
  convertWidth, valueTranslate, DropdownEdit, encrypted,
} from '../common/utils';
import DatePickerEditor from '../components/DatePickerEditor';
import ModalConfirm from '../components/modal/ModalConfirm';

const userModel = new Member();
const paymentStatus = new PaymentStatus();
const paymentMethod = new PaymentMethod();

const useStyle = makeStyles((theme) => ({
  qrcodeBox: {
    margin: '1rem 1rem 1rem 3rem',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      marginLeft: '1rem',
    },
  },
  invitationCode: {
    fontSize: 20,
    fontWeight: 'bold',
    textDecoration: 'underline',
    color: '#222A35',
  },
  configTable: {
    height: '600px',
  },
}));

// declare column percentage
const listColumPercent = {
  category: 8,
  status: 10,
  name: 12,
  userId: 8,
  disabled: 7,
  email: 20,
  password: 10,
  representId: 9,
  startDate: 10,
  power: 8,
  battery: 10,
  longlat: 12,
  accquisitionTime: 15,
  actions: 8,
  ratePlan: 15,
  expirationDate: 10,
};

const DetailsEditTable = () => {
  const classes = useStyle();
  const gridRef = useRef();
  const t = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const boxRef = useRef();
  const { id } = useParams();
  const ratePlan = new RatePlan();
  const billingMethod = new BillingMethod();

  const [users, setUsers] = useState([]);
  const [changedRows, setChangedRows] = useState([]);
  const [originalUsers, setOriginalUsers] = useState([]);
  const [manager, setManager] = useState(undefined);
  const [gridApi, getGridApi] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleOpenSnackbar = () => {
    setOpenSnackbar(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleClose}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  const onCellValueChanged = useCallback((e) => {
    const dataOnRow = e.data;
    const oldItemIndex = e.rowIndex;

    const copyChangedRows = [...changedRows];
    const copyUsers = [...users];

    copyChangedRows[oldItemIndex] = dataOnRow;
    copyUsers[oldItemIndex] = dataOnRow;

    setChangedRows(copyChangedRows);
    setUsers(copyUsers);
  });

  const onCellEditRequest = useCallback((e) => {
    const dataOnRow = e.data;
    const { field } = e.colDef;
    const { newValue } = e;
    const oldItem = users.find((row) => row.id === dataOnRow.id);

    const newItem = { ...oldItem };
    newItem[field] = newValue;
    dispatch(helperActions.updateData(newItem));
  });

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchApi(`/api/admin/members/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.ok) {
        const userList = await response.json();
        const newData = [];
        const transformedUsersData = userList.map((data) => ({
          ...data,
          billingmethod: data.billing_method,
          userpaymentid: data.user_payment_id,
          lastbillingdate: data.last_billing_date,
          nextbillingdate: data.next_billing_date,
          updatedate: data.update_date,
          paymentmethod: data.payment_method,
          paymentnumber: data.payment_number,
          paymentspan: data.payment_span,
          rateplan: data.rate_plan,
          historyList: `${data.latitude},${data.longitude}`,
        }));
        transformedUsersData.forEach((val) => {
          const cond = newData.find((data) => data.id === val.id);
          if (!cond) newData.push(val);
        });
        const represent = newData.find((data) => data.role === 1);
        const nullArray = new Array(newData.length).fill(null);
        setManager(represent);
        setUsers(newData);
        setOriginalUsers(newData);
        setChangedRows(nullArray);
      }
    };

    if (id) fetchData();
  }, [id]);

  const differentElements = changedRows.filter((element1) => {
    const existsInArray2 = originalUsers.some((element2) => JSON.stringify(element1) === JSON.stringify(element2));

    return !existsInArray2;
  });

  const result = differentElements.filter((element) => element !== null);

  const representGroupId = users.find((user) => user.id === user.representationId)?.groupId;

  const updateData = result.map((item) => {
    const data = {
      id: item.id,
      name: item.name,
      email: item.email,
      payment_status_code: item.paymentstatuscode,
      rateplan: item.rateplan,
      billingMethod: item.billingmethod,
      paymentMethod: item.paymentmethod,
      enddate: moment.utc(item.nextbillingdate).valueOf(),
    };

    if (item.id === 0) {
      data.groupId = representGroupId;
      data.password = item.password;
      data.managerId = id;
      if (item.category === 1) {
        data.rateplan = item.rateplan;
        data.paymentMethod = 3;
        data.billingMethod = 3;
        data.enddate = item.nextbillingdate ? moment.utc(item.nextbillingdate).valueOf() : moment.utc().valueOf();
      } else {
        data.billingMethod = 1;
        data.paymentMethod = 1;
      }
    }

    return data;
  });

  const detailsColumnDefs = [
    {
      headerName: t('categoryTableHeader'),
      width: convertWidth(listColumPercent.category),
      field: userModel.field2,
      valueFormatter: (params) => valueTranslate(t, params.value, 'account'),
    },
    {
      headerName: t('statusTableHeader'),
      width: convertWidth(listColumPercent.status),
      field: userModel.field3,
      valueFormatter: (params) => valueTranslate(t, params.data.paymentstatuscode, 'paymentStatus'),
      editable: ((params) => {
        if (params.data[userModel.field5] === 0) {
          return true;
        }
        return false;
      }),
      cellEditor: DropdownEdit,
      cellEditorParams: {
        values: [
          { name: t(paymentStatus.newApplication), value: paymentStatus.newApplication },
          { name: t(paymentStatus.continousBilling), value: paymentStatus.continousBilling },
          { name: t(paymentStatus.reminder), value: paymentStatus.reminder },
          { name: t(paymentStatus.cancelationLaw), value: paymentStatus.cancelationLaw },
        ],
      },
    },
    {
      headerName: t('nameTableHeader'),
      width: convertWidth(listColumPercent.name),
      field: userModel.field4,
      editable: true,
    },
    {
      headerName: t('userIdTableHeader'),
      width: convertWidth(listColumPercent.userId),
      field: userModel.field5,
    },
    {
      headerName: t('userStatusTableHeader'),
      width: convertWidth(listColumPercent.disabled),
      field: userModel.field6,
      cellRenderer: (params) => valueTranslate(t, params.value, 'account'),
      // editable: ((params) => {
      //   if (params.data[userModel.field5] === 0) {
      //     return true;
      //   }
      //   return false;
      // }),
      // cellEditor: DropdownEdit,
      // cellEditorParams: {
      //   values: [
      //     { name: t('stopped'), value: true },
      //     { name: t('inUse'), value: false },
      //   ],
      // },
    },
    {
      headerName: t('userEmailTableHeader'),
      width: convertWidth(listColumPercent.email),
      field: userModel.field7,
      editable: ((params) => {
        if (!params.data[userModel.field5]) {
          return true;
        }
        return false;
      }),
    },
    {
      headerName: t('passwordTableHeader'),
      width: convertWidth(listColumPercent.password),
      field: userModel.field13,
      editable: ((params) => {
        if (params.data[userModel.field5] === 0) {
          return true;
        }
        return false;
      }),
    },
    {
      headerName: t('representIdTableHeader'),
      width: convertWidth(listColumPercent.representId),
      field: userModel.field9,
      cellRenderer: (params) => {
        const { data } = params;
        const { representationId, managedUserId } = data;
        return representationId || managedUserId;
      },
    },
    {
      headerName: t('startDateTableHeader'),
      width: convertWidth(listColumPercent.startDate),
      field: userModel.field12,
      cellStyle: { textAlign: 'center' },

      cellRenderer: (params) => {
        const inputDate = params.value;
        const formattedDate = moment(inputDate).local().format('YYYY/MM/DD');
        return formattedDate;
      },
      editable: ((params) => {
        if (params.data[userModel.field5 === 0]) {
          return true;
        }
        return false;
      }),
    },
    {
      headerName: t('powerTableHeader'),
      width: convertWidth(listColumPercent.power),
      field: userModel.field14,
    },
    {
      headerName: t('positionBatteryLevel'),
      width: convertWidth(listColumPercent.battery),
      field: userModel.field15,
    },
    {
      headerName: t('historyList'),
      width: convertWidth(listColumPercent.longlat),
      field: 'historyList',
      cellRenderer: ((params) => (
        params.data[userModel.field7] ? (
          <div key={params.data.userModel?.field4} style={{ width: '100%' }}>
            <Link
              component="button"
              variant="body2"
              onClick={() => {
                navigate(`/admin/home/details/history/${params.data.id}`);
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <p style={{ margin: '0', textAlign: 'left' }}>
                  {params.data.latitude}
                  ,
                </p>
                <p style={{ margin: '0', textAlign: 'left' }}>{params.data.longitude}</p>
              </div>
            </Link>
          </div>
        ) : (<Typography>-</Typography>)
      )),
    },
    {
      headerName: t('acquisition'),
      width: convertWidth(listColumPercent.accquisitionTime),
      field: userModel.field16,
    },
    {
      headerName: t('billingMethodTableHeader'),
      field: userModel.field11,
      width: convertWidth(listColumPercent.billingMethod),
      valueFormatter: (params) => valueTranslate(t, params.data.billingmethod, 'billingMethod'),
      // editable: ((params) => {
      //   if (params.data[userModel.field2] === 1 && params.data[userModel.field5] === 0) {
      //     return true;
      //   }
      //   return false;
      // }),
      // cellEditor: DropdownEdit,
      // cellEditorParams: {
      //   values: [
      //     { name: t(billingMethod.inApp), value: 1 },
      //     { name: t(billingMethod.outside), value: 2 },
      //     { name: t(billingMethod.special), value: 3 },
      //   ],
      // },
    },
    {
      headerName: t('paymentTableHeader'),
      field: userModel.field19,
      width: convertWidth(listColumPercent.paymentMethod),
      valueFormatter: (params) => valueTranslate(t, params.data.paymentmethod, 'paymentMethod'),
      // editable: ((params) => {
      //   if (params.data[userModel.field2] === 1 && params.data[userModel.field5] === 0) {
      //     return true;
      //   }
      //   return false;
      // }),
      // cellEditor: DropdownEdit,
      // cellEditorParams: {
      //   values: [
      //     { name: t(paymentMethod.inApp), value: 1 },
      //     { name: t(paymentMethod.credit), value: 2 },
      //     { name: t(paymentMethod.bank), value: 3 },
      //   ],
      // },
    },
    {
      headerName: t('ratePlanTableHeader'),
      field: userModel.field18,
      width: convertWidth(listColumPercent.ratePlan),
      valueFormatter: (params) => valueTranslate(t, params.data.rateplan, 'ratePlan'),
      editable: ((params) => {
        if (params.data[userModel.field2] === 1 && params.data[userModel.field5] === 0) {
          return true;
        }
        return false;
      }),
      cellEditor: DropdownEdit,
      cellEditorParams: {
        values: [
          { name: t(ratePlan.basic), value: ratePlan.basic },
          { name: t(ratePlan.prenium), value: ratePlan.prenium },
        ],
      },
    },
    {
      headerName: t('expiryDateTableHeader'),
      width: convertWidth(listColumPercent.expirationDate),
      field: userModel.field28,
      cellStyle: { textAlign: 'center' },

      cellRenderer: (params) => {
        // if (params.data[userModel.field2] === 1) {
        //   const inputDate = params.value;
        //   if (inputDate !== null) {
        //     const formattedDate = moment(inputDate).local().format('YYYY/MM/DD');
        //     return formattedDate;
        //   }
        // } else {
        //   console.log(2);
        //   return null;
        // }
        // return null;
        const inputDate = params.value;
        if (inputDate !== null && params.data[userModel.field2] === 1) {
          const formattedDate = moment(inputDate).local().format('YYYY/MM/DD');
          return formattedDate;
        }
        return null;
      },
      editable: ((params) => {
        if (params.data[userModel.field2] === 1) {
          return true;
        }
        return false;
      }),
      cellEditor: DatePickerEditor,
    },
    {
      headerName: 'Actions',
      field: 'actions',
      width: convertWidth(listColumPercent.actions),
      cellRenderer: ((params) => (
        params.data[userModel.field5] ? (
          <Typography>-</Typography>
        ) : (
          <IconButton
            onClick={() => {
              const rowDisplay = [];
              params.api?.applyTransaction({ remove: [params.data] });
              params.api?.forEachNode((node) => {
                rowDisplay.push(node.data);
              });
              setUsers(rowDisplay);
            }}
          >
            <DeleteOutlineOutlinedIcon />
          </IconButton>
        )
      )),
    },
  ];

  useEffect(() => {
    const copyChangedRows = [...changedRows];
    const differentElements = copyChangedRows.filter((element1) => {
      const existsInArray2 = users.some((element2) => JSON.stringify(element1) === JSON.stringify(element2));
      return !existsInArray2;
    });

    const result = differentElements.filter((element) => element !== null);

    // Xóa các phần tử trong mảng 'copyChangedRows' có giá trị tương đương với các phần tử trong mảng 'result'
    result.forEach((item) => {
      const index = copyChangedRows.findIndex((element) => JSON.stringify(element) === JSON.stringify(item));
      if (index !== -1) {
        copyChangedRows.splice(index, 1);
      }
    });

    setChangedRows(copyChangedRows);
  }, [users]);

  const hasEmptyName = updateData.some((obj) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    if (obj.name === '' || obj.email === '') {
      return true;
    }

    if (obj.id === 0) {
      if (!passwordRegex.test(obj.password)) {
        return true;
      }
    }

    return false;
  });

  const handleSubmit = async () => {
    if (hasEmptyName) {
      handleOpenSnackbar();
    } else {
      // Password encryption
      updateData.forEach((item) => item.password = encrypted(item.password));

      const response = await fetchApi('/api/admin/payment_status/settings_v2', {

        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updateData),
      });
      if (response.ok) {
        dispatch(helperActions.setPathname(location.pathname));
        navigate('/admin/finish');
      }
    }
  };

  const dataTable = {
    getGridApi,
    newCustomeColumn: detailsColumnDefs,
    listData: users,
    tableRef: gridRef,
    boxRef,
    className: classes.configTable,
    floatingFilterOff: true,
    onCellEditRequest,
    singleClickEdit: true,
    suppressRowClickSelection: true,
    rowSelection: 'single',
    onCellValueChanged,
    childrenBottom: (
      <div>
        <div className={classes.qrcodeBox}>
          <Typography className={classes.invitationCode}>{t('qrcode')}</Typography>
          <QrCode qrCodeData={manager?.inviteCode} />
          <Typography className={classes.invitationCode}>{t('invitationCode')}</Typography>
          <Typography fontSize={26}>{manager?.inviteCode}</Typography>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: '20px' }}>
          <ConfirmationPopup
            btnTitle={t('perform')}
            btnColor="#ff0505"
            confirmText={t('confirmEdit1')}
            subConfirmText={t('confirmEdit2')}
            disabled={updateData.length === 0}
            submit={handleSubmit}
          />
        </div>
      </div>
    ),
    childrenTop: (
      <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <IconButton
          onClick={() => {
            const copyUsers = [...users];
            const copyOriginalUsers = [...originalUsers];
            copyUsers.push({
              category: manager.category,
              paymentstatuscode: 'newApplication',
              name: '',
              id: 0,
              disabled: null,
              email: '',
              password: '',
              representationId: manager.representationId,
              startDate: '',
              power: '',
              battery: '',
              longlat: '',
              accquisitionTime: '',
              billingmethod: manager.category === 1 ? 3 : null,
              rateplan: manager.category === 1 ? ratePlan.basic : null,
              paymentmethod: manager.category === 1 ? 3 : null,
              enddate: null,
              actions: '',
            });
            setUsers(copyUsers);
            setOriginalUsers(copyOriginalUsers);
          }}
        >
          <AddCircleOutlineOutlinedIcon />
        </IconButton>
      </div>
    ),
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleBack = () => {
    setOpenModal(false);
    navigate(`/admin/home/details/${id}`);
  };
  const handleCheckData = () => {
    // const hasValue = Object.values(watch()).some((value) => value !== '' && value);
    // console.log(hasValue, 'check add');
    if (updateData.length > 0) {
      setOpenModal(true);
    } else {
      navigate(`/admin/home/details/${id}`);
    }
  };
  return (
    <>
      <div className="upsert-device-header">
        <FormControl>
          <Header handleOnNavigate={handleCheckData} noDescription />
        </FormControl>
      </div>
      <ModalConfirm
        openModal={openModal}
        handleClose={handleCloseModal}
        propsModal={
          {
            confirm: t('confirmMessage'),
            msgWarning: t('messageWarning'),
            action: handleBack,
          }
        }
      />
      <NewTable {...dataTable} />
      <Snackbar
        open={openSnackbar}
        autoHideDuration={4000}
        onClose={handleClose}
        message={t('editvalidate')}
        action={action}
      />
    </>
  );
};

export default DetailsEditTable;
