import React, { useEffect, useState } from 'react';
import { Button, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from '../common/components/LocalizationProvider';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    margin: 'auto auto',
    flexDirection: 'column',
    position: 'absolute',
    left: '50%',
    top: '40%',
    transform: 'translate(-50%, -50%)',
  },
  btn: {
    backgroundColor: '#888888',
    border: 'outset 5px #939393',
    margin: '0 auto',
    color: 'white',
    width: '150px',
    '&:hover': {
      backgroundColor: '#888888',
    },
  },
  font: {
    fontWeight: 'bold',
    fontSize: '40px',
    color: '#222a35',
    marginBottom: '20px',
  },
});

const FinishAdminAccountPage = () => {
  const t = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const parts = location.pathname.split('/');
  const lastElement = parts[parts.length - 1];
  const handleNavigate = () => {
    switch (lastElement) {
      case 'adminAccount':
        navigate('/admin/settings/adminAccount');
        break;
      case 'device':
        navigate('/admin/devices');
        break;
      case 'finish':
        navigate('/admin/home');
        break;
      case 'roles':
        navigate('/admin/settings/roles');
        break;
      case 'tools':
        navigate('/admin/tools');
        break;
      case 'mapInfo':
        navigate('/admin/tools/mapInfo');
        break;
      case 'sendNotifications':
        navigate('/admin/tools/sendNotifications');
        break;
      case 'suspensionOrResume':
        navigate('/admin/tools/suspensionOrResume');
        break;
      case 'settings':
        navigate('/admin/tools/mapInfo/settings');
        break;
      case 'networkSetting':
        navigate('/admin/settings/networkSetting');
        break;
      default: break;
    }
  };

  return (
    <div className={classes.root}>
      <Typography className={classes.font}>{t('finish')}</Typography>
      <Button
        className={classes.btn}
        onClick={handleNavigate}
      >
        <Typography style={{ fontSize: '20px', fontWeight: 'bold' }}>{t('close')}</Typography>
      </Button>
    </div>
  );
};

export default FinishAdminAccountPage;
