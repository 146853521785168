import { createSlice } from '@reduxjs/toolkit';

const messageSuccessSlice = createSlice({
  name: 'messageSuccess',
  initialState: {
    message: null,
  },
  reducers: {
    setMessageSuccess(state, action) {
      state.message = action.payload;
    },
    clearMessage(state) {
      state.message = null;
    },
  },
});

export const { setMessageSuccess, clearMessage } = messageSuccessSlice.actions;
export const selectMessageSuccess = (state) => state.messageSuccess.message;
export default messageSuccessSlice.reducer;
