import React, { useEffect, useRef, useState } from 'react';
import {
  Box, Button, TextField, Typography,
} from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import Header from '../Header';
import { helperActions, setMessage } from '../../../../store';
import { useTranslation } from '../../../../common/components/LocalizationProvider';
import NewTable from '../../../components/NewTable';
import { getUserIds, getUserData } from '../../../common/utils';
import hasPermission from '../../../common/hasPermission';
import TableListSendNotify from './TableListSendNotify';
import baseRequest from '../../../../apis/baseRequest';
import uris from '../../../../apis/uris';
import ModalSuccess from '../../../components/modal/ModalSuccess';
import ModalConfirmAdmin from '../../../components/modal/ModalConfirm';

const useStyle = makeStyles((theme) => ({
  configTable: {
    height: '600px',
    width: '100%',
  },
  confirmButton: {
    display: 'flex',
    justifyContent: 'end',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'center',
    },
  },
}));

const ListSendNotifications = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyle();
  const t = useTranslation();

  const checkedList = useSelector((state) => state.helper.checkedList);
  const notification = useSelector((state) => state.helper.notification);
  const [propsModal, setPropsModal] = useState({});
  const [openModal, setOpenModal] = useState(false);

  const [gridApi, getGridApi] = useState(null);
  const [dataSelect, setDataSelect] = useState([]);
  const userIds = getUserIds(checkedList, dataSelect);
  const handleClose = () => {
    setOpenModal(false);
  };

  const handleDeleteNotify = async () => {
    const data = {
      ids: userIds,
    };
    baseRequest.post(uris.DELETE_NOTIFY, data).then((response) => {
      if (response?.status === 'NG') dispatch(setMessage(response.message));
      else { navigate('/admin/finish/tools/sendNotifications'); }
    });
  };
  useEffect(() => {
    const data = getUserData(checkedList, dataSelect);
    dispatch(helperActions.selectedUser(data));
  }, [checkedList, dataSelect]);

  useEffect(() => {
    if (checkedList.length === 0) {
      setDataSelect([]);
    }
  }, [checkedList]);

  const handleSelectionChange = () => {
    setDataSelect(gridApi?.getSelectedRows() || []);
  };

  const handleRowDataUpdated = () => {
    gridApi?.forEachNode((node) => {
      if (checkedList.length !== 0) {
        node.setSelected(true);
      }
    });
  };

  const dataTable = {
    listChecked: userIds,
    getGridApi,
    saveData: true,
    removeColumn: [1],
    showPagination: true,
    className: classes.configTable,
    onSelectionChanged: handleSelectionChange,
    onRowDataUpdated: handleRowDataUpdated,
    childrenTop: (
      <>
        <Header handleOnNavigate={() => navigate('/admin/tools')}>
          <div style={{ margin: '10px 0' }}>
            <Typography style={{ fontSize: '1.5rem' }}>{t('linkListSendNotifications')}</Typography>
            <Typography>{t('notificationContent')}</Typography>
          </div>

        </Header>
        <div className="list-btn-network">
          {hasPermission('settings.networkSetting.new') && (
          <Button
            onClick={() => navigate('/admin/tools/sendNotifications/add')}
            className="network-setting-btn"
            sx={{ width: '110px !important' }}
          >
            <BorderColorIcon style={{ paddingRight: '5px', fontSize: '30px' }} />
            <div>{t('new')}</div>
          </Button>
          )}
          {hasPermission('settings.networkSetting.delete') && (
          <Button
            onClick={() => { setOpenModal(true); setPropsModal({ confirm: t('confirmDelete'), msgWarning: t('confirmEdit2'), action: handleDeleteNotify }); }}
            className="network-setting-btn"
            sx={{ width: '110px !important' }}
            disabled={!(checkedList.length > 0 || dataSelect.length > 0)}
          >
            <DeleteOutlineOutlinedIcon
              style={{ paddingRight: '5px', fontSize: '30px' }}
            />
            <div>{t('sharedDelete')}</div>
          </Button>
          )}
          {hasPermission('settings.networkSetting.delete') && (
          <Button
            // onClick={() => { setOpenModal(true); setPropsModal({ confirm: t('confirmDelete'), msgWarning: t('confirmEdit2'), action: handleDeleteNetwork }); }}
            className="network-setting-btn"
            sx={{ width: '110px !important' }}
            disabled={!(checkedList.length > 0 || dataSelect.length > 0)}
          >
            <VisibilityIcon
              style={{ paddingRight: '5px', fontSize: '30px' }}
            />
            <div>{t('confirm')}</div>
          </Button>
          )}
        </div>
      </>
    ),
  };

  return (
    <>
      <TableListSendNotify {...dataTable} />
      <ModalConfirmAdmin
        openModal={openModal}
        handleClose={handleClose}
        propsModal={propsModal}
      />

    </>
  );
};

export default ListSendNotifications;
