const fetchApi = (url, config, mockData, env) => {
  if (env === 'TEST') {
    const mockdata = new Promise((resolve, reject) => {
      setTimeout(() => resolve(new Response(JSON.stringify(mockData))), 2000);
    });
    return mockdata;
  }

  return fetch(url, config);
};

export default fetchApi;
