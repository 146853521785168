import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Button, Pagination, TablePagination } from '@mui/material';
import BreadcrumbsCustom from '../components/BreadcrumbsCustom';
import { convertWidth } from '../common/utils';
import { useTranslation } from '../../common/components/LocalizationProvider';
import Table from '../components/Table';
import { helperActions } from '../../store';
import baseRequest from '../../apis/baseRequest';
import '../styles/pages/TimeSheetDetailLog.css';
import { formatDateUTCToLocal, formatTimeUTCToLocal } from '../common/handleTimeZone';

const DetailLog = () => {
  const { id, date } = useParams();
  const t = useTranslation();
  const dispatch = useDispatch();
  const tableRef = useRef();

  const [listData, setListData] = useState([]);
  const [checked, setChecked] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(50);

  const count = Math.ceil(totalPages / limit);

  const navigate = useNavigate();
  const filterData = useSelector((state) => state.helper.adminData.filter);
  const direct = useSelector((state) => state.helper.adminData.orderBy);
  const dataConversion = (deviceData) => {
    deviceData.managerUserID = deviceData.managerUser?.id;
    return deviceData;
  };
  const redirectLocationButton = (data) => <span>{data ? 'In' : 'Out'}</span>;
  const columnDefs = [
    {
      headerName: t('UserID'),
      field: 'userId',
      width: convertWidth(6.2),
      filter: false,
    },
    {
      headerName: t('Name'),
      field: 'name',
      width: convertWidth(21),
      filter: false,
    },
    // {
    //   headerName: 'Department',
    //   field: 'department',
    //   width: convertWidth(18),
    //   floatingFilterComponent: null,
    // },
    {
      headerName: t('statusDate'),
      field: 'time',
      width: convertWidth(10),
      cellStyle: { textAlign: 'center' },
      valueFormatter: (params) => formatDateUTCToLocal(params?.data?.time),
      filter: false,
    },
    {
      headerName: 'Time',
      field: 'time',
      filter: false,
      cellStyle: { textAlign: 'center' },

      valueFormatter: (params) => formatTimeUTCToLocal(params.data.time),
      width: convertWidth(10),
    },
    {
      headerName: 'In or Out',
      field: 'status',
      filter: false,
      cellStyle: { textAlign: 'left' },
      cellRenderer: (params) => redirectLocationButton(params?.value),
      width: convertWidth(8),
    },
  ];
  const getData = async (filter, limit, offset, direct, checked) => {
    baseRequest.call('/api/admin/devices/getLogTimesheet', 'POST', {
      userId: id,
      time: `${date.replace(/-/g, '')}+${-new Date().getTimezoneOffset() / 60}`,
      limit,
      offset,
      orderBy: direct?.filter((data) => data.direct),
      filterBy: filter.filter((data) => data.value),
    }).then((response) => {
      setTotalPages(response?.total);
      setListData(response?.data.map(dataConversion));
    }).catch((err) => console.log(err?.response?.data));
  };

  useEffect(() => {
    if (checked === true) {
      getData(filterData, limit, offset, direct, true);
    } else if (checked === null) {
      getData(filterData, limit, offset, direct, null);
    } else {
      getData(filterData, limit, offset, direct, false);
    }
  }, [filterData, direct, checked]);

  const dataTable = {
    tableRef,
    floatingFilterOff: true,
    customColumnDefs: columnDefs,
    listData,
  };
  const handlePageChange = (event, page) => {
    const offsetValue = page * limit - limit;
    setOffset(offsetValue);
    setCurrentPage(page);
    dispatch(helperActions.currentPage(page));
    setLimit(limit);
    setChecked(false);
    getData(filterData, limit, offsetValue, direct);
  };

  return (
    <div className="detail-table-content-box ag-theme-alpine">
      <Button className="back-btn" onClick={() => navigate(-1)}>
        <ArrowForwardIosIcon />
        {t('back')}
      </Button>
      <BreadcrumbsCustom getAfter="admin" className="breadcumbs-detail-log" />

      <Table {...dataTable} />

      <div className="container-pagination">
        <Pagination
          className="pagination"
          size="small"
          count={count}
          page={currentPage}
          onChange={handlePageChange}
          showFirstButton
          showLastButton
        />
        <TablePagination
          size="small"
          component="div"
          count={totalPages}
          rowsPerPage={limit}
          page={currentPage - 1}
          onPageChange={() => {}}
          rowsPerPageOptions={[]}
          nextIconButtonProps={{ style: { display: 'none' } }}
          backIconButtonProps={{ style: { display: 'none' } }}
        />
      </div>

    </div>
  );
};

export default DetailLog;
