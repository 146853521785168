/* eslint-disable react/no-unstable-nested-components */
import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Pagination, TablePagination } from '@mui/material';
import { filter } from 'lodash';
import store, { helperActions, setMessage } from '../../../../../store';
import Table from '../../../../components/Table';
import { useTranslation } from '../../../../../common/components/LocalizationProvider';
import FloatingFilterComponent from '../../../../components/FloatingFilterComponent';
import {
  AdminAccounts,
  AdminAccountStatus,
  Device,
  PaymentStatus,
} from '../../../../common/DefineClass';
import { convertWidth } from '../../../../common/utils';
import CheckBoxAll from '../../../../components/CheckBoxAll';
import DatePicker from '../../../../components/DatePicker';
import baseRequest from '../../../../../apis/baseRequest';
import '../../../../styles/components/NewDeviceTable.css';
import uris from '../../../../../apis/uris';
import DateRangePicker from '../../../../components/DateRangePicker';
import { formatDateUTCToLocal } from '../../../../common/handleTimeZone';
import UseFilter from '../../../../components/UseFilter';
import ModalSuccess from '../../../../components/modal/ModalSuccess';
import SelectFilter from '../../../../tools/components/mapInfo/SelectFilter';

const NewDeviceTable = (props) => {
  const {
    removeAllColumn,
    removeColumn,
    listData,
    editable,
    editGroup,
    changePasswordById,
    changeGroupById,
    newCustomeColumn,
    className,
    saveData,
    editStatus,
    showPagination,
    getElementRef,
    setGridApi,
    setIsLoadAdminAccount,
    listChecked,
    isLoadAdminAccount,
    ...others
  } = props;
  const mainRef = useRef();
  const dispatch = useDispatch();
  const t = useTranslation();
  const AccountModel = new AdminAccounts();
  const adminStatus = new AdminAccountStatus();
  const navigate = useNavigate();
  const filterData = useSelector((state) => state.helper.adminData.filter);
  const direct = useSelector((state) => state.helper.adminData.orderBy);

  const [data, setData] = useState([]);
  const [listNode, setListNode] = useState([]);

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(50);
  const [checked, setChecked] = useState(false);
  const [roles, setRoles] = useState([]);

  const count = Math.ceil(totalPages / limit);

  const dataConversion = (deviceData) => {
    deviceData.managerUserID = deviceData.managerUser?.id;
    return deviceData;
  };
  const fetchManagerUsers = async () => {
    await baseRequest.get(uris.LIST_ROLE_ACCOUNT).then((response) => {
      if (response?.status === 'NG') {
        store.dispatch(setMessage(response?.error || ''));
      } else {
        const rolelist = response?.data.map((item) => ({
          ...item,
          value: item.role, // Giá trị của thuộc tính `role` trong `item`
        }));
        setRoles(rolelist);
      }
    });
  };

  const redirectLocationButton = (data) => (
    <div
      onClick={() => navigate(`/admin/settings/adminAccount/edit/${data.id}`)}
      onKeyDown={() => {}}
      role="button"
      tabIndex={0}
      style={{ cursor: 'pointer' }}
    >
      {data?.name}
    </div>
  );

  // Declare columns
  const columnDefs = [
    {
      width: convertWidth(4),
      field: 'isChecked',
      filter: false,
      // headerCheckboxSelection: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      headerComponent: () => <CheckBoxAll setCheck={setChecked} />,
    },

    {
      headerName: t('accountName'),
      width: convertWidth(13),
      field: AccountModel.name,
      type: 'string',
      cellRenderer: (params) => (
        redirectLocationButton(params.data)
      ),
      floatingFilterComponent: FloatingFilterComponent,
    },
    {
      headerName: t('accountID'),
      field: AccountModel.uid,
      width: convertWidth(10),
      type: 'string',
      floatingFilterComponent: FloatingFilterComponent,
    },
    {
      headerName: t('status'),
      field: AccountModel.status,
      width: convertWidth(10),
      floatingFilterComponent: null,
      cellRenderer: (params) => (params?.data?.status ? t('disable') : t('enable')),
      filter: UseFilter,
      filterParams: {
        title: 'Status Account',
        values: [
          { value: true, category: 'adminStatus' },
          { value: false, category: 'adminStatus' },
        ],
      },
    },
    {
      headerName: t('department'),
      field: AccountModel.department,
      width: convertWidth(10),
      filter: false,
    },
    {
      headerName: t('email'),
      field: AccountModel.email,
      width: convertWidth(14),
      type: 'string',
      floatingFilterComponent: FloatingFilterComponent,
    },
    {
      headerName: t('phoneNumber'),
      field: AccountModel.phone,
      width: convertWidth(10),
      type: 'string',
      floatingFilterComponent: FloatingFilterComponent,

    },
    {
      headerName: t('role'),
      field: AccountModel.role,
      width: convertWidth(10),
      cellRenderer: (params) => (params?.data?.role === 0 ? 'ADMIN' : params?.data?.role),
      filter: false,
    },
    {
      headerName: t('lastLoginTime'),
      field: AccountModel.lastLoginTime,
      width: convertWidth(13),
      type: 'date',
      cellStyle: { textAlign: 'center' },
      cellRenderer: (params) => (params?.data?.lastLoginTime ? formatDateUTCToLocal(params?.data?.lastLoginTime, 'YYYY/MM/DD HH:mm:ss') : 'No data'),
      floatingFilterComponent: DateRangePicker,
    },
    {
      headerName: t('modifiedDateTime'),
      field: AccountModel.updateDate,
      width: convertWidth(13),
      type: 'date',
      cellStyle: { textAlign: 'center' },
      cellRenderer: (params) => (params?.data?.updateDate ? formatDateUTCToLocal(params?.data?.updateDate, 'YYYY/MM/DD HH:mm:ss') : 'No data'),
      floatingFilterComponent: DateRangePicker,
    },

  ];

  const components = useMemo(() => ({
    agDateInput: DatePicker,
  }), []);

  // Configuration for columns
  const configColumn = () => {
    const copyColumn = [...columnDefs];
    if (editable) {
      editable.forEach((number) => (copyColumn[number].editable = true));
    }
    if (removeAllColumn) {
      return [];
    }
    if (removeColumn) {
      removeColumn.forEach((number) => (copyColumn[number] = 'remove'));
      const newCol = copyColumn.filter((cols) => cols !== 'remove');
      return newCol;
    }
    return newCustomeColumn || columnDefs;
  };
  // useEffect(() => {
  //   fetchManagerUsers();
  // }, []);
  useEffect(() => {
    listNode.forEach((node) => {
      if (listChecked?.includes(node.data.id)) {
        node.setSelected(true);
      }
    });
  }, [listNode, listChecked]);

  const dataTable = {
    className,
    setListNode,
    rowSelection: 'multiple',
    rowMultiSelectWithClick: true,
    customColumnDefs: configColumn(),
    listData: data,
    components,
    ...others,
  };

  const getData = (filter, limit, offset, direct, checked) => {
    const valueSort = direct?.map((data) => {
      if (data?.name === 'status') {
        return { ...data, direct: data.direct === 'asc' ? 'desc' : 'asc' };
      }
      return data;
    });

    baseRequest.post(uris.ADMIN_ACCOUNT_LIST, {
      use: 0,
      limit,
      offset,
      orderBy: valueSort.filter((data) => data.direct),
      filterBy: filter.filter((data) => data.value),
      filterByGroup: [],
    }).then((response) => {
      setTotalPages(response?.total);
      setData(response?.data.map(dataConversion));
      if (checked === true) {
        dispatch(helperActions.getCheckedList(response?.data));
      } else if (checked === null) {
        dispatch(helperActions.getCheckedList(response?.data));
      } else {
        dispatch(helperActions.getCheckedList([]));
      }
    });
    if (!saveData) {
      dispatch(helperActions.removeAllData());
    }
  };

  // Function to change page
  const handlePageChange = (event, page) => {
    const offsetValue = page * limit - limit;
    setOffset(offsetValue);
    setCurrentPage(page);
    dispatch(helperActions.currentPage(page));
    setLimit(limit);
    setChecked(false);
    getData(filterData, limit, offsetValue, direct);
  };

  useEffect(() => {
    if (checked === true) {
      getData(filterData, 0, 0, direct, true);
    } else if (checked === null) {
      getData(filterData, limit, offset, direct, null);
    } else {
      getData(filterData, limit, offset, direct, false);
    }
  }, [filterData, direct, checked]);
  useEffect(() => {
    if (isLoadAdminAccount) {
      getData(filterData, limit, offset, direct, false);
      setIsLoadAdminAccount(false);
    }
  }, [isLoadAdminAccount]);
  useEffect(() => {
    fetchManagerUsers();
    setOffset(0);
    setLimit(50);
    setCurrentPage(1);
    setChecked(false);
    getData(filterData, 50, 0, direct, false);
  }, [filterData]);

  return (
    <div className="new-device-table-content-box" ref={mainRef}>
      {Array.isArray(roles) && (
      <Table {...dataTable} />
      )}
      {showPagination === true && (
      <div className="container-pagination">
        <Pagination
          className="pagination"
          size="small"
          count={count}
          page={currentPage}
          onChange={handlePageChange}
          showFirstButton
          showLastButton
        />
        <TablePagination
          size="small"
          component="div"
          count={totalPages}
          rowsPerPage={limit}
          page={currentPage - 1}
          onPageChange={() => {}}
          rowsPerPageOptions={[]}
          nextIconButtonProps={{ style: { display: 'none' } }}
          backIconButtonProps={{ style: { display: 'none' } }}
        />
      </div>
      )}

    </div>
  );
};

export default NewDeviceTable;
