import React, { useState } from 'react';
import { FormControl, FormHelperText, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useController } from 'react-hook-form';
import dayjs from 'dayjs';
import { useTranslation } from '../../../../common/components/LocalizationProvider';

const DateTimePickerWithValidation = ({ controllerProps, datePickerProps, textFieldProps, setError, clearErrors }) => {
  const { field, fieldState } = useController(controllerProps);
  const t = useTranslation();
  const { error } = fieldState;
  const handleCheckValidate = (value) => {
    if (value === 'invalidDate') {
      setError(controllerProps.name, { type: 'manual', message: t('invalidDate') });
    } else if (value === 'disablePast') {
      setError(controllerProps.name, { type: 'manual', message: t('errorDate') });
    } else {
      clearErrors(controllerProps.name);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <FormControl fullWidth>
        <DateTimePicker
          {...datePickerProps}
          fullWidth
          inputVariant="outlined"
          ampm={false}
          format="YYYY/MM/DD HH:mm"
          disablePast
          value={field.value ? dayjs(field.value) : null}
          onChange={(value) => field.onChange(value)}
          onError={(error, reason) => {
            handleCheckValidate(error);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              {...textFieldProps}
              error={!!error}
              helperText={error ? error.message : ''}
            />
          )}
        />
        {error && <FormHelperText sx={{ color: 'red' }}>{error.message}</FormHelperText>}
      </FormControl>
    </LocalizationProvider>
  );
};

export default DateTimePickerWithValidation;
