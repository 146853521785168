/* eslint-disable max-classes-per-file */

// START --fix bugs parser Data - 2023/10/06

export class Member {
  constructor() {
    this.field1 = 'detail';
    this.field2 = 'category';
    this.field3 = 'paymentstatuscode';
    this.field4 = 'name';
    this.field5 = 'id';
    this.field6 = 'disabled';
    this.field7 = 'email';
    this.field8 = 'groupName';
    this.field9 = 'representationId';
    this.field10 = 'userpaymentid';
    this.field11 = 'billingmethod';
    this.field12 = 'createDate';
    this.field13 = 'password';
    this.field14 = 'power';
    this.field15 = 'batteryLevel';
    this.field16 = 'accquisitionTime';
    this.field17 = 'groupId';
    this.field18 = 'rateplan';
    this.field19 = 'paymentmethod';
    this.field20 = 'paymentMethod2';
    this.field21 = 'paymentspan';
    this.field22 = 'updatedate';
    this.field23 = 'providerType';
    this.field25 = 'price';
    this.field27 = 'lastbillingdate';
    this.field28 = 'nextbillingdate';
    this.field29 = 'phone';
    this.field30 = 'withdrawal_date';
  }
}

export class Device {
  constructor() {
    this.edit = 'edit';
    this.id = 'id';
    this.groupId = 'groupId';
    this.name = 'name';
    this.uniqueId = 'uniqueId';
    this.status = 'status';
    this.userStatus = 'userStatus';
    this.emergency = 'emergency';
    this.imei = 'imei';
    this.locationhistory = 'locationhistory';
    this.osType = 'osType';
    this.lastUpdate = 'lastUpdate';
    this.positionId = 'positionId';
    this.geofenceIds = 'geofenceIds';
    this.phone = 'phone';
    this.model = 'model';
    this.contact = 'contact';
    this.category = 'category';
    this.managerUser = 'managerUser';
    this.managerUserID = 'managerUserID';
    this.registeredDate = 'registeredDate';
    this.registererdate = 'registererdate';
    this.locationDetail = 'locationdetail';
  }
}
export class AdminAccounts {
  constructor() {
    this.id = 'id';
    this.name = 'name';
    this.uid = 'uid';
    this.status = 'status';
    this.department = 'department';
    this.email = 'email';
    this.phone = 'phone';
    this.role = 'role';
    this.paymentId = 'paymentId';
    this.lastLoginTime = 'lastLoginTime';
    this.updateDate = 'updateDate';
  }
}
export class NetworkSetting {
  constructor() {
    this.id = 'id';
    this.ipAddress = 'ipAddress';
    this.createdAt = 'createdAt';
    this.lastModified = 'lastModified';
    this.spare = 'spare';
  }
}
export class Role {
  constructor() {
    this.role = 'role';
    this.reverse = 'reserve';
    this.enable = 'enable';
    this.creator = 'creator';
    this.createdAt = 'createdAt';
    this.lastModified = 'lastModified';
  }
}
export class TimeSheet {
  constructor() {
    this.userID = 'userId';
    this.name = 'name';
    this.department = 'department';
    this.date = 'date';
    this.checkIn = 'checkin';
    this.checkOut = 'checkout';
  }
}

export class PaymentStatus {
  constructor() {
    this.newApplication = 'newApplication';
    this.continousBilling = 'continousBilling';
    this.reminder = 'reminder';
    this.cancelationLaw = 'cancelationLaw';
  }
}
export class NotifyStatus {
  constructor() {
    this.sent = 'sent';
    this.saveDraft = 'draft';
    this.notSent = 'notSent';
  }
}

export class AdminAccountStatus {
  constructor() {
    this.enable = 'enable';
    this.disable = 'disable';
  }
}

export class BillingMethod {
  constructor() {
    this.inApp = 'editInApp';
    this.special = 'editSpecial';
    this.outside = 'editOutside';
  }
}

// START --fix bugs UAT341 - 2023/10/06
export class RatePlan {
  constructor() {
    this.basic = 'BASIC';
    this.prenium = 'PREMIUM';
  }
}
// END --fix bugs UAT341 - 2023/10/06

export class PaymentMethod {
  constructor() {
    this.inApp = 'inApp';
    this.credit = 'credit';
    this.bank = 'bank';
    this.other = 'other';
  }
}

// export class Payment2 {
//   constructor() {
//     this.representative = 'representativePayment';
//     this.payYourSelf = 'payYourSelf';
//     this.bank = 'bank';
//   }
// }

export class PaymentSpan {
  constructor() {
    this.singleMonth = 'singleMonth';
    this.advanceMonth = 'advanceMonth';
    this.advanceYear = 'advanceYear';
    this.firstTime = 'firstTime';
  }
}

export class Type {
  constructor() {
    this.local = 'local';
    this.google = 'google';
    this.line = 'line';
    this.yahoo = 'yahoo';
  }
}

export class ResponseAPI {
  constructor() {
    this.status = 'status';
    this.error = 'error';
    this.validationErrors = 'validationErrors';
    this.data = 'data';
    this.message = 'message';
  }
}
