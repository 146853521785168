import React, { useEffect, useRef } from 'react';
import 'maplibre-gl/dist/maplibre-gl.css';
import axios from 'axios';
import MaplibreGeocoder from '@maplibre/maplibre-gl-geocoder';
import { useDispatch } from 'react-redux';
import checkPoint from '../../../../assets/mapIcon/checkPoint.png';
import mapInfoChoosePoint from '../../../../assets/mapIcon/mapInfoChoosePoint.svg';
import { errorsActions } from '../../../../store';

const styleGoogleMap = {
  version: 8,
  sources: {
    google: {
      type: 'raster',
      tiles: ['https://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}&s=Ga'],
      tileSize: 256,
      maxzoom: 22,
    },
  },
  layers: [
    {
      id: 'google',
      type: 'raster',
      source: 'google',
    },
  ],
};

const MapViewInfo = ({ maplibregl, passCoords = () => {}, lngLat }) => {
  let mapInstance;
  const dispatch = useDispatch();
  const containerEl = useRef(null);
  let isPickingLocation = false;
  const markerLayerId = 'map-info-layer';
  const markerSourceId = 'map-info-source';

  const handleClickMap = async (e, isEdit) => {
    if (isPickingLocation) {
      const coords = e.lngLat;
      const url = `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${coords.lat}&lon=${coords.lng}&accept-language=en`;
      await axios.get(url).then((response) => {
        if (!isEdit) {
          coords.address = response?.data?.display_name;
        }
      }).catch((e) => console.error(e));
      if (mapInstance.getLayer(markerLayerId)) {
        mapInstance.removeLayer(markerLayerId);
        mapInstance.removeSource(markerSourceId);
      }
      mapInstance.addSource(markerSourceId, {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [coords.lng, coords.lat],
              },
              properties: {},
            },
          ],
        },
      });

      mapInstance.addLayer({
        id: markerLayerId,
        type: 'symbol',
        source: markerSourceId,
        layout: {
          'icon-image': 'image-pointer',
          'icon-size': 0.06,
        },
      });
      isPickingLocation = false;
      mapInstance.getCanvas().style.cursor = '';
      return passCoords(coords);
    }
    return passCoords();
  };

  useEffect(() => {
    if (!containerEl.current) return;

    mapInstance = new maplibregl.Map({
      container: containerEl.current,
      style: styleGoogleMap,
      zoom: 1.5,
      center: [0, 0],
    });
    const initializeMap = async () => {
      mapInstance.on('load', () => {
        mapInstance.loadImage(checkPoint, (error, image) => {
          if (error) {
            console.error('Error loading image:', error);
            return;
          }
          // eslint-disable-next-line no-undef
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
          canvas.width = 1600;
          canvas.height = 2000;
          context.drawImage(image, 0, -250, 1600, 1600);
          // eslint-disable-next-line no-undef
          const resizedImage = new Image();
          resizedImage.onload = function () {
            mapInstance.addImage('image-pointer', resizedImage);
          };
          resizedImage.src = canvas.toDataURL();
        });
      });

      const imageButton = document.createElement('img');
      imageButton.src = mapInfoChoosePoint;
      imageButton.alt = 'Get Coordinates';
      imageButton.style.position = 'absolute';
      imageButton.style.bottom = '1px';
      imageButton.style.right = '1px';
      imageButton.style.border = 'none';
      imageButton.style.cursor = 'pointer';
      imageButton.style.width = '80px';
      imageButton.style.height = '80px';

      imageButton.addEventListener('click', () => {
        isPickingLocation = true;
        mapInstance.getCanvas().style.cursor = 'crosshair';
      });

      mapInstance.getContainer().appendChild(imageButton);
      mapInstance.on('click', await handleClickMap);
    };
    const geocoder = {
      forwardGeocode: async (config) => {
        const features = [];
        try {
          const request = `https://nominatim.openstreetmap.org/search?q=${config.query}&format=geojson&polygon_geojson=1&addressdetails=1`;
          const response = await fetch(request);
          const geojson = await response.json();
          geojson.features.forEach((feature) => {
            const center = [
              feature.bbox[0] + (feature.bbox[2] - feature.bbox[0]) / 2,
              feature.bbox[1] + (feature.bbox[3] - feature.bbox[1]) / 2,
            ];
            features.push({
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: center,
              },
              place_name: feature.properties.display_name,
              properties: feature.properties,
              text: feature.properties.display_name,
              place_type: ['place'],
              center,
            });
          });
        } catch (e) {
          dispatch(errorsActions.push(e.message));
        }
        return { features };
      },
    };

    const control = new MaplibreGeocoder(geocoder, {
      maplibregl,
      collapsed: true,
    });
    mapInstance.addControl(control, 'top-left');
    initializeMap();
    // eslint-disable-next-line consistent-return
    return () => {
      mapInstance.removeControl(control);
      mapInstance.remove();
    };
  }, [maplibregl]);

  useEffect(() => {
    if (lngLat?.lat && lngLat?.lng) {
      isPickingLocation = true;
      handleClickMap({ lngLat }, true).catch((e) => {});
    }
  }, [lngLat]);

  return <div ref={containerEl} style={{ width: '100%', height: '100%' }} />;
};

export default MapViewInfo;
