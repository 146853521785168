import React from 'react';
import { Navigate, Outlet, Route } from 'react-router-dom';
import hasPermission from '../admin/common/hasPermission';

const RoutePermission = ({ permission, element }) => {
  if (hasPermission(permission)) {
    return element || <Outlet />;
  }
  return <Navigate to="403" />;
};

export default RoutePermission;
