/* eslint-disable react/no-unstable-nested-components */
import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Pagination, TablePagination } from '@mui/material';
import moment from 'moment';
import { helperActions } from '../../store';
import Table from './Table';
import { useTranslation } from '../../common/components/LocalizationProvider';
import fetchApi from '../FetchApi';
import FloatingFilterComponent from './FloatingFilterComponent';

import { TimeSheet } from '../common/DefineClass';
import { convertWidth } from '../common/utils';
import CheckBoxAll from './CheckBoxAll';
import DateRangePicker from './DateRangePicker';
import { formatDateUTCToLocal, formatTimeUTCToLocal } from '../common/handleTimeZone';
import baseRequest from '../../apis/baseRequest';
import uris from '../../apis/uris';

const useStyle = makeStyles((theme) => ({
  contentBox: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '1',
  },
  pagination: {
    paddingTop: '10px',
    '& .MuiPaginationItem-root': {
      '&.Mui-selected': {
        backgroundColor: '#f47024',
      },
    },
  },
  link: {
    color: '#1e70c4',
  },
}));

// declare column percentage
const listColumPercent = {
  checkbox: 4,
  edit: 5,
  uniqueId: 10,
  category: 8,
  status: 10,
  name: 20,
  userId: 8,
  disabled: 7,
  email: 20,
  groupName: 10,
  representId: 9,
  paymentId: 22,
  billingMethod: 15,
  ratePlan: 15,
  paymentMethod: 20,
  paymentSpan: 15,
  price: 8,
  lastBillingDate: 10,
  nextBillingDate: 10,
  startDate: 10,
  phone: 10,
  withdrawDate: 10,
  updateDate: 10,
  type: 7,
};

const TimeSheetTable = (props) => {
  const {
    removeAllColumn,
    removeColumn,
    listData,
    editable,
    editGroup,
    changePasswordById,
    changeGroupById,
    newCustomeColumn,
    className,
    saveData,
    editStatus,
    showPagination,
    getElementRef,
    setGridApi,
    listChecked,
    ...others
  } = props;

  const classes = useStyle();
  const mainRef = useRef();
  const dispatch = useDispatch();
  const t = useTranslation();
  const navigate = useNavigate();

  const deviceModel = new TimeSheet();

  const filterData = useSelector((state) => state.helper.adminData.filter);
  const direct = useSelector((state) => state.helper.adminData.orderBy);

  const [data, setData] = useState([]);
  const [listNode, setListNode] = useState([]);

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(50);
  const [checked, setChecked] = useState(false);

  const count = Math.ceil(totalPages / limit);

  const dataConversion = (deviceData) => {
    deviceData.managerUserID = deviceData.managerUser?.id;
    return deviceData;
  };
  const redirectLocationButton = (data) => (
    <div>
      {data?.checkin || data?.checkout ? (
        <Link
          disable
          to={`log/${data.userId}/${moment(data?.checkin ? data?.checkin : data?.checkout).local().format('YYYY-MM-DD')}`}
        >
          Detail
        </Link>
      )
        :
        ''}
    </div>
  );

  // Declare columns
  const columnDefs = [
    {
      width: convertWidth(listColumPercent.checkbox),
      field: 'isChecked',
      filter: false,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      headerComponent: () => <CheckBoxAll setCheck={setChecked} />,
    },
    {
      headerName: t('userId'),
      width: convertWidth(listColumPercent.userId),
      field: deviceModel.userID,
      type: 'string',
      floatingFilterComponent: null,
    },
    {
      headerName: t('name'),
      field: deviceModel.name,
      width: convertWidth(listColumPercent.name),
      filter: false,
    },
    {
      headerName: t('department'),
      field: deviceModel.department,
      width: convertWidth(15),
      filter: false,
    },
    {
      headerName: t('statusDate'),
      field: deviceModel.checkIn,
      cellStyle: { textAlign: 'center' },
      width: convertWidth(12),
      valueFormatter: (params) => {
        const checkin = params?.data?.checkin;
        const checkout = params?.data?.checkout;
        if (checkin || checkout) {
          return checkin ? formatDateUTCToLocal(checkin) : formatDateUTCToLocal(checkout);
        } return 'No data';
      },
      type: 'date',
      floatingFilterComponent: DateRangePicker,
      // floatingFilterComponentParams: { suppressFilterButton: true },
    },
    {
      headerName: t('checkIn'),
      field: deviceModel.checkIn,
      cellStyle: { textAlign: 'center' },

      valueFormatter: (params) => (params?.data?.checkin ? formatTimeUTCToLocal(params?.data?.checkin) : 'No data'),
      width: convertWidth(10),
      filter: false,
    },
    {
      headerName: t('checkOut'),
      cellStyle: { textAlign: 'center' },

      field: deviceModel.checkOut,
      valueFormatter: (params) => (params?.data?.checkout ? formatTimeUTCToLocal(params?.data?.checkout) : 'No data'),
      width: convertWidth(10),
      filter: false,
    },
    {
      headerName: t('detailTableHeader'),
      field: deviceModel.userID,
      cellStyle: { textAlign: 'center' },
      width: convertWidth(10),
      cellRenderer: (params) => redirectLocationButton(params?.data),
      floatingFilterComponent: FloatingFilterComponent,
      filter: false,
    },
  ];

  const components = useMemo(() => ({
    agDateInput: DateRangePicker,
  }), []);

  const getData = async (filter, limit, offset, direct, checked) => {
    const newFilter = filter.map((item) => ({ ...item }));
    const checkFilter = filterData?.some((item) => item?.value !== '');
    if (checkFilter) {
      offset = 0;
      setOffset(0);
      setCurrentPage(1);
    }
    for (let i = 0; i < newFilter.length; i += 1) {
      if (newFilter[i].name === deviceModel.userID && newFilter[i].value) {
        const intValue = parseInt(newFilter[i].value, 10);
        const descriptor = Object.getOwnPropertyDescriptor(newFilter[i], 'value');
        if (descriptor && !descriptor.writable) {
          newFilter[i] = { ...newFilter[i], value: intValue };
        } else {
          newFilter[i].value = intValue;
        }
      }
    }
    baseRequest.post(uris.USERTIMESHEET, {
      limit,
      offset,
      orderBy: direct.filter((data) => data.direct),
      filterBy: filter.filter((data) => data.value),
    }).then((response) => {
      setTotalPages(response?.total);
      setData(response?.data.map(dataConversion));
      if (checked === true) {
        dispatch(helperActions.getCheckedList(response?.data));
      } else if (checked === null) {
        dispatch(helperActions.getCheckedList(response?.data));
      } else {
        dispatch(helperActions.getCheckedList([]));
      }
    });
    if (!saveData) {
      dispatch(helperActions.removeAllData());
    }
  };

  // Configuration for columns
  const configColumn = () => {
    const copyColumn = [...columnDefs];
    if (editable) {
      editable.forEach((number) => (copyColumn[number].editable = true));
    }
    if (removeAllColumn) {
      return [];
    }
    if (removeColumn) {
      removeColumn.forEach((number) => (copyColumn[number] = 'remove'));
      const newCol = copyColumn.filter((cols) => cols !== 'remove');
      return newCol;
    }
    return newCustomeColumn || columnDefs;
  };

  useEffect(() => {
    listNode.forEach((node) => {
      if (listChecked?.includes(node.data.id)) {
        node.setSelected(true);
      }
    });
  }, [listNode, listChecked]);

  const dataTable = {
    className,
    setListNode,
    rowSelection: 'multiple',
    rowMultiSelectWithClick: true,
    customColumnDefs: configColumn(),
    listData: listData || data,
    components,
    ...others,
  };

  // Function to change page
  const handlePageChange = (event, page) => {
    const offsetValue = page * limit - limit;
    setOffset(offsetValue);
    setCurrentPage(page);
    dispatch(helperActions.currentPage(page));
    setLimit(limit);
    setChecked(false);
    getData(filterData, limit, offsetValue, direct);
  };

  useEffect(() => {
    if (checked === true) {
      getData(filterData, 0, 0, direct, true);
    } else if (checked === null) {
      getData(filterData, limit, offset, direct, null);
    } else {
      getData(filterData, limit, offset, direct, false);
    }
  }, [filterData, direct, checked]);

  return (
    <div className={classes.contentBox} ref={mainRef}>
      <Table {...dataTable} />
      {showPagination === true && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: '#f1f0f0',
          }}
        >
          <Pagination
            className={classes.pagination}
            size="small"
            count={count}
            onChange={handlePageChange}
            showFirstButton
            showLastButton
          />
          <TablePagination
            size="small"
            component="div"
            count={totalPages}
            rowsPerPage={limit}
            page={currentPage - 1}
            onPageChange={() => {}}
            rowsPerPageOptions={[]}
            nextIconButtonProps={{ style: { display: 'none' } }}
            backIconButtonProps={{ style: { display: 'none' } }}
          />
        </div>
      )}
    </div>
  );
};

export default TimeSheetTable;
