import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box, Button, FormControl, FormControlLabel, Radio, RadioGroup, Typography,
} from '@mui/material';
import './sendnotify.css';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useTranslation } from '../../../../common/components/LocalizationProvider';
import Header from '../Header';
import FormTextField from '../../../components/form/FormTextField';
import LabelAdd from '../../../devices/components/LabelAdd';
import CustomDateTimePicker from './CustomDateTimePicker';
import NewTable from '../../../components/NewTable';
import { getUserData, getUserIds } from '../../../common/utils';
import store, { helperActions, setMessage } from '../../../../store';
import baseRequest from '../../../../apis/baseRequest';
import uris from '../../../../apis/uris';
import FormDatePicker from '../../../components/form/FormDatePicker';

const ConfirmNotify = () => {
  const { id } = useParams();
  const t = useTranslation();
  const dispatch = useDispatch();
  const { control, setValue, setError, clearErrors } = useForm();
  const [data, setData] = useState();
  const checkedList = useSelector((state) => state.helper.checkedList);
  const [dataSelect, setDataSelect] = useState([]);
  const userIds = getUserIds(checkedList, dataSelect);
  const [gridApi, getGridApi] = useState(null);
  const handleSelectionChange = () => {
    setDataSelect(gridApi?.getSelectedRows() || []);
  };
  const handleRowDataUpdated = (params) => {
    gridApi?.forEachNode((node) => {
      if (checkedList.length !== 0) {
        node.setSelected(true);
      }
    });
    if (Array.isArray(data?.userId)) {
      params.api.forEachNode((node) => {
        if (data?.userId?.some((item) => item === node.data?.id)) {
          node.setSelected(true);
        }
      });
    }
  };

  const dataTable = {
    listChecked: userIds,
    getGridApi,
    saveData: true,
    removeColumn: [1],
    showPagination: true,
    onSelectionChanged: handleSelectionChange,
    onRowDataUpdated: handleRowDataUpdated,
  };

  const GetDataById = () => {
    baseRequest.post(uris.DETAIL_SEND_NOTIFY, { id }).then((response) => {
      if (response?.status === 'NG') {
        store.dispatch(setMessage(response?.error || ''));
      } else {
        const data = response?.data;
        setValue('title', data?.title);
        setValue('content', data?.content);
        setValue('scheduledSendTime', dayjs(data?.scheduledSendTime).tz(dayjs.tz.guess()));
        setData(data);
      }
    });
  };

  useEffect(() => {
    const data = getUserData(checkedList, dataSelect);
    dispatch(helperActions.selectedUser(data));
  }, [checkedList, dataSelect]);
  useEffect(() => {
    GetDataById();
  }, []);
  return (
    <div>
      <Header>
        <div style={{ margin: '10px 0' }}>
          <Typography style={{ fontSize: '1.5rem' }}>{t('linkListSendNotifications')}</Typography>
          <Typography>{t('notificationContent')}</Typography>
        </div>
      </Header>
      <form>
        <FormTextField
          label={t('title')}
          controllerProps={{
            control,
            name: 'title',
          }}
          textFieldProps={{
            disabled: true,
          }}
          autocomplete="off"
        />
        <FormTextField
          label={t('content')}
          controllerProps={{
            control,
            name: 'content',
          }}
          textFieldProps={{
            disabled: true,
            multiline: true,
            rows: 10,
          }}
          required
          autocomplete="off"
        />
        <div className="send-notify-box">
          <Typography mr={10}>{t('attachedFile')}</Typography>
          <Box>
            <Typography sx={{ textDecoration: 'underline' }}>{data?.fileName ? data?.fileName : t('attachedFile')}</Typography>
          </Box>

        </div>
        <div className="send-notify-box">
          <div className="label-time">
            <LabelAdd label={t('timeOfSending')} required />
          </div>
          <div>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={data?.scheduledSendTime ? 0 : 1}
              >
                <FormControlLabel
                  value={1}
                  disabled
                  control={<Radio color="success" size="small" />}
                  label={t('sendNow')}
                />
                <div style={{ display: 'flex' }}>
                  <FormControlLabel
                    value={0}
                    disabled
                    control={<Radio color="success" size="small" />}
                    label={t('sendAfter')}
                  />
                  {data?.scheduledSendTime && (
                  <div>
                    <FormDatePicker
                      controllerProps={{
                        control,
                        name: 'scheduledSendTime',
                      }}
                      dateTimePickerProps={{
                        disabled: true,
                      }}
                    />
                  </div>
                  )}
                </div>
              </RadioGroup>
            </FormControl>
          </div>
        </div>
        <div className="label-time">
          <LabelAdd label={t('mailRecipient')} required />
        </div>
        <NewTable {...dataTable} />
      </form>
    </div>
  );
};

export default ConfirmNotify;
