import { decrypted } from '../../payment/components/utils';

export default function hasPermission(role, listPer) {
  // eslint-disable-next-line no-undef
  const permissions = listPer || JSON.parse(decrypted(localStorage.getItem('permissions'))) || [];
  const checkPermission = (items) => items?.some((item) => {
    if (item.role === role) return true;
    return !!(item.children && checkPermission(item.children));
  });

  return checkPermission(permissions);
}
