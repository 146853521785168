/* eslint-disable no-undef */
import React, {
  useRef, useState,
} from 'react';
import {
  Button,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  Download,
} from '@mui/icons-material';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import { utils, writeFile } from 'xlsx';
import BreadcrumbsCustom from '../components/BreadcrumbsCustom';
import { useTranslation } from '../../common/components/LocalizationProvider';
import { getDeviceData, getDeviceIds } from '../common/utils';
import TimeSheetTable from '../components/TimeSheetTable';
import configColorLayout from '../../settings/ConfigColorLayout';
import { formatDateUTCToLocal, formatTimeUTCToLocal } from '../common/handleTimeZone';
import hasPermission from '../common/hasPermission';

const useStyle = makeStyles((theme) => ({
  cardContainer: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-evenly',
    },
  },
  accountDefault: {
    backgroundColor: configColorLayout,
    color: 'white',
    margin: '1rem 3rem 1rem 0',
    width: '200px',
    [theme.breakpoints.down('sm')]: {
      width: '130px',
    },
  },
  accountSpecial: {
    backgroundColor: '#D0F000',
    color: 'black',
    margin: '1rem 0',
    width: '200px',
    [theme.breakpoints.down('sm')]: {
      width: '130px',
    },
  },
  csvBtn: {
    display: 'inline-flex',
    color: 'black',
    textDecoration: 'none',
    backgroundColor: '#e0e0e0',
    width: '90px',
    height: '40px',
    borderRadius: '5px',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    marginRight: '1rem',
    '&:hover': {
      backgroundColor: '#e0e0e0',
    },
  },
  settingBtn: {
    display: 'inline-flex',
    color: '#fff',
    textDecoration: 'none',
    backgroundColor: '#6C6A6A',
    borderRadius: '5px',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    marginRight: '1rem',
    paddingTop: '25px',
    paddingBottom: '25px',
    '&:hover': {
      backgroundColor: '#6C6A6A',
    },
  },
  listBtn: {
    display: 'flex',
    margin: '2rem 0 1rem 0',
  },
  configDiv: {
    margin: '1rem 0 2rem 0',
  },
  configTable: {
    height: `${window.innerHeight - 200}px`,
  },
  submitBtn: {
    width: '120px',
    border: 'outset 5px red',
    backgroundColor: 'red',
    '&:hover': {
      backgroundColor: 'red',
    },
  },
  closeBtn: {
    width: '120px',
    border: 'outset 5px grey',
    backgroundColor: 'grey',
    '&:hover': {
      backgroundColor: 'grey',
    },
  },
  modalTitle: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const ListTimeSheet = () => {
  const classes = useStyle();
  const tableRef = useRef();
  const t = useTranslation();
  const navigate = useNavigate();

  const [gridApi, getGridApi] = useState(null);
  const [dataSelect, setDataSelect] = useState([]);
  const checkedList = useSelector((state) => state.helper.checkedList);
  const data = getDeviceData(checkedList, dataSelect);
  const id = getDeviceIds(checkedList, dataSelect);
  const handleSelectionChange = () => {
    setDataSelect(gridApi?.getSelectedRows() || []);
  };

  const handleRowDataUpdated = () => {
    gridApi?.forEachNode((node) => {
      if (checkedList.length !== 0) {
        node.setSelected(true);
      }
    });
  };

  const onExportCSV = () => {
    let dataExport;
    if (Array.isArray(data)) {
      dataExport = data.map((item) => {
        const newItem = { ...item };
        newItem.date = formatDateUTCToLocal(item?.checkin || item?.checkout);
        newItem.checkin = item?.checkin ? formatTimeUTCToLocal(item?.checkin) : 'No data';
        newItem.checkout = item?.checkout ? formatTimeUTCToLocal(item?.checkout) : 'No data';
        delete newItem.managerUserID;
        return {
          userId: newItem.userId,
          name: newItem.name,
          Department: '',
          date: newItem.date,
          checkin: newItem.checkin,
          checkout: newItem.checkout,
        };
      });
    }

    const headers = [t('userId'), t('name'), t('department'), t('statusDate'), t('checkIn'), t('checkOut')];
    const worksheet = utils.json_to_sheet([]);
    utils.sheet_add_aoa(worksheet, [headers], { origin: 'A1' });
    utils.sheet_add_json(worksheet, dataExport, { origin: 'A2', skipHeader: true });
    worksheet['!cols'] = [
      { wch: 10 },
      { wch: 30 },
      { wch: 10 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
    ];
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    writeFile(workbook, `TimeSheet_data_${moment().format('YYYY-MM-DD HH:mm:ss')}.xlsx`);
  };

  const dataTable = {
    getGridApi,
    tableRef,
    floatingFilter: true,
    showPagination: true,
    className: classes.configTable,
    onSelectionChanged: handleSelectionChange,
    onRowDataUpdated: handleRowDataUpdated,
    childrenTop: (
      <div>
        <BreadcrumbsCustom getAfter="admin" />
        <div className={classes.configDiv}>
          {hasPermission('timeSheet.areaSetting') && (
          <Button
            className={classes.settingBtn}
            onClick={() => navigate('areaSetting')}
          >
            <SettingsIcon style={{ paddingRight: '5px', fontSize: '48px' }} />
            <div>Area Setting</div>
          </Button>
          )}
        </div>
        <div className={classes.listBtn}>
          {hasPermission('timeSheet.export') && (
          <Button
            onClick={() => onExportCSV()}
            className={classes.csvBtn}
            disabled={!(checkedList.length > 0 || dataSelect.length > 0)}
          >
            <Download style={{ paddingRight: '5px', fontSize: '30px' }} />
            <div>{t('reportExport')}</div>
          </Button>
          )}
        </div>
      </div>
    ),
  };
  return (
    <>
      <TimeSheetTable {...dataTable} />
    </>
  );
};

export default ListTimeSheet;
