/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/destructuring-assignment */
import { makeStyles } from '@mui/styles';
import React, {
  useEffect, useRef, useState,
} from 'react';
import {
  Card, List, ListItem, ListItemButton, ListItemIcon, ListItemText,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDownWideShort, faFilter } from '@fortawesome/free-solid-svg-icons';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { helperActions } from '../../store';
import { useTranslation } from '../../common/components/LocalizationProvider';
import fetchApi from '../FetchApi';

const useStyles = makeStyles((theme) => ({
  filterMain: {
    width: '100%',
    backgroundColor: 'white',
    display: 'flex',
  },
  listItemRoot: {
    '&:hover': {
      backgroundColor: '#f46717',
    },
  },
}));

const FilterComponent = (props, ref) => {
  const { field, filterParams, type } = props.colDef;
  const t = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const cardRef = useRef();
  const filterSelectRef = useRef(null);

  const adminData = useSelector((state) => state.helper.adminData);

  const [open, setOpen] = useState(false);
  const [filterType, setFilterType] = useState(() => (type === 'string' ? t('textContains') : t('textExactly')));
  const [filterPosition, setFilterPosition] = useState();
  const [filterSelect, setFilterSelect] = useState(false);

  useEffect(() => {
    props.onUiChange();
  }, []);

  const handleClick = (values) => {
    const copyData = { ...adminData };
    const filter = [...copyData.filter];
    const existingFilterIndex = filter.findIndex((filterField) => filterField.name === field);
    if (existingFilterIndex === -1) {
      filter.push({ name: field, type: values, value: null });
      copyData.filter = filter;
      dispatch(helperActions.setAdminData(copyData));
    } else {
      const copyFilter = [...filter];
      copyFilter[existingFilterIndex] = { ...filter[existingFilterIndex], type: values, value: filterParams?.type !== 'NUMBER' ? filter[existingFilterIndex].value : (values === '05' || values === '06') ? Number(filter[existingFilterIndex].value) : filter[existingFilterIndex].value };
      dispatch(helperActions.setAdminData({ ...copyData, filter: copyFilter }));
    }
  };

  const handleDirect = (order) => {
    const copyData = { ...adminData };
    const orderBy = [...copyData.orderBy];
    const existingOrderByIndex = orderBy.findIndex((filterField) => filterField.name === field);
    if (existingOrderByIndex === -1) {
      orderBy.push({ name: field, direct: order });
      copyData.orderBy = orderBy;
      dispatch(helperActions.setAdminData(copyData));
    } else {
      const copyOrderBy = [...orderBy];
      copyOrderBy[existingOrderByIndex] = { ...orderBy[existingOrderByIndex], direct: order };
      dispatch(helperActions.setAdminData({ ...copyData, orderBy: copyOrderBy }));
    }
  };

  useEffect(() => {
    const table = cardRef.current?.offsetParent?.offsetParent;
    const tableWidth = table.offsetWidth;
    const cardPosition = cardRef.current?.offsetParent.offsetLeft;
    const cardWidth = cardRef.current?.offsetParent.offsetWidth;
    if (cardWidth + cardPosition + 200 >= tableWidth) {
      setFilterPosition({ right: '220px' });
    } else {
      setFilterPosition({ left: '220px' });
    }
  }, []);

  return (
    <div>
      <List style={{ position: 'relative', overflow: 'initial', minWidth: '220px', zIndex: 99999 }} ref={cardRef}>
        <ListItem disablePadding>
          <ListItemButton onClick={() => {
            handleDirect('asc');
          }}
          >
            <ListItemIcon>
              <FontAwesomeIcon icon={faArrowDownWideShort} flip="vertical" size="xl" />

            </ListItemIcon>
            <ListItemText
              primary={t('asc')}
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={() => {
            handleDirect('desc');
          }}
          >
            <ListItemIcon>
              <FontAwesomeIcon icon={faArrowDownWideShort} size="xl" />
            </ListItemIcon>
            <ListItemText
              primary={t('desc')}
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding ref={filterSelectRef}>
          <ListItemButton onClick={() => {
            setOpen(false);
            setFilterSelect(!filterSelect);
          }}
          >
            <ListItemIcon>
              <FontAwesomeIcon icon={faFilter} size="xl" />
            </ListItemIcon>
            <ListItemText
              primary={t('filter')}
            />
          </ListItemButton>
        </ListItem>
        <Card style={{ position: 'absolute', top: '70%', ...filterPosition, width: '220px', display: !filterSelect ? 'none' : 'block' }}>
          <ListItem style={{ height: filterSelectRef?.current?.clientHeight, backgroundColor: '#CDC6C2' }} disablePadding>
            <ListItemButton onClick={() => setOpen(!open)}>
              <ListItemText>
                {filterType}
              </ListItemText>
              {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon /> }
            </ListItemButton>
          </ListItem>
          {open && (
            <>
                {type === 'string' && (
                <div>
                  <ListItem disablePadding>
                    <ListItemButton
                      className={classes.listItemRoot}
                      onClick={() => {
                        handleClick('01');
                        setOpen(false);
                        setFilterType(t('textContains'));
                      }}
                    >
                      <ListItemText primary={t('textContains')} />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      className={classes.listItemRoot}
                      onClick={() => {
                        handleClick('02');
                        setOpen(false);
                        setFilterType(t('textDoesNotContains'));
                      }}
                    >
                      <ListItemText primary={t('textDoesNotContains')} />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      className={classes.listItemRoot}
                      onClick={() => {
                        handleClick('03');
                        setOpen(false);
                        setFilterType(t('textStartWith'));
                      }}
                    >
                      <ListItemText primary={t('textStartWith')} />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      className={classes.listItemRoot}
                      onClick={() => {
                        handleClick('04');
                        setOpen(false);
                        setFilterType(t('textEndWith'));
                      }}
                    >
                      <ListItemText primary={t('textEndWith')} />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      className={classes.listItemRoot}
                      onClick={() => {
                        handleClick('05');
                        setOpen(false);
                        setFilterType(t('textExactly'));
                      }}
                    >
                      <ListItemText primary={t('textExactly')} />
                    </ListItemButton>
                  </ListItem>
                </div>
                )}

                {type === 'number' && (
                <div>
                  <ListItem disablePadding>
                    <ListItemButton
                      className={classes.listItemRoot}
                      onClick={() => {
                        handleClick('06');
                        setOpen(false);
                        setFilterType(t('notEqual'));
                      }}
                    >
                      <ListItemText primary={t('notEqual')} />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      className={classes.listItemRoot}
                      onClick={() => {
                        handleClick('07');
                        setOpen(false);
                        setFilterType(t('greaterThan'));
                      }}
                    >
                      <ListItemText primary={t('greaterThan')} />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      className={classes.listItemRoot}
                      onClick={() => {
                        handleClick('07');
                        setOpen(false);
                        setFilterType(t('lessThan'));
                      }}
                    >
                      <ListItemText primary={t('lessThan')} />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      className={classes.listItemRoot}
                      onClick={() => {
                        handleClick('05');
                        setOpen(false);
                        setFilterType(t('textExactly'));
                      }}
                    >
                      <ListItemText primary={t('textExactly')} />
                    </ListItemButton>
                  </ListItem>
                </div>
                )}

                {type === 'date' && (
                <div>
                  <ListItem disablePadding>
                    <ListItemButton
                      className={classes.listItemRoot}
                      onClick={() => {
                        handleClick('07');
                        setOpen(false);
                        setFilterType(t('textExactly'));
                      }}
                    >
                      <ListItemText primary={t('textExactly')} />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      className={classes.listItemRoot}
                      onClick={() => {
                        handleClick('10');
                        setOpen(false);
                        setFilterType(t('from'));
                      }}
                    >
                      <ListItemText primary={t('from')} />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      className={classes.listItemRoot}
                      onClick={() => {
                        handleClick('11');
                        setOpen(false);
                        setFilterType(t('to'));
                      }}
                    >
                      <ListItemText primary={t('to')} />
                    </ListItemButton>
                  </ListItem>
                </div>
                )}
              {type === 'select' && (
              <div>
                <ListItem disablePadding>
                  <ListItemButton
                    className={classes.listItemRoot}
                    onClick={() => {
                      handleClick('01');
                      setOpen(false);
                      setFilterType(t('textContains'));
                    }}
                  >
                    <ListItemText primary={t('textContains')} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    className={classes.listItemRoot}
                    onClick={() => {
                      handleClick('05');
                      setOpen(false);
                      setFilterType(t('textExactly'));
                    }}
                  >
                    <ListItemText primary={t('textExactly')} />
                  </ListItemButton>
                </ListItem>
              </div>
              )}

            </>
          )}
        </Card>
      </List>
    </div>
  );
};
export default React.forwardRef(FilterComponent);
