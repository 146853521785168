import React, { useEffect, useRef, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import Papa from 'papaparse';
// eslint-disable-next-line import/no-extraneous-dependencies
import { parseISO, isValid } from 'date-fns';
import {
  Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Select, FormControl, MenuItem, TextField, tableCellClasses, Snackbar, IconButton,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Box,
  Alert,
  Pagination,
  TablePagination,
} from '@mui/material';

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { styled } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { makeStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import DatePicker from 'react-datepicker';
import CloseIcon from '@mui/icons-material/Close';
import Header from '../Header';
import ConfirmationPopup from '../ComfirmationPopup';
import { useTranslation } from '../../../../common/components/LocalizationProvider';
import { useCatch } from '../../../../reactHelper';
import { BillingMethod, RatePlan, PaymentMethod } from '../../../common/DefineClass';
import store, { helperActions, setMessage } from '../../../../store';
import { snackBarDurationVeryLongMs } from '../../../../common/util/duration';
import baseRequest from '../../../../apis/baseRequest';
import uris from '../../../../apis/uris';
import DatePopupEditor from '../../../components/DatePickerEditor';
import configColorLayout from '../../../../settings/ConfigColorLayout';
import ModalSuccess from '../../../components/modal/ModalSuccess';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#333f50',
    border: '1px solid black',
    color: theme.palette.common.white,
  },
}));

const useStyle = makeStyles({
  required: {
    // fontSize: '20px',
    '&::before': {
      content: '"*"',
      color: 'red',
      position: 'relative',
      top: '0',
      right: 5,
    },
    '&.MuiButtonBase-root': { backgroundColor: 'red' },
  },
  autofill: {
    fontSize: '20px',
    '&::before': {
      content: '"*"',
      color: 'white',
      position: 'relative',
      top: '0',
    },
  },
  borderCell: {
    height: '100px',
    border: '1px solid black',
  },
  dateCell: {
    height: '100px',
    border: '1px solid black',
    width: '150px',
  },
  selectField: {
    '&:hover': { backgroundColor: '#de560a' },
    '&:focus': { backgroundColor: '#de560a' },
  },
  datePicker: {
    border: '1px solid #ced4da',
    borderRadius: '4px',
    padding: '8px',
    width: '120px',
    fontWeight: 400,
    fontSize: '1rem',
  },
});

const CreateAccountByCSV = ({ valueOption, setValues }) => {
  const { control, handleSubmit, getValues, watch, setFocus, formState: { isValid, isDirty } } = useForm({
    mode: 'onChange',
  });
  const navigate = useNavigate();
  const t = useTranslation();
  const classes = useStyle();
  const dispatch = useDispatch();
  const location = useLocation();

  const isMobile = useMediaQuery('(max-width:600px)');
  const centerStyle = isMobile ? { position: 'absolute', left: '0', right: '0', margin: 'auto', bottom: '30px', width: '200px' } : { position: 'absolute', right: '60px', margin: '30px' };

  const [readyImport, setReadyImport] = useState(false);
  const [startDate, setStartDate] = useState({});
  const [endDate, setEndDate] = useState({});
  const [file, setFile] = useState(null);
  const [errors, setErrors] = useState([]);
  const [data, setData] = useState([]);
  const [listItem, setListItem] = useState([]);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const count = Math.ceil(data.length / 50);
  const handleChangeData = (index, field, value) => {
    const updatedUsers = [...data];
    const globalIndex = (currentPage - 1) * 50 + index;
    if (updatedUsers[globalIndex]) {
      updatedUsers[globalIndex] = { ...updatedUsers[globalIndex], [field]: value };
      setData(updatedUsers);
    }
  };
  useEffect(() => {
    handleChangeData(startDate.index, 'startDate', startDate.startDate);
  }, [startDate]);
  useEffect(() => {
    handleChangeData(endDate.index, 'endDate', endDate.endDate);
  }, [endDate]);

  const expectedColumns = ['name', 'startDate', 'endDate', 'note'];

  const checkValidateDate = (dataDate) => {
    const now = moment();
    const errorsList = [];
    dataDate.forEach((row, index) => {
      if (row.startDate && row.endDate) { // Kiểm tra xem các thuộc tính có tồn tại không
        const startDate = moment(row.startDate);
        const endDate = moment(row.endDate);
        const rowIndex = index + 1;
        const endDatePastError = `Row ${rowIndex}: endDate must not be in the past.`;
        if (endDate.isBefore(now, 'day')) {
          errorsList.push(endDatePastError);
        } else {
          const indexToRemove = errorsList.indexOf(endDatePastError);
          if (indexToRemove > -1) {
            errorsList.splice(indexToRemove, 1);
          }
        }

        const endDateBeforeStartDateError = `Row ${rowIndex}: endDate must not be before startDate.`;
        if (endDate.isBefore(startDate)) {
          errorsList.push(endDateBeforeStartDateError);
        } else {
          const indexToRemove = errorsList.indexOf(endDateBeforeStartDateError);
          if (indexToRemove > -1) {
            errorsList.splice(indexToRemove, 1);
          }
        }
      } else {
        errorsList.push(`Row ${index + 1}: startDate or endDate is missing.`);
      }
    });
    setErrors(errorsList);
  };
  const validateCsvData = (csvData, header) => {
    const errors = [];
    if (csvData.length > 0) {
      const headers = Object.keys(csvData[0]);

      // Kiểm tra số lượng và thứ tự cột
      if (header.length !== expectedColumns.length) {
        errors.push(`Expected ${expectedColumns.length} columns, but found ${header.length}.`);
      }
      if (headers.length !== expectedColumns.length) {
        errors.push(`Expected ${expectedColumns.length} columns, but found ${headers.length}.`);
      }

      expectedColumns.forEach((column, index) => {
        if (headers[index] !== column) {
          errors.push(`Expected column "${column}" at position ${index + 1}, but found "${headers[index]}".`);
        }
      });
      const now = moment();

      csvData.forEach((row, index) => {
      // Check if startDate and endDate are valid dates
        const startDate = moment(row.startDate, ['YYYY/MM/DD', 'YYYY/MM/DD HH:mm:ss'], true);
        const endDate = moment(row.endDate, ['YYYY/MM/DD', 'YYYY/MM/DD HH:mm:ss'], true);

        if (!startDate.isValid() || !endDate.isValid()) {
          errors.push(`Row ${index + 1}: Invalid date format for startDate or endDate.`);
        } else {
        // Check if dates are not smaller than current date
          if (endDate.isBefore(now, 'day')) {
            errors.push(`Row ${index + 1}: endDate must not be in the past.`);
          }

          // Check if endDate is not smaller than startDate
          if (endDate.isBefore(startDate)) {
            errors.push(`Row ${index + 1}: endDate must not be before startDate.`);
          }
        }
      });
    }
    return errors;
  };

  const handleImportAccount = () => {
    if (file) {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          if (results.data.length === 0) {
            setErrors(['The file contains only header and no data.']);
            setData([]);
          } else {
            const validationErrors = validateCsvData(results.data, results.meta.fields);
            if (validationErrors.length > 0) {
              setErrors(validationErrors);
            } else {
              // Process the data as needed
              const updatedData = results.data.map((row) => ({
                ...row,
                startDate: moment(row.startDate, 'YYYY/MM/DD HH:mm:ss'),
                endDate: moment(row.endDate, 'YYYY/MM/DD HH:mm:ss'),
              }));
              setData(updatedData);
              setCurrentPage(1);
              setListItem(updatedData.slice(0, 50));
              setErrors([]);
            }
          }
        },
        error: (error) => {
          setErrors(['Error reading file.']);
        },
      });
    }
  };

  const handleCreateAccount = () => {
    data.map((item) => {
      if (item) {
        item.endDate = moment(item.endDate).format();
        item.startDate = moment(item.startDate).format();
      }
      return item;
    });
    const listUser = { listUser: data };

    if (errors.length === 0) {
      baseRequest.post(uris.IMPORT_ACCOUNT, listUser).then((response) => {
        if (response?.status === 'NG') {
          store.dispatch(setMessage(response?.error || ''));
        } else setOpenSuccess(true);
      });
    }
  };
  const inputFile = useRef(null);
  const isValidFile = () => {
    if (file?.name?.length > 0) {
      const maxSize = 5 * 1024 * 1024;
      if ((/\.(csv)$/i.test(file.name)) && (file.size < maxSize)) {
        setReadyImport(true);
      } else {
        setReadyImport(false);
        dispatch(setMessage('You need to select the csv file and have a capacity of less than 5MB.'));
      }
    }
  };
  useEffect(() => { isValidFile(); }, [file]);
  const handleChangeStartDate = (data, i) => {
    if (data) { // Kiểm tra nếu data không undefined
      setStartDate({
        startDate: moment(data).format('YYYY-MM-DD HH:mm:ss'),
        index: i,
      });
    }
  };

  const handleChangeEndDate = (data, i) => {
    if (data) { // Kiểm tra nếu data không undefined
      setEndDate({
        endDate: moment(data).format('YYYY-MM-DD HH:mm:ss'),
        index: i,
      });
    }
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
    navigate('/admin/tools/representAccount');
    setValues('manual');
  };
  const handleClearFile = () => {
    setFile(null);
    if (inputFile.current) {
      inputFile.current.value = ''; // Reset giá trị của input file
    }
  };
  const handleChangeFile = (e) => {
    if (e.target.files.length > 0) {
      setFile(null);

      setFile(e.target.files[0]);
    }
  };
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };
  useEffect(() => {
    checkValidateDate(data);
  }, [data]);
  useEffect(() => {
    const start = (currentPage - 1) * 50;
    const end = start + 50;
    setListItem(data.slice(start, end));
  }, [data, currentPage]);
  return (
    <div style={{ marginBottom: '40px' }}>
      <ModalSuccess
        openSuccess={openSuccess}
        handleClose={handleCloseSuccess}
        title="Account has resgistered"
      />
      <Box display="flex" alignItems="center">
        <Box onClick={() => valueOption === 'csv' && inputFile.current.click()}>
          <Typography sx={{ cursor: 'pointer', textDecoration: 'underline' }}>{file ? file.name : t('chooseFile')}</Typography>
          <input style={{ display: 'none' }} type="file" accept=".csv" onChange={handleChangeFile} ref={inputFile} />
        </Box>
        <Button
          onClick={() => readyImport && handleImportAccount()}
          className="csv-btn"
          sx={{ margin: '0 20px' }}
        >
          <div>{t('sharedImport')}</div>
        </Button>
        <Box sx={{ cursor: 'pointer' }} onClick={() => handleClearFile()}>
          <DeleteOutlineOutlinedIcon style={{ paddingRight: '5px', fontSize: '36px' }} />
        </Box>
      </Box>
      <Typography sx={{ color: 'red' }}>{errors.length > 0 && errors[0]}</Typography>
      <div style={{ margin: '20px 0' }}>
        {
      data?.length > 0 && (
        <div>
          <TableContainer component={Paper} style={{ maxHeight: '700px' }}>
            <Table aria-label="collapsible table" style={{ minWidth: '100%' }}>
              <TableHead style={{ position: 'sticky', top: 0, backgroundColor: '#fff', zIndex: 1 }}>
                <TableRow>
                  <StyledTableCell align="center">{t('category1')}</StyledTableCell>
                  <StyledTableCell align="center">{t('category2')}</StyledTableCell>
                  <StyledTableCell align="center">{t('statusTableHeader')}</StyledTableCell>
                  <StyledTableCell align="center" className={classes.required}>{t('nameTableHeader')}</StyledTableCell>
                  <StyledTableCell align="center">{t('userIdTableHeader')}</StyledTableCell>
                  <StyledTableCell align="center">{t('userStatusTableHeader')}</StyledTableCell>
                  <StyledTableCell align="center">{t('loginId')}</StyledTableCell>
                  <StyledTableCell align="center" className={classes.required}>{t('reportStartDate')}</StyledTableCell>
                  <StyledTableCell align="center" className={classes.required}>{t('endDate')}</StyledTableCell>
                  <StyledTableCell align="center">{t('note')}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ overflowY: 'auto', height: 'calc(100% - 56px)' }}>
                {
        listItem?.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <TableRow style={{ backgroundColor: index === 0 ? '#ccc' : 'transparent' }} key={`${item.name}.${index}`}>
            <TableCell className={classes.borderCell}>
              <div>
                <Typography variant="body1" sx={{ display: 'flex', justifyContent: 'center' }}>{t('smartPhone')}</Typography>
              </div>
            </TableCell>
            <TableCell className={classes.borderCell}>
              <div>
                <Typography variant="body1" sx={{ display: 'flex', justifyContent: 'center' }}>{index === 0 ? t('represent') : t('member')}</Typography>
              </div>
            </TableCell>
            <TableCell className={classes.borderCell}>
              <div>
                <Typography variant="body1" sx={{ display: 'flex', justifyContent: 'center' }}>{t('newApplication')}</Typography>
              </div>
            </TableCell>
            <TableCell className={classes.borderCell}>
              <FormControl fullWidth>
                <TextField
                  required
                  sx={{ width: '150px' }}
                  name="name"
                  value={item.name}
                  autoComplete="name"
                  autoFocus
                  onChange={(e) => handleChangeData(index, 'name', e.target.value)}
                />
              </FormControl>
            </TableCell>
            <TableCell className={classes.borderCell}>
              <div>
                <Typography variant="body1" sx={{ display: 'flex', justifyContent: 'center' }}>{t('autoGenerate')}</Typography>
              </div>
            </TableCell>
            <TableCell className={classes.borderCell}>
              <div>
                <Typography variant="body1" sx={{ display: 'flex', justifyContent: 'center' }}>{t('inUse')}</Typography>
              </div>
            </TableCell>
            <TableCell className={classes.borderCell}>
              <div>
                <Typography variant="body1" sx={{ display: 'flex', justifyContent: 'center' }}>{t('autoGenerate')}</Typography>
              </div>
            </TableCell>
            <TableCell className={classes.dateCell}>
              <div>
                <DatePopupEditor
                  valueDate={item.startDate}
                  handleChangeDate={(e) => handleChangeStartDate(e, index)}
                />
              </div>
            </TableCell>
            <TableCell className={classes.dateCell}>
              <div>
                <DatePopupEditor
                  valueDate={item.endDate}
                  handleChangeDate={(e) => handleChangeEndDate(e, index)}
                />
              </div>
            </TableCell>
            <TableCell className={classes.borderCell}>
              <div>
                <input style={{ padding: '8px' }} type="text" value={item.note} onChange={(e) => handleChangeData(index, 'note', e.target.value)} />
              </div>
            </TableCell>
          </TableRow>
        ))
      }
              </TableBody>
            </Table>

          </TableContainer>
          <div style={{ width: '1500px' }} className="container-pagination">
            <Pagination
              className="pagination"
              size="small"
              count={count}
              page={currentPage}
              onChange={handlePageChange}
              showFirstButton
              showLastButton
            />
            <TablePagination
              size="small"
              component="div"
              count={data?.length}
              rowsPerPage={50}
              page={currentPage - 1}
              onPageChange={() => {}}
              rowsPerPageOptions={[]}
              nextIconButtonProps={{ style: { display: 'none' } }}
              backIconButtonProps={{ style: { display: 'none' } }}
            />
          </div>
        </div>

      )
      }

      </div>
      <Button
        disabled={errors?.length > 0}
        type="submit"
        variant="contained"
        onClick={handleCreateAccount}
        styleButton={centerStyle}
        style={{ backgroundColor: configColorLayout, width: '150px', display: data?.length > 0 ? 'flex' : 'none', position: 'absolute', right: '60px', margin: '30px' }}
      >
        {t('perform')}
      </Button>
      {/* <Snackbar
        open={open}
        onClose={handleClose}
        autoHideDuration={snackBarDurationVeryLongMs}
        message={t(errorMessage)}
        action={action}
      /> */}
    </div>
  );
};

export default CreateAccountByCSV;
