import React, { useEffect, useState } from 'react';
import {
  Button,
  FormControl,
  Modal,
  Box,
  Typography,
  Grid,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useForm } from 'react-hook-form';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import HeaderDevice from '../../../tools/components/HeaderDevice';
import configColorLayout from '../../../../settings/ConfigColorLayout';
import { useTranslation } from '../../../../common/components/LocalizationProvider';
import FormTextField from '../../../components/form/FormTextField';
import FormAutoComplete from '../../../components/form/FormAutoComplete';
import baseRequest from '../../../../apis/baseRequest';
import uris from '../../../../apis/uris';
import store, { setMessage } from '../../../../store';
import ModalConfirm from '../../../components/modal/ModalConfirm';
import MasterDataPermission from '../../../../config-permission/masterDataPermission';
import PageSection from './components/PageSection';
import TargetCitySection from './components/TargetCitySection';
import ButtonSection from './components/ButtonSection';
import ModalSuccess from '../../../components/modal/ModalSuccess';
import dataPermisstion from './components/MockData';

const findParentByRole = (list, role) => {
  let result = null;

  list.some((item) => {
    if (item.role === role) {
      result = item;
      return true; // Dừng lặp khi tìm thấy
    }
    if (item.children) {
      result = findParentByRole(item.children, role);
      return result !== null; // Dừng lặp khi tìm thấy
    }
    return false;
  });

  return result;
};

const SetupRole = () => {
  const { control, handleSubmit, getValues, setValue, watch, setFocus, formState: { isValid, isDirty, errors } } = useForm({
    mode: 'onChange',
  });
  const [openModal, setOpenModal] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  // const mockData = dataPermisstion;
  const [selectedItems, setSelectedItems] = useState([]);

  const [childRole, setChildRole] = useState([]);

  const { id } = useParams();

  const navigate = useNavigate();
  const t = useTranslation();
  const handleCreateRole = (data) => {
    const params = {
      name: data.name,
      reserve: data.reserve ? data.reserve : '',
      permissions: selectedItems,
    };
    if (id) params.id = id;
    baseRequest.post(id ? uris.EDIT_ROLE : uris.CREATE_ROLE, params).then((response) => {
      if (response?.status === 'NG') {
        store.dispatch(setMessage(response?.error || ''));
      } else setOpenSuccess(true);
    });
  };

  const handleClose = () => {
    setOpenModal(false);
  };
  const handleBack = () => {
    setOpenModal(false);
    navigate('/admin/settings/roles');
  };

  const handleCheckData = () => {
    if (isDirty) {
      setOpenModal(true);
    } else {
      navigate('/admin/settings/roles');
    }
  };
  const handleCheckedCheckBox = (isChecked, role) => {
    if (isChecked) {
      if (Array.isArray(findParentByRole(MasterDataPermission, role)?.children)) {
        setChildRole((prevItems) => [...prevItems, findParentByRole(MasterDataPermission, role)]);
      }
      setSelectedItems((prevItems) => [...prevItems, role]);
    } else {
      setChildRole(childRole.filter((item) => item?.role?.indexOf(role)));
      setSelectedItems((prevItems) => prevItems.filter((item) => item?.indexOf(role)));
    }
  };
  const handleDetailRole = async () => {
    const data = { roleId: Number(id) };
    await baseRequest.post(uris.DETAIL_ROLE, data).then((response) => {
      if (response?.status === 'NG') {
        store.dispatch(setMessage(response?.error || ''));
      } else {
        setValue('name', response.data.name);
        setValue('reserve', response.data.reserve);
        response.data.permissions.map((item) => handleCheckedCheckBox(true, item));

        setSelectedItems(response.data.permissions);
      }
    });
  };
  useEffect(() => {
    if (id) {
      handleDetailRole();
    }
  }, []);

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
    navigate('/admin/settings/roles');
  };
  return (
    <div>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <div style={{ borderBottom: 'none' }} className="upsert-device-header">
          <FormControl>
            <HeaderDevice handleOnNavigate={handleCheckData} noDescription />
          </FormControl>
        </div>
        <Button
          disabled={!id ? !isValid : !(Object.keys(errors).length === 0 && errors.constructor === Object)}
          type="submit"
          onClick={handleSubmit(handleCreateRole)}
          variant="contained"
          style={{ backgroundColor: configColorLayout, width: '150px' }}
        >
          {id ? t('sharedSave') : t('shareAddNew')}
        </Button>
      </Box>
      <ModalSuccess
        openSuccess={openSuccess}
        handleClose={handleCloseSuccess}
        title={t('roleSuccess')}
      />
      <ModalConfirm
        openModal={openModal}
        handleClose={handleClose}
        propsModal={
          {
            confirm: t('confirmMessage'),
            msgWarning: t('messageWarning'),
            action: handleBack,
          }
        }
      />
      <form onSubmit={handleSubmit(handleCreateRole)}>
        <Box mb={5} className="container-form">
          <div className="title-form">{t('roleBasicInfo')}</div>
          <Grid container spacing={1} ml={6} mt={3}>
            <Grid item xs={6}>
              <FormTextField
                label={t('roleName')}
                controllerProps={{
                  control,
                  type: 'checkbox',
                  name: 'name',
                  rules: { required: t('FieldNotNull'), maxLength: { value: 50, message: t('Max50DRole') } },
                }}
                required
                autocomplete="off"
              />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
                label={t('reserve')}
                controllerProps={{
                  control,
                  name: 'reserve',
                }}
              />
            </Grid>
          </Grid>

        </Box>

        <PageSection
          title={t('screenDisplaySetting')}
          childRole={childRole}
          handleCheckedCheckBox={handleCheckedCheckBox}
          permisstion={selectedItems}
        />

        <ButtonSection
          title={t('fuctionSetting')}
          childRole={childRole}
          handleCheckedCheckBox={handleCheckedCheckBox}
          permisstion={selectedItems}
        />
      </form>

    </div>
  );
};

export default SetupRole;
