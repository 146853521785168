/* eslint-disable no-alert */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  Button, useMediaQuery, useTheme, Step, StepLabel, Stepper, CircularProgress, Box, AppBar, Toolbar, IconButton, Container,
} from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import CardCustom from './components/CardCustom';
import PaymentForm from './stepper-form/PaymentForm';
import CheckoutResult from './stepper-form/CheckoutResult';
import CheckForm from './stepper-form/CheckForm';
import { combinedString, encrypted } from './components/utils';
import fetchApi from '../admin/FetchApi';
import { useTranslation } from '../common/components/LocalizationProvider';
import VisaImage from './components/VisaImage';
import ConfirmInformation from './stepper-form/ConfirmInformation';
import LogoImage from '../login/LogoImage';
import Footer from './components/Footer';

const useStyles = makeStyles({
  field: {
    margin: '5px 0px',
  },
  boxContainer: {
    margin: '30px',
    width: '50%',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: '20px',
    paddingBottom: '30px',
  },
  button: {
    maxWidth: '200px',
    width: '100px',
    backgroundColor: '#0eaa92',
    '&:hover': {
      backgroundColor: '#0eaa92',
    },
  },
  returnBtn: {
    maxWidth: '200px',
    width: '100px',
    backgroundColor: '#0eaa92',
    marginLeft: '10px',
    '&:hover': {
      backgroundColor: '#0eaa92',
    },
  },
  confirmBtn: {
    width: '200px',
    backgroundColor: 'red',
    color: 'white',
    marginBottom: '20px',
    '&:hover': { backgroundColor: 'red' },
  },
  plans: {
    display: 'flex', flexDirection: 'column',
  },
  step: {
    backgroundColor: 'red', height: 50, width: '100%', margin: '15px 0', display: 'flex', alignItems: 'center',
  },
  stepTitle: {
    color: 'white', margin: '0 10px',
  },
  stepperRoot: {
    paddingTop: '30px',
    '& .Mui-active': { color: '#0eaa92' },
    '& .Mui-completed': { color: '#0eaa92' },
    '& .Mui-disabled .MuiStepIcon-root': { color: '#0eaa92' },
    '& .MuiStepConnector-root': { color: '#0eaa92' },
    '& .MuiStepLabel-alternativeLabel ': { color: 'black' },
  },
});

const getSteps = () => ['selectPackage', 'enterInfor', 'confirmation'];

const PaymentPage = () => {
  const t = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));

  const steps = getSteps();

  const [products, setProducts] = useState([]);
  const [productCode, setProductCode] = useState('');
  const [activeStep, setActiveStep] = useState(0);
  const [result, setResult] = useState();
  const [endDate, setEndDate] = useState('');

  const [cardData, setCardData] = useState({
    aid: '126295',
    fn: '',
    ln: '',
    cn: '',
    em: '',
    ey: '',
    cvv: '',
    pn: '',
  });

  const basicPlans = products.filter((item) => item.payment_rate_plan_code.includes('BASIC'));
  const premiumPlans = products.filter((item) => item.payment_rate_plan_code.includes('PREMIUM'));

  //*  Y U P   V A L I D A T I O N    S C H E M A S
  const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
  const paymentValidationSchema = yup.object({
    name: yup
      .string('Enter your name')
      .min(2, 'Name should be of minimum 2 characters length')
      .required('Name is required'),
    pn: yup
      .string('Enter your phone number')
      .matches(phoneRegExp, 'Phone number is not valid')
      .required('Phone is required'),
  });

  //*  F O R M I K
  const formikPayment = useFormik({
    initialValues: {
      aid: '126295',
      fn: '',
      ln: '',
      cn: '',
      em: '',
      ey: '',
      cvv: '',
      pn: '',
    },
    validationSchema: paymentValidationSchema,
  });

  useEffect(() => {
    setEndDate(combinedString(formikPayment.values.ey, formikPayment.values.em));
  }, [formikPayment.values.em, formikPayment.values.ey]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    const fetchProducts = async () => {
      const response = await fetchApi('/api/payment/payment_products', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.ok) {
        const products = await response.json();
        setProducts(products.data);
      }
    };

    fetchProducts();
  }, []);

  const handleReset = () => {
    setActiveStep(0);
    formikPayment.resetForm();
  };

  const handlePayment = async () => {
    const payments = encrypted(JSON.stringify({
      aid: '126295',
      iid: productCode,
      rt: '1',
      tkn: $('#tkn').val(),
      pn: '0868700023',
      cod: '123',
    }));
    const response = await fetchApi('/api/payment/out_site_web_gateway_with_product', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ payments }),
    });
    if (response.ok) {
      const resultCode = await response.json();
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setResult(resultCode.data.ec);
    } else {
      console.log('error');
    }
  };

  //*  H A N D L E   S T E P P E R
  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <div style={desktop ? { margin: '20px 0px', display: 'flex', justifyContent: 'space-evenly', paddingBottom: '21px' } : { margin: '20px 0px', display: 'flex', justifyContent: 'space-evenly', flexWrap: 'wrap', paddingBottom: '30px' }}>
            <div className={classes.plans}>
              {basicPlans.map((item) => (
                <CardCustom
                  key={item.code}
                  type={item.name}
                  cost={item.commodity_price}
                  description={item.description}
                  contract={item.contract}
                  quantity={item.quantity}
                  unit={item.unit}
                  onClick={() => {
                    setProductCode(item.code);
                    setActiveStep(activeStep + 1);
                  }}
                />
              ))}
            </div>
            <div className={classes.plans}>
              {premiumPlans.map((item) => (
                <CardCustom
                  premium
                  key={item.code}
                  type={item.name}
                  cost={item.commodity_price}
                  description={item.description}
                  contract={item.contract}
                  quantity={item.quantity}
                  unit={item.unit}
                  onClick={() => {
                    setProductCode(item.code);
                    setActiveStep(activeStep + 1);
                  }}
                />
              ))}
            </div>
          </div>
        );
      case 1:
        return (
          <>
            <VisaImage />
            <PaymentForm formik={formikPayment} />
          </>
        );
      case 2:
        return (
          <>
            <ConfirmInformation dataCheck={cardData} endDate={endDate} setActiveStep={setActiveStep} />
          </>
        );
      default:
        return 'Unknown step';
    }
  };

  //*  H A N D L E   P A Y M E N T
  /**
* 1．Thực hiện xử lý quyết toán
* Chuẩn bị một chức năng để thực hiện quyết toán.
*/
  function execPurchase(resultCode, errMsg) {
    if (resultCode !== 'Success') {
      window.alert(errMsg);
    } else {
      $('#cn').val('');
      $('#ey').val('');
      $('#em').val('');
      $('#fn').val('');
      $('#ln').val('');
      // Thực hiện quy trình thực hiện yêu cầu thanh toán đến merchant server.
      handlePayment();
    }
  }
  /**

  /**
* 2. Tạo mã thông báo thẻ tín dụng
* Chuẩn bị một hàm để tạo mã thông báo.
*/
  const doPurchase = () => {
    try {
      CPToken.TokenCreate({
        aid: '126295',
        cn: cardData.cn, // số thẻ
        ed: endDate, // ngày hết hạn
        fn: cardData.fn, // tên chủ thẻ
        ln: cardData.ln, // tên chủ thẻ
        cvv: cardData.cvv,
      }, execPurchase);
    } catch (err) {
      console.log('error token');
    }
    // Gọi quá trình tạo mã thông báo.
  };

  const handleSubmit = () => {
    switch (activeStep) {
      case 1:
        setCardData(formikPayment.values);
        handleNext();
        break;
      case 2:
        doPurchase();
        break;
      default:
        // eslint-disable-next-line no-unused-expressions
        null;
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ backgroundColor: '#f2d13d' }}>
        <Toolbar>
          <div style={{ margin: '5px' }}>
            <LogoImage color={theme.palette.primary.main} />
          </div>
        </Toolbar>
      </AppBar>
      <input id="tkn" name="tkn" type="hidden" value="" />
      <Stepper alternativeLabel activeStep={activeStep} className={classes.stepperRoot}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{t(label)}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div style={{ padding: '0px 20px' }}>
        {activeStep === steps.length ? (
          <div style={desktop ? { margin: '50px 100px', paddingBottom: '30px' } : {}}>
            <CheckoutResult resultCode={result} />
            <Button variant="contained" onClick={handleReset} className={classes.returnBtn}>{t('return')}</Button>
            <div style={desktop ? { paddingTop: '252px' } : { paddingTop: '306px' }} />
          </div>
        ) : (
          <div>
            {getStepContent(activeStep)}
            {activeStep !== 0 && activeStep === steps.length - 2 && (
              <Container maxWidth="sm">
                <div className={classes.buttons}>
                  <Button
                    variant="contained"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.button}
                  >
                    {t('return')}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    className={classes.button}
                    disabled={
                    !formikPayment.values.fn ||
                    !formikPayment.values.ln ||
                    !formikPayment.values.cn ||
                    !formikPayment.values.cvv
                  }
                  >
                    {t('next')}
                  </Button>
                </div>
              </Container>
            )}
            {activeStep === steps.length - 1 && (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={handleSubmit}
                  className={classes.confirmBtn}
                >
                  {t('sharedConfirm')}
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
      <Footer />
    </Box>
  );
};

export default PaymentPage;
