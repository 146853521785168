/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-undef */
import React, { useEffect, useRef, useState } from 'react';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import {
  Button, Typography, TextField,
  FormControl,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import './createBtn.css';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
// import {
//   CircleMode,
//   DragCircleMode,
//   DirectMode,
//   SimpleSelectMode,
// } from 'maplibre-gl-draw-circle';
import MapCurrentLocation from '../../../../map/MapCurrentLocation';
import MapGeocoder from '../../../../map/geocoder/MapGeocoder';
import Header from '../Header';
import MapDefaultCamera from '../../../../map/main/MapDefaultCamera';
import MapView, { map } from '../../../../map/core/MapView';
import { useTranslation } from '../../../../common/components/LocalizationProvider';
import { errorsActions, geofencesActions } from '../../../../store';
import CircleMode from './CircleMode';
import theme from '../../../../map/draw/theme';
import { useCatchCallback } from '../../../../reactHelper';
import {
  circleFormat, geometryToArea, polygonToCircle, reverseCoordinates,
} from '../../../../map/core/mapUtil';
import NewTable from '../../../components/NewTable';
import { getUserIds } from '../../../common/utils';
import ModalConfirm from '../../../components/modal/ModalConfirm';

const draw = new MapboxDraw({
  displayControlsDefault: false,
  // controls: {
  //   // polygon: true,
  //   // line_string: true,
  //   trash: true,
  // },
  modes: {
    ...MapboxDraw.modes,
    draw_circle: CircleMode,
  },
  userProperties: false,
});

const useStyles = makeStyles({
  configTable: {
    height: '600px',
  },
  saveBtn: {
    width: '100px',
    backgroundColor: 'red',
    border: '5px outset red',
    color: 'white',
    '&:hover': {
      backgroundColor: 'red',
    },
    '&:disabled': {
      backgroundColor: 'gray',
      border: '5px outset gray',
      color: 'white',
      '&:hover': {
        backgroundColor: 'gray',
      },
    },
  },
  title: {
    fontWeight: 'bold',
    margin: '20px 0 20px 0',
    '&::after': {
      content: '"*"',
      color: 'red',
    },
  },
  areaBtn: {
    width: '120px !important',
    height: '40px !important',
    backgroundColor: '#ffebad !important',
    color: 'black !important',
    border: '7px outset #ffebad !important',
  },
});

const CreateArea = () => {
  const navigate = useNavigate();
  const t = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const tableRef = useRef();
  // true là timesheet false là tool
  const checkPath = window.location.pathname.includes('areaSetting');

  const [gridApi, getGridApi] = useState(null);
  const [areaData, setAreaData] = useState({ name: '', area: null });
  const [drawBtn, setDrawBtn] = useState();
  const [dataSelect, setDataSelect] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const checkedList = useSelector((state) => state.helper.checkedList);
  // const selectedUser = useSelector((state) => state.helper.selectedUser);

  const userIds = getUserIds(checkedList, dataSelect);

  class MyCustomControl {
    onAdd(map) {
      this.containerCir = document.createElement('button');

      this.map = map;

      this.containerCir.onclick = () => {
        const zoom = this.map.getZoom();

        draw.changeMode('draw_circle', {
          initialRadiusInKm: 1 / 2 ** (zoom - 11),
        });

        draw.delete('-96.5801808656236544.76489866786821');
      };
      this.containerCir.className =
        `mapbox-gl-draw_ctrl-draw-btn my-custom-control-cir ${classes.areaBtn}`;

      this.content = document.createElement('div');
      this.content.innerText = t('areaBtn');
      this.mainContainer = document.createElement('div');

      this.mainContainer.className = 'mapboxgl-ctrl-group mapboxgl-ctrl';
      this.mainContainer.appendChild(this.containerCir);
      this.containerCir.appendChild(this.content);

      return this.mainContainer;
    }

    onRemove() {
      // this.container.parentNode.removeChild(this.container);
      this.mainContainer.remove();
      this.map = undefined;
    }
  }

  const myCustomControl = new MyCustomControl();

  const handleSelectionChange = () => {
    setDataSelect(gridApi?.getSelectedRows() || []);
  };

  const handleRowDataUpdated = () => {
    gridApi?.forEachNode((node) => {
      if (checkedList.length !== 0) {
        node.setSelected(true);
      }
    });
  };

  const dataTable = {
    getGridApi,
    removeColumn: [1],
    tableRef,
    floatingFilter: true,
    showPagination: true,
    className: classes.configTable,
    onSelectionChanged: handleSelectionChange,
    onRowDataUpdated: handleRowDataUpdated,
  };

  const refreshGeofences = useCatchCallback(async () => {
    const response = await fetch('/api/geofences');
    if (response.ok) {
      dispatch(geofencesActions.refresh(await response.json()));
    } else {
      throw Error(await response.text());
    }
  }, [dispatch]);

  useEffect(() => {
    refreshGeofences();

    map.addControl(myCustomControl, 'bottom-right');
    map.addControl(draw);
    setDrawBtn(myCustomControl);
    return () => map.removeControl(draw, myCustomControl);
  }, [map, draw]);

  useEffect(() => {
    const copyData = { ...areaData };
    const onDrawCreate = async (event) => {
      const feature = event.features[0];
      const circle = {
        centerCircle: reverseCoordinates(feature.properties.center),
        radiusCircle: feature.properties.radius * 1000,
      };
      draw.deleteAll();
      draw.add(feature);
      copyData.area = circleFormat(circle.centerCircle.join(' '), circle.radiusCircle);
      setAreaData(copyData);
    };

    map.on('draw.create', onDrawCreate);
    return () => {
      map.off('draw.create', onDrawCreate);
    };
  }, [areaData, draw, map]);

  useEffect(() => () => {
    map.removeControl(myCustomControl);
  }, []);

  useEffect(() => {
    const copyData = { ...areaData };
    const listener = async (event) => {
      const feature = event.features[0];
      draw.add(feature);
      copyData.area = polygonToCircle(geometryToArea(feature.geometry));
      setAreaData(copyData);
    };

    map.on('draw.update', listener);
    return () => map.off('draw.update', listener);
  }, [dispatch, refreshGeofences, areaData]);

  const handleSaveArea = async () => {
    try {
      const response = await fetch('/api/admin/geofences/linktouser', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          name: areaData.name,
          area: areaData.area,
          userIds,
          geofenceType: checkPath ? 1 : 0,
        }),
      });
      if (response.ok) {
        const item = await response.json();
        map.removeControl(drawBtn);
        dispatch(geofencesActions.refresh(await response.json()));
      } else {
        throw Error(await response.text());
      }
    } catch (error) {
      // dispatch(errorsActions.push(error.message));

    }
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleBack = () => {
    setOpenModal(false);
    return checkPath ? navigate('/admin/timesheet/areaSetting') : navigate('/admin/tools/setupArea');
  };
  const handleCheckData = () => {
    if (areaData.name || areaData.area) {
      setOpenModal(true);
    } else if (checkPath) navigate('/admin/timesheet/areaSetting');
    else navigate('/admin/tools/setupArea');
  };
  return (
    <div>
      <div>
        <FormControl>
          <Header handleOnNavigate={handleCheckData} noDescription />
        </FormControl>
      </div>
      <ModalConfirm
        openModal={openModal}
        handleClose={handleCloseModal}
        propsModal={
          {
            confirm: t('confirmMessage'),
            msgWarning: t('messageWarning'),
            action: handleBack,
          }
        }
      />
      <div style={{ margin: '10px' }}>
        <div>
          <Typography variant="h5" className={classes.title}>{t('sharedAreaName')}</Typography>
          <TextField
            required
            style={{ width: '80%', minWidth: '100px', maxWidth: '400px' }}
            name="areaName"
            label={t('sharedName')}
            value={areaData.name}
            onChange={(event) => {
              const copyData = { ...areaData };
              copyData.name = event.target.value;
              setAreaData(copyData);
            }}
          />
        </div>
        <div>
          <Typography variant="h5" className={classes.title}>{t('sharedArea')}</Typography>
          <Typography>{t('areaText')}</Typography>
        </div>
        <div style={{ flexGrow: 1, height: '500px', width: '100%', border: '4px solid' }}>
          <MapView>
            <MapDefaultCamera />
          </MapView>
          <MapCurrentLocation />
          <MapGeocoder />
        </div>
        <Typography variant="h5" sx={{ fontWeight: 'bold', margin: '20px 0 20px 0' }}>{t('applicableObject')}</Typography>
        {/* <TableConfig {...dataTable} /> */}
        <NewTable {...dataTable} />
        <div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: '20px' }}>
          <Button
            className={classes.saveBtn}
            disabled={!areaData.name || !areaData.area}
            onClick={() => {
              handleSaveArea();
              refreshGeofences();
              navigate(checkPath ? '/admin/timesheet/areaSetting' : '/admin/tools/setupArea');
            }}
          >
            {t('sharedSave')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CreateArea;
