import { addImageToMap, createIconCanvas } from '../../map/core/mapUtil';
import ShareMarkerBg from '../../resources/images/location_share/marker_bg.png';
import { map } from '../../map/core/MapView';

export default async (dataMapInfo, sourceId) => {
  try {
    const addedImages = [];

    await Promise.all(
      dataMapInfo?.map(async (item) => {
        const iconImage = await createIconCanvas(ShareMarkerBg, item?.category?.pathIcon, item?.category?.name, item?.category?.iconColor);
        if (!map.isStyleLoaded()) {
          await new Promise((resolve) => {
            map.once('styledata', () => {
              resolve();
            });
          });
        }
        await addImageToMap(map, iconImage, item.lon, item.lat, item.id, sourceId);
        addedImages.push(item.id);
      }),
    );

    return addedImages;
  } catch (error) {
    console.error('Failed to add icon to map:', error);
    return [];
  }
};

export const cleanupMap = (dataMapInfo) => {
  dataMapInfo?.forEach((item) => {
    const id = `point-${item.id}`;
    if (map.hasImage(id)) {
      map.removeImage(id);
      map.removeLayer(id);
      map.removeSource(id);
    }
  });
};
