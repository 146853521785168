export const listRole = [
  {
    role: 'home',
    type: 'screen',
    permissiongroup: 'functional',
    children: [
      {
        role: 'device.CSV',
        type: 'button',
        permissiongroup: 'edit',
      },
      {
        role: 'device.edit',
        type: 'button',
        permissiongroup: 'edit',
      },
      {
        role: 'device.delete',
        type: 'button',
        permissiongroup: 'edit',
      },
      {
        role: 'device.detail',
        type: 'button',
        permissiongroup: 'edit',
      },
    ],
  },
  {
    role: 'device',
    type: 'screen',
    permissiongroup: 'functional',
    children: [
      {
        role: 'device.add',
        type: 'button',
        permissiongroup: 'edit',
      },
      {
        role: 'device.delete',
        type: 'button',
        permissiongroup: 'edit',
      },
      {
        role: 'device.import',
        type: 'button',
        permissiongroup: 'edit',
      },
      {
        role: 'device.export',
        type: 'button',
        permissiongroup: 'edit',
      },
      {
        role: 'device.edit',
        type: 'button',
        permissiongroup: 'edit',
      },
      {
        role: 'device.viewHistory',
        type: 'button',
        permissiongroup: 'edit',
      },
    ],
  },
  {
    role: 'tool',
    type: 'screen',
    permissiongroup: 'functional',
    remarks: '',
    attribute: '',
    children: [
      {
        role: 'tools.reset',
        type: 'screen',
        permissiongroup: 'subFunctional',
      },
      {
        role: 'tools.areaSetting',
        type: 'screen',
        permissiongroup: 'subFunctional',
        children: [
          {
            role: 'tools.areaSetting.createArea',
            type: 'button',
            permissiongroup: 'edit',
          },
          {
            role: 'tools.areaSetting.edit',
            type: 'button',
            permissiongroup: 'edit',
          },
        ],
      },
      {
        role: 'tools.settingAccount',
        type: 'screen',
        permissiongroup: 'subFunctional',
      },
      {
        role: 'tools.changePassword',
        type: 'screen',
        permissiongroup: 'subFunctional',
      },

      {
        role: 'tools.movingAdministrativeAccounts',
        type: 'screen',
        permissiongroup: 'subFunctional',
      },
      {
        role: 'tools.suspensionOrResume',
        type: 'screen',
        permissiongroup: 'subFunctional',
        children: [
          {
            role: 'tools.suspensionOrResume.continueUsing',
            type: 'button',
            permissiongroup: 'edit',
          },
          {
            role: 'tools.suspensionOrResume.pauseUsing',
            type: 'button',
            permissiongroup: 'edit',
          },
        ],
      },

      {
        role: 'tools.sendNotifications',
        type: 'screen',
        permissiongroup: 'subFunctional',
      },
      {
        role: 'tools.generateRepresentAccount',
        type: 'screen',
        permissiongroup: 'subFunctional',
      },
    ],
  },
  {
    role: 'timeSheet',
    type: 'screen',
    permissiongroup: 'functional',
    remarks: '',
    attribute: '',
    children: [
      {
        role: 'timeSheet.areaSetting',
        type: 'screen',
        permissiongroup: 'subFunctional',
        children: [
          {
            role: 'timeSheet.areaSetting.createArea',
            type: 'button',
            permissiongroup: 'edit',
          },
          {
            role: 'timeSheet.areaSetting.edit',
            type: 'buton',
            permissiongroup: 'edit',
          },
        ],
      },
      {
        role: 'timeSheet.mainList',
        type: 'screen',
        permissiongroup: 'subFunctional',
        children: [
          {
            role: 'timeSheet.mainList.export',
            type: 'button',
            permissiongroup: 'edit',
          }],
      },
    ],
  },
  {
    role: 'settings',
    type: 'screen',
    permissiongroup: 'functional',
    remarks: '',
    attribute: '',
    children: [
      {
        role: 'settings.adminAccounts',
        type: 'screen',
        permissiongroup: 'subFunctional',
        children: [
          {
            role: 'settings.adminAccounts.CSV',
            type: 'button',
            permissiongroup: 'edit',
          },
          {
            role: 'settings.adminAccounts.create',
            type: 'button',
            permissiongroup: 'edit',
          },
          {
            role: 'settings.adminAccounts.edit',
            type: 'button',
            permissiongroup: 'edit',
          },
          {
            role: 'settings.adminAccounts.delete',
            type: 'button',
            permissiongroup: 'edit',
          },
          {
            role: 'settings.adminAccounts.disable',
            type: 'button',
            permissiongroup: 'edit',
          },
          {
            role: 'settings.adminAccounts.enable',
            type: 'button',
            permissiongroup: 'edit',
          },
        ],
      },
      {
        role: 'settings.roles',
        type: 'screen',
        permissiongroup: 'subFunctional',
        children: [
          {
            role: 'settings.roles.CSV',
            type: 'button',
            permissiongroup: 'edit',
          },
          {
            role: 'settings.roles.create',
            type: 'button',
            permissiongroup: 'edit',
          },
          {
            role: 'settings.roles.edit',
            type: 'button',
            permissiongroup: 'edit',
          },
          {
            role: 'settings.roles.delete',
            type: 'button',
            permissiongroup: 'edit',
          },
          {
            role: 'settings.roles.disable',
            type: 'button',
            permissiongroup: 'edit',
          },
          {
            role: 'settings.roles.enable',
            type: 'button',
            permissiongroup: 'edit',
          },
        ],
      },
      {
        role: 'settings.networkSetting',
        type: 'screen',
        permissiongroup: 'edit',
      },
      {
        role: 'settings.policySetting',
        type: 'screen',
        permissiongroup: 'edit',
      },
    ],
  },
];
export const dataPermisstion = [
  'device',
  'device.export',
  'device.add',
  'device.edit',
  'device.delete',
  'timeSheet',
  'timeSheet.areaSetting.createArea',
  'tools',
  'tools.areaSetting',
  'tools.settingAccount',
  'tools.reset',
  'tools.sendNotifications',
  'timeSheet.areaSetting.edit',
  'tools.changePassword',
  'tools.movingAdministrativeAccounts',
  'tools.areaSetting.edit',
  'tools.areaSetting.createArea',
  'timeSheet.areaSetting',
  'timeSheet.export',
  'home',
  'home.csv',
  'home.edit',
  'home.delete',
  'home.detail',
  'settings',
  'settings.adminAccounts',
  'settings.adminAccounts.csv',
  'settings.adminAccounts.create',
  'settings.adminAccounts.edit',
  'settings.adminAccounts.delete',
  'settings.adminAccounts.disable',
  'settings.adminAccounts.enable',
  'settings.roles',
  'settings.roles.csv',
  'settings.roles.create',
  'settings.roles.edit',
  'settings.roles.delete',
  'settings.roles.disable',
  'settings.roles.enable',
  'settings.networkSetting',
  'settings.policySetting',
  'device.import',
  'device.viewHistory',
  'tools.suspensionOrResume',
  'tools.suspensionOrResume.continueUsing',
  'tools.suspensionOrResume.pauseUsing',
  'tools.generateRepresentAccount',
  'tools.mapInformation',
];

export const targetCity = [
  { id: 0, role: 'hanoi' },
  { id: 1, role: 'tphcm' },
  { id: 2, role: 'dongnai' },
  { id: 3, role: 'ninhbinh' },
  { id: 4, role: 'nghean' },
  { id: 5, role: 'hue' },
  { id: 6, role: 'danang' },
];
export const Roledatas = [1, 2, 3];
