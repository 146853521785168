export default {
  REPRESENTATION_MEMBERS: '/api/admin/users/getAllRepresentationMembers',
  DEVICES_UPSERT: '/api/admin/devices/upsert/',
  DEVICES_HISTORY: '/api/admin/devices/history',
  DEVICES_DELETE_ALL: '/api/admin/devices/delete/all',
  DEVICES_SEARCH: '/api/admin/devices/search/',
  IMPORT_DEVICE: '/api/admin/devices/import',

  // admin account
  ADMIN_ACCOUNT_LIST: '/api/admin/admin-accounts/list',
  LIST_ROLE_ACCOUNT: '/api/admin/roles/list-role',
  DETAIL_ADMIN_ACCOUNT: '/api/admin/admin-accounts/detail',
  EDIT_ADMIN_ACCOUT: '/api/admin/admin-accounts/edit',
  ADMIN_ACCOUNT_DELETE: '/api/admin/admin-accounts/delete',
  ADMIN_ACCOUNT_ENABLE_DISABLE: '/api/admin/admin-accounts/enable',
  ADMIN_ACCOUNT_CREATE: '/api/admin/admin-accounts/create',
  ADMIN_ACCOUNT_STATUS: '/api/admin/admin-accounts/enable',

  // Role
  ROLE_LIST: '/api/admin/roles/list',
  CREATE_ROLE: '/api/admin/roles/create',
  DETAIL_ROLE: '/api/admin/roles/detail',
  EDIT_ROLE: '/api/admin/roles/edit',
  ROLES_ENABLE_DISABLE: '/api/admin/roles/enable',
  ROLES_DELETE: '/api/admin/roles/delete',
  // Timesheet
  USERTIMESHEET: '/api/admin/userTimesheet',

  // Area settingss
  LISTAREA: '/api/admin/geofences/list',
  SAVEAREA: '/api/admin/geofences/linktouser',

  // Permission
  GET_PERMISSION_BY_USER: '/api/admin/permission-admin/list-permission-by-user',
  GET_ALL_PERMISSION: '/api/admin/permission-admin/list-permission',
  // Import account
  IMPORT_ACCOUNT: '/api/admin/account/import',
  SHOW_QRCODE: '/api/admin/users/qr',

  // network Setting
  NETWORK_SETTING_LIST: '/api/admin/network/list',
  IMPORT_NETWORK_SETTING: '/api/admin/network/import',
  DELETE_NETWORK_SETTING: '/api/admin/network/delete',
  ADD_NETWORK_SETTING: '/api/admin/network/create',
  EDIT_NETWORK_SETTING: '/api/admin/network/edit',
  GET_NETWORK_SETTING_BY_ID: '/api/admin/network/detail',

  // Map info
  CREATE_MAP_INFO: '/api/admin/maps/create',
  EDIT_MAP_INFO: '/api/admin/maps/edit',
  LIST_MAP_INFO: '/api/admin/maps/list',
  SHARE_LOCATION_LIST_MAP_INFO: '/api/admin/maps/map-sharelocation',
  GET_MAP_INFO_BY_ID: '/api/admin/maps/detail',
  IMPORT_MAP_INFO: '/api/admin/maps/import',

  // Map info settings
  CREATE_CATEGORY: '/api/admin/categories/create',
  EDIT_CATEGORY: '/api/admin/categories/edit',
  LIST_CATEGORY: '/api/admin/categories/list',
  LIST_CATEGORY_ENABLE: '/api/admin/maps/categories',
  DELETE_CATEGORY: '/api/admin/categories/delete',
  HIDDEN_CATEGORY: '/api/admin/categories/hide',
  DETAIL_CATEGORY: '/api/admin/categories/detail',
  LIST_ICON: '/api/admin/categories/icons',
  CREATE_TARGET: '/api/admin/targets/create',
  EDIT_TARGET: '/api/admin/targets/edit',
  LIST_TARGET: '/api/admin/targets/list',
  LIST_TARGET_ENABLE: '/api/admin/maps/targets',
  DELETE_TARGET: '/api/admin/targets/delete',
  HIDDEN_TARGET: '/api/admin/targets/hide',
  DETAIL_TARGET: '/api/admin/targets/detail',
  CREATE_FACILITIES: '/api/admin/facilities/create',
  EDIT_FACILITIES: '/api/admin/facilities/edit',
  LIST_FACILITIES: '/api/admin/facilities/list',
  LIST_FACILITIES_ENABLE: '/api/admin/maps/facilities',
  DELETE_FACILITIES: '/api/admin/facilities/delete',
  HIDDEN_FACILITIES: '/api/admin/facilities/hide',
  DETAIL_FACILITIES: '/api/admin/facilities/detail',
  UPLOAD_ICON_CATEGORY: '/api/admin/icon/upload',
  DELETE_ICON_CATEGORY: '/api/admin/icon/delete',
  DELETE_MAP_INFO: '/api/admin/maps/delete',

  // send notification
  LIST_SEND_NOTIFY: '/api/admin/notify/list',
  CREATE_SEND_NOTIFY: '/api/admin/notify/create',
  DRAFT_SAVE: '/api/admin/notify/draft-save',
  DETAIL_SEND_NOTIFY: '/api/admin/notify/detail',
  EDIT_SEND_NOTIFY: '/api/admin/notify/edit',
  DELETE_NOTIFY: '/api/admin/notify/delete',

  // Statitics user
  FILTER_USER: '/api/admin/statistics/users',
};
