/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  useMediaQuery,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Button,
  TextField,
  Link,
  Snackbar,
  IconButton,
  Tooltip,
  FormControlLabel,
  Checkbox,
  Typography,
  OutlinedInput,
  InputAdornment,
  Alert,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import store, { sessionActions, setMessage, setPermission } from '../store';
import { useLocalization, useTranslation } from '../common/components/LocalizationProvider';
import LoginLayout from './LoginLayout';
import usePersistedState from '../common/util/usePersistedState';
import { handleLoginTokenListeners, nativeEnvironment, nativePostMessage } from '../common/components/NativeInterface';
import LogoImage from '../resources/images/playStoreIcon.png';
import { useCatch } from '../reactHelper';
import { decrypted, encrypted } from '../payment/components/utils';
import baseRequest from '../apis/baseRequest';
import uris from '../apis/uris';
import { selectMessageError } from '../store/messageError';

const useStyles = makeStyles((theme) => ({
  options: {
    position: 'fixed',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    // gap: theme.spacing(1),
    border: '1px solid black',
    padding: '30px',
  },
  extraContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    gap: theme.spacing(2),
  },
  registerButton: {
    minWidth: 'unset',
    color: '#0070c0',
    textDecoration: 'underline',
    fontSize: '16px',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  resetPassword: {
    cursor: 'pointer',
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },
  fieldGap: {
    paddingTop: '20px',
  },
}));

const LoginPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const t = useTranslation();

  const { languages, language, setLanguage } = useLocalization();
  const languageList = Object.entries(languages).map((values) => ({ code: values[0], name: values[1].name }));

  const [failed, setFailed] = useState(false);

  const [password, setPassword] = useState(decrypted(localStorage.getItem('password')));
  const [rememberMe, setRememberMe] = useState(false);
  const [email, setEmail] = useState(localStorage.getItem('email') || '');
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const languageEnabled = useSelector((state) => !state.session.server.attributes['ui.disableLoginLanguage']);

  const [announcementShown, setAnnouncementShown] = useState(false);
  const announcement = useSelector((state) => state.session.server.announcement);
  const errorMessage = useSelector(selectMessageError);

  const generateLoginToken = async () => {
    if (nativeEnvironment) {
      let token = '';
      try {
        const expiration = moment().add(6, 'months').toISOString();
        const response = await fetch('/api/session/token', {
          method: 'POST',
          body: new URLSearchParams(`expiration=${expiration}`),
        });
        if (response.ok) {
          token = await response.text();
        }
      } catch (error) {
        token = '';
      }
      nativePostMessage(`login|${token}`);
    }
  };

  const handlePasswordLogin = async (event) => {
    const encryptedPasword = encrypted(password);
    event.preventDefault();
    try {
      const response = await fetch('/api/session', {
        method: 'POST',
        body: new URLSearchParams(`email=${encodeURIComponent(email)}&password=${encodeURIComponent(encryptedPasword)}`),
      });
      if (response.ok) {
        const user = await response.json();
        if (user.administrator === true) {
          generateLoginToken();
          dispatch(sessionActions.updateUser(user));
          await localStorage.setItem('email', email);
          await localStorage.setItem('password', encrypted(password));
          // if (!rememberMe === true) {
          //   localStorage.setItem('email', email);
          //   localStorage.setItem('password', encrypted(password));
          // } else {
          //   localStorage.removeItem('email');
          //   localStorage.removeItem('password');
          // }
          await baseRequest.get(`${uris.GET_PERMISSION_BY_USER}/${user.id}`).then((response) => {
            window.localStorage.setItem('permissions', encrypted(JSON.stringify(response?.data || [])));
          });
          navigate('/admin');
        } else {
          throw Error(await response.text());
        }
      } else {
        store.dispatch(setMessage(await response?.text() || ''));
        throw Error(await response.text());
      }
    } catch (error) {
      setFailed(true);
      setPassword('');
    }
  };

  const handleTokenLogin = useCatch(async (token) => {
    const response = await fetch(`/api/session?token=${encodeURIComponent(token)}`);
    if (response.ok) {
      const user = await response.json();
      dispatch(sessionActions.updateUser(user));
      navigate('/');
    } else {
      throw Error(await response.text());
    }
  });

  const handleSpecialKey = (e) => {
    if (e.keyCode === 13 && email && password) {
      handlePasswordLogin(e);
    }
  };

  const handleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(setMessage(null));
  };

  useEffect(() => nativePostMessage('authentication'), []);

  useEffect(() => {
    const listener = (token) => handleTokenLogin(token);
    handleLoginTokenListeners.add(listener);
    return () => handleLoginTokenListeners.delete(listener);
  }, []);

  useEffect(() => {
    const savedEmail = localStorage.getItem('email');
    const savedPassword = decrypted(localStorage.getItem('password') || '');
    const savedRememberMe = localStorage.getItem('rememberMe');
    if (savedRememberMe === 'true' && savedEmail && savedPassword) {
      setEmail(savedEmail);
      setPassword(savedPassword);
      setRememberMe(true);
    }
  }, []);

  const remember = (isChecked) => {
    setRememberMe(isChecked);
    localStorage.setItem('email', email);
    localStorage.setItem('password', encrypted(password));
    if (isChecked) {
      localStorage.setItem('rememberMe', isChecked);
    } else {
      localStorage.removeItem('email');
      localStorage.removeItem('password');
      localStorage.removeItem('rememberMe');
    }
  };

  return (
    <LoginLayout>
      <Snackbar
        open={errorMessage}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error" variant="filled">
          {errorMessage}
        </Alert>
      </Snackbar>
      <div className={classes.options}>
        {nativeEnvironment && (
          <Tooltip title={t('settingsServer')}>
            <IconButton onClick={() => navigate('/change-server')}>
              <LockOpenIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
      <div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '5px' }}>
          <img src={LogoImage} alt="" height={40} />
          <div style={{ fontSize: '1.5rem', marginLeft: '1rem', fontWeight: 'bold' }}>ADMIN</div>
        </div>
        <div className={classes.container}>
          <Typography className={classes.fieldGap}>{t('loginId')}</Typography>
          <TextField
            required
            error={failed}
            name="email"
            value={email}
            autoComplete="email"
            autoFocus={!email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyUp={handleSpecialKey}
            helperText={failed && 'Invalid username or password'}
          />
          <Typography className={classes.fieldGap}>{t('userPassword')}</Typography>
          <FormControl fullWidth variant="outlined">
            <OutlinedInput
              required
              id="password"
              type={showConfirmPassword ? 'text' : 'password'}
              value={password}
              error={failed}
              autoFocus={!!email}
              autoComplete="current-password"
              onKeyUp={handleSpecialKey}
              endAdornment={(
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleShowConfirmPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
                   )}
              onChange={(e) => setPassword(e.target.value)}
            />
          </FormControl>
          <FormControlLabel
            control={(
              <Checkbox
                checked={rememberMe}
                onChange={(event) => remember(event.target.checked)}
                name="rememberMe"
                color="primary"
              />
        )}
            label={t('loginRemember')}
          />
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              onClick={handlePasswordLogin}
              onKeyUp={handleSpecialKey}
              variant="contained"
              style={{ backgroundColor: '#c00000', width: '100px' }}
              disabled={!email || !password}
            >
              {t('loginLogin')}
            </Button>
          </div>
        </div>
        <Button
          onClick={() => navigate('/reset-password')}
          className={classes.registerButton}
        >
          {t('loginReset')}
        </Button>
        {/* <div className={classes.extraContainer}>
          <Button
            className={classes.registerButton}
            onClick={() => navigate('/register')}
            disabled={!registrationEnabled}
            color="secondary"
          >
            {t('loginRegister')}
          </Button>
          {emailEnabled && (
          <Link
            onClick={() => navigate('/reset-password')}
            className={classes.resetPassword}
            underline="none"
            variant="caption"
          >
            {t('loginReset')}
          </Link>
          )}
          <Button
            onClick={() => navigate('/reset-password')}
            className={classes.registerButton}
            color="secondary"
          >
            {t('loginReset')}
          </Button>
        </div> */}
      </div>
      {languageEnabled && (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Typography>{t('loginlanguage')}</Typography>
        <FormControl sx={{ paddingLeft: '10px' }}>
          {/* <InputLabel>{t('loginLanguage')}</InputLabel> */}
          <Select
          // label={t('loginLanguage')}
            value={language}
            onChange={(e) => setLanguage(e.target.value)}
          >
            {languageList.map((it) => <MenuItem key={it.code} value={it.code}>{it.name}</MenuItem>)}
          </Select>
        </FormControl>
      </div>
      )}
      <Snackbar
        open={!!announcement && !announcementShown}
        message={announcement}
        action={(
          <IconButton size="small" color="inherit" onClick={() => setAnnouncementShown(true)}>
            <CloseIcon fontSize="small" />
          </IconButton>
        )}
      />
    </LoginLayout>
  );
};

export default LoginPage;
