import React, { useState } from 'react';
import {
  Button, Modal, Box, Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from '../../../common/components/LocalizationProvider';

const useStyles = makeStyles({
  closeBtn: {
    width: '120px',
    border: 'outset 5px grey',
    backgroundColor: 'grey',
    '&:hover': {
      backgroundColor: 'grey',
    },
  },
  submitBtn: {
    width: '120px',
    border: 'outset 5px red',
    backgroundColor: 'red',
    '&:hover': {
      backgroundColor: 'red',
    },
  },
  continueBtn: {
    width: '120px',
    border: 'outset 5px #4b7bd0',
    backgroundColor: '#4b7bd0',
    '&:hover': {
      backgroundColor: '#4b7bd0',
    },
  },
  modalTitle: {
    display: 'flex',
    justifyContent: 'center',
  },
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  height: 200,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const ComfirmationPopup = (props) => {
  const {
    btnTitle, submit, disabled, styleButton, btnIcon, confirmText, width, subConfirmText, btnColor, confirmContinue,
  } = props;
  const classes = useStyles();
  const t = useTranslation();

  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => setOpenModal(true);

  const handleClose = () => {
    setOpenModal(false);
  };
  return (
    <div>
      <Button
        sx={{
          maxWidth: width || '130px',
          display: 'flex',
          fontSize: '16px',
          backgroundColor: btnColor,
          border: `outset 5px ${btnColor}`,
          '&:hover': { backgroundColor: btnColor },
          '&:disabled': { backgroundColor: 'grey', border: 'outset 5px grey' },
          ...styleButton,
        }}
        variant="contained"
        onClick={handleOpen}
        disabled={disabled}
      >
        <span style={{ marginBottom: '5px' }}>{btnTitle}</span>
        {btnIcon && (
          <span style={{ margin: ' 0px 0px 5px 10px' }}>
            {btnIcon}
          </span>
        )}
      </Button>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <Typography className={classes.modalTitle}>{confirmText}</Typography>
            <br />
            <Typography className={classes.modalTitle}>{subConfirmText}</Typography>
            <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '30px' }}>
              <Button
                className={classes.closeBtn}
                variant="contained"
                sx={{ mt: 1, mb: 2 }}
                onClick={handleClose}
              >
                {t('sharedCancel')}
              </Button>
              <Button
                className={confirmContinue ? classes.continueBtn : classes.submitBtn}
                variant="contained"
                onClick={() => {
                  setOpenModal(false);
                  submit();
                }}
                sx={{ mt: 1, mb: 2 }}
              >
                {t('perform')}
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ComfirmationPopup;
