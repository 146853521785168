import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import {
  Alert, Button, Pagination, Snackbar, TablePagination,
} from '@mui/material';
import { AddBoxOutlined, Upload } from '@mui/icons-material';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ConstructionIcon from '@mui/icons-material/Construction';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import debounce from 'lodash/debounce';
import { utils } from 'xlsx';
import moment from 'moment';
// eslint-disable-next-line import/no-extraneous-dependencies
import { saveAs } from 'file-saver';
import Header from '../Header';
import MapView, { map } from '../../../../map/core/MapView';
import MapDefaultCamera from '../../../../map/main/MapDefaultCamera';
import MapCurrentLocation from '../../../../map/MapCurrentLocation';
import MapGeocoder from '../../../../map/geocoder/MapGeocoder';
import { useTranslation } from '../../../../common/components/LocalizationProvider';
import { getAdminAccountIds, getDeviceData } from '../../../common/utils';
import './mapInfo.css';
import { useEffectAsync } from '../../../../reactHelper';
import TableMapInfo from './TableMapInfo';
import hasPermission from '../../../common/hasPermission';
import canvasMapInfo, { cleanupMap } from '../../../../common/util/canvasMapInfo';
import { formatDateTime } from '../../../common/handleTimeZone';
import baseRequest from '../../../../apis/baseRequest';
import uris from '../../../../apis/uris';
import store, { setMessage } from '../../../../store';

const Index = () => {
  const navigate = useNavigate();
  const t = useTranslation();
  const tableRef = useRef();
  const [gridApi, getGridApi] = useState(null);
  const [dataSelect, setDataSelect] = useState([]);
  const checkedList = useSelector((state) => state.helper.checkedList);
  const dataChecked = getDeviceData(checkedList, dataSelect);
  const [data, setData] = useState([]);
  const [file, setFile] = useState({});
  const [showError, setShowError] = useState(false);
  const [dataMapInfo, setDataMapInfo] = useState([]);
  const [isLoadMapInfo, setIsLoadMapInfo] = useState(false);
  const id = getAdminAccountIds(checkedList, dataSelect);
  const inputFile = useRef(null);
  const dispatch = useDispatch();

  const handleSelectionChange = () => {
    setDataSelect(gridApi?.getSelectedRows() || []);
  };

  const handleRowDataUpdated = () => {
    gridApi?.forEachNode((node) => {
      if (checkedList.length !== 0) {
        node.setSelected(true);
      }
    });
  };

  const fetchListMarker = () => {
    baseRequest.get(uris.SHARE_LOCATION_LIST_MAP_INFO).then((response) => setDataMapInfo(response?.data));
  };

  const dataTable = {
    data,
    setData,
    getGridApi,
    tableRef,
    floatingFilter: true,
    showPagination: true,
    isLoadMapInfo,
    setIsLoadMapInfo,
    className: 'map-info-table',
    onSelectionChanged: handleSelectionChange,
    onRowDataUpdated: handleRowDataUpdated,
  };

  useEffectAsync(async () => {
    await canvasMapInfo(dataMapInfo);
    return () => {
      cleanupMap(dataMapInfo);
    };
  }, [dataMapInfo]);

  useEffect(() => {
    fetchListMarker();
    map.flyTo({
      offset: [0, 0],
      zoom: 1.5,
      essential: true,
    });
  }, []);
  const onBtnExport = () => {
    let dataExport;
    if (Array.isArray(dataChecked)) {
      dataExport = dataChecked.map((item) => {
        const newItem = { ...item };
        const newCategory = { ...item?.category, enable: item?.category?.enable ? t('commandEnable') : t('sharedHide') };

        newItem.open = item?.open ? t('open') : t('close');
        newItem.crowed = item?.crowed ? t('crowed') : t('notCrowed');
        newItem.category = newCategory;
        newItem.targets = item?.listTarget?.map((target) => target?.name);
        newItem.facilities = item?.listFacilities?.map((facility) => facility?.name);

        delete newItem?.category?.id;
        return {
          id: newItem.id,
          name: newItem.name,
          address: newItem.address,
          lat: newItem.lat,
          lon: newItem.lon,
          openStatus: newItem.open,
          crowedStatus: newItem.crowed,
          category: `${newItem.category.name} - ${newItem.category.iconColor} - ${newItem.category.enable} - ${newItem.category.pathIcon}`,
          targets: newItem.targets.join(', '),
          facilities: newItem.facilities.join(', '),
          note: newItem.note,
        };
      });
    }
    const headers = [t('id'), t('nameTableHeader'), t('positionAddress'), t('positionLatitude'), t('positionLongitude'),
      t('openStatus'), t('crowedStatus'), t('categoryTableHeader'), t('targetsMapInfo'), t('facilitiesMapInfo'), t('note')];
    const worksheet = utils.json_to_sheet([]);
    utils.sheet_add_aoa(worksheet, [headers], { origin: 'A1' });
    utils.sheet_add_json(worksheet, dataExport, { origin: 'A2', skipHeader: true });
    worksheet['!cols'] = [
      { wch: 10 },
      { wch: 30 },
      { wch: 10 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 60 },
      { wch: 60 },
      { wch: 60 },
    ];
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const csvOutput = utils.sheet_to_csv(worksheet, { header: true });
    const blob = new Blob([csvOutput], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, `ARINA_MapInfo_list_${moment().format('YYYY-MM-DD_HH-mm-ss')}.csv`);
  };
  const handleImportMapInfo = (data) => {
    baseRequest.call(uris.IMPORT_MAP_INFO, 'POST', data, null, true).then((response) => {
      if (response.status === 'NG') dispatch(setMessage(response.message));
      else { setShowError(true); setIsLoadMapInfo(true); }
    });
  };
  const isValid = () => {
    if (file?.name?.length > 0) {
      const maxSize = 5 * 1024 * 1024;
      if ((/\.(csv)$/i.test(file.name)) && (file.size < maxSize)) {
        handleImportMapInfo(file);
      } else {
        dispatch(setMessage('You need to select the csv file and have a capacity of less than 5MB.'));
      }
    }
  };

  useEffect(() => {
    isValid();
  }, [file]);
  const handleClearFile = () => {
    setFile(null);
    if (inputFile.current) {
      inputFile.current.value = ''; // Reset giá trị của input file
    }
  };
  const handleChange = (e) => {
    if (e.target.files.length > 0) {
      setFile(e.target.files[0]);
    }
  };
  const handleDeleteMapInfo = async () => {
    const data = {
      ids: id,
    };
    baseRequest.post(uris.DELETE_MAP_INFO, data).then((response) => {
      if (response?.status === 'NG') {
        store.dispatch(setMessage(response?.error || ''));
      } else navigate('/admin/finish/tools/mapInfo');
    });
  };
  return (
    <div>
      <Header link="/admin/tools" noDescription />
      <Snackbar
        open={showError}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setShowError(false)}
      >
        <Alert onClose={() => setShowError(false)} severity="success" variant="filled">
          {t('importSuccess')}
        </Alert>
      </Snackbar>
      <div className="map-container">
        <MapView>
          <MapDefaultCamera />
          <MapCurrentLocation />
          <MapGeocoder />
        </MapView>
      </div>
      {hasPermission('device.export') && (
        <Button
          className="map-info-btn"
          onClick={onBtnExport}
          disabled={!(checkedList.length > 0 || dataSelect.length > 0)}
        >
          <InsertDriveFileIcon className="icon-btn" />
          <div>CSV</div>
        </Button>
      )}
      <Button
        className="map-info-btn"
        onClick={() => {
          handleClearFile();
          inputFile.current.click();
        }}
      >
        <Upload className="icon-btn" />
        <div>{t('sharedImport')}</div>
        <input style={{ display: 'none' }} type="file" onChange={handleChange} ref={inputFile} accept=".csv" />
      </Button>

      <Button className="map-info-btn" onClick={() => navigate('add')}>
        <AddBoxOutlined className="icon-btn" />
        <div>{t('sharedAdd')}</div>
      </Button>

      <Button className="map-info-btn" disabled={!(checkedList.length > 0 || dataSelect.length > 0)} onClick={handleDeleteMapInfo}>
        <DeleteOutlineOutlinedIcon className="icon-btn" />
        <div>{t('sharedDelete')}</div>
      </Button>
      <Button onClick={() => navigate('settings')} className="map-info-btn" style={{ float: 'right' }}>
        <ConstructionIcon className="icon-btn" />
        <div>{t('settingsTitle')}</div>
      </Button>
      <TableMapInfo {...dataTable} />
    </div>
  );
};

export default Index;
