import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box, Button, FormControl, FormControlLabel, Radio, RadioGroup, Typography,
} from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import dayjs from 'dayjs';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

import utc from 'dayjs/plugin/utc';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import timezone from 'dayjs/plugin/timezone';

import Header from '../Header';
import ModalConfirm from '../../../components/modal/ModalConfirm';
import { useTranslation } from '../../../../common/components/LocalizationProvider';
import FormTextField from '../../../components/form/FormTextField';
import './sendnotify.css';
import NewTable from '../../../components/NewTable';
import { getUserData, getUserIds } from '../../../common/utils';
import store, { helperActions, setMessage } from '../../../../store';
import CustomDateTimePicker from './CustomDateTimePicker';
import LabelAdd from '../../../devices/components/LabelAdd';
import baseRequest from '../../../../apis/baseRequest';
import uris from '../../../../apis/uris';

dayjs.extend(utc);
dayjs.extend(timezone);

const useStyle = makeStyles((theme) => ({
  configTable: {
    height: '600px',
    width: '100%',
  },
  confirmButton: {
    display: 'flex',
    justifyContent: 'end',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'center',
    },
  },
}));
const UpsertNotify = () => {
  const {
    control, handleSubmit, setValue, setError, getValues, register, watch, clearErrors, formState: { isValid, isDirty, errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      scheduledSendTime: dayjs().add(1, 'hour'),
    },
  });

  const t = useTranslation();
  const classes = useStyle();
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [file, setFile] = useState(null);
  const [gridApi, getGridApi] = useState(null);
  const [dataSelect, setDataSelect] = useState([]);
  const [checked, setChecked] = useState(false);
  const checkedList = useSelector((state) => state.helper.checkedList);

  const inputFile = useRef(null);
  const [dataEdit, setDataEdit] = useState();
  const [statusTime, setStatusTime] = React.useState(1);
  const [statusFile, setStatusFile] = useState(0);
  const handleChangeRadioOption = (event) => {
    setStatusTime(parseInt(event.target.value, 10));
    if (event.target.value === '1') clearErrors('scheduledSendTime');
  };

  const userIds = getUserIds(checkedList, dataSelect);

  useEffect(() => {
    const data = getUserData(checkedList, dataSelect);
    dispatch(helperActions.selectedUser(data));
  }, [checkedList, dataSelect]);

  useEffect(() => {
    if (checked === false && dataSelect.length > 0) {
      setDataEdit((prevState) => ({
        ...prevState,
        userId: [],
      }));
      setDataSelect([]);
    }
  }, [checked]);
  const handleClose = () => {
    setOpenModal(false);
  };
  const handleBack = () => {
    setOpenModal(false);
    navigate('/admin/tools/sendNotifications');
  };
  const handleClearFile = () => {
    setFile(null);
    setStatusFile(2);
    if (inputFile.current) {
      inputFile.current.value = '';
    }
  };
  const handleCheckData = () => {
    if (isDirty) {
      setOpenModal(true);
    } else {
      handleBack();
    }
  };
  const handleUploadFile = (e) => {
    const file = e.target.files[0];
    if (file?.name?.length > 0) {
      if (file.size > 10000000) {
        dispatch(setMessage('You need to select file and have a capacity of less than 10MB.'));
      } else {
        setValue('file', file);
        setStatusFile(1);
        setFile(file);
      }
      inputFile.current.value = null;
    }
    console.log(file);
  };
  const onRowSelected = (event) => {
    if (event?.node?.selected === true && !userIds?.some((item) => item === event?.data?.id)) {
      setDataSelect((prevItems) => [...prevItems, event?.data]);
    } else if (event?.node?.selected === false) {
      setDataSelect((prevItems) => prevItems.filter((item) => item.id !== event?.data?.id));
    }
  };
  const handleRowDataUpdated = (params) => {
    gridApi?.forEachNode((node) => {
      if (checkedList.length !== 0) {
        node.setSelected(true);
      }
    });
    if (Array.isArray(dataSelect) && dataSelect.length > 0) {
      params.api.forEachNode((node) => {
        if (dataSelect?.some((item) => item?.id === node.data?.id)) {
          node.setSelected(true);
        }
      });
    } else {
      params.api.forEachNode((node) => {
        if (dataEdit?.userId?.some((item) => item === node.data?.id)) {
          node.setSelected(true);
        }
      });
    }
  };
  const dataTable = {
    listChecked: userIds,
    getGridApi,
    saveData: true,
    removeColumn: [1],
    showPagination: true,
    className: classes.configTable,
    onRowSelected,
    onRowDataUpdated: handleRowDataUpdated,

  };

  const onSubmit = (data, statusDraft) => {
    const formData = new FormData();
    if (statusTime === 0) {
      formData.append('scheduledSendTime', data?.scheduledSendTime?.utc().format('YYYY-MM-DD HH:mm'));
    }
    if (file && statusFile === 1) {
      formData.append('attachment', file.name && file);
      formData.append('fileName', file && file.name);
      formData.append('type', file && file.type);
    }
    if (id) {
      formData.append('id', id);
      if (statusFile === 2) {
        formData.append('cancelAttachment', 1);
      } else formData.append('cancelAttachment', 0);
    }
    formData.append('status', statusDraft);
    formData.append('sendNow', statusTime);
    formData.append('content', data.content && data.content);
    formData.append('title', data?.title ? data?.title : '');
    userIds.map((item) => formData.append('userIds', item));

    baseRequest.call(id ? uris.EDIT_SEND_NOTIFY : uris.CREATE_SEND_NOTIFY, 'POST', formData, null, true)
      .then((response) => {
        if (response?.status === 'NG') {
          store.dispatch(setMessage(response?.error || ''));
        } else {
          navigate('/admin/finish/tools/sendNotifications');
        }
      })
      .catch((error) => {
        store.dispatch(setMessage(error?.message || ''));
      });
  };

  const GetDataById = async () => {
    await baseRequest.post(uris.DETAIL_SEND_NOTIFY, { id }).then((response) => {
      if (response?.status === 'NG') {
        store.dispatch(setMessage(response?.error || ''));
      } else {
        setDataEdit(response?.data);
      }
    });
  };
  useEffect(() => {
    if (id) {
      GetDataById();
    }
  }, [id]);

  useEffect(() => {
    if (dataEdit) {
      setValue('title', dataEdit?.title);
      setValue('content', dataEdit?.content);
      setValue('scheduledSendTime', dayjs(dataEdit?.scheduledSendTime).tz(dayjs.tz.guess()));
      setStatusTime(dataEdit?.scheduledSendTime ? 0 : 1);
      setValue('file', dataEdit?.fileName);
      if (dataEdit?.fileName) setFile(dataEdit?.fileName);
      setDataSelect((prevState) => {
        const newIds = dataEdit?.userId?.filter((id) => !prevState.some((prev) => prev.id === id));
        return [...prevState, ...newIds.map((id) => ({ id }))];
      });
    }
  }, [dataEdit]);

  const draftSave = () => {
    const data = getValues();
    const formData = new FormData();
    if (statusTime === 0) {
      formData.append('scheduledSendTime', data?.scheduledSendTime ? data?.scheduledSendTime?.utc().format('YYYY-MM-DD HH:mm') : '');
    }
    if (file) {
      formData.append('attachment', file);
    }
    formData.append('fileName', file ? file.name : '');
    formData.append('type', file ? file.type : '');
    formData.append('status', 2);
    formData.append('sendNow', statusTime);
    formData.append('content', data.content ? data.content : '');
    formData.append('title', data?.title ? data?.title : '');
    if (userIds.length > 0) userIds.map((item) => formData.append('userIds', item));
    baseRequest.call(uris.DRAFT_SAVE, 'POST', formData, null, true)
      .then((response) => {
        if (response?.status === 'NG') {
          store.dispatch(setMessage(response?.error || ''));
        } else {
          navigate('/admin/finish/tools/sendNotifications');
        }
      })
      .catch((error) => {
        store.dispatch(setMessage(error?.message || ''));
      });
  };

  return (
    <div>
      <Header handleOnNavigate={handleCheckData}>
        <div style={{ margin: '10px 0' }}>
          <Typography style={{ fontSize: '1.5rem' }}>{t('linkListSendNotifications')}</Typography>
          <Typography>{t('notificationContent')}</Typography>
        </div>
      </Header>
      <ModalConfirm
        openModal={openModal}
        handleClose={handleClose}
        propsModal={
                    {
                      confirm: t('confirmMessage'),
                      msgWarning: t('messageWarning'),
                      action: handleBack,
                    }
                }
        textAcceptButton={t('accept')}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormTextField
          label={t('title')}
          controllerProps={{
            control,
            name: 'title',
            // rules: {
            //   required: t('FieldNotNull'),
            //   maxLength: { value: 15, message: t('Max15DAll') },
            // },
          }}
          autocomplete="off"
        />
        <FormTextField
          label={t('content')}
          controllerProps={{
            control,
            name: 'content',
            rules: {
              required: t('FieldNotNull'),
            },
          }}
          textFieldProps={{
            multiline: true,
            rows: 10,
          }}
          required
          autocomplete="off"
        />
        <FormControl>
          <div className="send-notify-box" style={{ alignItems: 'center' }}>
            <Typography mr={10}>{t('attachedFile')}</Typography>
            <Box sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }} onClick={() => inputFile.current.click()}>
              <Typography sx={{ textDecoration: 'underline' }}>
                {file ? (file.name || file) : t('attachedFile')}
              </Typography>
              <input
                {...register('file')}
                name="file"
                style={{ display: 'none' }}
                type="file"
                onChange={(e) => {
                  // setValue('file', e.target.file[0]);
                  handleUploadFile(e);
                }}
                ref={inputFile}
              />
            </Box>
            {id && (
            <Button sx={{ cursor: 'pointer', marginLeft: '10px', borderRadius: '10px', color: '#333' }} onClick={() => handleClearFile()}>
              <DeleteOutlineOutlinedIcon style={{ paddingRight: '5px', fontSize: '36px' }} />
            </Button>
            )}

          </div>
        </FormControl>
        <div className="send-notify-box">
          <div className="label-time">
            <LabelAdd label={t('timeOfSending')} required />
          </div>
          <div>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={statusTime}
                onChange={handleChangeRadioOption}
              >
                <FormControlLabel name="1" value={1} control={<Radio color="success" size="small" />} label={t('sendNow')} />
                <div style={{ display: 'flex' }}>
                  <FormControlLabel name="0" value={0} control={<Radio color="success" size="small" />} label={t('sendAfter')} />
                  {statusTime === 0 && (
                    <div>
                      <CustomDateTimePicker
                        controllerProps={{
                          control,
                          name: 'scheduledSendTime',
                        }}
                        clearErrors={clearErrors}
                        setError={setError}
                        autocomplete="off"
                      />
                    </div>
                  )}
                </div>
              </RadioGroup>
            </FormControl>
          </div>
        </div>
        <div className="label-time">
          <LabelAdd label={t('mailRecipient')} required />
        </div>
        <NewTable {...dataTable} checkedProps={(v) => setChecked(v)} />
        <div style={{ flexDirection: 'row-reverse' }} className="list-btn-network">
          <Button
            onClick={handleSubmit((data) => onSubmit(data, 0))}
            className="send-notify-btn"
            sx={{ width: '110px !important' }}
            type="submit"
            disabled={id ?
              dataSelect.length <= 0 || !(Object.keys(errors).length === 0 && errors.constructor === Object) :
              dataSelect.length <= 0 || !isValid}
          >
            <div>{t('commandSend')}</div>
          </Button>

          <Button
            onClick={() => (!id ? draftSave(watch()) : onSubmit(watch(), 2))}
            className="network-setting-btn"
            sx={{ width: '200px !important' }}
          >
            <div>{t('saveDraft')}</div>
          </Button>
          <Button
            onClick={() => handleCheckData()}
            className="network-setting-btn"
            sx={{ width: '110px !important' }}
          >
            <div>{t('sharedCancel')}</div>
          </Button>
        </div>
      </form>
    </div>
  );
};

export default UpsertNotify;
