import React from 'react';
import {
  Box, Checkbox, FormControlLabel, Grid,
} from '@mui/material';
import { useTranslation } from '../../../../../common/components/LocalizationProvider';
import masterDataPermission from '../../../../../config-permission/masterDataPermission';
import { formatString } from '../../../../common/utils';

const findParentByRole = (items, role) => {
  let result = null;
  items?.some((item) => {
    if (item?.role === role) {
      result = item?.role;
      return true; // Dừng lặp khi tìm thấy
    }
    if (item?.children) {
      result = findParentByRole(item.children, role);
      return result !== null; // Dừng lặp khi tìm thấy
    }
    return false;
  });
  return result;
};

// Component chính
const RenderScreens = ({ title, childRole, handleCheckedCheckBox, permisstion }) => {
  const t = useTranslation();
  return (
    <Box mb={5} className="container-form">
      <div className="title-form">{title}</div>
      <Grid container spacing={1} ml={6} mt={3}>
        <Grid textTransform="capitalize" item xs={1} mt={1}>{t('page')}</Grid>
        <Grid item xs={11}>
          <Grid container>
            {masterDataPermission.map((item, index) => (
              <Grid item xs={3}>
                <FormControlLabel
                  key={item.role}
                  style={{ textTransform: 'capitalize' }}
                  control={(
                    <Checkbox
                      checked={permisstion.includes(item.role)}
                      onChange={(_, status) => handleCheckedCheckBox(status, item.role)}
                      color="success"
                    />
              )}
                  label={t(item?.label)}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      {childRole?.map((item) => (
        <Box>
          {item?.children?.some((itemCheck) => itemCheck.type === 'screen') && (
            <Grid container spacing={1} ml={6} mt={3}>
              <Grid textTransform="capitalize" item xs={1} mt={1}>{t(item.label)}</Grid>
              <Grid item xs={11}>
                <Grid container>
                  {item?.children?.map((item2) => item2.type === 'screen' && (
                  <Grid item xs={3}>
                    <FormControlLabel
                      key={item2.role}
                      style={{ textTransform: 'capitalize' }}
                      control={(
                        <Checkbox
                          checked={permisstion.includes(item2.role)}
                          onChange={(_, status) => handleCheckedCheckBox(status, item2.role)}
                          color="success"
                        />
              )}
                      label={t(item2.label)}
                    />
                  </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          )}
        </Box>
      ))}
    </Box>
  );
};

export default RenderScreens;
