import React, { useEffect, useState } from 'react';
import {
  Button,
  FormControl,
  Modal,
  Box,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useForm } from 'react-hook-form';
import HeaderDevice from '../tools/components/HeaderDevice';
import configColorLayout from '../../settings/ConfigColorLayout';
import { useTranslation } from '../../common/components/LocalizationProvider';
import FormTextField from '../components/form/FormTextField';
import FormAutoComplete from '../components/form/FormAutoComplete';
import baseRequest from '../../apis/baseRequest';
import '../styles/pages/UpsertDevice.css';
import uris from '../../apis/uris';
import store, { setMessage } from '../../store';
import ModalConfirm from '../components/modal/ModalConfirm';

const AddDevice = () => {
  const { control, handleSubmit, watch, setFocus, formState: { isValid } } = useForm({
    mode: 'onChange',
  });
  const [openModal, setOpenModal] = useState(false);
  const [managerUsers, setManagerUsers] = useState();
  const navigate = useNavigate();
  const t = useTranslation();

  const handleAddDevice = (data) => {
    data.id = '';
    data.userId = data.userId.id;
    baseRequest.post(uris.DEVICES_UPSERT, data).then((response) => {
      if (response?.status === 'NG') {
        store.dispatch(setMessage(response?.error || ''));
      } else navigate('/admin/finish/device');
    });
  };

  const fetchManagerUsers = async () => {
    setManagerUsers(await baseRequest.get(uris.REPRESENTATION_MEMBERS));
  };
  const handleClose = () => {
    setOpenModal(false);
  };
  const handleBack = () => {
    setOpenModal(false);
    navigate('/admin/devices');
  };

  const handleCheckData = () => {
    const hasValue = Object.values(watch()).some((value) => value !== '' && value);
    if (hasValue) {
      setOpenModal(true);
    } else {
      navigate('/admin/devices');
    }
  };
  useEffect(() => {
    fetchManagerUsers();
  }, []);
  return (
    <div>
      <div style={{ borderBottom: 'none' }} className="upsert-device-header">
        <FormControl>
          <HeaderDevice handleOnNavigate={handleCheckData} />
        </FormControl>
      </div>
      <ModalConfirm
        openModal={openModal}
        handleClose={handleClose}
        propsModal={
          {
            confirm: t('confirmMessage'),
            msgWarning: t('messageWarning'),
            action: handleBack,
          }
        }
      />
      <form onSubmit={handleSubmit(handleAddDevice)} className="upsert-device-main-form">
        <FormTextField
          label="DeviceID/IMEI:"
          controllerProps={{
            control,
            name: 'deviceUid',
            rules: { required: t('FieldNotNull'), maxLength: { value: 50, message: t('Max50D') } },
          }}
          required
        />
        <FormTextField
          label="Device Name:"
          controllerProps={{
            control,
            name: 'name',
            rules: { required: t('FieldNotNull'), maxLength: { value: 30, message: t('Max30D') } },
          }}
          required
        />
        {managerUsers && (
          <FormAutoComplete
            label="Manager User:"
            controllerProps={{
              control,
              name: 'userId',
              rules: { required: t('FieldNotNull') },
            }}
            autoCompleteProps={{
              getOptionLabel: (option) => `${option?.name} | ${option?.email}`,
              options: managerUsers,
            }}
            required
          />
        )}
        <div className="upsert-device-add-item">
          <Button
            disabled={!isValid}
            type="submit"
            variant="contained"
            style={{ backgroundColor: configColorLayout, width: '150px' }}
          >
            Add Device
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddDevice;
