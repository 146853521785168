import React, { useEffect, useState } from 'react';
import maplibregl from 'maplibre-gl';
import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Typography,
} from '@mui/material';
import { useFieldArray, useForm } from 'react-hook-form';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Header from '../Header';
import MapViewInfo from './MapViewInfo';
import { useTranslation } from '../../../../common/components/LocalizationProvider';
import FormTextField from '../../../components/form/FormTextField';
import './mapInfo.css';
import FormAutoComplete from '../../../components/form/FormAutoComplete';
import { openStatus, crowedStatus } from './masterDataMapInfo';
import LabelAdd from '../../../devices/components/LabelAdd';
import baseRequest from '../../../../apis/baseRequest';
import uris from '../../../../apis/uris';
import store, { setMessage } from '../../../../store';
import ModalConfirm from '../../../components/modal/ModalConfirm';

const CreateMapInfo = () => {
  const t = useTranslation();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const { control, watch, setValue, handleSubmit, formState: { isValid, isDirty } } = useForm({ mode: 'onChange' });
  const targetFieldArray = useFieldArray({ control, name: 'targets' });
  const facilitiesFieldArray = useFieldArray({ control, name: 'facilities' });
  const [defaultValueEdit, setDefaultValueEdit] = useState({});
  const [listTarget, setListTarget] = useState([]);
  const [listFacility, setListFacility] = useState([]);
  const [listCategory, setListCategory] = useState([]);
  const [cancelImages, setCancelImages] = useState([]);
  const [loadingEdit, setLoadingEdit] = useState(!id);

  const handleCoords = (value) => {
    if (value) {
      setValue('lat', value.lat);
      setValue('lon', value.lng);
      if (value?.address) {
        setValue('address', value.address);
      }
    }
  };
  const handleTarget = (fieldArray, checked, value) => {
    if (checked) {
      fieldArray.append(value);
    } else {
      fieldArray.remove(value);
    }
  };
  const fetchTargets = () => {
    baseRequest.get(uris.LIST_TARGET_ENABLE).then((response) => {
      setListTarget(response?.data);
    });
  };
  const fetchFacilities = () => {
    baseRequest.get(uris.LIST_FACILITIES_ENABLE).then((response) => {
      setListFacility(response?.data);
    });
  };
  const fetchCategories = () => {
    baseRequest.get(uris.LIST_CATEGORY_ENABLE).then((response) => {
      setListCategory(response?.data);
    });
  };
  const onHandleUploadFiles = (e) => {
    const files = Array.from(e.target.files);
    if (Array.isArray(files)) {
      files.forEach((item) => {
        if (!item.type.startsWith('image/') || item?.size > 10000000) {
          dispatch(setMessage('Please choose image files under 10MB.'));
        }
      });
      const newImages = [
        ...files,
        ...(Array.isArray(watch('images')) ? watch('images') : [watch('images')]),
      ].filter((item) => item !== undefined && item !== null);
      setValue('images', newImages, { shouldDirty: true });
    }
  };

  const onSubmit = (data) => {
    const formData = new FormData();
    if (id) {
      formData.append('id', id);
    }
    formData.append('open', data.open.value);
    formData.append('crowed', data.crowed.value);
    data?.targets?.map((item) => formData.append('targets', item?.id));
    data?.facilities?.map((item) => formData.append('facilities', item?.id));
    formData.append('categoryId', data?.categoryId?.id);
    data?.images?.forEach((file) => {
      if (!file?.id) {
        formData.append('images', file);
      }
    });
    cancelImages?.forEach((value) => {
      formData.append('cancelImages', value);
    });
    if (data?.note) {
      formData.append('note', data.note);
    }
    if (data?.volume) {
      formData.append('volume', data.volume);
    }
    formData.append('name', data?.name);
    formData.append('address', data?.address);
    formData.append('lat', data?.lat);
    formData.append('lon', data?.lon);
    const endPoint = id ? uris.EDIT_MAP_INFO : uris.CREATE_MAP_INFO;
    baseRequest.call(endPoint, 'POST', formData, null, true).then((response) => {
      if (response?.status === 'NG') {
        store.dispatch(setMessage(response?.error || ''));
      } else navigate('/admin/finish/tools/mapInfo');
    });
  };
  const fetchDataById = () => {
    setLoadingEdit(false);
    baseRequest.post(uris.GET_MAP_INFO_BY_ID, { mapId: id }).then(async (response) => {
      if (!Number.isNaN(id)) {
        setValue('id', parseInt(id, 10));
      }
      const result = {
        ...response?.data,
        crowed: response?.data?.crowed ? crowedStatus[0] : crowedStatus[1],
        open: response?.data?.open ? openStatus[0] : openStatus[1],
        categoryId: listCategory?.find((item) => item.id === response?.data?.category),
      };
      setDefaultValueEdit(result);
      setLoadingEdit(true);
      setValue('name', result?.name);
      setValue('address', result?.address);
      setValue('volume', result?.volume);
      setValue('lat', result?.lat);
      setValue('lon', result?.lon);
      setValue('categoryId', result.category);
      setValue('open', result.open);
      setValue('crowed', result.crowed);
      setValue('note', result?.note);
      setValue('images', result?.images);
      setValue('facilities', result?.listFacilities);
      setValue('targets', result?.listTarget);
    });
  };

  const handleCheckData = () => {
    if (isDirty || watch('lat')) {
      if (defaultValueEdit?.lat !== watch('lat') || isDirty) {
        setOpenModal(true);
      } else navigate('/admin/tools/mapInfo');
    } else {
      navigate('/admin/tools/mapInfo');
    }
  };

  const handleClose = () => {
    setOpenModal(false);
  };
  const handleBack = () => {
    setOpenModal(false);
    navigate('/admin/tools/mapInfo');
  };

  const handleDeleteImage = (index, item) => {
    if (item?.id) {
      setCancelImages((prevState) => [...prevState, item?.id]);
    }
    const images = watch('images');
    images.splice(index, 1);
    setValue('images', images);
  };

  const clearImage = () => {
    setValue('images', null);
    if (defaultValueEdit?.images?.length > 0) {
      setCancelImages(defaultValueEdit?.images?.map((item) => item?.id));
    }
  };

  useEffect(() => {
    fetchTargets();
    fetchFacilities();
    fetchCategories();
    if (id) {
      fetchDataById();
    } else {
      setDefaultValueEdit({});
    }
  }, []);

  return (
    <div>
      <Header link={-1} noDescription handleOnNavigate={handleCheckData} />
      <ModalConfirm
        openModal={openModal}
        handleClose={handleClose}
        propsModal={
            {
              confirm: t('confirmMessage'),
              msgWarning: t('messageWarning'),
              action: handleBack,
            }
          }
      />
      <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: 2 }}>{t('MapInfo')}</Typography>
      <div className="map-container">
        {loadingEdit && (
        <MapViewInfo
          maplibregl={maplibregl}
          passCoords={(e) => handleCoords(e)}
          lngLat={{ lat: defaultValueEdit?.lat, lng: defaultValueEdit?.lon }}
        />
        )}
      </div>
      <Typography variant="h5" sx={{ fontWeight: 'bold', margin: '20px 0 20px 0' }}>{t('registerInfo')}</Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="map-flex">
          <FormTextField
            textFieldProps={{ disabled: true, variant: 'filled' }}
            label={t('positionLatitude')}
            controllerProps={{
              control,
              name: 'lat',
              rules: { required: t('FieldNotNull') },
            }}
            required
          />
          <FormTextField
            textFieldProps={{ disabled: true, variant: 'filled' }}
            label={t('positionLongitude')}
            controllerProps={{
              control,
              name: 'lon',
              rules: { required: t('FieldNotNull') },
            }}
            required
          />
        </div>
        <div className="map-flex">
          <FormTextField
            label={t('nameTableHeader')}
            controllerProps={{
              control,
              name: 'name',
              rules: { required: t('FieldNotNull'), maxLength: { value: 30, message: t('Max30DAll') } },
            }}
            required
          />
          <FormTextField
            label={t('positionAddress')}
            controllerProps={{
              control,
              name: 'address',
              rules: { required: t('FieldNotNull'), maxLength: { value: 255, message: t('Max255D') } },
            }}
            required
          />
          <FormTextField
            label={t('volume')}
            controllerProps={{
              control,
              name: 'volume',
              rules: { required: false, maxLength: { value: 30, message: t('Max30DAll') } },
            }}
            required={false}
          />
        </div>
        <div className="map-flex">
          {loadingEdit && (
            <FormAutoComplete
              label={t('deviceCategory')}
              controllerProps={{
                control,
                name: 'categoryId',
                rules: { required: t('FieldNotNull') },
              }}
              autoCompleteProps={{
                getOptionLabel: (option) => option?.name,
                renderOption: (props, option) => (
                  <li {...props}>
                    <div className="map-category">
                      <img
                        style={{ filter: option?.iconColor }}
                        className="map-category-img"
                        src={option?.pathIcon}
                        alt={option?.name}
                        width={20}
                        height={20}
                      />
                      {option?.name}
                    </div>
                  </li>
                ),
                options: listCategory,
              }}
              required
            />
          )}
          {loadingEdit && (
            <FormAutoComplete
              label={t('openStatus')}
              controllerProps={{
                control,
                name: 'open',
                rules: { required: t('FieldNotNull') },
              }}
              autoCompleteProps={{
                getOptionLabel: (option) => option?.name,
                options: openStatus,
              }}
              required
            />
          )}
          {loadingEdit && (
            <FormAutoComplete
              label={t('crowedStatus')}
              controllerProps={{
                control,
                name: 'crowed',
                rules: { required: t('FieldNotNull') },
              }}
              autoCompleteProps={{
                getOptionLabel: (option) => option?.name,
                options: crowedStatus,
              }}
              required
            />
          )}
        </div>
        <FormTextField
          label={t('note')}
          required={false}
          controllerProps={{
            control,
            name: 'note',
          }}
        />
        <div className="form-text-field" style={{ flexWrap: 'wrap' }}>
          <LabelAdd label={t('targetMapInfo')} required={false} />

          {loadingEdit && listTarget?.map((item) => (
            <FormControlLabel
              key={item?.id}
              control={(
                <Checkbox
                  defaultChecked={id ? defaultValueEdit?.listTarget?.some((value) => value?.id === item?.id) : false}
                  onChange={(_, checked) => handleTarget(targetFieldArray, checked, item)}
                />
)}
              label={item.name}
              value={item.id}
            />
          ))}
        </div>
        <div className="form-text-field" style={{ flexWrap: 'wrap' }}>
          <LabelAdd label={t('facilitiesMapInfo')} required={false} />
          {loadingEdit && listFacility?.map((item) => (
            <FormControlLabel
              key={item?.id}
              control={(
                <Checkbox
                  defaultChecked={id ? defaultValueEdit?.listFacilities?.some((value) => value?.id === item?.id) : false}
                  onChange={(_, checked) => handleTarget(facilitiesFieldArray, checked, item)}
                />
                  )}
              label={item.name}
              value={item.id}
            />
          ))}
        </div>
        <div style={{ alignItems: watch('images') ? 'center' : 'normal' }} className="map-container-flex">
          <LabelAdd label={t('positionImage')} required={false} />
          <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <Button
              sx={{ margin: 'auto 0' }}
              className="map-info-btn-submit"
              component="label"
              variant="contained"
              tabIndex={-1}
              startIcon={<CloudUploadIcon />}
            >
              Upload file
              <input onChange={(v) => onHandleUploadFiles(v)} className="map-info-input-file" type="file" multiple accept="image/*" />
            </Button>
            {watch('images')?.length > 0 && (
            <Button variant="contained" color="error" onClick={() => clearImage()}>
              <DeleteOutlineOutlinedIcon />
              <div>{t('reportClear')}</div>
            </Button>
            )}
          </div>
          {watch('images')?.length > 0 && (
            <ImageList
              sx={{
                marginLeft: 5,
                height: 120,
                transform: 'translateZ(0)',
              }}
              variant="masonry"
              cols={8}
              rowHeight="auto"
            >
              {watch('images')?.map((item, index) => (
                <ImageListItem>
                  <img
                    style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                    src={typeof item?.url === 'string' ? item?.url : (item instanceof File ? URL.createObjectURL(item) : '')}
                    alt={item}
                    loading="lazy"
                  />
                  <ImageListItemBar
                    onClick={() => handleDeleteImage(index, item)}
                    sx={{
                      background:
                                'linear-gradient(to bottom, rgba(0,0,0,0.3) 0%, ' +
                                'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                    }}
                    position="top"
                    actionIcon={(
                      <IconButton
                        sx={{ color: 'white' }}
                      >
                        <DeleteOutlineOutlinedIcon />
                      </IconButton>
                          )}
                  />
                </ImageListItem>
              ))}
            </ImageList>
          )}
        </div>
        <Button
          sx={{ margin: 'auto 0', float: 'right' }}
          disabled={!isValid}
          onClick={handleSubmit(onSubmit)}
          className="map-info-btn-submit"
          variant="contained"
        >
          {id ? t('sharedSave') : t('shareAddNew')}
        </Button>
      </form>
    </div>
  );
};

export default CreateMapInfo;
