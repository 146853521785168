import { createSlice } from '@reduxjs/toolkit';

const permissionSlice = createSlice({
  name: 'permission',
  initialState: {
    permission: null,
  },
  reducers: {
    setPermission(state, action) {
      state.permission = action.payload;
    },
    clearPermission(state) {
      state.permission = null;
    },
  },
});

export const { setPermission, clearPermission } = permissionSlice.actions;
export const selectPermission = (state) => state.permission.permission;
export default permissionSlice.reducer;
