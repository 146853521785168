/* eslint-disable react/no-unstable-nested-components */
import React, {
  useCallback,
  useEffect, useMemo, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Pagination, TablePagination } from '@mui/material';
import debounce from 'lodash/debounce';
import { helperActions } from '../../../../store';
import { useTranslation } from '../../../../common/components/LocalizationProvider';
import { convertWidth } from '../../../common/utils';
import Table from '../../../components/Table';
import CheckBoxAll from '../../../components/CheckBoxAll';
import FloatingFilterComponent from '../../../components/FloatingFilterComponent';
import SelectFilter from './SelectFilter';
import baseRequest from '../../../../apis/baseRequest';
import uris from '../../../../apis/uris';
import masterDataMapInfo from './masterDataMapInfo';
import { useEffectAsync } from '../../../../reactHelper';

const TableMapInfo = (props) => {
  const {
    data,
    setData,
    removeAllColumn,
    removeColumn,
    listData,
    editable,
    editGroup,
    changePasswordById,
    changeGroupById,
    newCustomeColumn,
    className,
    saveData,
    isLoadMapInfo,
    setIsLoadMapInfo,
    editStatus,
    showPagination,
    getElementRef,
    setGridApi,
    listChecked,
    ...others
  } = props;

  const mainRef = useRef();
  const dispatch = useDispatch();
  const t = useTranslation();
  const navigate = useNavigate();

  const filterData = useSelector((state) => state.helper.adminData.filter);
  const direct = useSelector((state) => state.helper.adminData.orderBy);
  const [listNode, setListNode] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(50);
  const [checked, setChecked] = useState(false);

  const count = Math.ceil(totalPages / limit);

  const detailButton = (data) => (
    <Link
      underline="always"
      style={{ color: '#1e70c4' }}
      to={`/admin/tools/mapInfo/edit/${data.id}`}
    >
      Edit
    </Link>
  );

  const columnDefs = [
    {
      width: convertWidth(4),
      field: 'isChecked',
      filter: false,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      headerComponent: () => <CheckBoxAll setCheck={setChecked} />,
    },
    {
      headerName: 'Edit',
      cellStyle: { textAlign: 'center' },
      width: convertWidth(5),
      cellRenderer: (params) => detailButton(params.data),
      field: 'id',
      filter: false,
    },
    {
      headerName: t('name'),
      width: convertWidth(18),
      field: 'name',
      floatingFilterComponent: FloatingFilterComponent,
      type: 'string',
    },
    {
      headerName: t('address'),
      field: 'address',
      width: convertWidth(29),
      floatingFilterComponent: FloatingFilterComponent,
      type: 'string',
    },
    {
      headerName: t('latitude'),
      field: 'lat',
      width: convertWidth(18),
      floatingFilterComponent: FloatingFilterComponent,
      type: 'string',
    },
    {
      headerName: t('longitude'),
      field: 'lon',
      width: convertWidth(18),
      floatingFilterComponent: FloatingFilterComponent,
      type: 'string',
    },
    {
      headerName: t('categoryId'),
      field: 'categoryId',
      width: convertWidth(15),
      floatingFilterComponent: SelectFilter,
      type: 'select',
      cellRenderer: (params) => params?.data?.category?.name,
    },
  ];

  const fetchData = async (filter, limit, offset, direct, checked) => {
    const filterNew = filter?.map((item) => {
      if (item?.name === 'lat' || item?.name === 'lon') {
        return {
          ...item,
          value: parseFloat(item?.value),
        };
      }
      return item.value && item;
    });
    baseRequest.post(uris.LIST_MAP_INFO, {
      limit,
      offset,
      orderBy: direct.filter((data) => data.direct),
      filterBy: filterNew.filter((data) => data?.value),
    }).then((response) => {
      setTotalPages(response?.total);
      setData(response?.data);
      if (checked === true) {
        dispatch(helperActions.getCheckedList(response?.data));
      } else if (checked === null) {
        dispatch(helperActions.getCheckedList(response?.data));
      } else {
        dispatch(helperActions.getCheckedList([]));
      }
    });
  };

  // Configuration for columns
  const configColumn = () => {
    const copyColumn = [...columnDefs];
    if (editable) {
      editable.forEach((number) => (copyColumn[number].editable = true));
    }
    if (removeAllColumn) {
      return [];
    }
    if (removeColumn) {
      removeColumn.forEach((number) => (copyColumn[number] = 'remove'));
      const newCol = copyColumn.filter((cols) => cols !== 'remove');
      return newCol;
    }
    return newCustomeColumn || columnDefs;
  };

  useEffect(() => {
    listNode.forEach((node) => {
      if (listChecked?.includes(node.data.id)) {
        node.setSelected(true);
      }
    });
  }, [listNode, listChecked]);

  const dataTable = {
    className,
    setListNode,
    rowSelection: 'multiple',
    rowMultiSelectWithClick: true,
    customColumnDefs: configColumn(),
    listData: listData || data,
    ...others,
  };
  const handlePageChange = async (event, page) => {
    const offsetValue = page * limit - limit;
    setOffset(offsetValue);
    setCurrentPage(page);
    dispatch(helperActions.currentPage(page));
    setLimit(limit);
    setChecked(false);
    await fetchData(filterData, limit, offsetValue, direct);
  };

  const debounceFetchData = useCallback(
    debounce(async (filterData, limit, offset, direct, checked) => {
      await fetchData(filterData, limit, offset, direct, checked);
    }, 1250),
    [],
  );
  useEffect(() => {
    if (isLoadMapInfo) debounceFetchData(filterData, limit, offset, direct, false);
    setIsLoadMapInfo(false);
  }, [isLoadMapInfo]);
  useEffectAsync(async () => {
    if (checked === true) {
      await debounceFetchData(filterData, 0, 0, direct, true);
    } else if (checked === null) {
      await debounceFetchData(filterData, limit, offset, direct, null);
    } else {
      await debounceFetchData(filterData, limit, offset, direct, false);
    }
  }, [filterData, limit, offset, direct, checked, debounceFetchData]);

  return (
    <div ref={mainRef}>
      <Table {...dataTable} />
      {showPagination === true && (
      <div className="container-pagination">
        <Pagination
          className="pagination"
          size="small"
          count={count}
          onChange={handlePageChange}
          page={currentPage}
          showFirstButton
          showLastButton
        />
        <TablePagination
          size="small"
          component="div"
          count={totalPages}
          rowsPerPage={limit}
          page={currentPage - 1}
          onPageChange={() => {
          }}
          rowsPerPageOptions={[]}
          nextIconButtonProps={{ style: { display: 'none' } }}
          backIconButtonProps={{ style: { display: 'none' } }}
        />
      </div>
      )}
    </div>
  );
};

export default TableMapInfo;
