/* eslint-disable react/no-unstable-nested-components */
import React, {
  useCallback,
  useEffect, useMemo, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Box, Pagination, TablePagination } from '@mui/material';
import debounce from 'lodash/debounce';
import { helperActions } from '../../../../store';
import { useTranslation } from '../../../../common/components/LocalizationProvider';
import { convertWidth } from '../../../common/utils';
import Table from '../../../components/Table';
import DateRangePicker from '../../../components/DateRangePicker';
import CheckBoxAll from '../../../components/CheckBoxAll';
import FloatingFilterComponent from '../../../components/FloatingFilterComponent';
import baseRequest from '../../../../apis/baseRequest';
import uris from '../../../../apis/uris';
import { useEffectAsync } from '../../../../reactHelper';
import UseFilter from '../../../components/UseFilter';
import { formatDateUTCToLocal } from '../../../common/handleTimeZone';

const TableListSendNotify = (props) => {
  const {
    removeAllColumn,
    removeColumn,
    listData,
    editable,
    editGroup,
    changePasswordById,
    changeGroupById,
    newCustomeColumn,

    className,
    saveData,
    editStatus,
    showPagination,
    getElementRef,
    setGridApi,
    listChecked,
    ...others
  } = props;

  const mainRef = useRef();
  const dispatch = useDispatch();
  const t = useTranslation();
  const navigate = useNavigate();

  const filterData = useSelector((state) => state.helper.adminData.filter);
  const direct = useSelector((state) => state.helper.adminData.orderBy);
  const [listNode, setListNode] = useState([]);
  const [data, setData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(50);
  const [checked, setChecked] = useState(false);

  const count = Math.ceil(totalPages / limit);

  const detailButton = (data) => (
    <Link
      underline="always"
      style={{ color: data.status === 1 ? '#ccc' : '#1e70c4' }}
      to={`/admin/tools/sendNotifications/edit/${data.id}`}
      onClick={(e) => data.status === 1 && e.preventDefault()}
    >
      {t('sharedEdit')}
    </Link>

  );

  const confirmButton = (data) => <Link style={{ color: '#1e70c4' }} to={`/admin/tools/sendNotifications/confirm/${data.id}`}>{t('linkListConfirm')}</Link>;

  const columnDefs = [
    {
      width: convertWidth(4),
      field: 'isChecked',
      filter: false,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      headerComponent: () => <CheckBoxAll setCheck={setChecked} />,
    },
    {
      headerName: 'Edit',
      cellStyle: { textAlign: 'center' },
      width: convertWidth(5),
      //   cellRenderer: (params) => detailButton(params.data),
      field: 'id',
      filter: false,
    },
    {
      headerName: 'Confirm',
      cellStyle: { textAlign: 'center' },
      width: convertWidth(7),
      field: 'id',
      cellRenderer: (params) => confirmButton(params.data),
      filter: false,
    },
    {
      headerName: t('sharedEdit'),
      cellStyle: { textAlign: 'center' },
      width: convertWidth(5),
      cellRenderer: (params) => detailButton(params.data),
      field: 'id',
      filter: false,
    },
    {
      headerName: t('status'),
      field: 'status',
      width: convertWidth(12),
      floatingFilterComponent: null,
      cellRenderer: (params) => {
        if (params.data.status === 1) {
          return t('sent');
        } if (params.data.status === 0) return t('waiting');
        return t('draft');
      },
      filter: UseFilter,
      filterParams: {
        title: 'Status Notify',
        values: [
          { value: 0, category: 'notifyStatus' },
          { value: 1, category: 'notifyStatus' },
          { value: 2, category: 'notifyStatus' },

        ],
      },
    },
    {
      headerName: t('title'),
      field: 'title',
      width: convertWidth(22),
      floatingFilterComponent: FloatingFilterComponent,
      type: 'string',
    },
    {
      headerName: t('content'),
      field: 'content',
      width: convertWidth(30),
      floatingFilterComponent: FloatingFilterComponent,
      type: 'string',
    },
    {
      headerName: t('dateCreate'),
      field: 'createdAt',
      width: convertWidth(16),
      cellStyle: { textAlign: 'center' },
      cellRenderer: (params) => (params?.data?.createdAt ? formatDateUTCToLocal(params?.data?.createdAt, 'YYYY/MM/DD HH:mm') : 'No data'),
      floatingFilterComponent: DateRangePicker,
      type: 'date',
    },
    {
      headerName: t('dateSend'),
      field: 'scheduledSendTime',
      width: convertWidth(16),
      cellStyle: { textAlign: 'center' },
      cellRenderer: (params) => (params?.data?.scheduledSendTime ? formatDateUTCToLocal(params?.data?.scheduledSendTime, 'YYYY/MM/DD HH:mm') : 'No data'),
      floatingFilterComponent: DateRangePicker,
      type: 'date',
    },
  ];

  const fetchData = (filter, limit, offset, direct, checked) => {
    const adjustedFilterBy = filter.filter((data) => data.value !== undefined && data.value !== null);

    baseRequest.post(uris.LIST_SEND_NOTIFY, {
      limit,
      offset,
      orderBy: direct.filter((data) => data.direct),
      filterBy: adjustedFilterBy,
    }).then((response) => {
      setTotalPages(response?.total);
      setData(response?.data);
      if (checked === true) {
        dispatch(helperActions.getCheckedList(response?.data));
      } else if (checked === null) {
        dispatch(helperActions.getCheckedList(response?.data));
      } else {
        dispatch(helperActions.getCheckedList([]));
      }
    });
  };

  // Configuration for columns
  const configColumn = () => {
    const copyColumn = [...columnDefs];
    if (editable) {
      editable.forEach((number) => (copyColumn[number].editable = true));
    }
    if (removeAllColumn) {
      return [];
    }
    if (removeColumn) {
      removeColumn.forEach((number) => (copyColumn[number] = 'remove'));
      const newCol = copyColumn.filter((cols) => cols !== 'remove');
      return newCol;
    }
    return newCustomeColumn || columnDefs;
  };

  useEffect(() => {
    listNode.forEach((node) => {
      if (listChecked?.includes(node.data.id)) {
        node.setSelected(true);
      }
    });
  }, [listNode, listChecked]);

  const dataTable = {
    className,
    setListNode,
    rowSelection: 'multiple',
    rowMultiSelectWithClick: true,
    customColumnDefs: configColumn(),
    listData: listData || data,
    ...others,
  };
  const handlePageChange = async (event, page) => {
    const offsetValue = page * limit - limit;
    setOffset(offsetValue);
    setCurrentPage(page);
    dispatch(helperActions.currentPage(page));
    setLimit(limit);
    setChecked(false);
    await fetchData(filterData, limit, offsetValue, direct);
  };

  useEffect(() => {
    if (checked === true) {
      fetchData(filterData, 0, 0, direct, true);
    } else if (checked === null) {
      fetchData(filterData, limit, offset, direct, null);
    } else {
      fetchData(filterData, limit, offset, direct, false);
    }
  }, [filterData, direct, checked]);

  return (
    <div ref={mainRef}>
      <Table {...dataTable} />
      {showPagination === true && (
        <div className="container-pagination">
          <Pagination
            className="pagination"
            size="small"
            count={count}
            onChange={handlePageChange}
            page={currentPage}
            showFirstButton
            showLastButton
          />
          <TablePagination
            size="small"
            component="div"
            count={totalPages}
            rowsPerPage={limit}
            page={currentPage - 1}
            onPageChange={() => {
            }}
            rowsPerPageOptions={[]}
            nextIconButtonProps={{ style: { display: 'none' } }}
            backIconButtonProps={{ style: { display: 'none' } }}
          />
        </div>
      )}
    </div>
  );
};

export default TableListSendNotify;
