import React from 'react';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const NotFoundPage = () => (
  <Box
    sx={{
      gap: 10,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100vh',
    }}
  >
    <Typography variant="h1">
      403 - Forbidden
    </Typography>
    <Link to="/admin">
      redirect to admin page
    </Link>
  </Box>
);

export default NotFoundPage;
