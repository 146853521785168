import React, {
  forwardRef, useEffect, useState, useRef, useImperativeHandle,
} from 'react';
import { makeStyles } from '@mui/styles';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { helperActions } from '../../store';
import { convertTimestampLocalToEndDateUTC, convertTimestampLocalToStartDateUTC } from '../common/handleTimeZone';

const useStyles = makeStyles({
  datePicker: {
    borderColor: '#E0E0E0',
    width: '100%',
    border: '1px solid black',
    borderRadius: '5px',
    padding: '4px',
    fontWeight: 400,
    fontSize: '1rem',
  },
});

const CustomDateComponent = forwardRef((props, ref) => {
  const { field } = props.column.colDef;
  const classes = useStyles();
  const dispatch = useDispatch();

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const refDatePicker = useRef();
  const refInput = useRef();

  const adminData = useSelector((state) => state.helper.adminData);

  const handleFilterDate = (startDate, endDate) => {
    const start = convertTimestampLocalToStartDateUTC(startDate);
    const end = convertTimestampLocalToEndDateUTC(endDate || startDate);
    const copyData = { ...adminData };
    const filter = [...copyData.filter];
    const existingFilterIndex = filter.findIndex((filterField) => filterField.name === field);
    if (existingFilterIndex === -1) {
      filter.push({ name: field, type: '07', value: start, optionalValue: end, dataType: 'date' });
      copyData.filter = filter;
      dispatch(helperActions.setAdminData(copyData));
    } else {
      const copyFilter = [...filter];
      copyFilter[existingFilterIndex] = { ...filter[existingFilterIndex], value: start, optionalValue: end, dataType: 'date' };
      dispatch(helperActions.setAdminData({ ...copyData, filter: copyFilter }));
    }
  };

  const onDateChanged = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    handleFilterDate(start, end);
  };

  return (
    <div ref={refInput} className="ag-input-wrapper">
      <DatePicker
        ref={refDatePicker}
        portalId="root"
        popperClassName="ag-custom-component-popup"
        className={classes.datePicker}
        selected={startDate}
        onChange={onDateChanged}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        dateFormat="yyyy/MM/dd"
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
      />
    </div>
  );
});

export default CustomDateComponent;
