import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  FormControl, IconButton, InputAdornment, OutlinedInput, TextField, Typography, Box, Grid, Checkbox, FormControlLabel,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from '../../../../common/components/LocalizationProvider';
import ComfirmationPopup from '../ComfirmationPopup';
import fetchApi from '../../../FetchApi';
import { helperActions } from '../../../../store';
import Header from '../Header';
import { encrypted } from '../../../../payment/components/utils';

const useStyles = makeStyles((theme) => ({
  inforGrid: {
    border: '1px solid',
    backgroundColor: '#c5e0b4',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  checkboxGrid: {
    border: '1px solid',
    backgroundColor: '#e2f0d9',
    padding: '5px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto auto',
    maxWidth: '550px',
    minWidth: '200px',
  },
  disabledTextField: {
    '& .MuiInputBase-adornedEnd': {
      backgroundColor: '#bfbfbf',
    },
    '& .MuiInputBase-input.Mui-disabled ': {
      backgroundColor: '#bfbfbf',
    },
  },
}));

const ChangePasswordForm = () => {
  const navigate = useNavigate();
  const t = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const { userId } = useParams();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isValidLength, setIsValidLength] = useState(false);
  const [isValidPassword, setIsValidPassword] = useState(false);
  const [isMatches, setIsMatches] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const changePasswordUser = useSelector((state) => state.helper.changePasswordUser);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchApi(`/api/admin/members/${changePasswordUser.representationId || changePasswordUser.managedUserId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const objRecive = await response.json();
        const userData = objRecive.find((user) => user.id === Number(userId));
        setEmail(userData.email);
        // setPassword(userData.password);
      }
    };
    fetchData();
  }, []);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async () => {
    const encryptedPassword = encrypted(newPassword);
    const response = await fetchApi(`/api/admin/users/change-password-member/${userId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        password: encryptedPassword,
      }),
    });
    if (response.ok) {
      dispatch(helperActions.setPathname(location.pathname));
      navigate('/admin/finish/tools');
    } else {
      throw Error(await response.text());
    }
  };

  return (
    <>
      <Header link={-1}>
        <Typography>{t('changePassContent')}</Typography>
      </Header>
      <div className={classes.container}>
        <Box component="form" noValidate sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography style={{ marginBottom: '10px' }}>{t('changePasswordEmail')}</Typography>
              <TextField
                required
                disabled
                fullWidth
                name="email"
                value={email}
                sx={{
                  '& .MuiInputBase-input.Mui-disabled': {
                    backgroundColor: '#bfbfbf',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} className={classes.disabledTextField}>
              <Typography style={{ marginBottom: '10px' }}>{t('adminSettingsCurrent')}</Typography>
              <FormControl fullWidth variant="outlined">
                <OutlinedInput
                  required
                  disabled
                  id="current-password"
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  endAdornment={(
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                                    )}
                  onChange={(event) => {
                    setPassword(event.target.value);
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography style={{ marginBottom: '10px' }}>{t('changePasswordNew')}</Typography>
              <FormControl fullWidth variant="outlined">
                <OutlinedInput
                  required
                  id="new-password"
                  placeholder={t('passwordField')}
                  type={showNewPassword ? 'text' : 'password'}
                  value={newPassword}
                  endAdornment={(
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleShowNewPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showNewPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                                    )}
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                    if (e.target.value.length >= 8) {
                      setIsValidLength(true);
                    } else {
                      setIsValidLength(false);
                    }

                    if (
                      e.target.value.match(
                        /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*+=]).{3,}$/,
                      ) ||
                                            e.target.value.match(
                                              /^(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*+=]).{3,}$/,
                                            ) ||
                                            e.target.value.match(
                                              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*+=]).{3,}$/,
                                            ) ||
                                            e.target.value.match(
                                              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{3,}$/,
                                            )
                    ) {
                      setIsValidPassword(true);
                    } else {
                      setIsValidPassword(false);
                    }
                    if (e.target.value === confirmPassword) {
                      setIsMatches(true);
                    } else {
                      setIsMatches(false);
                    }
                  }}
                />
              </FormControl>
            </Grid>
            <div style={{ padding: '16px 0 0 16px' }}>
              <Grid container>
                <Grid item xs={2} className={classes.inforGrid}>
                  <InfoRoundedIcon />
                </Grid>
                <Grid item xs={10} className={classes.checkboxGrid}>
                  <Typography>{t('passwordConditions')}</Typography>
                  <Grid item>
                    <FormControlLabel
                      control={(
                        <Checkbox
                          id="validLength"
                          checked={isValidLength}
                          color="secondary"
                        />
                                            )}
                      label={t('passWordValidLength')}
                    />
                    <FormControlLabel
                      control={(
                        <Checkbox
                          id="validPassword"
                          checked={isValidPassword}
                          color="secondary"
                        />
                                            )}
                      label={t('passWordValidation')}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </div>
            <Grid item xs={12}>
              <Typography style={{ marginBottom: '10px' }}>{t('changePasswordConfirm')}</Typography>
              <FormControl fullWidth variant="outlined">
                <OutlinedInput
                  required
                  id="outlined-adornment-password"
                  placeholder={t('passwordField')}
                  type={showConfirmPassword ? 'text' : 'password'}
                  value={confirmPassword}
                  endAdornment={(
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleShowConfirmPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                                    )}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                    if (e.target.value === newPassword) {
                      setIsMatches(true);
                    } else {
                      setIsMatches(false);
                    }
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <div style={{ marginTop: '1rem', display: 'flex', justifyContent: 'flex-end' }}>
            <ComfirmationPopup
              btnTitle={t('perform')}
              submit={handleSubmit}
              btnColor="#ff0505"
              confirmText={t('confirmEdit1')}
              disabled={
                                //  !/(.+)@(.+)\.(.{2,})/.test(email) ||
                                !isValidPassword ||
                                !isValidLength ||
                                !isMatches
                            }
            />
          </div>
        </Box>
      </div>
    </>
  );
};

export default ChangePasswordForm;
