import React, { useEffect, useState } from 'react';
import { Autocomplete, Box, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import baseRequest from '../../../apis/baseRequest';
import uris from '../../../apis/uris';
import { setMessage } from '../../../store';

const StatiticUsers = () => {
  const t = useTranslation();
  const dispatch = useDispatch();
  const option = [
    {
      label: t('all'),
    },
    {
      label: t('normalUser'),
      category: 0,
      name: 'category',
    },
    {
      label: t('specialUser'),
      category: 1,
      name: 'category',
    },
    {
      label: t('representUser'),
      role: 1,
      name: 'role',
    },
    {
      label: t('memberUser'),
      role: 2,
      name: 'role',
    },
  ];
  const [value, setValue] = React.useState();

  const fetchTotalUser = (newValue) => {
    let data = { filterBy: [] };
    if (newValue?.name) {
      const valueToUse = newValue?.category !== undefined ? newValue?.category : newValue?.role;
      data = { filterBy: [{ name: newValue?.name, type: '05', value: valueToUse }] };
    }
    baseRequest.post(uris.FILTER_USER, data).then((response) => (response.status === 'NG' ? dispatch(setMessage(response?.message)) : setValue(response?.total)));
  };
  useEffect(() => { fetchTotalUser(value); }, []);
  return (
    <div>
      <Autocomplete
        onChange={(event, newValue) => {
          fetchTotalUser(newValue);
        }}
        defaultValue={option[0]}
        options={option}
        getOptionLabel={(option) => option.label}
        disableClearable
        sx={{ width: 400 }}
        renderInput={(params) => (
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <TextField
              {...params}
              sx={{
                '& .MuiInputBase-root': {
                  backgroundColor: '#F0C000',
                  color: '#fff',
                },
              }}
            />
            <Box sx={{ position: 'relative', left: '-80px', color: '#fff' }}>{value}</Box>
          </Box>
        )}
      />
    </div>
  );
};

export default StatiticUsers;
