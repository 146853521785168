import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import BreadcrumbsCustom from '../components/BreadcrumbsCustom';
import accountAdmin from '../../resources/images/admin.png';
import role from '../../resources/images/role.png';
import networkSetting from '../../resources/images/networkSetting.png';
import policySettings from '../../resources/images/policy.png';
import { useTranslation } from '../../common/components/LocalizationProvider';
import './components/settings.css';
import configColorLayout from '../../settings/ConfigColorLayout';
import hasPermission from '../common/hasPermission';
import { decrypted } from '../../payment/components/utils';

const Settings = () => {
  const t = useTranslation();
  const navigate = useNavigate();
  // eslint-disable-next-line no-undef
  const permissions = JSON.parse(decrypted(localStorage.getItem('permissions')));
  const listItemSetting = [
    {
      id: 1,
      icon: accountAdmin,
      name: t('adminAccount'),
      link: '/admin/settings/adminAccount',
      permission: 'settings.adminAccounts',
    },
    {
      id: 2,
      icon: role,
      name: t('roles'),
      link: '/admin/settings/roles',
      permission: 'settings.roles',
    },
    {
      id: 3,
      icon: networkSetting,
      name: t('networkSetting'),
      link: '/admin/settings/networkSetting',
      permission: 'settings.networkSetting',
    },
    {
      id: 4,
      icon: policySettings,
      name: t('policySetting'),
      link: '/admin/settings/policySetting',
      permission: 'settings.policySetting',
    },
  ];

  const handleClick = (link) => {
    navigate(link);
  };
  return (
    <>
      <BreadcrumbsCustom getAfter="admin" />
      <Grid mt={3} container>
        {listItemSetting?.map((item) => hasPermission(item?.permission) && (
          <Grid justifyContent="center" display="flex" key={item.id} item xs={3}>
            <Box
              key={item.permission}
              onClick={() => handleClick(item.link)}
              p={3}
              sx={{
                border: `3px solid ${configColorLayout}`,
                borderRadius: 3,
                width: '80%',
                cursor: hasPermission(item.permission) ? 'pointer' : 'default',
                opacity: hasPermission(item.permission) ? 1 : 0.5,
              }}
            >
              <img src={item.icon} alt="adminAccount" />
              <Typography variant="h6" fontWeight={600}>{item.name}</Typography>
            </Box>
          </Grid>
        ))}
      </Grid>

    </>
  );
};
export default Settings;
