import React from 'react';
import { FormControl, TextField } from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useController } from 'react-hook-form';
import LabelAdd from '../../devices/components/LabelAdd';
import '../../styles/components/FormTextField.css';

const FormTextField = ({ label, required, controllerProps, textFieldProps }) => {
  const { field, fieldState } = useController(controllerProps);
  const { error } = fieldState;
  return (
    <div className="form-text-field">
      {label &&
        <LabelAdd label={label} required={required} />}
      <FormControl className="form-control">
        <TextField
          {...textFieldProps}
          {...field}
          error={!!error}
          value={field.value || ''}
          helperText={error ? error.message : ''}
        />
      </FormControl>
    </div>
  );
};

export default FormTextField;
