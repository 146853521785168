// eslint-disable-next-line import/no-named-as-default
import React, { useState, useEffect } from 'react';
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { LinearProgress } from '@mui/material';
import LoginPage from './login/LoginPage';
import ResetPasswordPage from './login/ResetPasswordPage';
import useQuery from './common/util/useQuery';
import { useEffectAsync } from './reactHelper';
import { devicesActions } from './store';
import App from './App';
import ListTable from './admin/home/ListTable';
import EditPage from './admin/home/EditPage';
import DetailsTable from './admin/home/DetailsTable';
import Admin from './admin/Admin';
import Analys from './admin/analys/Analys';
import HistoryTable from './admin/home/HistoryTable';
import Finish from './admin/FinishPage';
import ResetPage from './admin/tools/components/reset/ResetPage';
import ChangePassword from './admin/tools/components/change password/ChangePassword';
import MovingAccount from './admin/tools/components/moving account/MovingAccount';
import SendNotifications from './admin/tools/components/send notification/SendNotifications';
import RepresentAccount from './admin/tools/components/create account/RepresentAccount';
import MapInfo from './admin/tools/components/mapInfo/Index';
import SetupArea from './admin/tools/components/area/SetupArea';
import SettingAdminAccount from './admin/home/SettingAdminAccount';
import ChangePasswordForm from './admin/tools/components/change password/ChangePasswordForm';
import ConfirmNotification from './admin/tools/components/send notification/ConfirmNotification';
import Tools from './admin/tools/Tools';
import AreaDetails from './admin/tools/components/area/AreaDetails';
import EditArea from './admin/tools/components/area/EditArea';
import CreateArea from './admin/tools/components/area/CreateArea';
import DetailsEditTable from './admin/home/DetailsEditTable';
import SuspensionOrResume from './admin/tools/components/suspension/SuspensionOrResume';
import AccountSetting from './admin/tools/components/monitor target/AccountSetting';
import PaymentPage from './payment/PaymentPage';
import LoginPaymentPage from './payment/LoginPaymentPage';
import NavigateLocationSharingPage from './location_sharing/NavigateLocationSharingPage';
import AddDevice from './admin/devices/AddDevice';
import ListDevice from './admin/listdevice/ListDevice';
import EditDevicePage from './admin/devices/EditDevicePage';
import HistoryDevices from './admin/listdevice/HistoryDevices';
import ListTimeSheet from './admin/timesheet/ListTimeSheet';
import DetailLog from './admin/timesheet/DetailLog';
import ListAccounts from './admin/settings/components/adminAccounts/ListAccounts';
import CreateAccountAdmin from './admin/settings/components/adminAccounts/UppsertAdminAccount';
import Settings from './admin/settings/Settings';
import ListRoles from './admin/settings/components/roles/ListRoles';
import SetupRole from './admin/settings/components/roles/UppsertRole';
import CreateMapInfo from './admin/tools/components/mapInfo/CreateMapInfo';
import SettingsMapInfo from './admin/tools/components/mapInfo/settings';
import NotFoundPage from './config-permission/NotFoundPage';
import RoutePermission from './config-permission/RoutePermission';
import AddCategoryMapInfo from './admin/tools/components/mapInfo/settings/AddCategoryMapInfo';
import ListNetworkSetting from './admin/settings/components/networkSetting/ListNetworkSetting';
import UpsertNetworkSetting from './admin/settings/components/networkSetting/UpsertNetworkSetting';
import UpsertNotify from './admin/tools/components/send notification/upsertNotify';
import ListSendNotifications from './admin/tools/components/send notification/ListSendNotification';
import ConfirmNotify from './admin/tools/components/send notification/ConfirmNotify';

const Navigation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [redirectsHandled, setRedirectsHandled] = useState(false);
  const { pathname, search } = useLocation();

  const query = useQuery();

  const [isAuth, setIsAuth] = useState(false);

  useEffectAsync(async () => {
    if (query.get('token')) {
      const token = query.get('token');
      await fetch(`/api/session?token=${encodeURIComponent(token)}`);
      navigate(pathname);
    } else if (query.get('deviceId')) {
      const deviceId = query.get('deviceId');
      const response = await fetch(`/api/devices?uniqueId=${deviceId}`);
      if (response.ok) {
        const items = await response.json();
        if (items.length > 0) {
          dispatch(devicesActions.selectId(items[0].id));
        }
      } else {
        throw Error(await response.text());
      }
      navigate('/admin');
    } else if (query.get('eventId')) {
      const eventId = parseInt(query.get('eventId'), 10);
      navigate(`/event/${eventId}`);
    } else {
      setRedirectsHandled(true);
    }
  }, [query]);

  if (!redirectsHandled) {
    return <LinearProgress />;
  }

  return (
    <Routes>
      <Route path="/login-payment" element={<LoginPaymentPage setIsAuth={setIsAuth} />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/reset-password" element={<ResetPasswordPage />} />
      <Route path="/location_sharing" element={<NavigateLocationSharingPage />} />

      <Route path="/" element={<App />}>
        <Route path="payment" element={<PaymentPage />} />
        <Route path="admin" element={<Admin />}>
          <Route path="home" element={<RoutePermission permission="home" />}>
            <Route index element={<ListTable />} />
            <Route path="details/:id" element={<RoutePermission permission="home.detail" element={<DetailsTable />} />} />
            <Route path="details/:id/edit" element={<RoutePermission permission="home.detail" element={<DetailsEditTable />} />} />
            <Route path="details/history/:id" element={<RoutePermission permission="home.detail" element={<HistoryTable />} />} />
            <Route path="edit" element={<RoutePermission permission="home.edit" element={<EditPage />} />} />
          </Route>
          <Route path="devices" element={<RoutePermission permission="device" />}>
            <Route index element={<ListDevice />} />
            <Route path="add" element={<RoutePermission permission="device.add" element={<AddDevice />} />} />
            <Route path="edit/:id" element={<RoutePermission permission="device.edit" element={<EditDevicePage />} />} />
            <Route path="details/history/:id" element={<RoutePermission permission="device.viewHistory" element={<HistoryDevices />} />} />
          </Route>

          {/* Settings Screens */}
          <Route path="settings" element={<RoutePermission permission="settings" />}>
            <Route index element={<Settings />} />
            <Route path="roles" element={<RoutePermission permission="settings.roles" />}>
              <Route index element={<ListRoles />} />
              <Route path="add" element={<RoutePermission permission="settings.roles.create" element={<SetupRole />} />} />
              <Route path="edit/:id" element={<RoutePermission permission="settings.roles.edit" element={<SetupRole />} />} />
            </Route>

            <Route path="adminAccount" element={<RoutePermission permission="settings.adminAccounts" />}>
              <Route index element={<ListAccounts />} />
              <Route path="add" element={<RoutePermission permission="settings.adminAccounts.create" element={<CreateAccountAdmin />} />} />
              <Route path="edit/:id" element={<RoutePermission permission="settings.adminAccounts.edit" element={<CreateAccountAdmin />} />} />
            </Route>
            <Route path="networkSetting" element={<RoutePermission permission="settings.networkSetting" />}>
              <Route index element={<ListNetworkSetting />} />
              <Route path="add" element={<UpsertNetworkSetting />} />
              <Route path="edit/:id" element={<UpsertNetworkSetting />} />
            </Route>
          </Route>

          {/* TimeSheet Screens */}
          <Route path="timesheet" element={<RoutePermission permission="timeSheet" />}>
            <Route index element={<ListTimeSheet />} />
            <Route path="log/:id/:date" element={<DetailLog />} />
            <Route path="areaSetting" element={<RoutePermission permission="timeSheet.areaSetting" />}>
              <Route index element={<SetupArea />} />
              <Route path="edit/:id" element={<RoutePermission permission="timeSheet.areaSetting.edit" element={<EditArea />} />} />
              <Route path="new" element={<RoutePermission permission="timeSheet.areaSetting.createArea" element={<CreateArea />} />} />
              <Route path="detail/:id" element={<AreaDetails />} />
            </Route>
          </Route>

          {/* Tools Screens */}
          <Route path="tools" element={<RoutePermission permission="tools" />}>
            <Route index element={<Tools />} />
            <Route path="reset" element={<RoutePermission permission="tools.reset" element={<ResetPage />} />} />
            <Route path="setupArea" element={<RoutePermission permission="tools.areaSetting" />}>
              <Route index element={<SetupArea />} />
              <Route path="createArea" element={<RoutePermission permission="tools.areaSetting.createArea" element={<CreateArea />} />} />
              <Route path="editArea/:id" element={<RoutePermission permission="tools.areaSetting.edit" element={<EditArea />} />} />
              <Route path="areaDetails/:id" element={<AreaDetails />} />
            </Route>
            <Route path="accountSetting" element={<RoutePermission permission="tools.settingAccount" element={<AccountSetting />} />} />
            <Route path="changepassword" element={<RoutePermission permission="tools.changePassword" element={<ChangePassword />} />} />
            <Route path="changePasswordForm/:userId" element={<RoutePermission permission="tools.changePassword" element={<ChangePasswordForm />} />} />
            <Route path="movingaccount" element={<RoutePermission permission="tools.movingAdministrativeAccounts" element={<MovingAccount />} />} />
            <Route path="suspensionorresume" element={<RoutePermission permission="tools.suspensionOrResume" />}>
              <Route index element={<SuspensionOrResume />} />
            </Route>
            <Route path="sendNotifications" element={<RoutePermission permission="tools.sendNotifications" />}>
              <Route index element={<ListSendNotifications />} />
              <Route path="confirm" element={<ConfirmNotification />} />
              <Route path="confirm/:id" element={<ConfirmNotify />} />
              <Route path="add" element={<UpsertNotify />} />
              <Route path="edit/:id" element={<UpsertNotify />} />
            </Route>
            <Route path="representAccount" element={<RoutePermission permission="tools.generateRepresentAccount" element={<RepresentAccount />} />} />
            {/* map info */}
            <Route path="mapInfo" element={<RoutePermission permission="tools.mapInformation" />}>
              <Route index element={<MapInfo />} />
              <Route path="add" element={<CreateMapInfo />} />
              <Route path="edit/:id" element={<CreateMapInfo />} />
              <Route path="settings" element={<SettingsMapInfo />} />
              <Route path="settings/addCategory" element={<AddCategoryMapInfo />} />
              <Route path="settings/editCategory/:id" element={<AddCategoryMapInfo />} />
            </Route>
          </Route>

          {/* Analysis and Finish Screens */}
          <Route path="analys" element={<Analys />} />
          <Route path="settingAccount" element={<SettingAdminAccount />} />
          <Route path="finish">
            <Route index element={<Finish />} />
            <Route path="device" element={<Finish />} />
            <Route path="settings/roles" element={<Finish />} />
            <Route path="settings/adminAccount" element={<Finish />} />
            <Route path="settings/networkSetting" element={<Finish />} />
            <Route path="tools" element={<Finish />} />
            <Route path="tools/suspensionOrResume" element={<Finish />} />
            <Route path="tools/mapInfo" element={<Finish />} />
            <Route path="tools/sendNotifications" element={<Finish />} />
            <Route path="tools/mapInfo/settings" element={<Finish />} />
          </Route>
        </Route>
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Routes>

  );
};

export default Navigation;
