/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-array-index-key */
import { makeStyles } from '@mui/styles';
import React, { useCallback, useEffect, useState } from 'react';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Card, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { helperActions } from '../../store';
import fetchApi from '../FetchApi';

const useStyles = makeStyles((theme) => ({
  filterMain: {
    width: '100%',
    display: 'flex',
  },
  textFieldConfig: {
    width: '100%',
    borderColor: '#E0E0E0',
    position: 'absolute',
    top: '50%',
    border: '1px solid black',
    borderRadius: '5px',
    transform: 'translate(0, -50%)',
    '& .MuiOutlinedInput-root': {
      height: '30px',
    },
  },
}));

const FloatingFilterComponent = (props, ref) => {
  const { column } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  let keyHold = false;

  const [data, setData] = useState('');

  const currentPage = useSelector((state) => state.helper.currentPage);
  const adminData = useSelector((state) => state.helper.adminData);
  const filterType = useSelector((state) => state.helper.filterType);

  const pageSize = 50;
  const offset = (currentPage - 1) * pageSize;

  const handleFilterChange = (inputValue, field) => {
    const copyData = { ...adminData };
    const filter = [...copyData.filter];
    const existingFilterIndex = filter.findIndex((filterField) => filterField.name === field);
    if (existingFilterIndex === -1) {
      filter.push({ name: field, type: '01', value: inputValue });
      copyData.filter = filter;
      dispatch(helperActions.setAdminData(copyData));
    } else {
      const copyFilter = [...filter];
      copyFilter[existingFilterIndex] = { ...filter[existingFilterIndex], value: column?.colDef?.filterParams?.type !== 'NUMBER' ? inputValue : (filter[existingFilterIndex].type === '05' || filter[existingFilterIndex].type === '06') ? Number(inputValue) : inputValue };
      dispatch(helperActions.setAdminData({ ...copyData, filter: copyFilter }));
    }
  };
  return (
    <div className={classes.filterMain}>
      <TextField
        className={classes.textFieldConfig}
        id="outlined-basic"
        variant="outlined"
        value={data}
        onChange={(e) => {
          setData(e.target.value);
          handleFilterChange(e.target.value, column.colDef.field);
        }}
        onKeyDown={(event) => {
          if (column?.colDef?.filterParams?.type === 'NUMBER') {
            const { key } = event; // Lấy ký tự từ sự kiện keydown

            // Kiểm tra xem ký tự có thuộc dạng chữ không
            const isLetter = /^[a-zA-Z]$/.test(key);

            // Kiểm tra xem ký tự có phải là ký tự đặc biệt không
            const isSpecialCharacter = /^[!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?]*$/.test(key);

            // Kiểm tra nếu ký tự là chữ hoặc ký tự đặc biệt, ngăn chặn sự kiện keydown
            if (isLetter || isSpecialCharacter) {
              event.preventDefault();
            }
          }
          if (!keyHold) {
            keyHold = true;
          }
        }}
        onKeyUp={(event) => {
          keyHold = false;
          event.preventDefault();
        }}
      />
    </div>
  );
};

export default React.forwardRef(FloatingFilterComponent);
