import React, { useState } from 'react';
import { QRCodeCanvas } from 'qrcode.react';

const QrCode = (props) => {
  const { qrCodeData } = props;
  const [url, setUrl] = useState('');
  const downloadQRCode = (e) => { e.preventDefault(); setUrl(''); };
  const qrCodeEncoder = (e) => { setUrl(e.target.value); };
  return (
    <div className="qrcode">
      <div>
        <QRCodeCanvas
          id="qrCode"
          value={qrCodeData}
          size={150}
          level="H"
          includeMargin
        />
      </div>
      {/* <div className="input__group">
        <form onSubmit={downloadQRCode}>
          <label> Enter URL </label>
          <input
            type="text"
            value={url}
            onChange={qrCodeEncoder}
            placeholder="https://hackernoon.com"
          />
          <button type="submit" disabled={!url}>
            Download QR code
          </button>
        </form>
      </div> */}
    </div>
  );
};

export default QrCode;
