import React, { useEffect, useState } from 'react';
import {
  Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Select, FormControl, MenuItem, TextField, tableCellClasses, Snackbar, IconButton,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { makeStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useDispatch } from 'react-redux';
import DatePicker from 'react-datepicker';
import CloseIcon from '@mui/icons-material/Close';
import Header from '../Header';
import ConfirmationPopup from '../ComfirmationPopup';
import { useTranslation } from '../../../../common/components/LocalizationProvider';
import { useCatch } from '../../../../reactHelper';
import { BillingMethod, RatePlan, PaymentMethod } from '../../../common/DefineClass';
import { helperActions } from '../../../../store';
import { snackBarDurationVeryLongMs } from '../../../../common/util/duration';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#333f50',
    border: '1px solid black',
    color: theme.palette.common.white,
  },
}));

const useStyle = makeStyles({
  required: {
    fontSize: '20px',
    '&::before': {
      content: '"*"',
      color: 'red',
      position: 'relative',
      top: '0',
    },
    '&.MuiButtonBase-root': { backgroundColor: 'red' },
  },
  autofill: {
    fontSize: '20px',
    '&::before': {
      content: '"*"',
      color: 'white',
      position: 'relative',
      top: '0',
    },
  },
  borderCell: {
    height: '100px',
    border: '1px solid black',
  },
  selectField: {
    '&:hover': { backgroundColor: '#de560a' },
    '&:focus': { backgroundColor: '#de560a' },
  },
  datePicker: {
    border: '1px solid #ced4da',
    borderRadius: '4px',
    padding: '8px',
    width: '120px',
    fontWeight: 400,
    fontSize: '1rem',
  },
});

const CreateManualAccount = () => {
  const navigate = useNavigate();
  const t = useTranslation();
  const classes = useStyle();
  const dispatch = useDispatch();
  const location = useLocation();

  const isMobile = useMediaQuery('(max-width:600px)');
  const centerStyle = isMobile ? { position: 'absolute', left: '0', right: '0', margin: 'auto', bottom: '30px', width: '200px' } : { position: 'absolute', right: '60px', margin: '30px' };

  const billing = new BillingMethod();
  const rate = new RatePlan();
  const payment = new PaymentMethod();

  const [category, setCategory] = useState(0);
  const [paymentStatusCode, setPaymentStatusCode] = useState('newApplication');
  const [name, setName] = useState('');
  const [disabled, setDisabled] = useState('');
  const [email, setEmail] = useState('');
  const [billingMethod, setBillingMethod] = useState(3);
  const [ratePlan, setRatePlan] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [date, setDate] = useState(Date.now());
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [value, setValue] = React.useState('female');

  const handleChangeRadioOption = (event) => {
    setValue(event.target.value);
  };
  const registrationDate = moment().format('YYYY.M.D');

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleClose}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  const handleCreateAccount = useCatch(async () => {
    const response = await fetch('/api/admin/members/register/manager', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(category === 1 ? {
        category, paymentStatusCode, name, disabled, email, billingMethod, rateplan: ratePlan, enddate: date, paymentMethod,
      } : { category, paymentStatusCode, name, disabled, email, billingMethod: 1, paymentMethod: 1 }),
    });
    if (response.ok) {
      const result = await response.json();
      dispatch(helperActions.setPathname(location.pathname));
      if (result.status === 'NG' && result.error !== null) {
        setOpen(true);
        setErrorMessage(result.error);
      } else {
        navigate('/admin/finish');
      }
    }
  });

  const handleChange = (event, callback) => {
    callback(event.target.value);
  };

  useEffect(() => {
    if (category === 0) {
      setBillingMethod(3);
      setRatePlan('');
      setPaymentMethod('');
      setDate(Date.now());
    }
  }, [category]);

  const emailRegex = /^\S+@\S+\.\S+$/;
  const generalDisable = !(name.length !== 0 && category !== undefined && paymentStatusCode !== undefined && disabled !== '' && email.length !== 0 && emailRegex.test(email));
  const specialDisable = !(name.length !== 0 && category !== undefined && paymentStatusCode !== undefined && disabled !== '' && email.length !== 0 && emailRegex.test(email) && billingMethod !== undefined && ratePlan !== '' && paymentMethod !== '' && date !== null);

  return (
    <div>

      <div style={{ margin: '20px 0' }}>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">{t('categoryTableHeader')}</StyledTableCell>
                <StyledTableCell align="center">{t('statusTableHeader')}</StyledTableCell>
                <StyledTableCell align="center">{t('nameTableHeader')}</StyledTableCell>
                <StyledTableCell align="center">{t('userStatusTableHeader')}</StyledTableCell>
                <StyledTableCell align="center">{t('userEmailTableHeader')}</StyledTableCell>
                <StyledTableCell align="center">{t('registrationDate')}</StyledTableCell>
                {/* <StyledTableCell align="center">{t('paymentIdTableHeader')}</StyledTableCell> */}
                {category === 1 && (
                  <>
                    <StyledTableCell align="center">{t('billingMethodTableHeader')}</StyledTableCell>
                    <StyledTableCell align="center">{t('ratePlanTableHeader')}</StyledTableCell>
                    <StyledTableCell align="center">{t('paymentTableHeader')}</StyledTableCell>
                    <StyledTableCell align="center">{t('expiryDateTableHeader')}</StyledTableCell>
                  </>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell className={classes.borderCell}>
                  <FormControl fullWidth className={classes.required}>
                    <Select
                      labelId="select-category"
                      id="category"
                      sx={{ width: '120px' }}
                      value={category}
                      onChange={(e) => handleChange(e, setCategory)}
                    >
                      <MenuItem className={classes.selectField} value={0}>{t('accountGeneral')}</MenuItem>
                      <MenuItem className={classes.selectField} value={1}>{t('accountSpecial')}</MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell className={classes.borderCell}>
                  <FormControl fullWidth className={classes.required}>
                    <Select
                      labelId="select-payment-status"
                      id="paymentStatus"
                      sx={{ width: '200px' }}
                      value={paymentStatusCode}
                      onChange={(e) => handleChange(e, setPaymentStatusCode)}
                    >
                      <MenuItem className={classes.selectField} value="newApplication">{t('newApplication')}</MenuItem>
                      <MenuItem className={classes.selectField} value="continousBilling">{t('continousBilling')}</MenuItem>
                      <MenuItem className={classes.selectField} value="reminder">{t('reminder')}</MenuItem>
                      <MenuItem className={classes.selectField} value="cancelationLaw">{t('cancelationLaw')}</MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell className={classes.borderCell}>
                  <FormControl fullWidth className={classes.required}>
                    <TextField
                      required
                      sx={{ width: '180px' }}
                      name="name"
                      value={name}
                      autoComplete="name"
                      autoFocus
                      onChange={(e) => handleChange(e, setName)}
                    />
                  </FormControl>
                </TableCell>
                <TableCell className={classes.borderCell}>
                  <FormControl fullWidth className={classes.required}>
                    <Select
                      labelId="select-disabled"
                      id="disabled"
                      sx={{ width: '120px' }}
                      value={disabled}
                      onChange={(e) => handleChange(e, setDisabled)}
                    >
                      <MenuItem className={classes.selectField} value>{t('stopped')}</MenuItem>
                      <MenuItem className={classes.selectField} value={false}>{t('inUse')}</MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell className={classes.borderCell}>
                  <FormControl fullWidth className={classes.required}>
                    <TextField
                      name="managerAccountEmail"
                      value={email}
                      sx={{ width: '180px' }}
                      onChange={(e) => handleChange(e, setEmail)}
                    />
                  </FormControl>
                </TableCell>
                <TableCell className={classes.borderCell}>
                  <div className={classes.autofill}>
                    <Typography variant="body1" sx={{ display: 'flex', justifyContent: 'center' }}>{registrationDate}</Typography>
                  </div>
                </TableCell>
                {/* <TableCell className={classes.borderCell}>
                  <FormControl fullWidth className={classes.required}>
                    <TextField
                      required
                      sx={{ width: '180px' }}
                      name="name"
                      value="tre-app-123456789"
                      autoComplete="name"
                      autoFocus
                      // onChange={(event) => setName(event.target.value)}
                      disabled
                    />
                  </FormControl>
                </TableCell> */}
                {category === 1 && (
                <>
                  <TableCell className={classes.borderCell}>
                    <FormControl fullWidth className={classes.required}>
                      <Select
                        labelId="select-billing-method"
                        id="billingMethod"
                        sx={{ width: '120px' }}
                        value={billingMethod}
                        onChange={(e) => handleChange(e, setBillingMethod)}
                      >
                        <MenuItem className={classes.selectField} value={1}>{t(billing.inApp)}</MenuItem>
                        <MenuItem className={classes.selectField} value={2}>{t(billing.outside)}</MenuItem>
                        <MenuItem className={classes.selectField} value={3}>{t(billing.special)}</MenuItem>
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell className={classes.borderCell}>
                    <FormControl fullWidth className={classes.required}>
                      <Select
                        labelId="select-rate-plan"
                        id="ratePlan"
                        sx={{ width: '120px' }}
                        value={ratePlan}
                        onChange={(e) => handleChange(e, setRatePlan)}
                      >
                        <MenuItem className={classes.selectField} value={rate.basic}>{t(rate.basic)}</MenuItem>
                        <MenuItem className={classes.selectField} value={rate.prenium}>{t(rate.prenium)}</MenuItem>
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell className={classes.borderCell}>
                    <FormControl fullWidth className={classes.required}>
                      <Select
                        labelId="select-payment-method"
                        id="paymentMethod"
                        sx={{ width: '120px' }}
                        value={paymentMethod}
                        onChange={(e) => handleChange(e, setPaymentMethod)}
                      >
                        <MenuItem className={classes.selectField} value={1}>{t(payment.inApp)}</MenuItem>
                        <MenuItem className={classes.selectField} value={2}>{t(payment.credit)}</MenuItem>
                        <MenuItem className={classes.selectField} value={3}>{t(payment.bank)}</MenuItem>
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell className={classes.borderCell}>
                    <FormControl fullWidth className={classes.required}>
                      <DatePicker
                        portalId="root"
                        selected={date}
                        dateFormat="yyyy/MM/dd"
                        className={classes.datePicker}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        onChange={(selectedDate) => {
                          setDate(selectedDate?.getTime());
                        }}
                      />
                    </FormControl>
                  </TableCell>
                </>
                )}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <ConfirmationPopup
        btnTitle={t('sharedCreateSpecialAccount')}
        submit={handleCreateAccount}
        confirmText={t('confirmCreateAccount')}
        btnColor="#f91414"
        width="330px"
        disabled={category === 1 ? specialDisable : generalDisable}
        styleButton={centerStyle}
      />
      <Snackbar
        open={open}
        onClose={handleClose}
        autoHideDuration={snackBarDurationVeryLongMs}
        message={t(errorMessage)}
        action={action}
      />
    </div>
  );
};

export default CreateManualAccount;
