import icon1 from '../../../../assets/mapIcon/icon1.svg';
import icon2 from '../../../../assets/mapIcon/icon2.svg';
import icon3 from '../../../../assets/mapIcon/icon3.svg';
import icon4 from '../../../../assets/mapIcon/icon4.svg';
import icon5 from '../../../../assets/mapIcon/icon5.svg';
import icon6 from '../../../../assets/mapIcon/icon6.svg';
import icon7 from '../../../../assets/mapIcon/icon7.svg';
import icon8 from '../../../../assets/mapIcon/icon8.svg';
import icon9 from '../../../../assets/mapIcon/icon9.svg';
import icon10 from '../../../../assets/mapIcon/icon10.svg';
import icon11 from '../../../../assets/mapIcon/icon11.svg';
import icon12 from '../../../../assets/mapIcon/icon12.svg';

export default [
  { id: 1, src: icon1, name: 'Refuge' },
  { id: 2, src: icon2, name: 'Evacuation place' },
  { id: 3, src: icon3, name: 'Shelter for people with disabilities' },
  { id: 4, src: icon4, name: 'Medical station' },
  { id: 5, src: icon5, name: 'Water supply station' },
  { id: 6, src: icon6, name: 'Support material supply station' },
  { id: 7, src: icon7, name: 'Bathing place' },
  { id: 8, src: icon8, name: 'Toilet' },
  { id: 9, src: icon9, name: 'Support station returning home after a disaster' },
  { id: 10, src: icon10, name: 'Temporary waiting facilities for those unable to return home' },
  { id: 11, src: icon11, name: 'Surveillance cameras' },
  { id: 12, src: icon12, name: 'Traffic signs' },
];
export const colorSettings = [
  { label: '#C00000', value: 'invert(13%) sepia(74%) saturate(3418%) hue-rotate(350deg) brightness(108%) contrast(127%)' },
  { label: '#FF0000', value: 'invert(23%) sepia(79%) saturate(4829%) hue-rotate(357deg) brightness(86%) contrast(137%)' },
  { label: '#FFC000', value: 'invert(84%) sepia(54%) saturate(5273%) hue-rotate(3deg) brightness(104%) contrast(105%)' },
  { label: '#FFFF00', value: 'invert(83%) sepia(82%) saturate(7500%) hue-rotate(357deg) brightness(99%) contrast(107%)' },
  { label: '#92D050', value: 'invert(77%) sepia(8%) saturate(2757%) hue-rotate(45deg) brightness(96%) contrast(90%)' },
  { label: '#00B050', value: 'invert(36%) sepia(98%) saturate(1766%) hue-rotate(126deg) brightness(100%) contrast(101%)' },
  { label: '#00B0F0', value: 'invert(72%) sepia(79%) saturate(5817%) hue-rotate(165deg) brightness(104%) contrast(101%)' },
  { label: '#0070C0', value: 'invert(26%) sepia(88%) saturate(3069%) hue-rotate(189deg) brightness(90%) contrast(101%)' },
  { label: '#002060', value: 'invert(11%) sepia(80%) saturate(2357%) hue-rotate(210deg) brightness(91%) contrast(108%)' },
  { label: '#7030A0', value: 'invert(21%) sepia(31%) saturate(4757%) hue-rotate(259deg) brightness(94%) contrast(91%)' },
];
export const openStatus = [{ name: 'Open', value: true }, { name: 'Close', value: false }];
export const crowedStatus = [{ name: 'Crowed', value: true }, { name: 'Not Crowed', value: false }];
