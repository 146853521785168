import React from 'react';
import { Autocomplete, FormControl, TextField } from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useController } from 'react-hook-form';
import LabelAdd from '../../devices/components/LabelAdd';
import '../../styles/components/FormTextField.css';

const FormAutoComplete = ({ label, required, controllerProps, autoCompleteProps }) => {
  const { field, fieldState } = useController(controllerProps);
  const { error } = fieldState;
  const onChangeAutoComplete = (_, value) => {
    field.onChange(value);
  };

  return (
    <div className="form-text-field">
      <LabelAdd label={label} required={required} />

      <FormControl className="form-control">
        <Autocomplete
          disablePortal
          {...autoCompleteProps}
          {...field}
          onChange={onChangeAutoComplete}
          renderInput={(params) => (<TextField {...params} error={!!error} helperText={error ? error.message : ''} />)}
        />
      </FormControl>
    </div>
  );
};

export default FormAutoComplete;
