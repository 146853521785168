/* eslint-disable no-undef */
import React, {
  useRef, useState, useCallback, useEffect,
} from 'react';
import {
  Button, Modal, Typography, Box,
  Alert,
  Snackbar,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import moment from 'moment';
import {
  AddBoxOutlined,
  Download,
  Upload,
} from '@mui/icons-material';
import BreadcrumbsCustom from '../components/BreadcrumbsCustom';
import { useTranslation } from '../../common/components/LocalizationProvider';
import { getDeviceData, getDeviceIds } from '../common/utils';
import { helperActions } from '../../store';
import NewDeviceTable from '../components/NewDeviceTable';
import baseRequest from '../../apis/baseRequest';
import '../styles/pages/ListDevice.css';
import uris from '../../apis/uris';
import { selectMessageError, setMessage } from '../../store/messageError';
import ModalConfirm from '../components/modal/ModalConfirm';
import hasPermission from '../common/hasPermission';

const ListDevice = () => {
  const navigate = useNavigate();
  const tableRef = useRef();
  const t = useTranslation();
  const dispatch = useDispatch();
  const inputFile = useRef(null);
  const [file, setFile] = useState({});
  const [gridApi, getGridApi] = useState(null);
  const [dataSelect, setDataSelect] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [showError, setShowError] = useState(false);
  const [isLoadDevice, setIsLoadDevice] = useState(false);
  const checkedList = useSelector((state) => state.helper.checkedList);
  const data = getDeviceData(checkedList, dataSelect);
  const id = getDeviceIds(checkedList, dataSelect);
  const handleImportDevice = (data) => {
    baseRequest.call(uris.IMPORT_DEVICE, 'POST', data, null, true).then((response) => {
      if (response.status === 'NG') dispatch(setMessage(response.message));
      else { setShowError(true); setIsLoadDevice(true); }
    });
  };

  const isValid = () => {
    if (file?.name?.length > 0) {
      if ((/\.(csv)$/i.test(file.name)) && (file.size < 10000000)) {
        handleImportDevice(file);
      } else {
        dispatch(setMessage('You need to select the csv file and have a capacity of less than 10MB.'));
      }
    }
  };

  useEffect(() => {
    isValid();
  }, [file]);

  const handleClose = () => {
    setOpenModal(false);
  };
  const handleChange = (e) => {
    setFile(e.target.files[0]);
  };
  const handleDeleteDevice = async () => {
    baseRequest.post(uris.DEVICES_DELETE_ALL, { id }).then(() => {
      navigate('/admin/finish/device');
    });
  };
  useEffect(() => {
    dispatch(helperActions.selectedDevice(data));
  }, [checkedList, dataSelect]);

  useEffect(() => {
    if (checkedList.length === 0) {
      setDataSelect([]);
    }
  }, [checkedList]);

  const handleSelectionChange = () => {
    setDataSelect(gridApi?.getSelectedRows() || []);
  };

  const handleRowDataUpdated = () => {
    gridApi?.forEachNode((node) => {
      if (checkedList.length !== 0) {
        node.setSelected(true);
      }
    });
  };

  const onBtnExport = useCallback(() => {
    const columnDefs = tableRef.current.api.getColumnDefs();
    columnDefs.splice(-1);
    const newColumnDefs = columnDefs.filter((item) => item.field !== 'name');
    const columnKeys = newColumnDefs.slice(2).map((columnDef) => columnDef.colId);
    const linkColumnConfig = {
      field: 'link',
      valueFormatter: (params) => linkValueFormatter(params.node.data),
    };

    const dateColumnConfig = {
      field: 'fixtime',
      valueFormatter: (params) => formatDate(params.value),
    };

    const managerUserIDConfig = {
      field: 'managerUserID',
      valueFormatter: (params) => params.value,
    };

    const managerUserConfig = {
      field: 'managerUser',
      valueFormatter: (params) => `${params.value?.name} | ${params.value?.email}`,
    };

    const timeColumnConfig = {
      field: 'registeredDate',
      valueFormatter: (params) => moment(params.value).format('YYYY/MM/DD HH:mm:ss'),
    };
    tableRef.current.api.exportDataAsCsv({
      processCellCallback: (params) => {
        const column = params.column.getColDef();

        if (column.field === 'link') {
          return linkColumnConfig.valueFormatter(params);
        }

        if (column.field === 'fixtime') {
          return dateColumnConfig.valueFormatter(params);
        }

        if (column.field === 'registeredDate') {
          return timeColumnConfig.valueFormatter(params);
        }

        if (column.field === 'managerUser') {
          return managerUserConfig.valueFormatter(params);
        }
        if (column.field === 'managerUserID') {
          return managerUserIDConfig.valueFormatter(params);
        }

        return params.value;
      },
      onlySelected: true,
      fileName: `Device_list_${moment().format('YYYY-MM-DD HH:mm:ss')}.csv`,
      ...{ columnKeys },
    });
  }, []);

  const dataTable = {
    getGridApi,
    tableRef,
    floatingFilter: true,
    showPagination: true,
    className: 'config-permission-table',
    isLoadDevice,
    onSelectionChanged: handleSelectionChange,
    onRowDataUpdated: handleRowDataUpdated,
    childrenTop: (
      <div>
        <BreadcrumbsCustom getAfter="admin" />
        <div className="list-btn">
          {hasPermission('device.add') && (
          <Button
            onClick={() => navigate('add')}
            className="csv-btn"
          >
            <AddBoxOutlined style={{ paddingRight: '5px', fontSize: '30px' }} />
            <div>Add</div>
          </Button>
          )}
          {/* <Button
            onClick={() => navigate('edit')}
            className={classes.csvBtn}
            disabled={!(checkedList.length > 0 || dataSelect.length > 0)}
          >
            <LaunchIcon style={{ paddingRight: '5px', fontSize: '30px' }} />
            <div>{t('sharedEdit')}</div>
          </Button> */}
          {hasPermission('device.delete') && (
          <Button
            onClick={() => setOpenModal(true)}
            className="csv-btn"
            disabled={!(checkedList.length > 0 || dataSelect.length > 0)}
          >
            <DeleteOutlineOutlinedIcon
              style={{ paddingRight: '5px', fontSize: '30px' }}
            />
            <div>{t('sharedDelete')}</div>
          </Button>
          )}
          {hasPermission('device.import') && (
          <Button
            onClick={() => inputFile.current.click()}
            className="csv-btn"
          >
            <Upload style={{ paddingRight: '5px', fontSize: '30px' }} />
            <div>Import</div>
            <input style={{ display: 'none' }} type="file" onChange={handleChange} ref={inputFile} accept=".csv" />
          </Button>
          )}
          {hasPermission('device.export') && (
          <Button
            onClick={onBtnExport}
            className="csv-btn"
            disabled={!(checkedList.length > 0 || dataSelect.length > 0)}
          >
            <Download style={{ paddingRight: '5px', fontSize: '30px' }} />
            <div>Export</div>
          </Button>
          )}
        </div>
      </div>
    ),
  };
  return (
    <>
      <NewDeviceTable {...dataTable} />
      <Snackbar
        open={showError}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setShowError(false)}
      >
        <Alert onClose={() => setShowError(false)} severity="success" variant="filled">
          {t('importSuccess')}
        </Alert>
      </Snackbar>
      <ModalConfirm
        openModal={openModal}
        handleClose={handleClose}
        propsModal={
          {
            confirm: t('confirmDelete'),
            msgWarning: t('confirmEdit2'),
            action: handleDeleteDevice,
          }
        }
      />

    </>
  );
};

export default ListDevice;
