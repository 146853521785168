/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import { Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from '../../../../common/components/LocalizationProvider';
import fetchApi from '../../../FetchApi';
import Header from '../Header';
import ComfirmationPopup from '../ComfirmationPopup';
import NewTable from '../../../components/NewTable';
import { helperActions } from '../../../../store';
import { getUserIds } from '../../../common/utils';
import hasPermission from '../../../common/hasPermission';

const useStyles = makeStyles((theme) => ({
  configTable: {
    height: `${window.innerHeight - 200}px`,
  },
  mobileStyle: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'center',
    },
  },
}));

const SuspensionOrResumeNew = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const t = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();

  const [gridApi, getGridApi] = useState(null);
  const [dataSelect, setDataSelect] = useState([]);
  const [checkAll, setCheckAll] = useState(false);

  const checkedList = useSelector((state) => state.helper.checkedList);

  const userIds = getUserIds(checkedList, dataSelect);

  useEffect(() => {
    if (checkedList.length === 0) {
      setDataSelect([]);
    }
  }, [checkedList]);

  const handleSubmit = async (status) => {
    const response = await fetchApi('/api/admin/users/change_status', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userIds,
        disable: status,
      }),
    });
    if (response.ok) {
      dispatch(helperActions.setPathname(location.pathname));
      navigate('/admin/finish/tools/suspensionOrResume');
    }
  };

  const handleSelectionChange = () => {
    setDataSelect(gridApi?.getSelectedRows() || []);
  };

  const handleRowDataUpdated = () => {
    gridApi?.forEachNode((node) => {
      if (checkedList.length !== 0) {
        node.setSelected(true);
      }
    });
  };

  const dataTable = {
    getGridApi,
    removeColumn: [1],
    floatingFilter: true,
    showPagination: true,
    className: classes.configTable,
    onSelectionChanged: handleSelectionChange,
    onRowDataUpdated: handleRowDataUpdated,
  };

  return (
    <div>
      <Header topic="Suspension Or Resume Account">
        <Typography>{t('mimamoritargetDescription')}</Typography>
      </Header>
      <NewTable {...dataTable} />
      <div className={classes.mobileStyle}>
        {hasPermission('tools.suspensionOrResume.continueUsing') && (
        <ComfirmationPopup
          styleButton={{ margin: '15px' }}
          btnColor="#4b7bd0"
          btnTitle={t('continueUsing')}
          submit={() => handleSubmit(false)}
          width="200px"
          confirmText={t('confirmContinue')}
          disabled={userIds.length === 0}
          confirmContinue
        />
        )}
        {hasPermission('tools.suspensionOrResume.pauseUsing') && (
        <ComfirmationPopup
          styleButton={{ margin: '15px' }}
          btnColor="#ff0505"
          btnTitle={t('pauseUsing')}
          submit={() => handleSubmit(true)}
          width="200px"
          confirmText={t('confirmPause')}
          disabled={userIds.length === 0}
        />
        )}
      </div>
    </div>
  );
};

export default SuspensionOrResumeNew;
