/* eslint-disable import/no-extraneous-dependencies */
import React, {
  useState, forwardRef, useImperativeHandle, useRef, useEffect,
} from 'react';
import { makeStyles } from '@mui/styles';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { ContactlessOutlined } from '@mui/icons-material';

const useStyles = makeStyles({
  datePicker: {
    width: '100%',
    padding: '8px',
  },
});

const DatePopupEditor = forwardRef((props, ref) => {
  const classes = useStyles();
  const { valueDate, handleChangeDate } = props;
  const refDatePicker = useRef();
  const parseDate = (dateString) => {
    const parsedDate = moment(dateString, 'YYYY/MM/DD', true);
    return parsedDate.isValid() ? parsedDate.toDate() : new Date();
  };

  const [selectedDate, setSelectedDate] = useState(() => (valueDate ? parseDate(valueDate) : new Date()));
  const [editing, setEditing] = useState(true);

  useEffect(() => {
    if (!editing) {
      props?.api?.stopEditing();
    }
  }, [editing]);

  const onChange = (selectedDate) => {
    setSelectedDate(selectedDate);
    setEditing(false);
    handleChangeDate(selectedDate);
  };
  return (
    <div>
      <DatePicker
        ref={refDatePicker}
        portalId="root"
        popperClassName="ag-custom-component-popup"
        onChange={onChange}
        selected={selectedDate}
        className={classes.datePicker}
        dateFormat="yyyy/MM/dd"
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
      />
    </div>
  );
});

export default DatePopupEditor;
