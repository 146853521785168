import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import FormTextField from '../../../components/form/FormTextField';
import { useTranslation } from '../../../../common/components/LocalizationProvider';
import configColorLayout from '../../../../settings/ConfigColorLayout';
import baseRequest from '../../../../apis/baseRequest';
import uris from '../../../../apis/uris';
import store, { setMessage } from '../../../../store';
import ModalConfirm from '../../../components/modal/ModalConfirm';
import Header from '../../../tools/components/Header';
import HeaderSettings from '../HeaderSettings';

const UpsertNetworkSetting = () => {
  const { control, handleSubmit, setValue, formState: { isValid, isDirty, errors } } = useForm({ mode: 'onChange' });
  const t = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [defaultValueEdit, setDefaultValueEdit] = useState({});
  const handleClose = () => {
    setOpenModal(false);
  };
  const handleBack = () => {
    setOpenModal(false);
    navigate('/admin/settings/networkSetting');
  };
  const handleCheckData = () => {
    if (isDirty) {
      setOpenModal(true);
    } else {
      handleBack();
    }
  };
  const fetchDataById = () => {
    baseRequest.post(uris.GET_NETWORK_SETTING_BY_ID, { id }).then((response) => {
      setValue('ipAddress', response?.data?.ipAddress);
      setValue('spare', response?.data?.spare);
    });
  };
  const onSubmit = (data) => {
    if (id) {
      data.id = parseInt(id, 10);
    }
    baseRequest.post(id ? uris.EDIT_NETWORK_SETTING : uris.ADD_NETWORK_SETTING, data).then((response) => {
      if (response?.status === 'NG') {
        store.dispatch(setMessage(response?.error || ''));
      } else {
        navigate('/admin/finish/settings/networkSetting');
      }
    });
  };
  useEffect(() => {
    if (id) fetchDataById();
  }, []);
  return (
    <div>
      <HeaderSettings noDescription handleOnNavigate={handleCheckData} />
      <ModalConfirm
        openModal={openModal}
        handleClose={handleClose}
        propsModal={
                {
                  confirm: t('confirmMessage'),
                  msgWarning: t('messageWarning'),
                  action: handleBack,
                }
            }
        textAcceptButton={t('accept')}
      />
      <Typography sx={{ margin: '30px 0' }}>
        {t('ExampleIP')}
      </Typography>
      <div className="container-form-network">
        <div className="title-form-network">{t('networkSettings')}</div>
        <form onSubmit={handleSubmit(onSubmit)} className="main-form-network">
          <FormTextField
            label={t('IPAddress')}
            controllerProps={{
              control,
              name: 'ipAddress',
              rules: {
                required: t('FieldNotNull'),
                maxLength: { value: 15, message: t('Max15DAll') },
                pattern: {
                  value: /(([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])/,
                  message: t('ValidateIPv4'),
                },
              },
            }}
            required
            autocomplete="off"
          />
          <FormTextField
            label={t('spare')}
            controllerProps={{
              control,
              name: 'spare',
            }}
          />

        </form>

      </div>
      <div className="upsert-network-add-item">
        <Button
          onClick={handleSubmit(onSubmit)}
          type="submit"
          variant="contained"
          disabled={!id ? !isValid : !(Object.keys(errors).length === 0 && errors.constructor === Object)}
          style={{ backgroundColor: configColorLayout, width: '150px' }}
        >
          {id ? t('sharedSave') : t('shareAddNew')}
        </Button>
      </div>
    </div>
  );
};

export default UpsertNetworkSetting;
