import React, { useRef, useState } from 'react';
import {
  Box, Button, Modal, Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useDispatch } from 'react-redux';
import Header from '../../Header';
import { colorSettings } from '../masterDataMapInfo';
import { useTranslation } from '../../../../../common/components/LocalizationProvider';
import FormTextField from '../../../../components/form/FormTextField';
import baseRequest, { basicAuth } from '../../../../../apis/baseRequest';
import uris from '../../../../../apis/uris';
import store, { setMessage, setMessageSuccess } from '../../../../../store';
import { useEffectAsync } from '../../../../../reactHelper';
import ModalConfirm from '../../../../components/modal/ModalConfirm';

const AddCategoryMapInfo = () => {
  const t = useTranslation();
  const { id } = useParams();
  const rootRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { control, handleSubmit, setValue, watch, reset, formState: { isValid, isDirty } } = useForm({ mode: 'onChange' });
  const [checkedIcon, setCheckedIcon] = useState({});
  const [checkedColor, setCheckedColor] = useState(0);
  const inputFile = useRef(null);
  const [openModal, setOpenModal] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);

  const [defaultValueEdit, setDefaultValueEdit] = useState({});
  const [listIcon, setListIcon] = useState([]);
  const [clientXY, setClientXY] = useState({ x: 0, y: 0 });
  const [statusMenu, setStatusMenu] = useState(false);
  const [idMenu, setIdMenu] = useState(null);

  const handleChecked = (id, state) => {
    state(id);
  };

  const clearData = () => {
    if (listIcon) {
      setCheckedIcon(listIcon[0]);
    }
    setCheckedColor(0);
    reset();
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleCloseMenu = () => {
    setStatusMenu(false);
  };

  const handleUploadFile = (e) => {
    const file = e.target.files[0];
    if (file?.name?.length > 0) {
      if ((file.type === 'image/svg+xml') && (file.size < 102400)) {
        const blob = new Blob([file], { type: 'image/svg+xml' });
        baseRequest.call(uris.UPLOAD_ICON_CATEGORY, 'POST', blob, null, false, {
          headers: {
            'Content-type': 'image/svg+xml',
            // eslint-disable-next-line no-undef
            Authorization: basicAuth(localStorage.getItem('email'), (localStorage.getItem('password'))),
          },
        }).then((response) => {
          if (response.status === 'NG') {
            dispatch(setMessage(response.error));
          } else {
            dispatch(setMessageSuccess(response?.message));
            baseRequest.get(uris.LIST_ICON).then((response) => setListIcon(response?.data));
          }
        });
      } else {
        dispatch(setMessage('You need to select the svg+xml file and have a capacity of less than 100KB.'));
      }
      inputFile.current.value = null;
    }
  };

  const deleteIcon = () => {
    baseRequest.post(uris.DELETE_ICON_CATEGORY, { id: idMenu }).then((response) => {
      if (response.status === 'NG') {
        dispatch(setMessage(response.error));
      } else {
        dispatch(setMessageSuccess(response?.message));
        baseRequest.get(uris.LIST_ICON).then((response) => setListIcon(response?.data));
      }
    });
  };

  const handleRightClickMenu = (e, id) => {
    setIdMenu(id);
    e.preventDefault();
    if (rootRef.current) {
      const rect = rootRef.current.getBoundingClientRect();
      const x = e.clientX - rect.left + rootRef.current.scrollLeft;
      const y = e.clientY - rect.top + rootRef.current.scrollTop;

      setClientXY({ x, y });
      setStatusMenu(true);
    }
  };

  const fetchIcons = async () => {
    baseRequest.get(uris.LIST_ICON).then((response) => {
      setListIcon(response?.data);
      if (id) {
        baseRequest.post(uris.DETAIL_CATEGORY, { id }).then((res) => {
          if (res?.status === 'NG') {
            store.dispatch(setMessage(response?.error || ''));
          } else {
            res.data.iconId = response?.data?.find((item) => item.id === res?.data?.iconId);
            res.data.iconColor = colorSettings?.findIndex((item) => item.value === res?.data?.iconColor);
            setDefaultValueEdit(res?.data);
            setValue('name', res?.data.name, { shouldValidate: true });
            setCheckedIcon(res.data.iconId);
            setCheckedColor(res.data.iconColor);
          }
        });
      } else {
        setDefaultValueEdit({ iconId: response?.data[0], iconColor: 0 });
        setCheckedIcon(response?.data[0]);
      }
    });
  };

  const onSubmit = (data) => {
    data.iconId = checkedIcon?.id;
    data.iconColor = colorSettings[checkedColor].value;
    if (id) {
      data.id = parseInt(id, 10);
    }
    baseRequest.post(id ? uris.EDIT_CATEGORY : uris.CREATE_CATEGORY, data).then((response) => {
      if (response?.status === 'NG') {
        store.dispatch(setMessage(response?.error || ''));
      } else {
        navigate('/admin/finish/tools/mapInfo/settings');
      }
    });
  };

  const handleCheckData = () => {
    if (defaultValueEdit?.iconId !== checkedIcon || defaultValueEdit?.iconColor !== checkedColor || isDirty) {
      setOpenModalEdit(true);
    } else navigate('/admin/tools/mapInfo/settings');
  };

  const handleBack = () => {
    setOpenModalEdit(false);
    navigate('/admin/tools/mapInfo/settings');
  };

  useEffectAsync(async () => {
    await fetchIcons();
  }, []);

  return (
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div ref={rootRef} onClick={handleCloseMenu}>
      <Header noDescription handleOnNavigate={handleCheckData} />
      {statusMenu && (
        <div className="context-menu" style={{ top: clientXY.y + 10, left: clientXY.x + 32 }}>
          <Button onClick={() => setOpenModalDelete(true)} className="context-menu-button">{t('sharedDelete')}</Button>
        </div>
      )}
      <ModalConfirm
        openModal={openModalEdit}
        handleClose={() => setOpenModalEdit(false)}
        propsModal={
                {
                  confirm: t('confirmMessage'),
                  msgWarning: t('messageWarning'),
                  action: handleBack,
                }
            }
      />
      <ModalConfirm
        openModal={openModalDelete}
        handleClose={() => setOpenModalDelete(false)}
        propsModal={
                {
                  confirm: t('confirmDelete'),
                  msgWarning: t('confirmEdit2'),
                  action: deleteIcon,
                }
            }
      />
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 350,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <div className="map-add-category-modal">
            <img style={{ filter: colorSettings[checkedColor].value }} src={checkedIcon?.path} width={100} height={100} alt="preview-icon" />
            <Typography variant="h6" ml={0.5} sx={{ marginTop: 3 }}>
              {watch('name')}
            </Typography>
            <Typography variant="h6" ml={0.5} sx={{ marginTop: 2 }}>
              {t('MakeAnAddition')}
            </Typography>
            <div className="map-add-category-container-button">
              <Button
                onClick={() => handleClose()}
                className="map-add-category-btn-cancel"
                variant="contained"
              >
                {t('close')}
              </Button>
              <Button
                onClick={handleSubmit(onSubmit)}
                className="map-add-category-btn"
                variant="contained"
              >
                {t('ok')}
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
      <Typography
        variant="h5"
        sx={{ fontWeight: 'bold', marginBottom: 2 }}
      >
        {t('Icon')}
        <Typography variant="span" color="error" ml={0.5}>
          *
        </Typography>
      </Typography>
      <Button
        onClick={() => inputFile.current.click()}
        className="map-add-icon-btn"
        variant="contained"
        startIcon={<CloudUploadIcon />}
      >
        <input style={{ display: 'none' }} type="file" ref={inputFile} onChange={handleUploadFile} accept="image/svg+xml" />
        {t('AddIcon')}
      </Button>
      <div className="map-add-category-img">
        {listIcon?.map((item) => (
          <div onContextMenu={(e) => handleRightClickMenu(e, item?.id)} key={item.id} className="map-add-category-container">
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
            <img src={item?.path} alt={item.name} width={50} height={50} onClick={() => handleChecked(item, setCheckedIcon)} />
            {checkedIcon?.id === item?.id && (
            <span className="map-add-category-checked" />
            )}
          </div>
        ))}
      </div>
      <Typography
        variant="h5"
        sx={{ fontWeight: 'bold', marginTop: 10 }}
      >
        {t('groupColor')}
        <Typography variant="span" color="error" ml={0.5}>
          *
        </Typography>
      </Typography>
      <div className="map-add-category-color">
        {colorSettings?.map((item, index) => (
          <div className="map-add-category-container">
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div style={{ background: item.label }} onClick={() => handleChecked(index, setCheckedColor)} />
            {checkedColor === index && (
            <span className="map-add-category-checked" />
            )}
          </div>
        ))}
      </div>
      <form>
        <Typography
          variant="h5"
          sx={{ fontWeight: 'bold', marginTop: 10 }}
        >
          {t('nameTableHeader')}
          <Typography variant="span" color="error" ml={0.5}>
            *
          </Typography>
        </Typography>
        <FormTextField
          controllerProps={{
            control,
            name: 'name',
            rules: { required: t('FieldNotNull'), maxLength: { value: 50, message: t('Max50DAll') } },
          }}
          textFieldProps={{
            placeholder: t('positionInput'),
            sx: { width: 300, marginTop: 1 },
          }}
          required
        />
        <div className="map-add-category-container-btn">
          <Button
            onClick={() => clearData()}
            className="map-add-category-btn-cancel"
            variant="contained"
          >
            {t('sharedCancel')}
          </Button>
          <Button
            onClick={() => setOpenModal(true)}
            disabled={!isValid}
            className="map-add-category-btn"
            variant="contained"
          >
            {id ? t('sharedSave') : t('sharedAdd')}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddCategoryMapInfo;
