import React from 'react';
import {
  Card, Divider, Grid, CardActionArea, Typography, useTheme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from '../../common/components/LocalizationProvider';
import hasPermission from '../common/hasPermission';

const url = '/admin/tools';
const menuNavigate = [
  {
    title: 'linkListReset',
    description: 'DescriptionReset',
    link: `${url}/reset`,
    permission: 'tools.reset',
  },
  {
    title: 'linkListCreateArea',
    description: 'DescriptionCreateArea',
    link: `${url}/setupArea`,
    permission: 'tools.areaSetting',
  },
  {
    title: 'linkListSettingAccount',
    description: 'DescriptionMonitorTarget',
    link: `${url}/accountSetting`,
    permission: 'tools.settingAccount',
  },
  // {
  //   title: 'linkListGroupSettings',
  //   description: 'DescriptionGroupSettings',
  //   link: `${url}/groupSettings`,
  // },
  {
    title: 'linkListChangePassword',
    description: 'DescriptionChangePassword',
    link: `${url}/changepassword`,
    permission: 'tools.changePassword',
  },
  {
    title: 'linkListMovingAccount',
    description: 'DescriptionMovingAccount',
    link: `${url}/movingAccount`,
    permission: 'tools.movingAdministrativeAccounts',
  },
  {
    title: 'linkListSuspensionOrResume',
    description: 'DescriptionSuspensionOrResume',
    link: `${url}/suspensionOrResume`,
    permission: 'tools.suspensionOrResume',
  },
  {
    title: 'linkListSendNotifications',
    description: 'DescriptionSendNotifications',
    link: `${url}/sendNotifications`,
    permission: 'tools.sendNotifications',
  },

  // START --update menu tool wireframe 4.0 - 2023/10/10
  {
    title: 'linkListRepresentAccount',
    description: 'DescriptionRepresentAccount',
    link: `${url}/representAccount`,
    permission: 'tools.generateRepresentAccount',
  },
  {
    title: 'linkListMapInfo',
    description: 'DescriptionMapInfo',
    link: `${url}/mapInfo`,
    permission: 'tools.mapInformation',
  },
  // END --update menu tool wireframe 4.0 - 2023/10/10

];

const useStyles = makeStyles((theme) => ({
  card: {
    height: '200px',
    borderRadius: '20px',
    backgroundColor: '#27313b',
    color: '#FFFFFF',
    [theme.breakpoints.down('md')]: {
      height: '150px',
    },
  },
  title: {
    height: '65px',
    fontSize: '24px',
    margin: '5px 15px',
    [theme.breakpoints.down('md')]: {
      height: '35px',
      fontSize: '12px',
      margin: '1px 3px',
    },
  },
  description: {
    margin: '12px',
    fontSize: '16px',
    [theme.breakpoints.down('md')]: {
      margin: '5px',
      fontSize: '10px',
    },
  },
}));

const Tools = () => {
  const classes = useStyles();
  const t = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <div style={matches ? {} : { margin: '4rem' }}>
      <Grid container spacing={{ xs: 4, md: 8 }} columns={{ xs: 8, sm: 8, md: 8, lg: 12, xl: 12 }}>
        {menuNavigate.map((item, index) => hasPermission(item?.permission) && (
          // eslint-disable-next-line react/no-array-index-key
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4} key={index}>
            <CardActionArea
              className={classes.card}
              onClick={() => navigate(item.link)}
            >
              <Card raised className={classes.card}>
                <div style={{ margin: '5px' }}>
                  <Typography className={classes.title}>{t(item.title)}</Typography>
                  <Divider sx={{ borderColor: 'white' }} />
                  <Typography className={classes.description}>{t(item.description)}</Typography>
                </div>
              </Card>
            </CardActionArea>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Tools;
