/* eslint-disable no-undef */
import React, {
  useState, useCallback, useEffect, useRef,
} from 'react';
import { makeStyles } from '@mui/styles';
import {
  Box, Button, Pagination, TablePagination,
} from '@mui/material';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import moment from 'moment';
import { useTranslation } from '../../common/components/LocalizationProvider';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import Table from '../components/Table';
import Header from '../tools/components/Header';
import { convertWidth, linkValueFormatter } from '../common/utils';

const linkButton = (data) => {
  const linkGoogle = ` http://maps.google.com/maps?q=${data.latitude},${data.longitude}`;
  return (
    <a href={linkGoogle} target="_blank" rel="noopener noreferrer">
      {linkGoogle}
    </a>
  );
};

const useStyles = makeStyles(() => ({
  configTable: {
    height: '800px',
  },
  csvBtn: {
    display: 'inline-flex',
    color: 'black',
    textDecoration: 'none',
    backgroundColor: '#e0e0e0',
    width: '90px',
    height: '40px',
    borderRadius: '5px',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    marginRight: '1rem',
    '&:hover': {
      backgroundColor: '#e0e0e0',
    },
  },
  boxConfig: {
    margin: '0 2rem',
  },
  pagination: {
    paddingTop: '10px',
    '& .MuiPaginationItem-root': {
      '&.Mui-selected': {
        backgroundColor: '#f47024',
      },
    },
  },
}));

const HistoryTable = () => {
  const t = useTranslation();
  const classes = useStyles();
  const tableRef = useRef();

  const [rowData, setRowData] = useState([]);
  const [linkData, setLinkData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(50);

  const count = Math.ceil(total / limit);

  const url = window.location.href;
  const id = url.match(/\/(\d+)$/)[1];

  const formatDate = (date) => {
    const formattedDate = moment(date).local().format('YYYY/MM/DD');
    return formattedDate;
  };

  const formatTime = (date) => {
    const formattedDate = moment(date).local().format('HH:mm:ss');
    return formattedDate;
  };

  const columPercent = {
    id: 8,
    name: 13,
    date: 10,
    time: 10,
    latitude: 10,
    longitude: 10,
    link: 41.4,
  };

  const getData = async (limit, offset) => {
    const response = await fetch(
      `/api/admin/locations/list?userId=${id}&limit=${limit}&offset=${offset}`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      },
    );
    if (response.ok) {
      const historyData = await response.json();
      const transformHistoryData = historyData.data?.map((data) => ({
        ...data,
        time: data.fixtime,
      }));
      setRowData(transformHistoryData);
      setTotal(historyData.total);
    } else {
      throw Error(await response.text());
    }
  };

  useEffect(() => {
    getData(limit, offset);
  }, []);

  const columnDefs = [
    {
      headerName: t('userId'),
      field: 'userid',
      filter: false,
      width: convertWidth(columPercent.id),
    },
    {
      headerName: t('nickname'),
      field: 'name',
      filter: false,
      width: convertWidth(columPercent.name),
    },
    {
      headerName: 'DATE',
      cellStyle: { textAlign: 'center' },
      field: 'fixtime',
      filter: false,
      valueFormatter: (params) => formatDate(params.value),
      width: convertWidth(columPercent.date),
    },
    {
      headerName: 'TIME',
      field: 'time',
      cellStyle: { textAlign: 'center' },
      filter: false,
      valueFormatter: (params) => formatTime(params.value),
      width: convertWidth(columPercent.time),
    },
    {
      headerName: 'LATITUDE',
      field: 'latitude',
      filter: false,
      width: convertWidth(columPercent.latitude),
    },
    {
      headerName: 'LONGITUDE',
      field: 'longitude',
      filter: false,
      width: convertWidth(columPercent.longitude),
    },
    {
      headerName: 'LINK',
      field: 'link',
      filter: false,
      cellStyle: { textAlign: 'center' },
      cellRenderer: (params) => {
        setLinkData(params.data);
        return linkButton(params.data);
      },
      width: convertWidth(columPercent.link),
    },
  ];

  const onBtnExport = useCallback(() => {
    const columnDefs = tableRef.current.api.getColumnDefs();
    const columnKeys = columnDefs.map((columnDef) => columnDef.colId);

    const params = {
      columnKeys,
    };

    const linkColumnConfig = {
      field: 'link',
      valueFormatter: (params) => linkValueFormatter(params.node.data),
    };

    const dateColumnConfig = {
      field: 'fixtime',
      valueFormatter: (params) => formatDate(params.value),
    };

    const timeColumnConfig = {
      field: 'time',
      valueFormatter: (params) => formatTime(params.value),
    };

    tableRef.current.api.exportDataAsCsv({
      processCellCallback: (params) => {
        const column = params.column.getColDef();
        if (column.field === 'link') {
          return linkColumnConfig.valueFormatter(params);
        }

        if (column.field === 'fixtime') {
          return dateColumnConfig.valueFormatter(params);
        }

        if (column.field === 'time') {
          return timeColumnConfig.valueFormatter(params);
        }

        return params.value;
      },
      fileName: `TREMIL-History-of-userId${id}.csv`,
      ...params,
    });
  }, [linkData]);

  const handlePageChange = (e, page) => {
    setCurrentPage(page);
    const offsetValue = page * limit - limit;
    setOffset(offsetValue);
    setLimit(limit);
    getData(limit, offsetValue);
  };

  const dataTable = {
    tableRef,
    autoResize: 1440,
    listData: rowData,
    floatingFilterOff: true,
    customColumnDefs: columnDefs,
    className: classes.configTable,
    childrenTop: (
      <div style={{ marginBottom: '1rem' }}>
        <Button onClick={onBtnExport} className={classes.csvBtn}>
          <InsertDriveFileIcon
            style={{ paddingRight: '5px', fontSize: '30px' }}
          />
          <div>CSV</div>
        </Button>
      </div>
    ),
  };

  return (
    <>
      <Header noDescription link={-1} />
      <Box className={classes.boxConfig}>
        <Table {...dataTable} />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: '#f1f0f0',
          }}
        >
          <Pagination
            className={classes.pagination}
            size="small"
            count={count}
            onChange={handlePageChange}
            showFirstButton
            showLastButton
          />
          <TablePagination
            size="small"
            component="div"
            count={total}
            rowsPerPage={limit}
            page={currentPage - 1}
            onPageChange={() => {}}
            rowsPerPageOptions={[]}
            nextIconButtonProps={{ style: { display: 'none' } }}
            backIconButtonProps={{ style: { display: 'none' } }}
          />
        </div>
      </Box>
    </>
  );
};

export default HistoryTable;
